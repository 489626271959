import React from 'react'
import ReactGoogleSlides from 'react-google-slides';
import { useLocation } from 'react-router-dom';
import BreadCrumb from '../../../../UI/BreadCrumb';

export const DataInterpretation = () => {
    const location= useLocation()?.pathname;

    const linkPpt2 =
        'https://docs.google.com/presentation/d/1zCFGeqc_DMLZbqg0g6AacBZiFNNoalnp/edit?usp=sharing';

        const crumbs =  location=='/partners/data-interpretation'?[
            { path: '/home', label: 'MAZ Partners' },
            { path: '/partners/partner-profile', label: 'Partner profile' },
            { label: 'Data Interpretation ' },
        ]:[
            { path: '/home', label: 'MAZ Partners' },
            { path: '/profile/partner-profile', label: 'Partner profile' },
            { label: 'Data Interpretation ' },
        ];
    return (
        <div className='main-content main-profile-overview' id="top">
            <div className="container-fluid" >
                <div className="col-12">
                    <BreadCrumb {...{ crumbs }} />
                </div>
                <div className="section-title text-bold fs-4 mb-4">
                    Data Interpretation
                </div>
                <div className='mt-5 mt-5 corporate-slides'>
                    <ReactGoogleSlides slidesLink={linkPpt2} position={1} showControls />
                </div>
            </div>
        </div>
    )
}
