import React, { ReactElement } from 'react';

import CommentCard from '../CommentCard';

type CommentListProps = {
  commentsToShow: // | {
  //     date: string;
  //     commentElement: string;
  //     userName: string;
  //     isLarge: boolean;
  //   }[]
  never[];
};

const CommentList = ({ commentsToShow }: CommentListProps) => {
  return (
    <>
      {commentsToShow
        .reduce((acc: any[], cur: any): ReactElement[] | [] => {
          const pushCur = () =>
            acc.push([
              <CommentCard
                imageUrl={cur.userImage}
                partnerName={cur.userName}
                datetime={cur.date}
                comment={cur.commentElement}
                grow={cur.isLarge}
                key={`comment-${cur.userName}-${cur.date}-${cur.commentElement}`}
              />,
            ]);

          if (!cur.isLarge && acc.length !== 0) {
            let pushed = false;

            for (const element of acc)
              if (element.length < 2 && !element[0].props.grow) {
                element.push(
                  <CommentCard
                    imageUrl={cur.userImage}
                    partnerName={cur.userName}
                    datetime={cur.date}
                    comment={cur.commentElement}
                    grow={false}
                    key={`comment-${cur.userName}-${cur.date}-${
                      cur.commentElement
                    }-${Math.random()}`}
                  />,
                );
                pushed = true;

                break;
              }

            if (!pushed) pushCur();
          } else pushCur();

          return acc;
        }, [])
        .map((elements: any) => (
          <div
            key={`comment-card-${Math.random()}`}
            className='col d-flex flex-column justify-content-between gap-3 comments-section mb-3 tw w-auto'>
            {elements}
          </div>
        ))}
    </>
  );
};

export default CommentList;
