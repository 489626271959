import React from 'react'

export const Purposes
 = () => {
  return (
    <div>Purposes of a Partners

    </div>
  )
}
