import React from 'react';

import { createPortal } from 'react-dom';

import Backdrop from '../Backdrop';
import Overlay from './Overlay';

import './InformationModal.scss';

type InformationModalProps = {
  onConfirm: () => void;
  title: string;
  message: string;
  imgSrc: string | JSX.Element;
  children?: JSX.Element;
  cardFooter?: JSX.Element;
  onBackdropClick?: () => void;
  onCancel?: () => void;
};

const InformationModal = ({
  onBackdropClick,
  onConfirm,
  message,
  title,
  children,
  imgSrc,
  cardFooter,
}: InformationModalProps) => {
  return (
    <>
      {createPortal(
        <Backdrop onClick={onBackdropClick} />,
        document.getElementById('terms-&-conditions-backdrop')!,
      )}
      {createPortal(
        <Overlay {...{ title, onConfirm, imgSrc, cardFooter }}>
          {children || <p className='card-message'>{message}</p>}
        </Overlay>,
        document.getElementById('terms-&-conditions')!,
      )}
    </>
  );
};

export default InformationModal;
