
import React, { Component, useState } from 'react'
import { Link } from 'react-router-dom';
import { domain_page, getDomain } from '../../../modules/settings/domain';
import { LPC_Component_GetReady } from './GetReady/lpc';

export default class LPC_Wrapper extends Component {
 
  
  constructor(props:any) {
    super(props);
    this.state = {date: new Date()};
  }
  changeIconsDefault(){
    let domainData = getDomain(window.location.href + '');
    let domain=domain_page(window.location.href + '');
    document.title = domainData.name || '';
    const favicon: any = this.getFaviconEl('favicon'); // Accessing favicon element appName
    favicon.href =`../../../main-maz/favicon.png`;
    const favicon1: any = this.getFaviconEl('apple'); // Accessing favicon element
    favicon1.href =`../../../main-maz/favicon.png`;
    const favicon2: any = this.getFaviconEl('maskicon'); // Accessing favicon element
    favicon2.href =`../../../main-maz/favicon.png`;
  }
  changeIconsLPC(){
    let domainData = getDomain(window.location.href + '');
    let domain=domain_page(window.location.href + '');
    document.title = domainData.name || '';
    const favicon: any = this.getFaviconEl('favicon'); // Accessing favicon element appName
    favicon.href =`../../resources/menu_lateral/lpc.svg`;
    const favicon1: any = this.getFaviconEl('apple'); // Accessing favicon element
    favicon1.href =`../../resources/menu_lateral/lpc.svg`;
    const favicon2: any = this.getFaviconEl('maskicon'); // Accessing favicon element
    favicon2.href =`../../resources/menu_lateral/lpc.svg`;
   
  }
   getFaviconEl(id: string) {
    return document.getElementById(id);
  }
  componentDidMount() {
   this.changeIconsLPC();
  //  document.documentElement.style.setProperty('--dark-color', '#000000');
  // document.documentElement.style.setProperty('--gold-color', '#f5e003');
  //   document.documentElement.style.setProperty('--text-color', '#FFFFFF');
   

  }

  componentWillUnmount() {
   this.changeIconsDefault();
  //  document.documentElement.style.setProperty('--gold-color', '#f5e003');
  //  document.documentElement.style.setProperty('--dark-color', '#000000');
  //  document.documentElement.style.setProperty('--text-color', '#ffffff');


  }

  render() {
    return (
      <div>
        <LPC_Component_GetReady></LPC_Component_GetReady>
      </div>
    );
  }
}

