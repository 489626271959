import React, { useEffect, useReducer } from 'react';
import BreadCrumb from '../../../UI/BreadCrumb';
import { useParams } from 'react-router-dom';
import { useGetEventSquadsQuery } from '../../../../services/api/api-socios-maz';
import { getDate } from '../../../../tools/getShortMonthDayDate';
import {
  DetailCases,
  detailInitialState,
  detailReducer,
} from './detailReducer';
import { GetBodyInnerHTML } from '../../../Layouts/default-section/getDefaultElements';
import DetailCard from './DetailCard';

import './Detail.scss';

export const CalendarDetail = () => {
  const { detailId = '' } = useParams();

  const [state, dispatch] = useReducer(detailReducer, detailInitialState);

  const crumbs = [
    { path: '/home', label: 'MAZ Partners' },
    { path: '/culture-squads', label: 'Culture Squads' },
    { path: '/calendar', label: 'Calendar' },
    { label: 'Event Detail' },
  ];

  const event = useGetEventSquadsQuery(detailId).data;

  useEffect(() => {
    if (event) {
      const {
        data: [{ attributes = {} }],
      } = event;

      const getTime = (time: number) => {
        const timeInit = new Date(time * 1000);
        const hour = timeInit.getHours();
        const minutes = timeInit.getMinutes();

        const padDigits = (n: number) => n.toString().padStart(2, '0');

        return `${padDigits(hour)}:${padDigits(minutes)}`;
      };
      const slashDate = (date: string) => date?.replace(/-/g, '/');

      const startDate = attributes.field_fecha_inicial;
      const endDate = attributes.field_fecha_final;
      const startTime = attributes?.field_hora_inicial;
      const endTime = attributes?.field_hora_end;
      const dates = [
        {
          date: getDate(startDate),
          time: getTime(startTime),
        },
      ];

      if (endDate)
        if (endDate !== startDate)
          dates.push({
            date: getDate(endDate),
            time: getTime(endTime),
          });
        else {
          const sameDateIdx = dates.findIndex(
            ({ date }) => date === getDate(endDate),
          );
          const t = dates[sameDateIdx].time;

          dates[sameDateIdx].time = `${t} to ${getTime(endTime)}`;
        }

      dispatch({
        type: DetailCases.SET_INITIAL_INFO,
        payload: {
          title: attributes?.title,
          description: attributes?.body?.value,
          dates,
          cardContent: {
            eventInfo: [
              {
                label: 'Location',
                content: attributes.field_ubicacion,
                icon: '../../../../resources/archetype/icons/Location.svg',
              },
              {
                label: 'Date',
                content: !endDate
                  ? slashDate(startDate)
                  : `${slashDate(startDate)} to ${slashDate(endDate)}`,
                icon: '../../../../resources/archetype/icons/Date.svg',
              },
              {
                label: 'Schedule',
                content: !endTime
                  ? getTime(startTime)
                  : `${getTime(startTime)} to ${getTime(endTime)}`,
                icon: '../../../../resources/archetype/icons/Schedule.svg',
              },
              {
                label: attributes.field_titulo_disponibilidad,
                content: attributes.field_disponibilidad,
                icon: '../../../../resources/archetype/icons/Availability.svg',
              },
            ],
            buttonInfo: {
              label: attributes.field_link?.title,
              uri: attributes.field_link?.uri,
            },
          },
        },
      });
    }
  }, [event]);

  return (
    <div className='main-content'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <BreadCrumb {...{ crumbs }} />
          </div>
        </div>
      </div>
      <div className='banner-calendar'>
        <div className='img-banner'>
          <img
            src='../../../../resources/banner-calendar.jpg'
            className='img-fluid'
            alt='banner'
          />
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-8 order-2 order-md-1 mb-5'>
            <div className='mt-5'>
              <h3 className='section-title text-start'>{state.title}</h3>
            </div>
            <div className='text-center content-event-description'>
              <GetBodyInnerHTML content={state.description} />
            </div>
          </div>
          <div className='col-md-4 order-1 order-md-2'>
            <DetailCard {...state.cardContent} />
          </div>
        </div>
      </div>
    </div>
  );
};
