import React, { useEffect, useReducer, useState } from 'react';

import Percentages, { Help } from './Percentages';

import { initialState, reducer } from './calculatorReducer';

import './Calculator.scss';
import { FullCash } from './FullCash';
import { MixBonus } from './MixBonus';
import Ammounts from './Ammounts';
import Shares from './Shares';
import { store } from '../../../../../modules/appState/store';
import TotalValue from './TotalValue';
import InfoSection from './InfoSection';

import { ReactComponent as BandLogo } from '../../../../../assets/AboutMeAtABI/STI/BandCalculator.svg';

const Calculator = (props: any) => {
  const { dataCalculator, band } = props;
  const storeApp = store;
  const [bonus, setBonus] = useState({
    bonus: band,
    currency: '-',
    tas: '0',
  });
  const [state, dispatch] = useReducer(reducer, initialState);
  let dollarUSLocale = Intl.NumberFormat('de-DE');
  storeApp.subscribe(() => {
    // setUser2({ image: storeApp.getState().AppReducer })
    setBonus({
      bonus: storeApp.getState().AppReducer?.bonus?.bonus,
      currency: storeApp.getState().AppReducer?.bonus?.currency,
      tas: storeApp.getState().AppReducer?.bonus?.tas,
    });
  });
  // const [dataUser, setDataUser] = useState({
  //   band: dataCalculator?.band || '',
  //   band_multiplier: dataCalculator?.band_multiplier || '',
  //   currency: dataCalculator?.currency,
  //   entity_target_range: dataCalculator?.entity_target_range || [],
  //   formula_achievement: dataCalculator?.formula_achievement || '',
  //   formula_bonus: dataCalculator?.formula_bonus || '',
  //   incentive_range: dataCalculator?.incentive_range || [],
  //   individual_range: dataCalculator?.individual_range || [],
  //   reference_salary: dataCalculator?.reference_salary || '',
  //   sop_range: dataCalculator?.sop_range || []
  // });

  const percentagesClassName = `percent-values ${
    !state.showPercentages && 'd-none'
  }`;
  const receiveButtonClassName = `btn calculator-button receive-button ${
    !state.showBonuses && 'calculator-button-float'
  }`;
  const bonusesClassName = `bonus-calculator-box ${
    !state.showBonuses ? 'd-none' : ''
  }`;
  const bonusActiveClassName = (id: number) =>
    `col bonus-choice ${id === state.activePos && 'is-active'}`;

  const onCalculateScoreClick = () => {
    dispatch({ type: 'SHOW_PERCENTAGES' });
  };
  const onReceiveClick = () => {
    dispatch({ type: 'SHOW_RECEIVE' });
  };
  const onBonusClick = (bonus: string) => {
    dispatch({ type: 'SET_BONUS', payload: bonus });
  };
  const onEquateButtonClick = () => {
    window.open(
      'https://www.equateplus.com/EquatePlusParticipant/?login&csrfpId=MLjMrWaQXDHdN8tVomY8EADv',
      '_blank',
    );
  };

  return (
    <>
      <div className='calc-header w-100 ps-4 pe-3 py-2 d-flex justify-content-between mb-2'>
        <div className='d-flex gap-2'>
          <BandLogo />
          <p className='mb-0 align-self-center fw-bold'>Band</p>
        </div>
        <div className='d-flex gap-4 superior-band'>
          <p className='fs-5'>{dataCalculator?.band}</p>
          <div className='mb-auto'>
            <Help
              id='btn-1'
              text='Is a range of salaries for each level inside ABI.'
              title='Band'
            />
          </div>
        </div>
      </div>
      <div className='px-3 py-2'>
        <Ammounts
          mrs={dataCalculator?.reference_salary}
          jobBand={dataCalculator?.band_multiplier}
          currency={dataCalculator?.currency}
        />
        <InfoSection
          label='Total Achievement Score'
          value={`${parseFloat(bonus?.tas).toFixed(0)}%`}
          descriptionMessage='Total Achievement Score description pendient'
          descriptionTitle='Total Achievement Score'
          hasInferiorDivision={false}
        />
        <button
          className='btn calculator-button'
          onClick={onCalculateScoreClick}>
          Calculate Score
        </button>
        <div className={percentagesClassName}>
          <Percentages dataCalculator={dataCalculator} />
        </div>
        <TotalValue
          label='Gross STI Bonus'
          amount={dollarUSLocale.format(bonus?.bonus)?.split(',')[0] || '0.0'}
          currency={
            dataCalculator?.currency?.toString()?.toUpperCase() || '- -'
          }
          descriptionMessage='Gross STI Bonus description in course.'
          descriptionTitle='Gross STI Bonus'
        />
        <div className='d-flex justify-content-center mt-4'>
          <button className={receiveButtonClassName} onClick={onReceiveClick}>
            Receive my bonus
          </button>
        </div>
        <div className={bonusesClassName}>
          <div className='row mx-2 gap-2 mt-4'>
            <div
              className={bonusActiveClassName(1)}
              onClick={() => onBonusClick('cash')}>
              <span>Full Cash</span>
            </div>
            <div
              className={bonusActiveClassName(2)}
              onClick={() => onBonusClick('mix')}>
              <span>Mix Cash/Shares</span>
            </div>
            <div
              className={bonusActiveClassName(3)}
              onClick={() => onBonusClick('shares')}>
              <span>Full Shares</span>
            </div>
          </div>
          {(state.activePos === 1 && <FullCash />) ||
            (state.activePos === 2 && <MixBonus />) ||
            (state.activePos === 3 && <Shares />) ||
            null}
          {state.activePos > 1 && (
            <button
              className='btn calculator-button-float calculator-button'
              onClick={onEquateButtonClick}>
              Go to EquatePlus
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Calculator;
