import React, { useEffect, useState } from 'react';
import { useGetSchedulePDFQuery, useGetScheduleQuery } from '../../../../../../services/api/api-socios-maz';
import { activity, summary, event } from '../../../../../../models/lpc.model';
import { GetBodyInnerHTML } from '../../../../../Layouts/default-section/getDefaultElements';
import LoadSpinner from '../../../../../UI/LoadSpinner/LoadSpinner';
import { getEventData } from './getElementsSchedule';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import UbicacionIcono from './Location.svg'
import './Schedule.scss';
import BreadCrumb from '../../../../../UI/BreadCrumb';
import { useNavigate } from 'react-router';
import { capitalizeFirstLetter } from '../../../../../../services/services';

export default function Schedule() {
  const [index, setIndex] = useState(0);
  const schedule = useGetScheduleQuery('11')?.data;
  const schedulePDF = useGetSchedulePDFQuery('')?.data;
  const programaciónCompleta: event[] = getEventData(schedule);
  console.log("Programación completa:  ", programaciónCompleta)

  function handleEvent(day: any) {
    setIndex(day);
  }

  return (
    <div className='main-content lpc-bg pb-5'>
      <div className='container-fluid'>
        <ScheduleRender
          programaciónCompleta={programaciónCompleta} schedulePDF={schedulePDF}></ScheduleRender>
      </div>
    </div>
  );
}

export const ScheduleRender = (props: any) => {
  const [indexState, setIndex] = useState(0);

  const { programaciónCompleta, schedulePDF } = props;
  const navigate = useNavigate();

  const crumbs = [
    { path: '/home', label: 'MAZ Partners' },
    { path: '/lpc', label: 'LPC' },
    { label: 'Schedule' },
  ];

  function handleEvent(day: any) {
    setIndex(day);
  }

  const onDownloadButtonClick = (name: string, url: string) => {
    saveAs(url, name);
  };
  const downloadDocument = (url: string) => {
    if (localStorage.getItem('mobile') === '1')
      navigate(`/download-pdf-link?${url}`);
    else window.open(url);
  };
  function focusInput() {
    document.getElementById('btn-date-schedule')?.focus();
  }

  return (
    <div className=''>
      <BreadCrumb {...{ crumbs }} />
      <h1 className='section-title'>Schedule</h1>
      <div className=' mx-auto text-center mt-5 mw-200'>
        <div className="d-flex justify-content-center amplify-bar">
          <div className='justify-content-center days-row  '>
            {programaciónCompleta?.map(
              (programación: event, indexProg: number) => {
                let date = new Date(programación?.date || '');
                date.setDate(date.getDate() + 1);
                return (
                  <div
                    className='buttonCategory'
                    key={indexProg}>
                    <button
                      id='btn-date-schedule'
                      className={
                        indexState == indexProg
                          ? 'btn  btn-outline-hover days-render'
                          : 'btn btn-not-selected '
                      }
                      onClick={() => {
                        handleEvent(indexProg);
                      }}>

                      <div className="day-name"> {date?.toLocaleDateString(['en-GB'], {
                        //Typescript ways of adding the type
                        weekday: 'short',

                      })}</div>
                      <div className="day-number"> {date?.toLocaleDateString(['en-GB'], {
                        //Typescript ways of adding the type
                        day: 'numeric',
                      }).toString().padStart(2, '0')}</div>
                    </button>
                  </div>
                );
              },
            )}
          </div>
        </div>

        {/* {programaciónCompleta?.map((programación: event, index: number) => {
            const { pdf } = programación;

            if (index == indexState && (pdf || programación?.summary?.length > 0)) {
              return (
                <div key={Math.random()}>
                  {pdf && (
                    <button
                      className='mt-5 px-3 py-2 mx-auto download-button d-flex align-items-center'
                      onClick={() =>
                        onDownloadButtonClick(pdf.fileName, pdf.url)
                      }>
                      <i className='bi bi-file-earmark-arrow-down fs-4 me-2' />
                      Download the letter here
                    </button>
                  )}
                  {
                    
                  }
                  <div className='row summary-section mt-5'>
                    {programación?.summary?.length > 0 && <Summary programación={programación} key={index}></Summary>}
                  </div>
                </div>
              );
            }
          })} */}
        <div className='mt-5'>
          {/* <h1 className='mt-5 mb-5'>Activity details</h1> */}
          {programaciónCompleta?.map((programación: event, index: number) => {
            if (index == indexState)
              return (
                <Activities
                  programación={programación}
                  key={index}></Activities>
              );
          })}
        </div>
        <div className="mt-1">
          {schedulePDF?.fileRouthe && (
            <button
              className='mb-3 px-3 py-2 mx-auto btn-primary btn-reports btn-di d-flex align-items-center active-btn'
              onClick={() =>
                downloadDocument(schedulePDF.fileRouthe)
              }>
              <i className='bi bi-file-earmark-arrow-down fs-4 me-2' />
              Download the Schedule
            </button>

          )}
        </div>

      </div>
    </div>
  );
};

export const Summary = (props: any) => {
  const { programación } = props;
  const { t, i18n } = useTranslation('global');
  useEffect(() => { }, [t]);
  useEffect(() => {
  }, [programación])

  return (
    <div>
      <h3 className='section-title mt-2'>Summary of the day</h3>
      <div className=''>
        <div className='row'>
          {programación?.summary?.map((sum: summary, e: number) => {
            return (
              <div className='col-md-4 mt-3 pt-5' key={e}>
                <div className='section-content'>
                  <div className='icon-top'>
                    <img
                      src={`../../../../resources/lpc/event/active-morning.svg`}
                      width='50'
                    />
                  </div>
                  <h5>{sum?.title || ''}</h5>
                  <div className='pb-2' key={e}>
                    <GetBodyInnerHTML
                      content={sum?.description}></GetBodyInnerHTML>
                  </div>
                  {sum?.category == 'required' && (
                    <div className='section-content-bottom'>
                      <div className='row align-items-center'>
                        <div className='col-auto'>
                          <div className='cat-sum-icon logo required' />
                        </div>
                        <div className='col text-start'>
                          <p>{t('lpc-summary.category-required')}</p>
                          <p>{t('lpc-summary.category-required')}</p>
                        </div>
                      </div>
                    </div>
                  )}
                  {sum?.category != 'required' && (
                    <div className='section-content-bottom'>
                      <div className='row align-items-center'>
                        <div className='col-auto'>
                          <div className='cat-sum-icon logo voluntary' />
                        </div>
                        <div className='col text-start'>
                          <p>{t('lpc-summary.category-voluntary')}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const Activities = (props: any) => {
  const { programación } = props;
  return (
    <div className='mt-5 pb-5 container-fluid'>
      {programación?.activities?.map((a: any, index: number) => {
        return (
          <div className="center-schedule">
            <div className='activity-box activity-web' key={index}>
              <div className='activity-time timeflex'>
                <div className="activity-time-desc text-center ">
                  {a?.hour?.hours ? `${a?.hour?.hours}:${a?.hour?.minutes}` : ''}
                  {a?.hourEnd && (
                    <div className="activity-time-desc">
                      <p className='small-item '>to</p>
                      {`${a?.hourEnd?.hours}:${a?.hourEnd?.minutes}`}
                    </div>
                  )}

                </div>

              </div>
              <div className='activity-description'>
                <div className='d-table align-items-center mb-2 w-100'>
                  {/* <div className=''>
                   {a?.category != 'required' && (
                    <div className='cat-sum-icon logo voluntary' />
                  )}
                  {a?.category == 'required' && (
                    <div className='cat-sum-icon logo required' />
                  )}
                  </div> */}
                  <div className='tw tc ps-3'>
                    <div className='title-activity'>{a?.description}</div>
                    <div className='items-description'>
                      <div className="justify-content-start">
                        {a?.dressCode && (
                          <div className="w-100 d-flex ">
                            <div className=" " style={{
                              minWidth: '75px'
                            }}>
                              <p className=''><strong>Dress Code:</strong> </p>
                            </div>
                            <div className=" text-left ps-1" >
                              <GetBodyInnerHTML content={' ' + a?.dressCode} />
                            </div>
                          </div>
                        )}
                        {a?.category && (
                          <div className="w-100 d-flex  ">
                            <div className="">
                              <p className=''><strong>Attendance:</strong> </p>
                            </div>
                            <div className="ps-1">
                              <p> {capitalizeFirstLetter(a?.category)}</p>
                            </div>
                          </div>
                        )}
                        {a?.comments && (
                          <div className="w-100 row ">

                            <div className="">
                              <p> {a?.comments} </p>
                            </div>
                          </div>
                        )}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className='activity-site'>
                <div className="w-100 row ms-1">
                  <div className="col col-1 pe-0">
                    <img className="img-location" src={UbicacionIcono} alt="Icono de Ubicación" />
                  </div>
                  <div className="col col-10 ps-1 " style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                    <p > {a?.site}</p>
                  </div>
                </div>
              </div>

            </div>
            {/* Responsive Version */}
            <div className='activity-box activity-responsive' key={index}>
              <div className="activity-time-desc res-activity-time text-left d-flex">
                {`${a?.hour?.hours}:${a?.hour?.minutes}  `}
                {a?.hourEnd && (
                  <div className="activity-time-desc fw-7">
                    <small className='ps-1'> to </small>
                    {`  ${a?.hourEnd?.hours}:${a?.hourEnd?.minutes}`}
                  </div>
                )}

              </div>
              <div className="res-description-activity">
                <div className='tw tc content-description pb-3'>
                  <div className='res-title-activity text-left'>{a?.description}</div>
                  {a?.dressCode && (<div className='res-item d-flex'>
                    <p><strong>Dress Code:</strong></p>
                    <div className="ps-1">
                      <GetBodyInnerHTML content={a?.dressCode} />
                    </div>

                  </div>)}
                  {a?.category && (<div className='res-item  d-flex'>

                    <p><strong>Attendance:</strong></p>
                    <p className='ps-1'> {a?.category}</p>
                  </div>)}
                  {a?.comments && (
                    <div className="res-item ">
                      <p>{a?.comments}</p>
                    </div>
                  )}
                </div>
                <div className='pt-3 res-site'>
                  <p className='w-100 '><img className="img-location pe-1" src={UbicacionIcono} alt="Icono de Ubicación" /><strong>{a?.site}</strong></p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
