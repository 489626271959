import React from 'react';
import classnames from 'classnames';
import BreadCrumb from '../../UI/BreadCrumb';
interface filterType {
  name: string;
  status: boolean;
}
let imgs: any = [];

let filters: filterType[] = [];
interface IProps {
  onClickAll?: () => void;
  all?: boolean;
  onClick?: (e: any) => void;
  filters?: { name: string; status: boolean }[];

  imgs?: {
    author: string;
    tag: string;
    src: string;
  }[];
}

const Filters = ({ onClickAll, all, onClick, filters }: IProps) => (
  <form className='items_list list-partners'>
    <ul className='items'>
      <li onClick={onClickAll}>
        <input type='checkbox' checked={all} />
        <label htmlFor='all'>All</label>
      </li>
      {filters?.map((filter: any, i: number) => (
        <li key={i} data-index={i} onClick={onClick}>
          <input id={filter.name} type='checkbox' checked={filter.status} />
          <label htmlFor={filter.name}>{filter.name}</label>
        </li>
      ))}
    </ul>
  </form>
);

const Cards = ({ imgs }: IProps) => (
  <ul className='items main_logos'>
    {imgs?.map((img: any, i: number) => (
      <li key={i}>
        <figure className='center-figure'>
          <div className='text-sup'>
            {img.text_dis}
            {/* Aqui irian los paises donde está disponible */}
            {/*  <span>
              <img src={img.logo_pais} width={img.width} height=''/>
            </span> */}
          </div>

          <a href={img.href} target='_blank'>
            <img src={img.src} alt={img.author} />
            {/*              <figcaption>
                <div>{img.author} </div>
                <span>{img.tag}</span>
              </figcaption>*/}
          </a>
          <div className='text-inf'>{img.text_info}</div>
        </figure>
      </li>
    ))}
  </ul>
);
type MyProps = {
  // using `interface` is also ok
  imgs: any;
  filters: any[];
};
export class Exhub extends React.Component<MyProps> {
  componentDidMount() {}
  state = {
    imgs,
    filters: this.props.filters,
    all: true,
  };
  componentDidUpdate() {
    filters.forEach((filter) => {
      filter.status = false;
    });
  }
  setFilter = (e: any) => {
    e.preventDefault();
    const { filters, all } = this.state;
    const { index } = e.currentTarget.dataset;
    filters.forEach((filter) => {
      filter.status = false;
    });

    filters[index].status = !filters[index].status;
    this.setState({
      filters,
      all: false,
    });
    this.updateImgs();
  };

  setAll = () => {
    const { filters } = this.state;

    filters.forEach((filter) => {
      filter.status = false;
    });

    this.setState({
      all: true,
      filters,
    });
  };

  updateFilters() {
    const allFiltersTrue = filters.every((filter) => filter.status === true);
    const allFiltersFalse = filters.every((filter) => filter.status === false);

    if (allFiltersTrue || allFiltersFalse) {
      this.setAll();
    } else {
      this.setState({
        all: false,
      });
    }
  }

  updateImgs() {
    const { filters, all } = this.state;
    let newImgs: any[] = [];
    let a = 0;
    imgs = this.props.imgs;
    imgs.forEach((img: any, imgKey: number) => {
      filters.forEach((filter, filterKey) => {
        if (img.tag == filter.name && filter.status == true) {
          newImgs[a] = img;
          a++;
        }
      });
    });

    this.setState({
      imgs: newImgs,
    });
  }

  render() {
    const { filters, all } = this.state;
    const { imgs } = this.props;

    const crumbs = [
      { path: '/home', label: localStorage.getItem('appName') || '' },
      { label: 'EX-HUB' },
    ];

    return (
      <div>
        <div className='main-content'>
          <div className='container-fluid'>
            <div className='row'>
              <BreadCrumb {...{ crumbs }} />
              <div className='col-12'>
                <Filters
                  onClickAll={this.setAll}
                  all={all}
                  onClick={this.setFilter}
                  filters={filters}
                />
                {all ? (
                  <Cards
                    imgs={
                      imgs
                        ? imgs
                        : [
                            {
                              author: '',
                              tag: '',
                              src: '',
                            },
                          ]
                    }
                  />
                ) : (
                  <Cards imgs={this.state.imgs} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
