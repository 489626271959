import React, { useEffect, useReducer } from 'react';
import MultiselectItems from '../../../../UI/Multiselect';

import {
  GalleryFiltersCases,
  GalleryFiltersReducer,
  initialGalleryFiltersState,
} from './GalleryFiltersReducer';
import {
  useGetAllBUsQuery,
  useGetAllTopicResourcesQuery,
} from '../../../../../services/api/api-socios-maz';

import HeartLike from '../../../../UI/HeartLike';

type GalleryFiltersProps = {
  onFiltersChange?: (bu: string, cat: string) => void;
  onFilterByLike?: (like: boolean) => void;
  hasLikes?: boolean;
};

const GalleryFilters = ({
  onFiltersChange,
  onFilterByLike,
}: GalleryFiltersProps) => {
  const [{ all, like, bus, categories }, dispatch] = useReducer(
    GalleryFiltersReducer,
    initialGalleryFiltersState,
  );

  const allBus: any = useGetAllBUsQuery().data;
  const allCategories = useGetAllTopicResourcesQuery().data;

  const onLikeButtonClick = () => {
    dispatch({ type: GalleryFiltersCases.SWITCH_LIKE });
  };
  const setAll = (value: boolean) => {
    dispatch({
      type: GalleryFiltersCases.SET_ALL_VALUE,
      payload: { all: value },
    });
  };
  const onAllButtonClick = () => {
    setAll(true);
  };
  const onSelectChange = (key: 'bus' | 'categories', val: any[]) => {
    dispatch({
      type: GalleryFiltersCases.CHANGE_SELECTORS,
      payload: { key, value: val },
    });
  };

  useEffect(() => {
    if (allBus && allCategories)
      dispatch({
        type: GalleryFiltersCases.SET_DROPDOWN_VALUES,
        payload: {
          allBus: allBus?.data?.map(
            (
              { attributes: { name, drupal_internal__tid: id } } = {
                attributes: { name: '', drupal_internal__tid: 0 },
              },
            ) => ({ label: name, value: id }),
          ),
          allCategories: allCategories?.data?.map(
            (
              { attributes: { name, drupal_internal__tid: id } } = {
                attributes: { name: '', drupal_internal__tid: 0 },
              },
            ) => ({ label: name, value: id }),
          ),
        },
      });
  }, [allBus, allCategories]);
  useEffect(() => {
    const getSelected = (arr: any) =>
      arr
        .filter(({ isSelected = false }) => isSelected)
        .map(({ value = 0 }) => value)
        .join(',');

    if (onFiltersChange)
      if (all) onFiltersChange('', '');
      else onFiltersChange(getSelected(bus), getSelected(categories));
  }, [all, bus, categories, onFiltersChange]);
  useEffect(() => {
    onFilterByLike && onFilterByLike(like);
  }, [like, onFilterByLike]);

  return (
    <div className='row my-3 maz-g-button-section'>
      <div className='col-sm-3 col-md-2'>
        <button
          className={`all-button ${all && 'active'}`}
          onClick={onAllButtonClick}
          disabled={all}>
          All
        </button>
      </div>
      <div className='col-sm-3 col-md-2'>
        <MultiselectItems
          overrideStrings={{ selectSomeItems: 'Topic' }}
          options={categories}
          disableSearch
          valueRenderer={() => 'Topic'}
          onChange={(e) => onSelectChange('categories', e)}
          clearItems={all}
        />
      </div>
      <div className='col-sm-3 col-md-2'>
        <MultiselectItems
          options={bus}
          disableSearch
          valueRenderer={() => 'BU'}
          onChange={(e) => onSelectChange('bus', e)}
          clearItems={all}
        />
      </div>
      <div className='col-sm-3 col-md-6'>
        <button
          className={`d-flex ms-md-auto ${like && 'active'}`}
          onClick={onLikeButtonClick}>
          <span>
            <HeartLike isLiked={like} />
          </span>{' '}
          My Favorites
        </button>
      </div>
    </div>
  );
};

export default GalleryFilters;
