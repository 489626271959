export type CardItemProps = { label: string; content: string; icon: string };

export type CardContentProps = {
  eventInfo: CardItemProps[];
  buttonInfo: { label: string; uri: string };
};

export type DetailState = {
  title: string;
  description: string;
  dates: { date: string; time: string }[];
  cardContent: CardContentProps;
};

export enum DetailCases {
  SET_INITIAL_INFO = 'SET_INITIAL_INFO',
}

export type DetailAction = {
  type: DetailCases;
  payload?: any;
};

export const detailInitialState = {
  title: '',
  description: '',
  dates: [{ date: '', time: '' }],
  cardContent: {
    eventInfo: [{ label: '', content: '', icon: '' }],
    buttonInfo: { label: '', uri: '' },
  },
};

export const detailReducer = (state: DetailState, action: DetailAction) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET_INITIAL_INFO': {
      const { ...props } = payload;

      return { ...state, ...props };
    }

    default:
      return { ...state };
  }
};
