import React, { forwardRef, memo } from 'react';
import { Link } from 'react-router-dom';
import { Tabs } from 'react-tabs';
import { capitalizeFirstLetter } from '../../../../../../services/services';
import { GetBodyInnerHTML } from '../../../../../Layouts/default-section/getDefaultElements';
import { RangePercentage } from '../../../../../UI/Range-percentage';
import { TabsCategory } from '../indexPrint';

import './ProfilePrint.scss';

export const apps = [
  {
    author: 'Macro Entrepreneur Attributes',
    name: 'Macro Entrepreneur Attributes',
    tag: 'Macro Entrepreneur Attributes',
    data: [
      {
        nombreCategoria: 'Risk Tolerance',
        valor: '20',
      },
      {
        nombreCategoria: 'Human centricity',
        valor: '50',
      },
      {
        nombreCategoria: 'Efficiency',
        valor: '80',
      },
      {
        nombreCategoria: 'Inclusion',
        valor: '50',
      },
      {
        nombreCategoria: 'Creativity',
        valor: '90',
      },
      {
        nombreCategoria: 'Agility',
        valor: '60',
      },
    ],
  },
  {
    author: 'Leadership Style',
    name: 'Leadership Style',
    tag: 'Leadership Style',
    data: [
      {
        nombreCategoria: 'Intelectual Curiosity',
        valor: '40',
      },
      {
        nombreCategoria: 'Aesthetic Sensitivy',
        valor: '20',
      },
      {
        nombreCategoria: 'Creative Imagination',
        valor: '50',
      },
      {
        nombreCategoria: 'Extraversion',
        valor: '90',
      },
      {
        nombreCategoria: 'Sociability',
        valor: '10',
      },
      {
        nombreCategoria: 'Assertiveness',
        valor: '60',
      },
    ],
  },
  {
    author: 'Leadership Style 2',
    name: 'Leadership Style 2',
    tag: 'Leadership Style 2',
    data: [
      {
        nombreCategoria: 'Intelectual Curiosity',
        valor: '40',
      },
      {
        nombreCategoria: 'Aesthetic Sensitivy',
        valor: '20',
      },
      {
        nombreCategoria: 'Creative Imagination',
        valor: '50',
      },
      {
        nombreCategoria: 'Extraversion',
        valor: '90',
      },
      {
        nombreCategoria: 'Sociability',
        valor: '10',
      },
      {
        nombreCategoria: 'Assertiveness',
        valor: '60',
      },
    ],
  },
];

export const PartnerProfileRenderPrint = memo(
  forwardRef((props: any, ref: any) => {
    const { idUser, data, archetypes, crumbs, mainArq, secArch, date } = props;
    const arqData = data?.dataArchetypes;

    return (
      <div
        className='pdf-content'
        ref={ref}
        style={{
          display: 'flex',
          gridTemplateRows: '1fr 1fr',
          paddingBottom: 2 + 'rem',
        }}>
        <Tabs>
          <div className='section-gray-partners'>
            <div className='mx-5'>
              <div>
                {/*                      ROW                           */}
                <div
                  className='pt-5'
                  style={{
                    display: 'flex',
                    gridTemplateRows: '1fr 1fr',
                    paddingBottom: 2 + 'rem',
                  }}>
                  {/*                      COL                           */}
                  <div
                    className='col-sm-5 ps-5 '
                    style={{
                      width: 35 + '%',
                      flex: '0 0 auto',
                      float: 'left',
                    }}>
                    <div className='img-perfil-archetype'>
                      <img
                        src={arqData?.archetype?.image || ''}
                        style={{
                          maxWidth: 100 + '%',
                          height: 'auto',
                        }}
                        alt='archetype-img'
                      />
                      <div className='photo-archetype'>
                        <img
                          src={
                            arqData?.userpicture ||
                            '../../../../resources/perfil-default.png'
                          }
                          style={{
                            maxWidth: 100 + '%',
                          }}
                          alt='user-img'
                        />
                      </div>
                    </div>
                  </div>
                  {/*                      COL                           */}
                  <div
                    className=' '
                    style={{
                      width: 65 + '%',
                      flex: '0 0 auto',
                      float: 'left',
                      paddingInline: 2 + 'rem',
                    }}>
                    <div>
                      <h5 className=''>
                        {' '}
                        Your results for: <strong> {` ${date}`}</strong>
                      </h5>
                      <div className='text-content'>
                        <strong>Hi {arqData?.username}</strong>
                      </div>
                      <div className='subtitle-archetype'>
                        {arqData?.archetype?.name || ''}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='  '>
              <div className=' p-generic  mx-5 '>
                <div className=' tw text-justify report-description'>
                  <GetBodyInnerHTML
                    content={arqData?.archetype?.descripcion || ''}
                  />
                </div>

                <div className='text-gold fs-5 text-bold pb-5'>
                  <GetBodyInnerHTML
                    content={arqData?.archetype?.complemento || ''}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='page-break'>
            <div
              className='section-gray-partners py-5 print-margin-top'
              style={{
                maxWidth: 100 + '%',
                pageBreakAfter: 'auto',
                paddingTop: 5 + 'rem',
                marginTop: 0 + 'rem',
                borderRadius: 20 + 'px',
              }}>
              <div
                className='container-fluid '
                style={{
                  pageBreakAfter: 'always',
                }}>
                <div
                  className='row'
                  style={{
                    paddingBottom: 5 + 'rem',
                  }}>
                  <div className='col-12'>
                    <h2 className='text-center mb-4 fs-1'>
                      The composition of this archetype
                    </h2>
                  </div>
                  <div className='col-sm-6 col-md-6 mx-auto mb-4 link-arch'>
                    <Link
                      to={
                        '/profile/partner-profile/archetype-detail?' +
                        mainArq[0]?.tid
                      }>
                      <div className='box-content-barprogress'>
                        <div className='icon-boxtext-partner'>
                          <img
                            src={
                              mainArq[0]?.face ||
                              `../../../../resources/lpc/event/active-morning.svg`
                            }
                            style={{
                              maxWidth: 100 + '%',
                              height: 'auto',
                            }}
                            alt='archetype-icon'
                          />
                        </div>
                        <div className='bar-progress'>
                          <div className='tw mb-1'>
                            {capitalizeFirstLetter(mainArq[0]?.name || '')} -{' '}
                            {parseFloat(mainArq[0]?.value)?.toFixed(0)}%
                          </div>
                          <RangePercentage
                            percentage={parseFloat(mainArq[0]?.value)?.toFixed(
                              2,
                            )}
                          />
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className='col-12'>
                    <div className='row'>
                      {secArch?.map((itemsArq: any, itemsArqIndex: number) => {
                        return (
                          <div
                            className='col-sm-4 col-md-3 link-arch'
                            key={itemsArqIndex}>
                            <Link
                              to={
                                '/profile/partner-profile/archetype-detail?' +
                                itemsArq?.tid
                              }>
                              <div className='box-content-barprogress'>
                                <div className='icon-boxtext-partner'>
                                  <img
                                    src={
                                      itemsArq?.face ||
                                      `../../../../resources/lpc/event/active-morning.svg`
                                    }
                                    style={{
                                      maxWidth: 100 + '%',
                                      height: 'auto',
                                    }}
                                    alt='archetype-icon'
                                  />
                                </div>
                                <div className='my-3 bar-progress '>
                                  <div className='tw'>
                                    {capitalizeFirstLetter(
                                      itemsArq?.name || '',
                                    )}{' '}
                                    - {parseFloat(itemsArq?.value).toFixed(0)}%
                                  </div>
                                  <RangePercentage
                                    percentage={
                                      parseFloat(itemsArq?.value).toFixed(2) ||
                                      '0'
                                    }
                                  />
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className='container-fluid page-break'
            style={{
              paddingTop: 0 + 'rem',
              pageBreakAfter: 'always',
            }}>
            <TabsCategory
              {...{ date }}
              hasFilters={false}
              imgs={apps}
              printFlag={true}
            />
          </div>
        </Tabs>
      </div>
    );
  }),
);
