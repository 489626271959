const longCodeChallenge = 128;
const longState = 40;

const generateRandomString = (length: number) => {
  var text = '';
  var possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

async function generateCodeChallenge2(codeVerifier: string) {
  var digest = await crypto.subtle.digest(
    'SHA-256',
    new TextEncoder().encode(codeVerifier),
  );

  return btoa(String.fromCharCode(...new Uint8Array(digest)))
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
}
export function generateCodeChallenge() {
  let randomCode = generateRandomString(longCodeChallenge);
  localStorage.setItem('code_verifier', randomCode);
  sessionStorage.setItem('code_verifier', randomCode);
  return generateCodeChallenge2(randomCode);
}
export function generateState() {
  let randomState = generateRandomString(longState);
  localStorage.setItem('state', randomState);
  return randomState;
}
