import React from 'react';
import CarouselView from '../../../../UI/Carousel/carousel';

import './CarouselImg.scss';

interface CarouselImgProps {
  content?: {
    altText?: string;
    link?: string;
    src?: any;
  }[];
}

const CarouselImg = ({
  content = [{ altText: '', link: '', src: { src: '' } }],
}: CarouselImgProps) => {
  return (
    <div className='container-fluid'>
      <div className='destination-img-carousel'>
        <CarouselView {...{ content }}></CarouselView>
      </div>
    </div>
  );
};

export default CarouselImg;
