import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router';
import { Link, BrowserRouter as Router } from 'react-router-dom';
import { store as storeApp } from '../../../modules/appState/store';
import { postGeneralPartner } from '../../../services/api/api-socios-maz';
import { PartnerProfile } from '../../pages/profile/Partners-profile/Partner-profile';
import { WhatIsYourPartnerProfile } from '../../pages/profile/Partners-profile/What-is-your-partner-profile';
import { Accordion } from '../../UI/Accordion/Accordion';
import { AnimatedNumbercomponent } from '../../UI/Animated/animated-number';
import { Filter } from './filter/filter';
import './menu-partner.scss';
export const MenuPartnerProfile = (props: any) => {
  const { filters, role, user } = props;
  const newFilters = cleanFilters(filters);
  const navigate = useNavigate();
  const location = useLocation();
  const teamProfilesUrl = `/partners/team-profiles`;

  const [showMyProfile, setShowMyProfile] = useState(false);

  const trigger = () => {
    if (location?.pathname !== '/partners/team-profiles') {
      navigate(teamProfilesUrl);
    }
  };

  const [view, setView] = useState(true);
  const [teams, setTeams] = useState(false);

  const partner = useMemo(async () => {
    const res = await postGeneralPartner(user?.drupal_id);

    return res?.json();
  }, [user?.drupal_id]);

  const onGoToPartnersButtonClick = async () => {
    const { dataArchetypes: archData } = await partner;

    archData?.archetype && archData?.data
      ? setShowMyProfile(true)
      : setShowMyProfile(false);
  };
  useEffect(() => {
    if (user?.drupal_id != '') onGoToPartnersButtonClick();
    if (location?.pathname === '/partners/team-profiles') {
      setTeams(true);
    } else {
      setTeams(false);
    }
  }, [teams, location, user]);
  return (
    <div>
      <div className='main-menu-partner'>
        {/* <Route path="/partners" element={<MenuData />}>
                  <Route path="/partners/profile/what-your-partner-profile" element={<PartnerProfileDirectivo />} />
                  <Route path="/partners/profile/partner-profile" element={<PartnerProfileSuperDirectivo />} />
                </Route> */}
        <div
          className='offcanvas offcanvas-start'
          id='menuPartner'
          aria-labelledby='menuPartnerLabel'>
          <div className='offcanvas-header'>
            <a
              className='button-menu-partner'
              data-bs-toggle='offcanvas'
              href='#menuPartner'
              role='button'
              aria-controls='menuPartner'></a>
          </div>
          <div className='offcanvas-body scroll'>
            <ul>
              <li data-bs-dismiss='offcanvas'>
                <Link to={`/partners/partner-profile`}>Partner Profile</Link>
              </li>
              {showMyProfile && (
                <>
                  <li data-bs-dismiss='offcanvas'>
                    <Link to={`/partners/partner-profile/my-partner-profile`}>
                      My Profile
                    </Link>
                  </li>
                  {/* <li data-bs-dismiss='offcanvas'>
                    <Link to='/partners/binnacle'>Binnacle</Link>
                  </li> */}
                </>
              )}
              <li data-bs-dismiss='offcanvas'>
                <Link to={`/partners/overview`}>Overview</Link>
              </li>
              <li className='item-menu-des'>
                <Link to={teamProfilesUrl}>Team Profiles</Link>
                <div className='first-accordion'>
                  <Accordion title='' trigger={trigger} flag={view}>
                    {teams && (
                      <ul>
                        <li key='Archetype'>
                          <div className='second-accordion'>
                            <Filter
                              title={'Archetype'}
                              filters={newFilters?.archetypes}
                              triggerFilter={() => {
                                setView(false);
                                setTimeout(() => {
                                  setView(true);
                                }, 200);
                              }}
                            />
                          </div>
                        </li>
                        <li key='Area'>
                          <div className='second-accordion'>
                            <Filter
                              title={'Area'}
                              filters={newFilters?.areas}
                              triggerFilter={() => {
                                setView(false);
                                setTimeout(() => {
                                  setView(true);
                                }, 200);
                              }}
                            />
                          </div>
                        </li>
                        <li key='BU'>
                          <div className='second-accordion'>
                            <Filter
                              title={'BU'}
                              filters={newFilters?.bus}
                              triggerFilter={() => {
                                setView(false);
                                setTimeout(() => {
                                  setView(true);
                                }, 200);
                              }}
                            />
                          </div>
                        </li>
                      </ul>
                    )}
                  </Accordion>
                </div>
              </li>
              <li>
                <Link to={`/partners/archetypes-definition`}>
                  Archetypes Definition
                </Link>
              </li>
              <li>
                <Link to={`/partners/data-interpretation`}>
                  Data Interpretation
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
};
export const PartnerProfileDirectivo = () => {
  return (
    <div className='main-content'>
      <div className='container-fluid'>
        <h1>PartnerProfileDirectivo</h1>
      </div>
    </div>
  );
};
export const PartnerProfileSuperDirectivo = () => {
  return (
    <div className='main-content'>
      <div className='container-fluid'>
        <h1>PartnerProfileSuperDirectivo</h1>
      </div>
    </div>
  );
};

export const PartnerProfileDirectivo2 = () => {
  return (
    <div className='main-content'>
      <div className='container-fluid'>
        <h1>DIFERENTE 1</h1>
      </div>
    </div>
  );
};
export const PartnerProfileSuperDirectivo2 = () => {
  return (
    <div className='main-content'>
      <div className='container-fluid'>
        <h1>DIFERENTE 2</h1>
      </div>
    </div>
  );
};
function cleanFilters(filters: any): any {
  //TODO: Va a quedar quemados solo 3 filtros
  let archetypes: any[] = [];
  let areas: any[] = [];
  let bus: any[] = [];
  if (filters && filters?.archetypes && filters?.areas && filters?.bus) {
    filters?.archetypes?.map((ar: any) => {
      archetypes?.push(ar);
    });
    filters?.areas?.map((ar: any) => {
      areas?.push(ar);
    });
    filters?.bus?.map((bu: any) => {
      bus?.push(bu);
    });
  }
  return {
    archetypes: archetypes,
    areas: areas,
    bus: bus,
  };
}
