import React from 'react';

import { Line } from 'react-chartjs-3';

const options = {
  responsive: true,
  borderColor: '#d8d8d8',

  legend: {
    display: false,
    position: 'top',
    labels: {
      fontColor: '#FFFFFF',
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          color: 'rgba(255,255,255,0)',
          zeroLineColor: 'rgba(255,255,255,0)',
        },
        ticks: {
          fontColor: '#fff',
        },
        barThickness: 10,
      },
    ],
    yAxes: [
      {
        gridLines: {
          color: 'rgba(255,255,255,0)',
          zeroLineColor: '#FFFFFF',
        },
        stacked: true,
        ticks: {
          min: 0,
          stepSize: 10,
          fontColor: '#fff',
        },
      },
    ],
  },
};

type UserBarDashboardProps = {
  labels: string[];
  dataArray?: number[];
  hasYGridLines?: boolean;
  datasets?: { [key: string]: any }[];
  fill?: boolean;
  hasLegend?: boolean;
  legendPosition?: 'top' | 'bottom';
  max?: number;
  stepSize?: number;
};

export default function UserBarDashboard(props: UserBarDashboardProps) {
  const {
    labels,
    dataArray,
    hasYGridLines = false,
    datasets,
    fill = false,
    hasLegend = false,
    legendPosition = 'top',
    max,
    stepSize = 20,
  } = props;

  const options = {
    legend: {
      display: hasLegend,
      position: legendPosition,
      labels: {
        usePointStyle: true,
        pointStyle: 'line',
        fontColor: '#fff',
      },
    },
    scales: {
      offset: true,
      xAxes: [
        {
          offset: true,
          gridLines: {
            display: false,
            zeroLineColor: 'transparent',
          },
          ticks: {
            fontColor: '#fff',
          },

          beginAtZero: false,
          categoryPercentage: 0.5,
        },
      ],
      yAxes: [
        {
          ...(hasYGridLines
            ? {
                gridLines: {
                  color: 'white',
                  zeroLineColor: 'white',
                },
              }
            : {
                gridLines: {
                  display: false,
                  color: 'white',
                  zeroLineColor: 'white',
                },
              }),
          ticks: {
            fontColor: '#fff',
            min: 0,
            ...(max && { max }),
            stepSize,
          },
          beginAtZero: false,
        },
      ],
    },
  };

  const data = {
    // labels: ['2019', '2020', '2021'],
    labels: labels,
    datasets: datasets || [
      {
        label: 'Value',
        fill,
        lineTension: 0.1,
        backgroundColor: '#f5e1035a',
        borderColor: '#F5E003',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#F5E003',
        pointBackgroundColor: '#F5E003',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#F5E003',
        pointHoverBorderColor: '#F5E003',
        pointHoverBorderWidth: 2,
        pointRadius: 5,
        pointHitRadius: 10,
        // data: [7.6, 60, 6.5, 10]
        data: dataArray,
      },
    ],
  };

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
}
