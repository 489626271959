import React from 'react';
import { Help } from './Percentages';

type InfoSectionProps = {
  label: string;
  value: string;
  descriptionMessage: string;
  descriptionTitle: string;
  hasInferiorDivision?: boolean;
};

const InfoSection = ({
  label,
  value,
  descriptionMessage,
  descriptionTitle,
  hasInferiorDivision = true,
}: InfoSectionProps) => {
  return (
    <>
      <div className='calculation-info row justify-content-between px-2 border-bottom-0'>
        <div className='col-6'>
          <p className='mb-0'>{label}</p>
        </div>
        <div className='col-6 d-flex calculation-num'>
          <span className='my-auto ms-auto me-2'>{value}</span>
          <div className='my-auto'>
            <Help text={descriptionMessage} title={descriptionTitle} />
          </div>
        </div>
      </div>
      {hasInferiorDivision && <div className='info-division' />}
    </>
  );
};

export default InfoSection;
