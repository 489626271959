import React from 'react';
import { Bar } from 'react-chartjs-3';

type BasicBarChartProps = {
  labels: string[];
  datasets?: {
    [key: string]: string | number | boolean | (string | number | null)[];
  }[];
  chartOptions?: { [key: string]: any };
  label?: string;
  barBackgroundColor?: string | string[];
  barHoverBackgroundColor?: string | string[];
  barHoverBorderColor?: string | string[];
  chartData?: number[];
  min?: number;
  max?: number | null;
  stepSize?: number;
  hasBarValue?: boolean;
  barThickness?: number;
  barPercentage?: number;
  categoryPercentage?: number;
};

const BasicBarChart = ({
  labels,
  datasets,
  chartOptions,
  chartData,
  barBackgroundColor = '#F5E003',
  barHoverBackgroundColor,
  barHoverBorderColor = '#F5E003',
  label = 'Value',
  min = 0,
  max = null,
  stepSize = 1,
  barThickness = 10,
  barPercentage = 0.55,
  categoryPercentage = 0.6,
}: BasicBarChartProps) => {
  const options = chartOptions || {
    spanGaps: true,
    legend: {
      display: false,
    },
    scales: {
      borderColor: 'rgba(255,255,255,0.8)',
      xAxes: [
        {
          // offset: true,
          gridLines: {
            display: false,
            zeroLineColor: 'transparent',
          },
          ticks: {
            fontColor: '#fff',
          },
          barPercentage,
          categoryPercentage,
          maxBarThickness: 25,
          spanGaps: true,
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: 'white',
            zeroLineColor: 'white',
          },
          ticks: {
            ...(max && { max }),
            min,
            stepSize,
            fontColor: '#fff',
            callback: (value: any) => value + '%',
          },
        },
      ],
    },
  };

  const data = {
    labels,
    datasets: datasets || [
      {
        label,
        backgroundColor: barBackgroundColor,
        hoverBackgroundColor: barHoverBackgroundColor || barBackgroundColor,
        hoverBorderColor: barHoverBorderColor,
        data: chartData,
      },
    ],
  };

  return <Bar {...{ data, options }} />;
};

export default BasicBarChart;
