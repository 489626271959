import React from 'react';
import LoadSpinner from '../../UI/LoadSpinner/LoadSpinner';
import BreadCrumb from '../../UI/BreadCrumb';
import MainResourceManager, { PresentationTypes } from './MainResourceManager';
import HeartLike from '../../UI/HeartLike';
import { GetBodyInnerHTML } from '../default-section/getDefaultElements';
import Complement, { ComplementProps } from './Complement';
import RelatedContent from './RelatedContent';
import { Resource } from '../../pages/MazResources/Gallery/GalleryReducer';

import './MazPresentation.scss';

type PresentationProps = {
  isLoading: boolean;
  crumbs: { path?: string; label: string }[];
  title: string;
  type: PresentationTypes;
  audioBanner?: string;
  mediaUrl: string;
  date: string;
  like: boolean;
  onLikeClick?: () => void;
  likeCounter?: number;
  topic?: string;
  bus?: string;
  description: string;
  complements?: ComplementProps[];
  relatedResources?: Resource[];
  additionalInfo?: { svg: string; label: string }[];
};

const Presentation = ({
  isLoading,
  crumbs,
  title,
  mediaUrl,
  type,
  audioBanner,
  date,
  like,
  likeCounter,
  onLikeClick,
  bus,
  topic,
  description,
  complements,
  relatedResources,
  additionalInfo,
}: PresentationProps) => {
  return (
    <div className='main-content'>
      <div className='container-fluid'>
        {isLoading && <LoadSpinner />}
        <BreadCrumb {...{ crumbs }} />
        <h1 className='text-center mb-4'>{title}</h1>
        <div className='maz-p-media'>
          <MainResourceManager {...{ type, audioBanner }} src={mediaUrl} />
        </div>
        <div className='maz-p-description'>
          <div className='d-flex justify-content-between maz-p-general'>
            <div className='d-flex flex-column align-items-end'>
              {additionalInfo && (
                <div className='d-flex'>
                  <div className='item d-flex'>
                    <span className='cat-img'></span>
                    <h6>{additionalInfo[0].label}</h6>
                  </div>
                  <div className='separator' />
                  <div className='item d-flex'>
                    <span className='cat-img'></span>
                    <h6>{additionalInfo[1].label}</h6>
                  </div>
                </div>
              )}
              <h6 className='mt-4 mb-3'>{date}</h6>
            </div>
            <HeartLike
              isLiked={like}
              onClick={onLikeClick}
              hasLikesCounter
              howManyLikes={likeCounter}
            />
          </div>
          <h6 className='subtitle mb-0'>
            {[topic, bus].filter(Boolean).join(' - ')}
          </h6>
          <div className='me-sm-5 maz-p-description'>
            <GetBodyInnerHTML content={description} />
          </div>
        </div>
      </div>
      <div className='maz-p-complement'>
        {complements?.map((args: ComplementProps) => (
          <Complement
            key={`complement-${args.type}-${args.content}`}
            {...args}
          />
        ))}
      </div>
      {relatedResources && (
        <div className='container-fluid'>
          <div className='maz-p-separator' />
          <div className='maz-p-related-c'>
            <RelatedContent {...{ relatedResources }} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Presentation;
