import React, { useCallback, useEffect, useReducer, useState } from 'react';
import {
  ProfileItem,
  UpdateCases,
} from '../../../../models/profileUpdate.models';
import { store } from '../../../../modules/appState/store';
import { apiMainURL } from '../../../../modules/settings/environment';
import {
  getAllHobbiesByPage,
  getRequiredItems,
  postProfileDeleteItem,
  postProfileUpdate,
  useGetAllBeersQuery,
  useGetAllHobbiesQuery,
  useGetAllItemsQuery,
} from '../../../../services/api/api-socios-maz';
import FavoriteSelectors from './FavoriteSelectors';
import ItemSectionUpdate from './ItemSectionUpdate';
import NewSection from './NewSection';
import { initialState, updateReducer } from './reducer';

import './Update.scss';

type UpdateProps = {
  user: any;
  onChangeImage: () => void;
  closeUpdate: () => void;
};

const Update = ({ user, onChangeImage, closeUpdate }: UpdateProps) => {
  const [state, dispatch] = useReducer(updateReducer, {
    ...initialState,
    ...user,
  });
  const [rawHobbies, setRawHobbies] = useState<{
    data: any[];
    included: any[];
  }>({ data: [], included: [] });

  const dataMap = useCallback(
    (data: any[]) =>
      data
        .map(({ attributes: { name, drupal_internal__tid } }: any) => ({
          label: name,
          value: drupal_internal__tid,
        }))
        .sort((a, b) =>
          a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1,
        ),
    [],
  );

  const { data: items } = useGetAllItemsQuery('');
  const { data: beers } = useGetAllBeersQuery();

  const isDark = store.getState().AppReducer.dark;

  const onAddNewSectionClick = () => {
    dispatch({
      type: UpdateCases.TOGGLE_NEW_SECTION,
      payload: { isActive: true },
    });
  };
  const onCloseNewSectionClick = () => {
    dispatch({
      type: UpdateCases.TOGGLE_NEW_SECTION,
      payload: { isActive: false },
    });
  };
  const onChange = (newHobbyId: string, listName: string, data: any) => {
    const isHobbies = listName === 'hobbies';
    const resourceName = isHobbies ? 'field_hobbie_image' : 'field_logo';

    if (newHobbyId) {
      const iconId = data.data.filter(
        (e: any) => e.attributes.drupal_internal__tid === +newHobbyId,
      )[0].relationships[resourceName].data?.id;

      dispatch({
        type: UpdateCases.ON_SELECTOR_CHANGE,
        payload: {
          listToChange: listName,
          value: {
            id: +newHobbyId,
            icon:
              (iconId &&
                apiMainURL +
                  data.included.filter((inc: any) => inc.id === iconId)[0]
                    ?.attributes.uri.url) ||
              '',
            name: data.data.filter(
              (e: any) => e.attributes.drupal_internal__tid === +newHobbyId,
            )[0].attributes.name,
          },
          maxSelectValue: isHobbies ? 6 : 3,
        },
      });
    }
  };
  const onDelete = (hobbyToDelete: number, listName: string) => {
    dispatch({
      type: UpdateCases.ON_OBJECT_DELETE,
      payload: { listToChange: listName, idToDelete: hobbyToDelete },
    });
  };
  const onItemSave = (item: string, description: string) => {
    dispatch({
      type: UpdateCases.CHANGE_ITEM_BY_NAME,
      payload: { item, description },
    });
  };
  const onNewSection = (newSection: ProfileItem) => {
    dispatch({
      type: UpdateCases.SET_NEW_ITEM,
      payload: { newItem: newSection },
    });
    dispatch({ type: UpdateCases.UPDATE_SELECTOR_ITEMS });
    onCloseNewSectionClick();
  };
  const onUpdateProfile = async () => {
    await postProfileUpdate({
      taxonomies: [
        {
          field_favorite_beer: state.beers.map(({ id }) => ({ id })),
          field_user_hobbies: state.hobbies.map(({ id }) => ({ id })),
        },
      ],
      partnersItems: state.items.map((item) => {
        const itemToSend: { [key: string]: string | number } = {
          field_partner_item_title: item.titleId,
          field_partner_item_description: `<p>${item.description}</p>`,
        };

        if (!item.isNew) itemToSend.id = item.itemId || 0;

        return itemToSend;
      }),
    });

    closeUpdate();
  };
  const onItemDelete = (title: string) => {
    const id = state.items.find(({ name }) => name === title)?.itemId;

    dispatch({ type: UpdateCases.DELETE_ITEM, payload: { title } });
    postProfileDeleteItem({ partnersItems: [{ id }] });
  };

  useEffect(() => {
    if (items && beers)
      dispatch({
        type: UpdateCases.INIT_SELECTORS,
        payload: {
          itemsToSelect: dataMap(items.data).filter(
            ({ value }) =>
              !state.items.some(({ titleId }) => titleId === value),
          ),
          beersToSelect: dataMap(beers.data),
        },
      });
  }, [items, beers, state.items, dataMap]);
  useEffect(() => {
    const getHobbies = async (offset: number) => {
      const hobbies = await getAllHobbiesByPage(offset);

      setRawHobbies((prevHobbies) => {
        return {
          data: [...prevHobbies.data, ...hobbies.data],
          included: [...prevHobbies.included, ...(hobbies.included || [])],
        };
      });

      dispatch({
        type: UpdateCases.SET_HOBBIES_TO_SELECT,
        payload: {
          hobbiesToSelect: dataMap(hobbies.data),
          rawHobbies: hobbies,
        },
      });

      if (hobbies.links.next) getHobbies(offset + 50);
    };
    const getData = async () => {
      const required = await getRequiredItems();

      dispatch({
        type: UpdateCases.SET_REQUIRED_ITEMS,
        payload: { requiredItems: required.data },
      });
    };

    getHobbies(0);
    getData();
  }, [dataMap]);

  return (
    <>
      <div className='w-100 user-basic-info-update'>
        <div className='me-4 position-relative'>
          <div className='overflow-hidden rounded-circle text-center profile-img-update'>
            <img src={state.img} alt={`update-user-img-${state.img}`} />
          </div>
          <span className='position-absolute top-0 change-img-icon'>
            <i className='bi bi-camera' onClick={onChangeImage} />
          </span>
        </div>
        <div className=''>
          <h1 className='user-name-title'>
            {state.name} - {state.nick}
          </h1>
          <p className='user-basic-info'>{state.position}</p>
          <p className='user-basic-info'>
            {state.zone} - {state.area}
          </p>
        </div>
      </div>
      {/* <div className='my-5'>
        <FavoriteSelectors
          title='My favorite Beer'
          maximum={3}
          opts={state.selectionBeers}
          favoriteItems={state.beers}
          onSelectChange={(v) => onChange(v, 'beers', beers)}
          onDelete={(v) => onDelete(v, 'beers')}
        />
      </div>
      <div className='my-5'>
        <FavoriteSelectors
          title='My Hobbies'
          maximum={6}
          opts={state.selectionHobbies || []}
          favoriteItems={state.hobbies}
          onSelectChange={(v) => onChange(v, 'hobbies', rawHobbies)}
          onDelete={(v) => onDelete(v, 'hobbies')}
          emptyItemsMessage='No hobbies detected.'
          hasBreak={isDark}
          isSvg={true}
        />
      </div> */}
      {/* <div className='items-and-sections'>
        <div className='container-fluid'>
          {state.items.map(({ name, description }, idx) => (
            <ItemSectionUpdate
              key={`item-section-update-${name}-${Math.random()}`}
              section='Section'
              item={`Item ${idx + 1}`}
              title={name}
              description={description.replace(/(<(|\/)p>)+/g, '')}
              onSave={onItemSave}
              onDelete={onItemDelete}
              hasDelete={
                !(state.requiredItems || []).some(
                  (e: any) => e.attributes?.name === name,
                )
              }
            />
          ))}
          {state.activeNewSection && (
            <NewSection
              {...{ onNewSection }}
              section='Section'
              item={`Item ${state.items.length + 1}`}
              onClose={onCloseNewSectionClick}
              titleOpts={state.selectionItems}
              hasBreak={isDark}
            />
          )}
          <div className='mt-4 d-flex justify-content-end'>
            {state.items.length < 6 && (
              <button
                className='me-2 py-1 px-2 single-button'
                onClick={onAddNewSectionClick}>
                <i className='bi bi-plus-circle me-2' />
                Add Section
              </button>
            )}
          </div>
          <div className='mt-4 d-flex justify-content-center gap-3'>
            <button className='single-button' onClick={closeUpdate}>
              Cancel
            </button>
            <button
              className='py-1 px-2 submit-button'
              onClick={onUpdateProfile}>
              Save Changes
            </button>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Update;
