import React, { useEffect, useState } from 'react';
import ReactGoogleSlides from 'react-google-slides';
import { useGetHomeDefaultContentQuery } from '../../../../../services/api/api-socios-maz';
import {
  GetBodyInnerHTML,
  getDefaultContent,
} from '../../../../Layouts/default-section/getDefaultElements';
import CarouselView from '../../../../UI/Carousel/carousel';

import './Townhalls.scss';

type TownhallsProps = {
  isLoading?: (value: boolean) => void;
};

export const Townhalls = ({ isLoading }: TownhallsProps) => {
  const [content, setContent] = useState<any>(null);

  const linkPpt =
    'https://docs.google.com/presentation/d/1RmUDp3UNQOkeZ_7gM4P4ErFZFMD3QCYT2iiy7K7XFE4/edit?usp=sharing';
  const linkPpt2 =
    'https://docs.google.com/presentation/d/1uA4BMSLMQzCZSfMjf-csun0bPGVXMfeL/edit?usp=sharing';

  const dataapi = useGetHomeDefaultContentQuery(
    'corporate/business-results-townhall',
  ).data;

  useEffect(() => {
    const getData = async () => {
      const cont: any = getDefaultContent(dataapi)?.content;

      setContent(cont);
    };

    if (dataapi) getData();
  }, [dataapi]);

  return (
    content && (
      <>
        <h3 className='section-title pb-3'>{content[0]?.content?.value}</h3>
        <GetBodyInnerHTML
          content={content[1]?.content?.value}></GetBodyInnerHTML>
        <div className='corporate-slides mb-4'>
          <ReactGoogleSlides slidesLink={linkPpt2} position={1} showControls />
        </div>
        <h3 className='section-title pb-3'>{content[8]?.content?.value}</h3>
        <div className='corporate-slides'>
          <ReactGoogleSlides slidesLink={linkPpt} position={1} showControls />
        </div>
      </>
    )
  );
};
