import React from 'react';

import './BinnacleCards.scss';
import Filters from './Filters';
import Cards from './Cards';

const items = [
  { label: 'All', isActive: true },
  { label: 'Creativity', isActive: false },
  { label: 'Leadership', isActive: false },
  { label: 'Management', isActive: false },
  { label: 'Soft Skills', isActive: false },
];

const BinnacleCards = () => {

  return (
    <div className='binnacle-cards'>
      <h1 className='text-center'>Suggested Resources</h1>
      <p>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
        clita.
      </p>
      <Filters {...{ items }} />
      <Cards />
    </div>
  );
};

export default BinnacleCards;
