import React from 'react';
import { useGetHomeDefaultContentQuery } from '../../../../../../../services/api/api-socios-maz';
import { DefaultContentCustom } from '../../../../../../Layouts/default-section/default-custom-page';
import {
  GetBodyInnerHTML,
  getDefaultContent,
} from '../../../../../../Layouts/default-section/getDefaultElements';
import BreadCrumb from '../../../../../../UI/BreadCrumb';
import { NavElementsLPC_Maps } from '../../../../../../UI/Buttons-LPC-Maps';
import CarouselView from '../../../../../../UI/Carousel/carousel';
import LoadSpinner from '../../../../../../UI/LoadSpinner/LoadSpinner';

export const RelaxationMapsComponent = () => {
  const dataapi = useGetHomeDefaultContentQuery(
    'lpc/events/maps/relaxation',
  ).data;
  // const nodeId = dataapi?.data[0]?.attributes?.drupal_internal__nid;

  // console.log("NODO ID:   ", nodeId)
  const elem: any = getDefaultContent(dataapi)?.content;

  if (elem) return <RelaxationMaps content={elem}></RelaxationMaps>;
  else return <LoadSpinner></LoadSpinner>;
};

export const RelaxationMaps = (props: any) => {
  const cont = props?.content;
  const crumbs = [
    { path: '/home', label: 'MAZ Partners' },
    { path: '/lpc', label: 'LPC' },
    { label: 'Relax' },
  ];

  return (
    <div className='main-content lpc-bg pb-5'>
      <div className='container-fluid'>
        <div className='col-12'>
          <BreadCrumb {...{ crumbs }} />
          {/* <h1 className='section-title'>{cont[0]?.content?.value}</h1> */}
          {/* <GetBodyInnerHTML content={cont[1]?.content?.value}></GetBodyInnerHTML> */}
          {/* <CarouselView content={cont[0]?.content}></CarouselView> */}
          <NavElementsLPC_Maps actived={2}></NavElementsLPC_Maps>
          <DefaultContentCustom elem={cont}></DefaultContentCustom>
        </div>
      </div>
    </div>
  );
};
