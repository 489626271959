import React, { useEffect, useState } from 'react';
import { useGetHomeDefaultContentQuery } from '../../../../../services/api/api-socios-maz';
import {
  GetBodyInnerHTML,
  getDefaultContent,
} from '../../../../Layouts/default-section/getDefaultElements';
import CarouselView from '../../../../UI/Carousel/carousel';

import './Flywheel.scss';

type FlywheelProps = {
  isLoading?: (value: boolean) => void;
};

export const Flywheel = ({ isLoading }: FlywheelProps) => {
  const [content, setContent] = useState<any>(null);

  const dataapi = useGetHomeDefaultContentQuery(
    'corporate/business-results-flywheel',
  ).data;

  useEffect(() => {
    const getData = async () => {
      const cont: any = getDefaultContent(dataapi)?.content;

      setContent(cont);
    };

    if (dataapi) getData();
  }, [dataapi]);

  return (
    content && (
      <>
        <h3 className='section-title pb-3'>How to achieve our goals</h3>
        <div className='flywheel-description mt-4 mb-5'>
          <GetBodyInnerHTML
            content={content[0]?.content?.value}></GetBodyInnerHTML>
        </div>
        <div className='text-center'>
          <img
            src={`../../../../resources/img-goals.png`}
            alt=''
            width='500'
            className='img-fluid'
          />
        </div>
        <h3 className='section-title pb-3 mt-5'>
          {content[1]?.content?.value}
        </h3>
        <div className='row pb-2 gx-5'>
          <div className='col-12 col-md-6'>
            <div className='result-title'>
              <h4 className='section-title text-start'>
                {content[2]?.content?.value}
              </h4>
            </div>
            <div className='text-result'>
              <GetBodyInnerHTML
                content={content[3]?.content?.value}></GetBodyInnerHTML>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='result-title'>
              <h4 className='section-title text-start'>
                {content[4]?.content?.value}
              </h4>
            </div>
            <div className='text-result'>
              <GetBodyInnerHTML
                content={content[5]?.content?.value}></GetBodyInnerHTML>
            </div>
          </div>
        </div>
        <div className='row pb-2 gx-5'>
          <div className='col-12 col-md-6'>
            <div className='result-title'>
              <h4 className='section-title text-start'>
                {content[6]?.content?.value}
              </h4>
            </div>
            <div className='text-result'>
              <GetBodyInnerHTML
                content={content[7]?.content?.value}></GetBodyInnerHTML>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='result-title'>
              <h4 className='section-title text-start'>
                {content[8]?.content?.value}
              </h4>
            </div>
            <div className='text-result'>
              <GetBodyInnerHTML
                content={content[9]?.content?.value}></GetBodyInnerHTML>
            </div>
          </div>
        </div>
        <div className='row pb-2 gx-5'>
          <div className='col-12 col-md-6'>
            <div className='result-title'>
              <h4 className='section-title text-start'>
                {content[10]?.content?.value}
              </h4>
            </div>
            <div className='text-result'>
              <GetBodyInnerHTML
                content={content[11]?.content?.value}></GetBodyInnerHTML>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='result-title'>
              <h4 className='section-title text-start'>
                {content[12]?.content?.value}
              </h4>
            </div>
            <div className='text-result'>
              <GetBodyInnerHTML
                content={content[13]?.content?.value}></GetBodyInnerHTML>
            </div>
          </div>
        </div>
      </>
    )
  );
};
