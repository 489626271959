import React, { useEffect, useState } from 'react';
import { MenuPartnerProfile } from '.';
import { store as storeApp } from '../../../modules/appState/store';
import { useGetParnterProfileFiltersQuery } from '../../../services/api/api-socios-maz';
import LoadSpinner from '../../UI/LoadSpinner/LoadSpinner';
import { NoData } from './no-data/noData';

export const MenuData = () => {
  const [data2, setData2] = useState<any>(undefined);
  const [error204, setError204] = useState(false);
  const [load, setLoad] = useState(0);
  const [role, setRole] = useState<Array<string>>([]);
  const [user, setUser] = useState(storeApp.getState().AppReducer?.userData);
  storeApp.subscribe(() => {
    setUser(storeApp?.getState()?.AppReducer?.userData || '');
  });

  const res = useGetParnterProfileFiltersQuery().data;

  useEffect(() => {
    const getDataProfile = async () => {
      setLoad(0);

      setData2(res);
      setRole([res?.role]);
      setLoad(1);

      // if (!res) setLoad(-1);
    };

    if (user?.drupal_id!='' && res?.role) getDataProfile();
  }, [user, res]);

  if (role.includes('super_directivo') || role.includes('directivo')) {
    return (
      <>
        {load == 1 && (
          <MenuPartnerProfile
            filters={data2?.relateds?.filters}
            role={data2?.role}
            user={user}></MenuPartnerProfile>
        )}
        {load == 0 && <LoadSpinner></LoadSpinner>}
      </>
    );
  } else
    return (
      <>
        {load == -1 && (
          <NoData
            title={
              'Sorry, it is not possible to enter the Patner Profile section.'
            }
            description={
              'If you want to know your indicators and your archetype, we invite you to paticipate in our next survey, which will be carried out in December.'
            }
            buttonText={'Ready, got it'}></NoData>
        )}
        {load == 0 && <LoadSpinner />}
      </>
    );
  // return (
  //     <div>
  //         {data2&&!error204 && !(role.includes('super_directivo') || role.includes('directivo')) && (
  //             <div className='main-content'>
  //                 <div className="container-fluid">
  //                     <h1>ERROR 404</h1>
  //                 </div>
  //             </div>
  //         )}
  //         {!data2 &&(
  //             <LoadSpinner></LoadSpinner>
  //         )}
  //     </div>
  // )
};
