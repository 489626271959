import React, { useEffect, useReducer, useRef } from 'react';

export declare interface AccordionProps {
  title?: string;
  subtitle?: string;
  show?: boolean;
  children?: any;
  trigger?: () => void;
  nested?: boolean;
  flag?: boolean;
  className?: string;
}

type State = {
  collapse: boolean;
};

type Action = { type: 'collapse' } | { type: 'show' };

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'collapse':
      return {
        collapse: !state.collapse,
      };
    case 'show':
      return {
        collapse: true,
      };
  }
}

export function Accordion({
  title = 'Accordion Title',
  subtitle,
  show = false,
  children,
  trigger,
  nested,
  flag,
  className,
}: AccordionProps) {
  const accordionBodyRef = useRef<HTMLDivElement>(null);
  const [{ collapse }, dispatch] = useReducer(reducer, {
    collapse: show,
  });

  const randomId = useRef(
    window.crypto.getRandomValues(new Uint32Array(1))[0].toString(36),
  );

  const actualClassName = `accordion-item ${className}`;

  useEffect(() => {
    if (show) dispatch({ type: 'show' });
  }, [show, flag]);

  function execInstructions() {
    dispatch({ type: 'collapse' });
    trigger && trigger();
  }
  return (
    <div className={actualClassName}>
      <h2 className='accordion-header' id={`heading-${randomId.current}`}>
        <button
          className={`accordion-button${collapse ? '' : ' collapsed'}`}
          type='button'
          aria-expanded={collapse}
          aria-controls={`collapse-${randomId.current}`}
          onClick={() => {
            execInstructions();
          }}>
          <div>
            {title}
            {subtitle && (
              <span>
                <h6>{subtitle}</h6>
              </span>
            )}
          </div>
        </button>
      </h2>

      <div
        id={`collapse-${randomId.current}`}
        aria-labelledby={`heading-${randomId.current}`}
        className={`accordion-collapse`}
        style={
          collapse
            ? {
                height: accordionBodyRef.current?.clientHeight,
                transition: 'height 0.2s ease',
                overflow: 'hidden',
              }
            : {
                height: 0,
                transition: 'height 0.2s ease',
                overflow: 'hidden',
              }
        }>
        <div className='accordion-body' ref={accordionBodyRef}>
          {children}
        </div>
      </div>
    </div>
  );
}
