import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import HeartLike from '../../UI/HeartLike';
import { postGalleryEventLike } from '../../../services/api/api-socios-maz';

interface GalleryItemProps {
  date: string;
  parent: string;
  title: string;
  src: string;
  hasLike: boolean;
  id: string;
  drupalId: string;
}

const GalleryItem = ({
  date,
  parent,
  hasLike,
  title,
  src,
  id,
  drupalId,
}: GalleryItemProps) => {
  const [liked, setLiked] = useState(false);

  const onLikeClick = async () => {
    const response = await postGalleryEventLike(drupalId, liked);
    setLiked(response?.isFavorite!);
  };

  useEffect(() => {
    setLiked(hasLike);
  }, [hasLike]);

  return (
    <div key={`moment-img-${date}-${title}`} className='element-gallery'>
      <Link to={`/lpc/gallery?id=${id}&idDate=${date}&idParent=${parent}`}>
        <img {...{ src }} alt={`gallery-object-${src}`} />
        <div className='degrade' />
      </Link>
      <HeartLike isLiked={liked} onClick={onLikeClick} />
    </div>
  );
};

export default GalleryItem;
