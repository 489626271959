import React, { useEffect, useState } from 'react';
import {
  getUsersData,
  useGetAllAreasQuery,
  useGetAllBUsQuery,
} from '../../../services/api/api-socios-maz';
import { apiMainURL } from '../../../modules/settings/environment';
import { useSearchParams } from 'react-router-dom';

import LoadSpinner from '../../UI/LoadSpinner/LoadSpinner';
import BreadCrumb from '../../UI/BreadCrumb';
import SearchBar from '../../UI/SearchBar';
import MultiselectItems from '../../UI/Multiselect';
import Partners from './Partners';
import NoDataFound from '../../Layouts/NoDataFound';

import './MeetThePartners.scss';

const initialUsersState = [
  {
    first_name: '',
    last_name: '',
    user_image: '',
    bu: '',
    id: '',
    sharpId: '',
    area: '',
    position: '',
    mail: '',
  },
];

export const MeetThePartners = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [users, setUsers] = useState(initialUsersState);
  const [inputFilter, setInputFilter] = useState('');
  const [save, setSave] = useState(1);
  const [hasButtons, setHasButtons] = useState({ next: true, prev: false });
  const [selectorsData, setSelectorsData] = useState({ bu: [], area: [] });
  const [page, setPage] = useState(0);
  const [grid, setGrid] = useState(true);

  const limit = '16';
  const allBU = useGetAllBUsQuery().data;
  const allAreas = useGetAllAreasQuery().data;

  const crumbs = [
    { path: '/home', label: 'MAZ Partners' },
    { label: 'Meet the Partners' },
  ];

  useEffect(() => {
    const getUsers = async () => {
      setSave(1);

      const data = await getUsersData({
        limit: +(searchParams.get('limit') || '16'),
        offset: +(searchParams.get('offset') || ''),
        areas: searchParams.get('areas') || undefined,
        bus: searchParams.get('bus') || undefined,
        name: searchParams.get('search') || undefined,
      });

      const actUsers = data?.data?.map((section: any) => {
        const imageId = section?.relationships?.user_picture?.data?.id || '';
        const bu =
          data.included.filter(
            ({ id }: { id: string }) =>
              id === section?.relationships?.field_bu?.data?.id,
          )[0]?.attributes?.name || '';
        const area =
          data.included.filter(
            ({ id }: { id: string }) =>
              id === section?.relationships?.field_area?.data?.id,
          )[0]?.attributes?.name || '';

        let imageUrl = '';

        for (const inc of data.included) {
          if (imageId !== '' && inc?.id === imageId)
            imageUrl = apiMainURL + inc?.attributes?.uri?.url || '';
        }

        if (imageId === '')
          imageUrl =
            'https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png';

        return {
          first_name: section?.attributes?.name || '',
          last_name: '',
          user_image: imageUrl,
          bu: bu || area ? [bu, area].join(' / ') : 'No data.',
          position: section?.attributes?.field_user_position || '',
          id: section?.attributes?.drupal_internal__uid || '',
          sharpId: section?.attributes.field_sharp_id || '',
          area: section?.attributes?.field_user_area || '',
          mail: section?.attributes?.mail || '',
          phone:section?.attributes?.field_phone||''
        };
      });
      const links = data.links;

      setInputFilter(searchParams.get('search') || '');
      setHasButtons({ next: !!links.next, prev: !!links.prev });
      setUsers(actUsers);
      setSave(0);
      setPage(
        +(searchParams.get('offset') || '') /
          +(searchParams.get('limit') || limit),
      );
    };

    getUsers();
  }, [searchParams]);

  const onInputChange = ({
    target: { value },
  }: {
    target: { value: string };
  }): void => {
    setInputFilter(value);
  };
  const onSearchClick = () => {
    setSave(1);

    searchParams.delete('areas');
    searchParams.delete('bus');
    searchParams.set('offset', '0');

    setSearchParams({
      ...[...searchParams].reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {}),
      search: inputFilter,
    });
  };
  const onSelectChange = (key: 'bus' | 'areas', values: any[]) => {
    setSave(1);

    const isEmpty = values.length === 0;

    if (isEmpty) searchParams.delete(key);

    searchParams.set('offset', '0');

    setSearchParams({
      ...[...searchParams].reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {}),
      ...(!isEmpty && { [key]: values.map(({ value }) => value).join(',') }),
    });
  };
  const onPageChange = (c: 'next' | 'prev') => {
    setSave(1);
    const params = { limit, offset: '' };
    const paramsOffset = +(searchParams.get('offset') || '');

    params.offset = `${
      c === 'prev' ? paramsOffset + -limit : paramsOffset + +limit
    }`;

    setSearchParams({
      ...[...searchParams].reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {}),
      ...params,
    });

    setPage((prevPage) => (c === 'next' ? prevPage + 1 : prevPage - 1));
  };
  const onChangeGrid = (v: boolean) => setGrid(v);

  useEffect(() => {
    const getData = async () => {
      setSelectorsData({
        bu: allBU.data.map((e: any) => {
          const a = e.attributes;
          return { label: a.name, value: a.drupal_internal__tid };
        }),
        area: allAreas.data.map((e: any) => {
          const a = e.attributes;
          return { label: a.name, value: a.drupal_internal__tid };
        }),
      });
    };

    if (allAreas && allBU) getData();
  }, [allAreas, allBU]);

  return (
    <div className='main-content'>
      <div className='container-fluid'>
        <BreadCrumb {...{ crumbs }} />
        <h1 className='text-center mb-4'>Meet the Partners</h1>
        <div className='my-4'>
          <p className='description text-center'>
            Time to meet our partners and a little bit about what makes them
            special. Get to know this group of people, the message they want to
            send out to their future selves, and what they will be celebrating
            in 5 years.
          </p>
        </div>
        <div className='d-flex search-filter-bar'>
          <div className='w-100'>
            <SearchBar
              {...{ onSearchClick }}
              inputValue={inputFilter}
              placeholder='Who are you looking for?'
              onChange={onInputChange}
            />
          </div>
          <div className='selector'>
            <MultiselectItems
              className='multi-select mtp-multi-select'
              options={selectorsData.area || []}
              label='Area'
              overrideStrings={{ selectSomeItems: 'Area' }}
              valueRenderer={() => 'Area'}
              onChange={(e) => onSelectChange('areas', e)}
              selectedIds={searchParams.get('areas')?.split(',') || []}
            />
          </div>
          <div className='selector'>
            <MultiselectItems
              className='multi-select mtp-multi-select'
              options={selectorsData.bu || []}
              label='BU'
              overrideStrings={{ selectSomeItems: 'BU' }}
              valueRenderer={() => 'BU'}
              onChange={(e) => onSelectChange('bus', e)}
              selectedIds={searchParams.get('bus')?.split(',') || []}
            />
          </div>
        </div>
        <div className='structure-filter'>
          <i
            className={'bi bi-list' + (!grid ? ' active' : '')}
            onClick={() => onChangeGrid(false)}></i>
          <i
            className={'bi bi-grid' + (grid ? ' active' : '')}
            onClick={() => onChangeGrid(true)}></i>
        </div>
        {save === 0 ? (
          users.length !== 0 ? (
            <Partners {...{ users }} gridType={grid} />
          ) : (
            <NoDataFound />
          )
        ) : (
          <LoadSpinner />
        )}
      </div>
      <div className='maz-g-pagination gallery-pagination'>
        <button
          className={`${!hasButtons.prev && 'disabled'}`}
          onClick={() => onPageChange('prev')}
          disabled={!hasButtons.prev}>
          Previous
        </button>
        <p>{page + 1}</p>
        <button
          className={`${!hasButtons.next && 'disabled'}`}
          onClick={() => onPageChange('next')}
          disabled={!hasButtons.next}>
          Next
        </button>
      </div>
    </div>
  );
};
