import React from 'react';

import './SectionButtons.scss';

type SectionButtonsProps = {
  categories: string[];
  activeSection: string | null;
  onChange: (value: string) => void;
};

export const SectionButtons = ({
  onChange,
  categories,
  activeSection,
}: SectionButtonsProps) => {
  const sectionActiveClassName = (section: string) =>
    `section-button rounded-pill ${section === activeSection && 'is-active'}`;

  return (
    <div className='d-sm-inline-block '>
      {categories.map((label) => (
        <button
          key={`section-button-${label}`}
          className={sectionActiveClassName(label)}
          onClick={() => onChange(label)} >
          {label}
        </button>
      ))}
    </div>
  );
};
