import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {Link, useLocation} from "react-router-dom";
export const Carouselhorizontal = (props:any) => {
  const location = useLocation();

    const {items}=props;
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const items2 = [
        {
            name: 'MagicianArray',
            image: 'https://api-socios-maz-dev.somosmaz.com/sites/default/files/2022-07/Captura%20de%20Pantalla%202022-07-22%20a%20la%28s%29%2014.48.18%20%281%29.png',
            id: '174'
        },
        {
            name: 'AlchemistArray',
            image: 'https://api-socios-maz-dev.somosmaz.com/sites/default/files/2022-07/Captura%20de%20Pantalla%202022-07-22%20a%20la%28s%29%2014.48.18%20%281%29.png',
            id: '174'
        },
        {
            name: 'HeroArray',
            image: 'https://api-socios-maz-dev.somosmaz.com/sites/default/files/2022-07/Captura%20de%20Pantalla%202022-07-22%20a%20la%28s%29%2014.48.18%20%281%29.png',
            id: '174'
        },
        {
            name: 'KnightArray',
            image: 'https://api-socios-maz-dev.somosmaz.com/sites/default/files/2022-07/Captura%20de%20Pantalla%202022-07-22%20a%20la%28s%29%2014.48.18%20%281%29.png',
            id: '174'
        },
    ]
    return (
        <Carousel additionalTransfrom={0}
            arrows
            autoPlaySpeed={3000}
            centerMode={false}
            className=""
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
                superLargeDesktop: {
                    // the naming can be any, depends on you.
                    breakpoint: { max: 4000, min: 3000 },
                    items: 5
                },
                desktop: {
                    breakpoint: {
                        max: 3000,
                        min: 1024
                    },
                    items: 3,
                    partialVisibilityGutter: 40
                },
                mobile: {
                    breakpoint: {
                        max: 576,
                        min: 0
                    },
                    items: 1,
                    partialVisibilityGutter: 30
                },
                tablet: {
                    breakpoint: {
                        max: 1024,
                        min: 576
                    },
                    items: 2,
                    partialVisibilityGutter: 30
                }
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable>
           {items?.map((i:any,indexItem:number)=>{
            let path='';
           switch(location.pathname){
            case '/partners/partner-profile/my-partner-profile':
                path='/partners/partner-profile/my-partner-profile/archetype-detail?';
                break;
            case '/partners/partner-profile/my-partner-profile/archetype-detail':
                path='/partners/partner-profile/my-partner-profile/archetype-detail?';
                break;
                case '/partners/archetypes-definition/archetype-detail':
                    path='/partners/archetypes-definition/archetype-detail?'
                    break;
            case '/partners/overview':
                path='/partners/overview/archetype-detail?'
                break;
            case '/partners/overview/archetype-detail':
                path='/partners/overview/archetype-detail?'
                break;
            default:
                path='/profile/partner-profile/archetype-detail?'
                break;
               


           }
            return(
                <div className="archetypes-card mx-5" key={indexItem}>
                    <div className="card">
                        <Link to={path+(i?.id||'')}>
                            <img className="card-img-top card-img-archetypes" src={i?.image} alt="Card image cap" />
                            <div className="card-body  archetype-body">
                                <p className="card-text tc">{i?.name}</p>
                            </div>
                        </Link>
                    </div>
                </div>
            )
           })
           }
            
        </Carousel>
    )
}
