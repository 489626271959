import React from 'react';

import MazDropdown from '../../../UI/Dropdown';
import LoadSpinner from '../../../UI/LoadSpinner/LoadSpinner';

type ProfileDropdownProps = {
  onDropdownClick: (v: string) => void;
  records: {
    label: string;
    value: string | number;
  }[];
  label?: string;
  titleClassName?: string;
};

const ProfileDropdown = ({
  onDropdownClick,
  records,
  label = 'This is the report of',
}: ProfileDropdownProps) => {
  return records.length !== 0 ? (
    <div className='d-flex'>
      <h5 className='ms-auto my-auto me-2 h-auto text_report_records'>
        {label}
      </h5>
      <div className='min-w-drop'>
        <MazDropdown items={records} onClick={onDropdownClick} />
      </div>
    </div>
  ) : (
    <LoadSpinner />
  );
};

export default ProfileDropdown;
