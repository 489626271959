import React, { useState } from 'react';

import './Binnacle.scss';
import BreadCrumb from '../../UI/BreadCrumb';
import PPNavBar from '../../UI/PPNavBar';
import { useLocation } from 'react-router';
import BinnacleHeader from './BinnacleHeader';
import ProfileDropdown from '../profile/ProfileDropdown';
import BinnacleInfographic from './BinnacleInfographic';
import BinnacleGoals from './BinnacleGoals';
import BinnacleCards from './BinnacleCards';
import { store as storeApp } from '../../../modules/appState/store';
import { useGetBinnacleDataQuery } from '../../../services/api/api-socios-maz';

const dropOptions = [
  {
    label: '2021',
    value: 2021,
  },
  {
    label: '2022',
    value: 2022,
  },
  {
    label: '2023',
    value: 2023,
  },
];

const Binnacle = () => {
  const [user, setUser] = useState(storeApp.getState().AppReducer?.userData);
  storeApp.subscribe(() => {
    setUser(storeApp?.getState()?.AppReducer?.userData || '');
  });
  const bitacora = useGetBinnacleDataQuery('')?.data;
  const { pathname } = useLocation();

  const crumbs =
    pathname.split('/')[1] !== 'profile'
      ? [
          { path: '/home', label: 'MAZ Partners' },
          { path: '/partners/partner-profile', label: 'Partner profile' },
          { label: 'Binnacle' },
        ]
      : [{ path: '/home', label: 'MAZ Partners' }, { label: 'Binnacle' }];

  return (
    <div className='main-content'>
      <div className='container-fluid'>
        <BreadCrumb {...{ crumbs }} />
        {/* <PPNavBar /> */}
        <BinnacleHeader />

        {/* <div className='binnacle-dropdown-container'>
          <ProfileDropdown
            records={dropOptions}
            onDropdownClick={() => {}}
            label='This is your binnacle for'
          />
        </div> */}

        <BinnacleInfographic
          bitacora={bitacora}
          included={bitacora?.included}
        />
        {bitacora?.data && bitacora?.data?.length > 0 && (
          <BinnacleGoals
            texto={bitacora?.data[0]?.attributes?.field_por_hacer?.value || ''}
            anio={bitacora?.data[0]?.attributes?.field_year_por_hacer}
          />
        )}
        <BinnacleCards />
      </div>
    </div>
  );
};

export default Binnacle;
