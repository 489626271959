import React, { useCallback, useEffect, useReducer, useState } from 'react';
import {
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
} from 'reactstrap';
import { SectionButtons } from '../../../Layouts/GallerysSections/SectionButtons';
import { loyaltyReducer, initialState } from './reducer';
import {
  getLoyaltyBenefits,
  getGenericPageWithBu,
} from '../../../../services/api/api-socios-maz';
import { apiMainURL } from '../../../../modules/settings/environment';
import { LoyaltyActionCases } from '../../../../models/loyalty.models';
import { GetBodyInnerHTML } from '../../../Layouts/default-section/getDefaultElements';
import { useTranslation } from 'react-i18next';
import { store } from '../../../../modules/appState/store';
import { v4 } from 'uuid';

import StepperCards from './StepperCards';
import BreadCrumb from '../../../UI/BreadCrumb';
import Section from '../../../Layouts/GallerysSections/Section';

import './Loyalty.scss';
import LoadSpinner from '../../../UI/LoadSpinner/LoadSpinner';
import CarouselView from '../../../UI/Carousel/carousel';
import NoDataFound from '../../../Layouts/NoDataFound';
import { Unsubscribe } from 'redux';

const Loyalty = () => {
  const { t } = useTranslation('global');

  const [state, dispatch] = useReducer(loyaltyReducer, initialState);
  const [activeIndex, setActiveIndex] = useState(0);

  const crumbs = [
    { path: '/home', label: 'MAZ Partners' },
    { path: '/about-me-at-abi', label: t('about-me-at-abi.title') },
    { label: 'Loyalty' },
  ];

  const getSections = useCallback(
    async (loyaltyQueryParams: {
      limit?: number;
      categoryId?: number;
      bu: string;
    }) => {
      const loyaltyBenefitsInfo = await getLoyaltyBenefits(
        'field_benefit_category, field_image, node_type',
        loyaltyQueryParams,
      );

      const categories =
        loyaltyBenefitsInfo?.included
          ?.filter((e: any) => e?.type === 'taxonomy_term--benefit_category')
          .map((e: any) => ({
            id: e?.id,
            nid: e?.attributes?.drupal_internal__tid,
            title: e?.attributes?.name,
          }))
          .filter(
            (e: any, idx: number, self: any) =>
              idx === self.findIndex((t: any) => t.id === e.id),
          ) || [];
      const sections = categories.map((e: any) => ({
        title: e?.title,
        cardsInfo: loyaltyBenefitsInfo?.data
          .filter(
            (data: any) =>
              data?.relationships?.field_benefit_category?.data?.id === e?.id,
          )
          .map((data: any) => ({
            id: data?.id,
            img: `${apiMainURL}${
              loyaltyBenefitsInfo?.included.filter(
                (include: any) =>
                  (include.id || '') ===
                  (data.relationships?.field_image?.data?.id || ''),
              )[0]?.attributes?.uri?.url
            }`,
            title: data?.attributes?.title,
            content:
              data?.attributes?.body?.value
                .replace(/(<(|\/)p>)+/g, '')
                .split('.')[0] || '',
          })),
      }));

      return { categories, sections };
    },
    [],
  );

  useEffect(() => {
    let unSub: Unsubscribe;

    const getBu = () => {
      const bu = store.getState().AppReducer.userData.bu;

      bu && dispatch({ type: LoyaltyActionCases.ON_BU_LOAD, payload: { bu } });
    };

    getBu();

    unSub = store.subscribe(() => {
      getBu();
    });

    return () => {
      unSub && unSub();
    };
  }, []);

  useEffect(() => {
    const getData = async () => {
      const loyaltyBaseInfo = await getGenericPageWithBu(
        '/about-me-at-abi/loyalty',
        state.bu,
        'field_arguments,field_arguments.field_contenido,field_arguments.field_contenido.field_list,field_arguments.field_contenido.field_list.field_imagen_galeria,field_arguments.field_contenido.field_card,field_arguments.field_contenido.field_card.field_icono,field_arguments.field_contenido.field_list.field_image',
      );
      const banner =
        loyaltyBaseInfo.included?.filter(
          ({ type }: { type: string }) => type === 'node--content_banner',
        )[0] || {};
      const { categories, sections } = await getSections({
        limit: 4,
        bu: state.bu,
      });

      dispatch({
        type: LoyaltyActionCases.INIT_BASE_PAGE,
        payload: {
          title: loyaltyBaseInfo.data[0]?.attributes?.title,
          subtitle: loyaltyBaseInfo?.included?.filter(
            (e: any) => e.type === 'paragraph--titulos',
          )[0]?.attributes?.field_title,
          description: loyaltyBaseInfo?.included
            ?.filter((e: any) => e.type === 'paragraph--paragraph_texto')[0]
            ?.attributes?.field_texto?.value?.replace(/(<(|\/)p>)+/g, '')
            .replace(/\n/g, '<br/>'),
          loyaltyImgs: loyaltyBaseInfo?.included
            ?.filter((e: any) => e.type === 'node--galeria')[0]
            ?.relationships?.field_imagen_galeria?.data?.map(
              ({ id: nodeGalleryId }: any) =>
                `${apiMainURL}${
                  loyaltyBaseInfo.included.filter(
                    (e: any) => e.id === nodeGalleryId,
                  )[0].attributes.uri.url
                }`,
            ),
          loyaltyStepperCards: loyaltyBaseInfo.included
            ?.filter((e: any) => e.type === 'paragraph--card')
            .map((e: any) => {
              const id = e.relationships.field_icono.data.id;

              return {
                title: e?.attributes?.field_title,
                description: e?.attributes?.field_descripcion_sumario?.value
                  .replace(/(<(|\/)p>)+/g, '')
                  .replace(/\n/g, '<br/>'),
                icon: `${apiMainURL}${
                  loyaltyBaseInfo?.included.filter((e: any) => e.id === id)[0]
                    ?.attributes?.uri?.url
                }`,
              };
            }),
          banner: {
            alt: banner?.attributes?.title,
            img: `${apiMainURL}${
              loyaltyBaseInfo?.included?.filter(
                ({ id }: { id: string }) =>
                  id === banner?.relationships?.field_image?.data?.id || '',
              )[0]?.attributes?.uri?.url
            }`,
            outerURL: banner?.attributes?.field_link?.uri,
          },
          categories,
          sections,
          isLoading: false,
        },
      });
    };

    state.bu && getData();
  }, [getSections, state.bu]);

  const { loyaltyImgs: items } = state;
  const buttonCategories = state.categories.map(({ title }) => title);
  const indItems = items.map((e: string) => ({ src: e }));

  const next = () => {
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const onLoadMoreAndSectionChange = async (section: string) => {
    const newSection = state.activeSection !== section ? section : null;

    if (newSection) {
      dispatch({
        type: LoyaltyActionCases.SET_LOAD,
        payload: { isLoading: true },
      });

      const { sections } = await getSections({
        categoryId: state.categories.filter(
          ({ title }) => title === newSection,
        )[0].nid,
        bu: state.bu,
      });

      dispatch({
        type: LoyaltyActionCases.SET_SECTIONS,
        payload: { sections, isLoading: false },
      });
    }

    dispatch({
      type: LoyaltyActionCases.CHANGE_ACTUAL_SECTION,
      payload: { activeSection: newSection },
    });
  };
  const onBannerClick = () => {
    window.open(state.banner.outerURL, '_blank');
  };
  const goToIndex = (v: number) => setActiveIndex(v);

  const slides = items.map((src) => {
    return (
      <CarouselItem
        key={`carousel-${Math.random()}`}
        className='overflow-hidden text-center'>
        <img
          className='carousel-img loyalty-carrousel-img'
          src={src}
          alt={src}
        />
      </CarouselItem>
    );
  });

  return (
    <div className='main-content loyalty-page'>
      {state.isLoading && <LoadSpinner />}
      <div className='container-fluid'>
        <BreadCrumb {...{ crumbs }} />
        <h1 className='text-center'>{state.title}</h1>
        <div className='row row-cols-1 row-cols-lg-2'>
          <div className='col mt-4 pt-2 pe-5 d-flex flex-column align-items-start'>
            <h2 className='mb-3'>{state.subtitle}</h2>
            <p className='benefits-body'>
              <GetBodyInnerHTML content={state.description} />
            </p>
          </div>
          <div className='col'>
            <Carousel
              className='event-description-carousel mx-auto'
              {...{ next, previous, activeIndex }}
              interval={false}>
              <CarouselIndicators
                {...{ activeIndex }}
                items={indItems}
                onClickHandler={goToIndex}
              />
              {slides}
              <CarouselControl
                direction='prev'
                directionText='<'
                onClickHandler={previous}
              />
              <CarouselControl
                direction='next'
                directionText='>'
                onClickHandler={next}
              />
            </Carousel>
          </div>
        </div>
        {state.loyaltyStepperCards.length !== 0 && (
          <StepperCards items={state.loyaltyStepperCards} />
        )}
        {state.banner.img && (
          <div className='loyalty-box' onClick={onBannerClick}>
            <img
              className='loyalty-box-img'
              src={state.banner.img}
              alt={state.banner.alt}
            />
            <div className='m-2 px-3 py-2 click-here-loyalty-box'>
              Claim Yours
            </div>
          </div>
        )}
        <h2 className='text-center'>Know and explore the benefits</h2>
        {buttonCategories[0] && (
          <SectionButtons
            activeSection={state.activeSection}
            categories={buttonCategories}
            onChange={onLoadMoreAndSectionChange}
          />
        )}
        {state.sections.length !== 0 ? (
          state.sections.map(({ title, cardsInfo, hasShowButton }) => {
            const uuid = v4();

            return (
              <Section
                {...{ title, cardsInfo, hasShowButton }}
                key={`content-section-${uuid}`}
                onLoadMore={onLoadMoreAndSectionChange}
              />
            );
          })
        ) : (
          <NoDataFound />
        )}
      </div>
    </div>
  );
};

export default Loyalty;
