import { apiMainURL } from '../../../modules/settings/environment';

import DOMpurify from 'dompurify';
import reactParser from 'html-react-parser';
import { suggestedPlan } from '../../../models/lpc.model';

export function getDefaultContent(section: any): any {
  var element: any;
  let categories: string[] = [];
  section?.data?.map((elem: any, index: number) => {
    let idContent = elem?.relationships?.field_arguments?.data?.id || '';
    let banners: object[] = [];
    let listLinks: object[] = [];
    let sections: object[] = [];
    let media: object[] = [];
    let idmedio: any;
    let breadCrumb: object[] = [];
    let locations: object[] = [];
    let arrayCards: any[] = [];

    section?.included?.map((inc: any, index: number) => {
      switch (inc?.type) {
        case 'taxonomy_term--sections':
          breadCrumb.unshift({
            name: inc?.attributes?.name || '',
            path: inc?.attributes?.path?.alias || '',
          });
          break;
      }
      if (inc?.id == idContent) {
        inc?.relationships?.field_contenido.data.map(
          async (content: any, index: number) => {
            section?.included?.map((i: any) => {
              if (content?.id == i.id) {
                idmedio = i;
              }
            });
            if (idmedio) {
              switch (idmedio?.type) {
                case 'paragraph--paragraph_banners':
                  banners = getBannerDefaultSection(
                    idmedio?.relationships?.field_list?.data,
                    section?.included,
                  );
                  sections.push({
                    type: 'banner',
                    content: banners,
                  });
                  break;
                case 'paragraph--titulos':
                  sections.push({
                    type: 'title',
                    content: {
                      type: idmedio?.attributes?.field_tipo_titulo || '',
                      value: idmedio?.attributes?.field_title || '',
                    },
                  });
                  break;
                case 'paragraph--paragraph_texto':
                  sections.push({
                    type: 'text',
                    content: {
                      sumary: idmedio?.attributes?.field_texto?.summary || '',
                      value: idmedio?.attributes?.field_texto?.value || '',
                    },
                  });
                  break;
                case 'paragraph--paragraph_enlaces':
                  listLinks = getListLinks(
                    idmedio?.relationships?.field_list?.data,
                    section?.included,
                  );
                  sections.push({
                    type: 'links',
                    content: listLinks,
                  });
                  break;
                case 'paragraph--paragraph_multimedia':
                  media = getMultimediaDefault(
                    idmedio?.relationships?.field_list?.data,
                    section?.included,
                  );
                  sections.push({
                    type: 'media',
                    content: media,
                  });
                  break;
                case 'paragraph--separador':
                  let sep = getSeparator(idmedio?.id, section?.included);
                  sections.push({
                    type: 'separator',
                    content: {
                      px: sep || '0',
                    },
                  });
                  break;

                case 'paragraph--paragraph_tarjeta':
                  sections.push({
                    type: 'card',
                    content: {
                      idCard:
                        idmedio?.relationships?.field_list?.data[0]?.id || '',
                    },
                  });
                  break;
                case 'paragraph--paragraph_suggested_plan':
                  let plan = getSuggestedPlans(idmedio, section?.included);
                  sections.push({
                    type: 'plan',
                    content: plan,
                  });
                  categories.push(plan?.category);
                  break;
                case 'paragraph--locations':
                  let l = getLocationItem(idmedio);
                  sections.push({
                    type: 'location',
                    content: l,
                  });
                  break;
                case 'paragraph--paragraph_button':
                  let b = getButton(idmedio);
                  sections.push({
                    type: 'button',
                    content: b,
                  });
                  break;
                case 'paragraph--cards_group':
                  let arrayCards: any[] = section?.included;
                  let group = getCardsGroup(
                    idmedio,
                    arrayCards.filter(
                      (elem: any) => elem?.type === 'paragraph--card',
                    ),
                    section?.included,
                  );

                  sections.push({
                    type: 'cardsGroup',
                    content: group,
                  });
                  break;
                case 'paragraph--horizontal_card':
                  let arrayCardsH: any[] = section?.included;
                  let hGroup = getHorizontalCards(
                    idmedio,
                    arrayCardsH.filter(
                      (elem: any) => elem?.type === 'paragraph--card',
                    ),
                    section?.included,
                  );
                  sections.push({
                    type: 'horizontalCards',
                    content: hGroup,
                  });
                  break;
                case 'paragraph--custom':
                  sections.push({
                    type: 'custom',
                    content: idmedio?.attributes?.field_custom || '' || '',
                  });
                  break;
                case 'paragraph--horizontal_card_textos':
                  sections.push({
                    type: 'horizontal_card_textos',
                    content: {
                      primaryText:
                        idmedio?.attributes?.field_descripcion_sumario?.value ||
                        '',
                      secondaryText:
                        idmedio?.attributes?.field_texto_secundario?.value ||
                        '',
                      title: idmedio?.attributes?.field_title || '',
                    },
                  });
                  break;

                case 'paragraph--horizontal_card_images':
                  let arrayCardsHI: any[] = section?.included;

                  let imagesGroup = horizontal_card_images(
                    idmedio,
                    arrayCardsHI.filter(
                      (elem: any) => elem?.type === 'file--file',
                    ),
                  );
                  sections.push({
                    type: 'paragraph--horizontal_card_images',
                    content: imagesGroup,
                  });
                  break;

                case 'paragraph--horitontal_card_video':
                  const attr = idmedio?.attributes;

                  sections.push({
                    type: 'horizontal_card_video',
                    content: {
                      title: attr?.field_title,
                      description: attr?.field_descripcion_sumario?.value,
                      video:
                        apiMainURL +
                        section?.included?.find(
                          ({ id = '' }) =>
                            id ===
                            section?.included?.find(
                              ({ id = '' }) =>
                                id ===
                                idmedio?.relationships?.field_video?.data?.id,
                            )?.relationships?.field_media_video_file?.data?.id,
                        )?.attributes?.uri?.url,
                    },
                  });

                  break;
              }
            }
          },
        );
      }
    });

    element = {
      breadcrumb: breadCrumb,
      title: elem?.attributes?.title || '',
      content: sections,
      categories: categories,
    };
  });
  return element;
}
export function getContentsArchetype(section: any): object {
  let arrayCards: any[] = [];
  let sections: any[] = [];
  let cardGroup: any[] = [];
  if (section?.data && section.data.length > 0) {
    section?.included?.map((inc: any) => {
      switch (inc?.type) {
        case 'paragraph--cards_group':
          arrayCards = section?.included;
          let group = getCardsGroup(
            inc,
            arrayCards.filter((elem: any) => elem?.type === 'paragraph--card'),
            section?.included,
          );
          sections.push({
            type: 'cardsGroup',
            content: group,
          });
          break;
        case 'paragraph--horizontal_card':
          let hGroup = getHorizontalCards(
            inc,
            arrayCards.filter((elem: any) => elem?.type === 'paragraph--card'),
            section?.included,
          );
          sections.push({
            type: 'horizontalCards',
            content: hGroup,
          });
          break;
      }
    });
  }
  return sections;
}

export function getCardsGroup(elem: any, cards: any[], includes: any): object {
  // console.log("ENTRANTE A getCardsGroup",elem);
  // console.log("INCLUDES ENTRANTE A getCardsGroup",includes);

  let cardGroup: any[] = [];
  elem?.relationships?.field_card?.data.map((card: any) => {
    cards.map((c: any) => {
      if (card?.id == c?.id) {
        cardGroup.push({
          title: c?.attributes?.field_title || '',
          description: c?.attributes?.field_descripcion_sumario?.value || '',
          src: getImage(c?.relationships?.field_icono?.data?.id, includes),
          link: c?.attributes?.field_link?.uri?.split(':')[1] || '',
          hrefLabel: c?.attributes?.field_link?.title || '',
          label: c?.attributes?.field_label || '',
        });
      }
    });
  });
  return cardGroup;
}
export function getHorizontalCards(
  elem: any,
  cards: any[],
  includes: any,
): object {
  let horizontalCard: any[] = [];
  horizontalCard.push({
    title: elem?.attributes?.field_title || '',
    description: elem?.attributes?.field_descripcion_sumario?.value || '',
    src: getImage(elem?.relationships?.field_imagen_card?.data?.id, includes),
  });
  return horizontalCard;
}
export function horizontal_card_images(elem: any, files: any[]): object {
  return {
    image1: getImage(
      elem?.relationships?.field_imagen_card?.data?.id || '',
      files,
    ),
    image2: getImage(
      elem?.relationships?.field_imagen_card_2?.data?.id || '',
      files,
    ),
  };
}
export function getLocationItem(l: any): object {
  let location: object = {
    name: l?.attributes?.field_title || 'default name',
    opening: l?.attributes?.field_location_schedule?.from || 0,
    closing: l?.attributes?.field_location_schedule?.to || 0,
    formal: l?.attributes?.field_location_formal,
    included: l?.attributes?.field_location_included,
  };
  return location;
}

export function getButton(l: any): object {
  let location: object = {
    name: l?.attributes?.field_title || 'Download',
    link: l?.attributes?.field_link?.uri || '/',
  };
  return location;
}
export function getBannerParagraph(id: any, included: any[]): string {
  let idMedia = '';
  let banner = '';
  included?.map((include: any, index: number) => {
    if (include.id == id) {
      idMedia = include?.relationships?.field_media_image?.data?.id || '';
      included?.map((inc: any) => {
        if (inc?.id == idMedia) {
          banner = apiMainURL + inc?.attributes?.uri?.url || '';
        }
      });
    }
  });
  return banner;
}
export function getBannerDefaultSection(
  elements: any[],
  included: any[],
): object[] {
  let banners: object[] = [];
  let image2 = {};
  elements?.forEach((element) => {
    let band = false;
    included?.map((inc: any, index: number) => {
      if (element?.id == inc.id) {
        if (inc?.relationships?.field_image?.data?.id) {
          band = true;

          let id = inc?.relationships?.field_image?.data?.id || '';
          included?.map((include: any) => {
            if (id === include.id) {
              image2 = {
                src: getImage(
                  inc?.relationships?.field_image?.data?.id,
                  included,
                ),
                type: 'image',
              };
            }
          });
        } else {
          if (inc?.relationships?.field_video?.data?.id) {
            band = true;

            let id = inc?.relationships?.field_video?.data?.id || '';
            image2 = {
              src: getBannerVideo(id, included),
              type: 'video',
            };
          }
        }
        banners.push({
          altText: '',
          caption: '',
          link: getURL(inc?.attributes?.field_link?.uri || '') || '',
          src: image2,
        });
      }
    });
  });
  return banners;
}
export function getSuggestedPlans(
  planData: any,
  includes: any[],
): suggestedPlan {
  let sugesstedPlan: suggestedPlan;
  let title = planData?.attributes?.field_texto?.value || '';
  let description = planData?.attributes?.field_title || '';
  let image = getImage(
    planData?.relationships?.field_image?.data?.id,
    includes,
  );
  let catId = planData?.relationships?.field_plan_categoria?.data?.id || '';
  let category = '';
  includes?.map((inc: any) => {
    if (inc?.id == catId) {
      category = inc?.attributes?.name || '';
    }
  });
  sugesstedPlan = {
    title: title,
    description: description,
    image: image,
    category: category,
  };
  return sugesstedPlan;
}
export function getListLinks(elements: any[], included: any[]): object[] {
  let links: object[] = [];
  elements?.forEach((element) => {
    included?.map((inc: any, index: number) => {
      if (element?.id == inc.id) {
        let image = getImage(
          inc?.relationships?.field_image?.data?.id,
          included,
        );
        links.push({
          title: inc?.attributes?.title || '',
          column: inc?.attributes.field_column || '',
          description: inc?.attributes.body?.summary || '',
          image: image || '',
          link: getURL(inc?.attributes?.field_link?.uri || ''),
          comingSoon: inc.attributes.field_coming_soon,
        });
      }
    });
  });
  return links;
}
export function getMultimediaDefault(
  elements: any[],
  included: any[],
): object[] {
  let media: object[] = [];
  elements?.forEach((element) => {
    included?.map((inc: any, index: number) => {
      if (element?.id == inc.id) {
        let mediaVideo = inc?.relationships?.field_image?.data?.id;
        let objElem = {};
        switch (inc?.type) {
          case 'node--video':
            let video = getVideo(inc?.id, included);
            objElem = {
              type: 'video',
              src: video,
            };
            break;
          case 'node--infografia':
            let info = getInfographic(inc?.id, included);
            objElem = {
              type: 'infografia',
              src: info,
            };
            break;
        }
        media.push(objElem);
      }
    });
  });
  return media;
}
export function getVideo(id: string, includes: any[]): string {
  let idNode = '';
  let idMedia = '';
  let video = '';
  includes?.forEach((include: any) => {
    if (include.type == 'node--video' && include.id == id) {
      idNode = include?.relationships?.field_video?.data?.id || '';
    }
  });
  includes?.forEach((include: any) => {
    if (include.type == 'media--video' && include.id == idNode) {
      idMedia = include?.relationships?.field_media_video_file?.data?.id || '';
    }
  });
  includes?.forEach((include: any) => {
    if (include.type == 'file--file' && include.id == idMedia) {
      video = include?.attributes?.uri?.url || '';
    }
  });

  return apiMainURL + video;
}
export function getBannerVideo(id: string, includes: any[]): string {
  let idMedia = '';
  let video = '';
  includes?.forEach((include: any) => {
    if (include.type == 'media--video' && include.id == id) {
      idMedia = include?.relationships?.field_media_video_file?.data?.id || '';
    }
  });
  includes?.forEach((include: any) => {
    if (include.type == 'file--file' && include.id == idMedia) {
      video = include?.attributes?.uri?.url || '';
    }
  });

  return apiMainURL + video;
}
export function getInfographic(id: string, includes: any[]): string {
  let idNode = '';
  let infographic = '';
  includes?.forEach((include: any) => {
    if (include.type == 'node--infografia' && include.id == id) {
      idNode = include?.relationships?.field_image?.data?.id || '';
    }
  });
  includes?.forEach((include: any) => {
    if (include.type == 'file--file' && include.id == idNode) {
      infographic = include?.attributes?.uri?.url || '';
    }
  });

  return apiMainURL + infographic;
}
export function getImage(id: string, includes: any[]): string {
  let image = '';
  includes?.forEach((include: any) => {
    if (include.type == 'file--file' && include.id == id) {
      image = include?.attributes?.uri?.url || '';
    }
  });

  return apiMainURL + image;
}
export function getURL(urlIn: string): string {
  let url_fake: string[] = urlIn.split(':');
  let url: string = '';
  url_fake[0] == 'internal' ? (url = url_fake[1]) : (url = urlIn);
  return url;
}
export function getSeparator(id: string, included: any[]): string {
  let band = false;
  let s = '';
  included?.map((inc: any, index: number) => {
    if (inc?.id == id) {
      band = true;
      s = inc?.attributes?.field_px;
      return s;
    }
  });
  return band ? s : '';
}
export function GetBodyInnerHTML(props: any) {
  const cleanHtmlString = DOMpurify.sanitize(props.content, {
    USE_PROFILES: { html: true },
  });
  const reactElement = reactParser(cleanHtmlString);

  return <>{reactElement}</>;
}
