import React, {
  FormEvent,
  LegacyRef,
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  getGalleryEventComments,
  postGalleryEventComment,
} from '../../../../services/api/api-socios-maz';

import CommentList from '../CommentList';
import LoadSpinner from '../../../UI/LoadSpinner/LoadSpinner';

import { apiMainURL } from '../../../../modules/settings/environment';
import NewCommentForm from '../NewCommentForm';

type CommentsProps = {
  eventId: string;
  drupalId: string;
  newMessageRef?: LegacyRef<HTMLTextAreaElement>;
};

const Comments = ({ eventId, drupalId, newMessageRef }: CommentsProps) => {
  const [commentsToShow, setCommentsToShow] = useState([]);
  const [loadingComments, setLoadingComments] = useState(true);
  const [isShowingEverything, setIsShowingEverything] = useState(false);

  const firstComments = isShowingEverything
    ? commentsToShow
    : commentsToShow.slice(0, 6);

  const slashDateTime = (date: string | number) =>
    new Date(date).toISOString().split('T')[0].split('-').join('/');

  const fetchComments = useCallback(async () => {
    setLoadingComments(true);
    const commentsData = await getGalleryEventComments(eventId!);
    const included = commentsData.included;

    const comments = commentsData.data.map(
      ({
        attributes: {
          changed,
          comment_body: { value },
        },
        relationships: {
          uid: {
            data: { id },
          },
        },
      }: {
        attributes: any;
        relationships: any;
      }) => {
        const picId =
          included.filter(
            (props: any) => props.type === 'user--user' && id === props.id,
          )[0].relationships.user_picture.data?.id || null;

        return {
          date: slashDateTime(changed),
          commentElement: value,
          userName: included.filter(
            ({ id: userId }: { id: string }) => userId === id,
          )[0].attributes.name,
          userImage:
            picId &&
            `${apiMainURL}${
              included.filter(
                ({ id: imageId }: { id: string }) => imageId === picId,
              )[0].attributes.uri.url
            }`,
          isLarge: value.length > 110,
        };
      },
    );

    setCommentsToShow(comments);
    setLoadingComments(false);
  }, [eventId]);

  useEffect(() => {
    fetchComments();
  }, [fetchComments]);

  const submitComment = (e: FormEvent, comment: string) => {
    e.preventDefault();

    if (comment.length > 0) {
      const formData = new FormData();

      formData.append('comment', comment);

      postGalleryEventComment(drupalId, formData);
      setLoadingComments(true);
      setTimeout(() => fetchComments(), 500);
    }
  };
  const onShowMoreClick = () => setIsShowingEverything(true);

  const NoComments = <p>No Comments Yet</p>;
  const hasComments =
    commentsToShow.length > 0 ? (
      <CommentList commentsToShow={firstComments} />
    ) : (
      NoComments
    );
  const isLoading = !loadingComments ? hasComments : <LoadSpinner />;

  return (
    <>
      <p className='comments-title my-4'>
        Comments{' '}
        <sup className='rounded-circle number-of-comments'>
          {commentsToShow.length}
        </sup>
      </p>
      <div className='row comments-cols'>{isLoading}</div>
      {commentsToShow.length > 5 && !isShowingEverything && (
        <div className='d-flex justify-content-center mt-2'>
          <button
            className='border-0 bg-transparent fs-5'
            onClick={onShowMoreClick}>
            Show More
          </button>
        </div>
      )}
      <h4 className='mt-4 mb-3'>Post a comment</h4>
      <div className='w-100 p-2 comment-text-area mb-4'>
        <NewCommentForm onSubmit={submitComment} textareaRef={newMessageRef} />
      </div>
    </>
  );
};

export default Comments;
