import React from 'react';
import { GetBodyInnerHTML } from '../../../Layouts/default-section/getDefaultElements';
import { ReactComponent as Seal } from '../../../../assets/LPC/GetReady/Seal.svg';

interface MemoriesProps {
  title: string;
  desc: string;
  video: string;
}

const Memories = ({ desc, title, video }: MemoriesProps) => {
  return (
    <div className='container-fluid'>
      <div className='row pt-5'>
        <div className='col col-12 col-md-5 gx-5 memories-body'>
          <Seal />
          <p className='subtitle-lpc-media'>{title}</p>
          <GetBodyInnerHTML content={desc} />
        </div>
        <div className='col col-12 col-md-7 text-center'>
          <video
            className='mt-4 w-100 media-lpc-landing'
            src={video}
            controls
          />
        </div>
      </div>
    </div>
  );
};

export default Memories;
