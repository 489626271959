import { ContentEventProps } from './ContentEvent';

type PageManagerProps = {
  actual: string;
  items: string;
  next?: number;
  prev?: number;
  total: string;
  totalPages: string;
};

export type TabsItemsState = {
  events: ContentEventProps[];
  pageManager: PageManagerProps;
  isLoading: boolean;
  activeTab: string;
};

export enum TabsItemsCases {
  SET_INITIAL_INFO = 'SET_INITIAL_INFO',
  CHANGE_PAGE = 'CHANGE_PAGE',
  SWITCH_LOAD = 'SWITCH_LOAD',
  CHANGE_ACTIVE_TAB = 'CHANGE_ACTIVE_TAB',
}

export type CalendarAction = {
  type: TabsItemsCases;
  payload?: any;
};

export const tabsItemsInitialState = {
  events: [],
  pageManager: {
    actual: '0',
    items: '0',
    total: '0',
    totalPages: '0',
  },
  activeTab: 'month',
  isLoading: false,
};

export const tabsItemsReducer = (
  state: TabsItemsState,
  action: CalendarAction,
) => {
  const { payload } = action;

  switch (action.type) {
    case 'SET_INITIAL_INFO': {
      const {
        events,
        pager,
      }: { events: ContentEventProps[]; pager: PageManagerProps } = payload;

      return { ...state, events, pageManager: pager };
    }

    case 'CHANGE_PAGE': {
      const { page }: { page: number } = payload;

      return {
        ...state,
        pageManager: { ...state.pageManager, actual: `${page}` },
      };
    }

    case 'SWITCH_LOAD': {
      const { isLoading }: { isLoading: boolean } = payload;

      return { ...state, isLoading };
    }

    case 'CHANGE_ACTIVE_TAB': {
      const { activeTab }: { activeTab: string } = payload;

      return { ...state, activeTab };
    }

    default:
      return { ...state };
  }
};
