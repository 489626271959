import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './scss/App.scss';
import { BusinessResults } from './components/pages/BusinessResults';
import { Landing } from './components/pages/Landing';
import { MeetThePartners } from './components/pages/Meet-the-partners';
import { Purposes } from './components/pages/Purpose-of-a-partner/index';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import { useEffect, useState } from 'react';
import { getDomain, domain_page } from './modules/settings/domain';
import LandingUserWithSession from './components/pages/Landing/Landing-with-out-session';
import FooterTerms from './components/Layouts/Footer';
import { AboutMeAtABI } from './components/pages/AboutMeAtABI';
import LPC_Wrapper from './components/pages/Lpc';
import TermsAndConditions from './components/pages/TermsAndConditions';
import Callback from './components/Layouts/Callback';
import LateralMenu from './components/Layouts/Lateral-menu';
import { Initiatives } from './components/pages/Iniciatives/index';
import ProfileConfig from './components/pages/ProfileConfig';
import LPCGallery from './components/pages/Lpc/Gallery';
import PartnerDetails from './components/pages/Meet-the-partners/PartnerDetails';
import { LPC_Component_Event } from './components/pages/Lpc/Event/lpc';
import { LPC_Component_Destiny } from './components/pages/Lpc/Destiny/lpc';
import Schedule from './components/pages/Lpc/Event/items/schedule/index';
import { apiMainURL } from './modules/settings/environment';
import { deleteSession } from './services/api/api-socios-maz';
import LoadSpinner from './components/UI/LoadSpinner/LoadSpinner';
import { EventHallsMapsComponent } from './components/pages/Lpc/Event/items/maps/Event-halls';
import { RestaurantsMapsComponent } from './components/pages/Lpc/Event/items/maps/Restaurants';
import { RelaxationMapsComponent } from './components/pages/Lpc/Event/items/maps/Relaxation';
import { SportsMapsComponent } from './components/pages/Lpc/Event/items/maps/Sports';
import EventDescription from './components/pages/Lpc/EventDescription';
import { Flywheel } from './components/pages/BusinessResults/Bussiness-results/Flywheel';
// import { Townhalls } from './components/pages/BusinessResults/Bussiness-results/Townhalls';
import { STI_BonusComponent } from './components/pages/AboutMeAtABI/STI-Bonus-Simulator';
import { CompensationSimulatorComponent } from './components/pages/AboutMeAtABI/Compensation -Simulator';
import { AlertsGenerator } from './components/UI/alerts-Service/alerts';
import { errorAlert } from './components/UI/alerts-Service/swalSignInErrorAlert';
import Content from './components/pages/Lpc/Content';
import ContentPresentation from './components/pages/Lpc/ContentPresentation';
import Loyalty from './components/pages/AboutMeAtABI/Loyalty';
import Benefit from './components/pages/AboutMeAtABI/Benefit';
import { WhatIsYourPartnerProfile } from './components/pages/profile/Partners-profile/What-is-your-partner-profile/index';
import { ArchetypesDetails } from './components/pages/profile/Partners-profile/Archetypes-details';
import { CultureSquads } from './components/pages/Culture-squads';
import { CommunitiesIndex } from './components/pages/Culture-squads/Communities/index';
import { CommunitiesDetails } from './components/pages/Culture-squads/Communities/details';
import { AppsHub } from './components/pages/ex-hub/apps-hub';
import { MenuData } from './components/Layouts/menu-profile/menu.data';
import { PartnerProfileExec } from './components/pages/profile/admin-partner-profile/executives/Partner-profile-exec';
import { TeamProfiles } from './components/pages/profile/admin-partner-profile/super-executives/team-profiles';
import { OverviewProfile } from './components/pages/profile/admin-partner-profile/general-items/Overview/index';
import { ArchetypesDefinition } from './components/pages/profile/admin-partner-profile/general-items/definition';
import { MyProfile } from './components/pages/profile/admin-partner-profile/general-items/My-profile';
import { WhatYouCanFind } from './components/pages/profile/admin-partner-profile/What-you-can-find';
import { DetailPartnerProfile } from './components/pages/profile/admin-partner-profile/general-items/detailt-partner-profile';
import { DetailTeamProfile } from './components/pages/profile/admin-partner-profile/general-items/detail-team-profile';
import { DataInterpretation } from './components/pages/profile/admin-partner-profile/data-interpretation';
import { CalendarIndex } from './components/pages/Culture-squads/Calendar';
import { CalendarDetail } from './components/pages/Culture-squads/Calendar/detail';
import LandingUserWithSessionApp from './components/pages/Landing/AutomaticLoginApp/index';
import Maintenance from './components/Layouts/Maintenance';
import MazGallery from './components/pages/MazResources/Gallery';
import MazPresentation from './components/pages/MazResources/Presentation';
import Gallery from './components/Layouts/Gallery';
import MazEventDescription from './components/Layouts/EventDescription';
import Binnacle from './components/pages/Binnacle';
import DownloadTest from './components/pages/DownloadTest';
import { CallbackPDF } from './components/Layouts/Callback/callbackPDF';
import BinnacleResource from './components/pages/BinnacleResource';
import { PartnerProfileRender } from './components/pages/profile/Partners-profile/Partner-profile';
import { DownloadLink } from './components/pages/Lpc/GetReady/download-link';
import { FormsLink } from './components/pages/Lpc/forms-link';
import { PaginaGenerica } from './components/Layouts/default-section/paginaGenerica';

function getFaviconEl(id: string) {
  return document.getElementById(id);
}
function App() {
  const [accept, setAccept] = useState('-1');
  const [domain, setDomain] = useState(
    domain_page(window.location.hostname + ''),
  );
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  const root = () => {
    setDomainData(getDomain(window.location.href + ''));
    document.documentElement.style.setProperty(
      '--font-family-mon',
      domain.fonts,
    );
  };
  const handleFavicon = () => {
    // const appName: any = getFaviconEl('appName');
    // appName.content = domainData.name || '';
  };
  useEffect(() => {
    root();
  }, []);

  if (localStorage.getItem('code_verifier')) {
    if (localStorage.getItem('localToken')) {
      var myHeaders = new Headers();
      myHeaders.append(
        'authorization',
        `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
      );
      var formdata = new FormData();
      let sharp: string = localStorage.getItem('sharp')?.toString() || '';
      formdata.append('sharp', sharp);
      var requestOptions: RequestInit = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
      };
      fetch(`${apiMainURL}/api/legal_validate`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          if (JSON.parse(result)?.legal == '1') {
            setAccept('1');
            return (
              <AlertsGenerator
                type='success'
                message='Si se pudo validar'></AlertsGenerator>
            );
          } else {
            setAccept('0');
          }
          localStorage.setItem(
            'accept-terms',
            JSON.parse(result)?.legal?.toString() || '0',
          );
        })
        .catch((error) => {
          errorAlert();
          deleteSession(window.location.href);
        });
    }

    if (accept == '0') {
      return (
        <div className={domain.color}>
          <Router>
            <Routes>
              <Route path='/auth/callback' element={<Callback />} />
              <Route path='*' element={<TermsAndConditions />} />
            </Routes>
          </Router>
          <FooterTerms />
        </div>
      );
    } else {
      if (accept == '1') {
        return (
          <div>
            <Router>
              <LateralMenu />
              <Routes>
                <Route path='/' element={<Landing />} />
                <Route path='/maintenance' element={<Maintenance />} />
                <Route path='/auth/callback' element={<Callback />} />
                <Route path='/home' element={<Landing />} />
                <Route path='/lpc' element={<LPC_Wrapper />} />
                <Route path='/lpc/gallery' element={<LPCGallery />} />
                <Route
                  path='/lpc/:id/gallery/:eventId'
                  element={<EventDescription />}
                />
                <Route
                  path='/lpc/:id/secondaryGallery/:eventId'
                  element={<EventDescription />}
                />
                <Route path='/lpc/schedule' element={<Schedule />} />
                <Route
                  path='/lpc/events/maps/event-halls'
                  element={<EventHallsMapsComponent />}
                />
                <Route
                  path='/lpc/events/maps/restaurants'
                  element={<RestaurantsMapsComponent />}
                />
                <Route
                  path='/lpc/events/maps/relaxation'
                  element={<RelaxationMapsComponent />}
                />
                <Route
                  path='/lpc/events/maps/sports'
                  element={<SportsMapsComponent />}
                />
                <Route
                  path='/lpc/event/maps'
                  element={<EventHallsMapsComponent />}
                />
                <Route
                  path='/lpc/content/:presentationId'
                  element={<ContentPresentation />}
                />
                <Route path='/lpc/content' element={<Content />} />
                <Route
                  path='/meet-the-partners/:id'
                  element={<PartnerDetails />}
                />
                <Route path='/lpc/event' element={<LPC_Component_Event />} />
                <Route
                  path='/lpc/city-and-hotel'
                  element={<LPC_Component_Destiny />}
                />
                <Route path='/business-results' element={<BusinessResults />} />

                <Route
                  path='/corporate/business-results-flywheel'
                  element={<Flywheel />}
                />
                {/* <Route
                  path='/corporate/business-results-townhalls'
                  element={<Townhalls />}
                /> */}

                <Route
                  path='/meet-the-partners'
                  element={<MeetThePartners />}
                />
                <Route path='/download-test' element={<DownloadTest />} />
                <Route path='/purposes' element={<Purposes />} />
                <Route path='/about-me-at-abi' element={<AboutMeAtABI />} />
                <Route
                  path='/about-me-at-abi/sti-bonus'
                  element={<STI_BonusComponent />}
                />
                <Route
                  path='/about-me-at-abi/compensation-simulator'
                  element={<CompensationSimulatorComponent />}
                />
                <Route path='/about-me-at-abi/loyalty' element={<Loyalty />} />
                <Route
                  path='/about-me-at-abi/loyalty/:benefitId'
                  element={<Benefit />}
                />
                <Route path='/initiatives' element={<Initiatives />} />
                <Route path='/profile' element={<ProfileConfig />} />
                <Route
                  path='/profile/what-is-your-partner-profile'
                  element={<WhatIsYourPartnerProfile />}
                />
                <Route
                  path='/profile/partner-profile/archetype-detail'
                  element={<ArchetypesDetails />}
                />
                <Route
                  path='/profile/partner-profile'
                  element={<MyProfile />}
                />
                <Route path='/profile/binnacle' element={<Binnacle />} />
                <Route path='/download-pdf-link' element={<DownloadLink />} />
                <Route path='/forms-link-lpc' element={<FormsLink />} />

                <Route
                  path='/profile/binnacle/:resourceId'
                  element={<BinnacleResource />}
                />
                <Route
                  path='/profile/data-interpretation'
                  element={<DataInterpretation />}
                />
                <Route path='culture-squads' element={<CultureSquads />} />
                <Route path='partners-clubs' element={<CommunitiesIndex />} />
                <Route
                  path='/partners-clubs/details'
                  element={<CommunitiesDetails />}
                />
                <Route path='calendar' element={<CalendarIndex />} />
                <Route
                  path='calendar/detail/:detailId'
                  element={<CalendarDetail />}
                />

                <Route
                  path='/partners/downloadTest'
                  element={<DownloadTest />}
                />
                <Route
                  path='/partners/downloadTest2'
                  element={<PartnerProfileRender />}
                />

                {/* SECONDARY ROUTING */}
                <Route path='/partners' element={<MenuData />}>
                  {/* <Route
                    path='/partners/what-is-your-partner-profile'
                    element={<PartnerProfileSuperExec />}
                  /> */}
                  <Route
                    path='/partners/partner-profile'
                    element={<PartnerProfileExec />}
                  />
                  <Route
                    path='/partners/team-profiles'
                    element={<TeamProfiles />}
                  />
                  <Route
                    path='/partners/overview'
                    element={<OverviewProfile />}
                  />
                  <Route
                    path='/partners/partner-profile/my-partner-profile'
                    element={<MyProfile />}
                  />
                  <Route path='/partners/binnacle' element={<Binnacle />} />
                  <Route
                    path='/partners/binnacle/:resourceId'
                    element={<BinnacleResource />}
                  />
                  <Route
                    path='/partners/archetypes-definition'
                    element={<ArchetypesDefinition />}
                  />

                  <Route
                    path='/partners/overview/archetype-detail'
                    element={<ArchetypesDetails />}
                  />
                  <Route
                    path='/partners/partner-profile/my-partner-profile/archetype-detail'
                    element={<ArchetypesDetails />}
                  />

                  <Route
                    path='/partners/archetypes-definition/archetype-detail'
                    element={<ArchetypesDetails />}
                  />
                  <Route
                    path='/partners/partner-profile/detail'
                    element={<DetailPartnerProfile />}
                  />
                  <Route
                    path='/partners/team-profiles/detail'
                    element={<DetailTeamProfile />}
                  />

                  <Route
                    path='/partners/data-interpretation'
                    element={<DataInterpretation />}
                  />
                </Route>
                <Route
                  path='/profile/data-interpretation'
                  element={<DataInterpretation />}
                />

                <Route
                  path='/profile/what-you-can-find'
                  element={<WhatYouCanFind />}
                />

                <Route
                  path='/terms-and-conditions'
                  element={<TermsAndConditions />}
                />

                <Route path='/ex-hub' element={<AppsHub />} />
                <Route path='/contenido' element={<PaginaGenerica />} />
                <Route path='maz-resources' element={<MazGallery />} />
                <Route
                  path='maz-resources/:presentationId'
                  element={<MazPresentation />}
                />
                <Route path='/gallery' element={<Gallery />} />
                <Route
                  path='/gallery/:eventId'
                  element={<MazEventDescription />}
                />
                <Route path='/customscheme' element={<CallbackPDF />} />
              </Routes>
            </Router>
            <FooterTerms content={domainData} />
          </div>
        );
      } else {
        return (
          <div>
            <Router>
              <Routes>
                <Route
                  path='/applogin'
                  element={<LandingUserWithSessionApp />}
                />
                <Route path='/auth/callback' element={<Callback />} />
                <Route path='/' element={<LoadSpinner />} />
              </Routes>
            </Router>
            <FooterTerms />
          </div>
        );
      }
    }
  } else {
    return (
      <div className={domain.color}>
        <Router>
          <Routes>
            <Route path='/applogin' element={<LandingUserWithSessionApp />} />
            <Route path='*' element={<LandingUserWithSession />} />
          </Routes>
        </Router>
        {/*  <button onClick={handleFavicon}>FAV</button> */}
      </div>
    );
  }
}

export default App;
