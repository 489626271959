import React from 'react';
import { useGetPartnersMetricsQuery } from '../../../../../../../services/api/api-socios-maz';

import PartnersMetricsTable from './PartnersMetricsTable';
import SingleMetrics from './SingleMetrics';
import PartnersLevelMetrics from './PartnersLevelMetrics';
import MetricsDoughnut from './MetricsDoughnut';
import LoadSpinner from '../../../../../../UI/LoadSpinner/LoadSpinner';
import MetricsLevelBands from './MetricsLevelBands';
import MetricsOPR from './MetricsOPR';
import MetricsEngagement from './MetricsEngagement';
import MetricsLCM from './MetricsLCM';
import MetricsTargetAchievement from './MetricsTargetAchievement';

import './PartnersMetrics.scss';

type PartnersMetricsProps = {
  date?: string;
};

export const romanBandOrder = ['0', 'I', 'II', 'III', 'IV', 'V'];
export const assignSecondColor = (level: string | number) => {
  switch (+level) {
    case 2:
      return '#000';

    case 3:
      return '#bebebe';

    case 4:
      return '#e08a2d';

    default:
      return '#f5e003';
  }
};

const PartnersMetrics = ({ date }: PartnersMetricsProps) => {
  const metrics = useGetPartnersMetricsQuery({ date }).data;

  const singleMetrics = {
    total: metrics?.total,
    dIAvg: metrics?.dyi_avg,
    engAvg: metrics?.engagement_avg,
    mgrEffAvg: metrics?.manager_effecti_avg,
    tgtAchAvg: metrics?.target_ach_avg,
  };

  return (
    <div className='d-flex flex-column align-items-center partners-metrics'>
      {!metrics && <LoadSpinner />}
      <SingleMetrics {...singleMetrics} />
      <div className='row justify-content-center mt-4 w-100'>
        <div className='col-md-4 d-flex flex-column my-3 pb-4 grid-border-bottom'>
          <MetricsDoughnut
            title='Gender Representation'
            metrics={metrics?.gender}
            backgroundColors={['#F5E003', '#000']}
            labelField={'gender'}
          />
        </div>
        <div className='col-md-4 d-flex flex-column my-3 pb-4 grid-border-bottom'>
          <MetricsDoughnut
            title='Partners Generation'
            metrics={metrics?.generation}
            backgroundColors={['#F5E003', '#000', '#6a6a6a']}
          />
        </div>
        <div className='col-md-4 d-flex flex-column my-3 pb-4 grid-border-bottom'>
          <PartnersMetricsTable metrics={metrics?.tobyband} />
        </div>
        <div className='col-md-6 my-3 pb-4 grid-border-bottom'>
          <PartnersLevelMetrics metrics={metrics?.partners_by_level} />
        </div>
        <div className='col-md-6 my-3 pb-4 grid-border-bottom'>
          <MetricsOPR metrics={metrics?.oprShape} />
        </div>
        <div className='col-md-6 my-3 pb-4 grid-border-bottom'>
          <MetricsLevelBands metrics={metrics?.level_band} />
        </div>
        <div className='col-md-6 my-3 pb-4 grid-border-bottom'>
          <MetricsEngagement metrics={metrics?.egamement_by_band} />
        </div>
        <div className='col-md-6 my-3'>
          <MetricsLCM metrics={metrics?.lcmScorePerCapability} />
        </div>
        <div className='col-md-6 my-3'>
          <MetricsTargetAchievement
            title={`Target Achievement ( ${metrics?.targetAchieventYear} )`}
            metrics={metrics?.targetAchievent}
          />
        </div>
      </div>
    </div>
  );
};

export default PartnersMetrics;
