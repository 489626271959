import {
  Category,
  ContentAction,
  ContentState,
} from '../../../../models/lpcContent.models';
import { Section as SectionModel } from '../../../../models/section.models';

export const initialCategories: Category[] = [
  { id: '', title: '', description: '' },
];
export const initialSections: SectionModel[] = [
  {
    title: '',
    hasShowButton: false,
    cardsInfo: [{ category: '', content: '', id: '', img: '', title: '' }],
  },
];
export const initialState: ContentState = {
  categories: initialCategories,
  sections: initialSections,
  activeSection: null,
  sectionsToShow: initialSections,
  isLoading: false,
};

export const contentReducer = (state: ContentState, action: ContentAction) => {
  const { payload, type } = action;

  switch (type) {
    case 'SET_SECTIONS': {
      const { sections } = payload || initialState;

      for (const section of sections!)
        section.hasShowButton = section?.cardsInfo?.length === 4 || false;

      return { ...state, sections, sectionsToShow: sections, isLoading: false };
    }

    case 'INIT_CATEGORIES': {
      const { categories } = payload || initialState;

      return { ...state, categories, isLoading: false };
    }

    case 'CHANGE_ACTUAL_SECTION': {
      const { activeSection } = payload || initialState;

      return { ...state, activeSection };
    }

    case 'LOAD_ON': {
      return { ...state, isLoading: true };
    }

    case 'SET_CARDS': {
      const { cards } = payload || initialState;

      return { ...state, cards, isLoading: false };
    }

    default: {
      return state;
    }
  }
};
