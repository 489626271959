import React from 'react';

type ButtonItemProps = {
  label: string;
  isActive?: boolean;
  onButtonClick?: (v: string) => void;
};

const ButtonItem = ({
  label,
  isActive = false,
  onButtonClick,
}: ButtonItemProps) => {
  const className = isActive ? 'active' : '';

  const onClick = () => {
    onButtonClick && onButtonClick(label);
  };

  return (
    <li className='d-flex button-item'>
      <button {...{ className, onClick }}>{label}</button>
    </li>
  );
};

export default ButtonItem;
