import React, { useId } from 'react';
import { GetBodyInnerHTML } from '../../../Layouts/default-section/getDefaultElements';

interface CardProps {
  src: string;
  category?: string;
  title: string;
  desc?: string;
  url?: { src: string; label: string };
}

const Card = ({ src, title = '', desc = '', url }: CardProps) => {
  const id = useId();

  return (
    <div key={id} className='lpc-content-card'>
      <a href={url ? url.src : '#no-url'} target='_blank' rel='noreferrer'>
        <div className='card-header'>
          <img src={src} alt='Content Card Header' />
        </div>
        <div className='card-body'>
          <h3>{title}</h3>
          <GetBodyInnerHTML content={desc} />
          {url && (
            <a href={url.src} target='_blank' rel='noreferrer'>
              {url.label}
            </a>
          )}
        </div>
      </a>
    </div>
  );
};

export default Card;
