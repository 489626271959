import React from 'react';

export type SingleMetricsProps = {
  total: number | string;
  engAvg: number | string;
  mgrEffAvg: number | string;
  dIAvg: number | string;
  tgtAchAvg: number | string;
};

const SingleMetricsPrint = ({
  dIAvg = 0,
  engAvg = 0,
  mgrEffAvg = 0,
  tgtAchAvg = 0,
  total,
}: SingleMetricsProps) => {
  return (
    <div className='row row-cols-1 row-cols-md-2 justify-content-center pb-4 number-metrics grid-border-bottom'>
      <div
        className='col col-md-4 d-flex flex-column align-items-center total-partners-border'
        style={{
          width: '25%',
        }}>
        <h1 className='mb-0 fw-bolder'>{total}</h1>
        <p className='tw text-center'>Total Partners</p>
      </div>
      <div className='col col-md-8 row row-cols-2 row-cols-md-4'>
        <div className='col col-6 d-flex flex-column align-items-center justify-content-center my-1'>
          <h3 className='mb-0 fw-bolder'>{engAvg}%</h3>
          <p className='tw text-center metrics-description'>Engagement Avg</p>
        </div>
        <div className='col col-6 d-flex flex-column align-items-center justify-content-center my-1'>
          <h3 className='mb-0 fw-bolder'>{mgrEffAvg}%</h3>
          <p className='tw text-center metrics-description'>Mgr Effecti Avg</p>
        </div>
        <div className='col col-6 d-flex flex-column align-items-center justify-content-center my-1'>
          <h3 className='mb-0 fw-bolder'>{dIAvg}%</h3>
          <p className='tw text-center metrics-description'>D&I Avg</p>
        </div>
        <div className='col col-6 d-flex flex-column align-items-center justify-content-center my-1'>
          <h3 className='mb-0 fw-bolder'>{tgtAchAvg}%</h3>
          <p className='tw text-center metrics-description'>Target Ach Avg</p>
        </div>
      </div>
    </div>
  );
};

export default SingleMetricsPrint;
