import React from 'react';

import { HorizontalBar } from 'react-chartjs-3';

const options = {
  legend: {
    display: false,
    position: 'top',
  },
  scales: {
    borderColor: '#fff',
    xAxes: [
      {
        gridLines: {
          color: 'rgba(255,255,255,1)',
          zeroLineColor: 'rgba(255,255,255,0.5)',
        },
        barThickness: 10,
        ticks: {
          stepSize: 2,
          fontColor: '#fff',
          min: 0,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: false,
        },
        barThickness: 10,
        ticks: {
          min: 0,

          fontColor: '#fff',
        },
      },
    ],
  },
};

export default function UserBarDashboard(props: any) {
  const { dream, develop, culture } = props;
  const data = {
    // labels: ['January'],
    labels: ['Dream Big', 'Develop People', 'Live Our Culture'],
    datasets: [
      {
        label: 'My First dataset',
        backgroundColor: '#F5E003',
        borderWidth: 0,
        hoverBackgroundColor: '#F5E003',
        hoverBorderColor: '#F5E003',
        data: [dream || 0, develop || 0, culture || 0],
        categoryPercentage: 1,
        barPercentage: 1,
        //data: ((value%2)==0)?[value, value]:[value, (value%2)+10],
      },
    ],
  };

  return (
    <div className=''>
      <HorizontalBar data={data} width={80} height={50} options={options} />
    </div>
  );
}
