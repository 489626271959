import React from 'react';

import './CommentCard.scss';

type CommentCardProps = {
  partnerName: string;
  datetime: string;
  comment: string;
  grow?: boolean;
  imageUrl?: string;
};

const CommentCard = ({
  partnerName,
  datetime,
  comment,
  grow = false,
  imageUrl,
}: CommentCardProps) => {
  const className = `d-flex border rounded-3 comment-card px-3 py-2 gap-3 h-50 ${
    grow && 'flex-grow-1'
  }`;

  return (
    <div {...{ className }}>
      <div className='d-flex flex-column'>
        <div className='partner-icon rounded-circle overflow-hidden position-relative text-center'>
          <img
            className='position-absolute h-100'
            src={
              imageUrl ||
              'https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png'
            }
            alt={partnerName}
          />
        </div>
      </div>
      <div className='w-100 d-flex flex-column h-100'>
        <div className='d-flex justify-content-between'>
          <p className='fw-bolder'>{partnerName}</p>
          <p>{datetime}</p>
        </div>
        <p>{comment}</p>
        {/* <div className='d-flex justify-content-end gap-3 mt-auto'>
          <div className='logo heart' />
          <p className='mt-2'>10</p>
        </div> */}
      </div>
    </div>
  );
};

export default CommentCard;
