import React, { useCallback, useEffect, useReducer } from 'react';
import { ContentActionCases } from '../../../../models/lpcContent.models';
import { apiMainURL } from '../../../../modules/settings/environment';
import {
  getLPCContentCategories,
  getLPCContentSections,
  useGetHomeDefaultContentQuery,
} from '../../../../services/api/api-socios-maz';
import LoadSpinner from '../../../UI/LoadSpinner/LoadSpinner';
import { contentReducer, initialState } from './reducer';
import BreadCrumb from '../../../UI/BreadCrumb';

import './Content.scss';
import Description from './Description';
import { getDefaultContent } from '../../../Layouts/default-section/getDefaultElements';
import Card from './Card';
import NoDataFound from '../../../Layouts/NoDataFound';

const Content = () => {
  const [state, dispatch] = useReducer(contentReducer, initialState);

  const crumbs = [
    { path: '/home', label: 'MAZ Partners' },
    { path: '/lpc', label: 'LPC' },
    { label: 'Content LPC 2022' },
  ];

  const data = useGetHomeDefaultContentQuery('lpc/content')?.data;
  const dataapi = getDefaultContent(data);
  const title = dataapi?.title;
  const desc =
    dataapi?.content?.find(({ type = '' }) => type === 'text')?.content
      ?.value || '';
  const errorMsg =
    dataapi?.content
      ?.find(({ type = '' }) => type === 'horizontal_card_textos')
      ?.content?.primaryText?.replace(/(<(|\/)p>)+/g, '') || '';

  // For future "Show More" button
  const getCategorySections = useCallback(async (limit?: number) => {
    const { data, ...otherData } = await getLPCContentSections(
      'field_imagen_destacada',
      limit,
    );

    return (
      data?.map(({ attributes = {}, relationships = {} }: any) => {
        const url = attributes?.field_link || {};
        const img = otherData?.included?.find(
          ({ id = '' }) =>
            id === relationships?.field_imagen_destacada?.data?.id,
        )?.attributes?.uri?.url;

        return {
          title: attributes?.title,
          desc: attributes?.body?.value,
          url: url && { label: url?.title || '', src: url?.uri || '' },
          src: img ? apiMainURL + img : '',
        };
      }) || null
    );
  }, []);

  useEffect(() => {
    const getData = async () => {
      dispatch({ type: ContentActionCases.LOAD_ON });
      const { data: categoriesData } = await getLPCContentCategories();
      const categories = categoriesData.map(
        ({ id, attributes: { name, description } }: any) => ({
          id,
          title: name,
          description: description?.value,
        }),
      );

      dispatch({
        type: ContentActionCases.INIT_CATEGORIES,
        payload: { categories },
      });
    };

    if (!state.activeSection) getData();
  }, [state.activeSection]);
  useEffect(() => {
    const getData = async () => {
      const cards = await getCategorySections();

      dispatch({
        type: ContentActionCases.SET_CARDS,
        payload: { cards },
      });
    };

    getData();
  }, [getCategorySections]);

  return (
    <div className='main-content lpc-bg lpc-content'>
      <div className='container-fluid'>
        <BreadCrumb {...{ crumbs }} />
        <h1 className='text-center'>{title}</h1>
      </div>
      <Description {...{ desc }} />
      <div className='container-fluid lpc-card-content'>
        {state.cards?.map((props) => (
          <Card {...props} />
        ))}
      </div>
      {!state.cards && <NoDataFound message={errorMsg} />}
      {state.isLoading && <LoadSpinner />}
    </div>
  );
};

export default Content;
