import React, { useEffect, useState } from 'react'
import { Input } from "reactstrap";
import { postMenuItems, postMenuItemsData } from '../../../../../../services/api/api-socios-maz';
import BreadCrumb from "../../../../../UI/BreadCrumb";
import LoadSpinner from '../../../../../UI/LoadSpinner/LoadSpinner';
import { ListUsersFiltereds, PartnerProfileSuperExec } from '../../super-executives/Partner-profile-exec';
import { TeamProfilesRender } from '../../super-executives/team-profiles';

export const PartnerProfileExec = () => {
    const crumbs = [
        { path: '/home', label: 'MAZ Partners' },
        { label: 'Partner profile' },
    ];
    const [data2, setData2] = useState<any>(undefined);
    const [load, setLoad] = useState(false);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (!open)
            getDataProfile();

    }, [open]);
    const getDataProfile = async () => {
        if (!data2) {
            setLoad(true);
            const response = await postMenuItems();
            if (response && response?.status && response?.status == 200) {
                const resData = await postMenuItemsData()?.then((response: any) => response.text())
                    .then((result) => {
                        setData2(JSON.parse(result));
                        setLoad(false);
                    }).catch(error => console.log("ERROR:  ", error));
            }
        }
    };

    if (data2?.relateds?.users)
        return (
            <div className='main-content'>
                <div className='container-fluid'>
                    <div className="row">
                        <div className="col-12">
                            <BreadCrumb {...{ crumbs }} />
                        </div>
                        {data2?.relateds?.users && (<PartnerProfileExecRender users={data2?.relateds?.users} />)}
                    </div>
                </div>
            </div>
        )
    else 
        return <LoadSpinner />
    
}


export const PartnerProfileExecRender = (props: any) => {
    const { title, description, users } = props;
    return (
        <div className='main-super-executives mt-5'>
            <ListUsersFiltereds link={'/partners/partner-profile/detail'} title={title} description={description} users={users} welcomeData={true} />
        </div>

    )
}
