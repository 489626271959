import React, { forwardRef, useEffect, useRef, useState } from 'react';
import {
  postGraphics,
  useGetallArchetypesQuery,
} from '../../../../../../services/api/api-socios-maz';
import { Accordion } from '../../../../../UI/Accordion/Accordion';
import HorizontalBar from '../../../../../UI/chartJs/horizontalBarChart';
import PartnersMetrics from './PartnersMetrics';
import jsPDF from 'jspdf';
import { store as storeApp } from '../../../../../../modules/appState/store';
import { getArchetypes } from '../../../Partners-profile/Partner-profile';
import { useReactToPrint } from 'react-to-print';
import ArchetypeCarousel from '../../../Partners-profile/Archetypes-details/ArchetypeCarousel';
import LoadSpinner from '../../../../../UI/LoadSpinner/LoadSpinner';
import PartnersMetricsPrint, {
  assignSecondColor,
} from './PartnersMetrics/partnerMetricsPrint';
import PartnersMetricsTable from './PartnersMetrics/PartnersMetricsTable';
import PartnersLevelMetrics from './PartnersMetrics/PartnersLevelMetrics';
import MetricsOPRPrint from './PartnersMetrics/MetricsPrint/MetricsOPR';
import { capitalizeFirstLetter } from '../../../../../../services/services';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
const small = '../../../../resources/zone-metrics-vertical.png';
const large = '../../../../resources/zone-metrics.png';
const date = '2022-07-01';
export const OverViewPrint = forwardRef((props: any, ref: any) => {
  const labels = [];

  const arrayImages: any[] = props?.canvas;
  const metrics = props?.metrics;
  const data2: { label: string; data: any[]; backgroundColor: string }[] = [];

  for (const [id, [level, v]] of Object.entries(metrics).entries()) {
    labels.push(level);

    for (const [idx, [opr, val]] of Object.entries(v as object)
      .sort(([aK], [bK]) => aK.localeCompare(bK))
      .entries()) {
      const pos = data2.findIndex(({ label }) => label === opr);

      if (pos >= 0) data2[pos].data.push(val.avg);
      else
        data2.push({
          label: opr,
          data: [...Array(id).fill(null), val.avg],
          backgroundColor: assignSecondColor(idx + 1),
        });
    }
  }
  const [user, setUser] = useState(storeApp.getState().AppReducer?.userData);
  const [data, setData] = useState<any>({});
  const componentRef = useRef(null);
  const archetypesListData = useGetallArchetypesQuery()?.data;
  const archetypesList = getArchetypes(archetypesListData);

  const [graphDataArch, setGraphDataArch] = useState<any>(null);
  const [graphDataFunc, setGraphDataFunc] = useState<any>(null);
  const [graphDataBu, setGraphDataBu] = useState<any>(null);
  const [showGraph, setShowGraph] = useState<boolean>(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  const [graph, setGraph] = useState(large);
  // const dates = useGetPartnersProfileDatesQuery()

  const crumbs = [
    { path: '/home', label: 'MAZ Partners' },
    { path: '/partners/partner-profile', label: 'Partner Profile' },
    { label: 'Overview' },
  ];
  storeApp.subscribe(() => {
    setUser(storeApp?.getState()?.AppReducer?.userData || '');
  });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Over view MAZ PARTNERS',
  });
  const trigger = () => {};

  function handleResize() {
    setWindowDimensions(getWindowDimensions());
  }
  window.addEventListener('resize', handleResize);
  useEffect(() => {
    if (windowDimensions?.width < 575) {
      setGraph(small);
    } else {
      setGraph(large);
    }
    if (!showGraph)
      setTimeout(() => {
        setShowGraph(true);
      }, 1000);
    getDataProfile();
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
  }, []);
  const getDataProfile = () => {
    // console.log("DRUPAL ID2 profile 3333  ",user?.drupal_id);
    postGraphics({ date })
      ?.then((response: any) => response?.json())
      .then((res) => {
        setData(res);
        setGraphDataArch(getDataGraph(res?.archetypes));
        setGraphDataFunc(getDataGraph(res?.functions));
        setGraphDataBu(getDataGraph(res?.bus));
      })
      .catch((e) => console.log(e));
  };
  if (
    user?.drupal_id != '' &&
    data &&
    graphDataArch &&
    graphDataFunc &&
    graphDataBu
  ) {
    return (
      <div className='mt-3 main-profile-overview' id='top' ref={ref}>
        <div className='container-fluid'>
          <div className='row'>
            {/* ----------------     Fila ----------------------*/}
            <div className='col-12 '>
              <div className='section-title text-bold fs-4 '>Overview</div>
              <div className='text-content text-center my-4'>
                In this section you will find general metrics of the area
                extracted from the OPR and general visualizations about the
                archetypes that make up your team. This information will allow
                you to better understand the characteristics of your team and
                evolve together.
              </div>

              <div className='accordion-partner'>
                <h3 className='text-center'>Zone Partners Metrics</h3>
                <h5 className='text-center'>Time Period: 2022/07</h5>

                <PartnersMetricsPrint {...{ date }} />
              </div>
              {/* ----------------     Fila ----------------------*/}
              <div
                className='overview-donut-graph'
                style={{
                  display: 'flex',
                  gridTemplateRows: '1fr 1fr',
                  paddingBottom: 1 + 'rem',
                  paddingTop: 2 + 'rem',
                }}>
                <div
                  style={{
                    width: 49 + '%',
                    flex: '0 0 auto',
                    float: 'left',
                  }}>
                  <h5 className='text-center'>Gender Representation </h5>
                  <img
                    src={`${arrayImages[0]}`}
                    className='w-100'
                    alt='SymLog'
                  />
                </div>
                <div
                  style={{
                    width: 49 + '%',
                    flex: '0 0 auto',
                    float: 'left',
                  }}>
                  <h5 className='text-center'>Partners Generation </h5>
                  <img
                    src={`${arrayImages[1]}`}
                    className='w-100'
                    alt='SymLog'
                  />
                </div>
              </div>
              {/* ----------------     Fila ----------------------*/}

              <div
                className='new-page section-gray'
                style={{
                  display: 'flex',
                  gridTemplateRows: '1fr 1fr',
                  paddingTop: 1 + 'rem',
                }}>
                <div
                  style={{
                    width: 100 + '%',
                    flex: '0 0 auto',
                    float: 'left',
                  }}>
                  <PartnersMetricsTable metrics={metrics?.tobyband} />
                </div>
              </div>
              {/* ----------------     Fila ----------------------*/}
              <div
                className=''
                style={{
                  display: 'flex',
                  gridTemplateRows: '1fr 1fr',
                  paddingBottom: 1 + 'rem',
                  paddingTop: 2 + 'rem',
                }}>
                <div
                  className='mx-2'
                  style={{
                    width: 49 + '%',
                    flex: '0 0 auto',
                    float: 'left',
                  }}>
                  <h5 className='text-center'>Partners By Level </h5>
                  <PartnersLevelMetrics metrics={metrics?.partners_by_level} />
                </div>

                <div
                  className=''
                  style={{
                    width: 49 + '%',
                    flex: '0 0 auto',
                    float: 'left',
                  }}>
                  <h5
                    className='text-center '
                    style={{
                      marginBottom: 2.8 + 'rem',
                    }}>
                    OPR Shape{' '}
                  </h5>
                  <img
                    src={`${arrayImages[2]}`}
                    className='w-100'
                    alt='SymLog'
                  />
                  <MetricsOPRPrint metrics={metrics?.oprShape} />
                </div>
              </div>
              {/* ----------------     Fila ----------------------*/}
              <div
                className=''
                style={{
                  display: 'flex',
                  gridTemplateRows: '1fr 1fr',
                  paddingBottom: 1 + 'rem',
                }}>
                <div
                  className='mx-2'
                  style={{
                    width: 49 + '%',
                    flex: '0 0 auto',
                    float: 'left',
                  }}>
                  <h5
                    className='text-center'
                    style={{
                      marginBottom: 2.8 + 'rem',
                    }}>
                    Partners by Level/Bands{' '}
                  </h5>
                  <img
                    src={`${arrayImages[3]}`}
                    className='w-100'
                    alt='SymLog'
                  />
                </div>
                <div
                  className=''
                  style={{
                    width: 49 + '%',
                    flex: '0 0 auto',
                    float: 'left',
                  }}>
                  <h5
                    className='text-center'
                    style={{
                      marginBottom: 2.8 + 'rem',
                    }}>
                    Engagement By Band{' '}
                  </h5>
                  <img
                    src={`${arrayImages[4]}`}
                    className='w-100'
                    alt='SymLog'
                  />
                </div>
              </div>
              {/* ----------------     Fila ----------------------*/}
              <div
                className=''
                style={{
                  display: 'flex',
                  gridTemplateRows: '1fr 1fr',
                  paddingBottom: 1 + 'rem',
                  paddingTop: 3 + 'rem',
                }}>
                <div
                  className='mx-2'
                  style={{
                    width: 49 + '%',
                    flex: '0 0 auto',
                    float: 'left',
                  }}>
                  <h5
                    className='text-center'
                    style={{
                      marginBottom: 2.8 + 'rem',
                    }}>
                    LCM Score Per Capability{' '}
                  </h5>
                  <img
                    src={`${arrayImages[5]}`}
                    className='w-100'
                    alt='SymLog'
                  />
                </div>
                <div
                  className=''
                  style={{
                    width: 49 + '%',
                    flex: '0 0 auto',
                    float: 'left',
                  }}>
                  <h5
                    className='text-center'
                    style={{
                      marginBottom: 2.8 + 'rem',
                    }}>
                    Target Achievement ( 2021 ){' '}
                  </h5>
                  <img
                    src={`${arrayImages[6]}`}
                    className='w-100'
                    alt='SymLog'
                  />
                </div>
              </div>
              {/* ----------------     Fila ----------------------*/}
              <div
                className='page-break'
                style={{
                  display: 'flex',
                  gridTemplateRows: '1fr 1fr',
                  paddingBottom: 1 + 'rem',
                }}>
                <div
                  className='mx-2'
                  style={{
                    width: 100 + '%',
                    flex: '0 0 auto',
                    float: 'left',
                  }}>
                  <h5
                    className='text-center'
                    style={{
                      marginBottom: 2.8 + 'rem',
                    }}>
                    Archetypes BU{' '}
                  </h5>
                  <img
                    src={`${arrayImages[7]}`}
                    className='w-100'
                    alt='SymLog'
                  />
                  <HorizontalBar
                    print={true}
                    dataInput={graphDataBu}
                    labels={data?.bus?.labels}></HorizontalBar>
                </div>
              </div>
              {/* ----------------     Fila ----------------------*/}
              <div
                className='page-break'
                style={{
                  display: 'flex',
                  gridTemplateRows: '1fr 1fr',
                  paddingBottom: 1 + 'rem',
                }}>
                <div
                  className='mx-2'
                  style={{
                    width: 100 + '%',
                    flex: '0 0 auto',
                    float: 'left',
                  }}>
                  <h5
                    className='text-center'
                    style={{
                      marginBottom: 2.8 + 'rem',
                    }}>
                    Functional Shape by Archetype{' '}
                  </h5>
                  <img
                    src={`${arrayImages[8]}`}
                    className='w-100'
                    alt='SymLog'
                  />
                  <HorizontalBar
                    print={true}
                    dataInput={graphDataArch}
                    labels={data?.archetypes?.labels}></HorizontalBar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else return <LoadSpinner />;
});
function getDataGraph(dataGraphs: any) {
  let colors = [
    'rgba(229,182,59)',
    'rgba(246,246,246)',
    'rgba(224,138,45)',
    'rgba(190,190,190)',
    'rgba(106,106,106)',
    'rgba(229,182,59)',
    'rgba(80,79,79)',
    'rgba(245,224,3)',
    'rgba(255,235,196)',
    'rgba(36,36,36)',
    'rgba(241, 196, 15, 1)',
    'rgba(255, 255, 255, 1)',
    'rgba(98, 101, 103, 1)',
    'rgba(250, 229, 211, 1)',
  ];
  let data: any[] = [];
  if (dataGraphs?.datasets?.length > 0) {
    dataGraphs?.datasets?.filter((ds: any) => {
      let secondaryData: any[] = [];
      if (ds?.data) {
        ds?.data?.map((d: any) => {
          secondaryData.push(d || 0);
        });
      } else {
        ds?.datasets?.map((d: any) => {
          secondaryData.push(d || 0);
        });
      }

      let c = colors?.pop();
      data.push({
        label: capitalizeFirstLetter(ds?.label) + '   ' || '',
        data: secondaryData || 0,
        backgroundColor: c,
        fillColor: c,
      });
    });
  }
  return data;
}
