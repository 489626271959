import React from 'react';
import { GetBodyInnerHTML } from '../../../../Layouts/default-section/getDefaultElements';
import { useNavigate } from 'react-router';

import { ReactComponent as Warning } from '../../../../../assets/LPC/Destiny/Restrictions.svg';

import './HotelDownload.scss';

interface HotelDownloadProps {
  title: string;
  desc: string;
  label?: string;
  src?: string;
  breakfastDesc: string;
}

const HotelDownload = ({
  desc,
  title,
  label = 'Your Reservation',
  src,
  breakfastDesc,
}: HotelDownloadProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (localStorage.getItem('mobile') === '1')
      navigate(`/download-pdf-link?${src}`);
    else window.open(src);
  };

  return (
    <div className='container-fluid hotel-download'>
      <div className='information'>
        <div className='titles'>
          <h3>Hotel</h3>
          <h2>{title}</h2>
        </div>
        <GetBodyInnerHTML content={desc} />
      </div>
      <button onClick={handleClick} disabled={!src}>
        <i className='bi bi-download' /> {label}
      </button>
      {breakfastDesc && (
        <div className='breakfast-exception'>
          <div>
            <div className='breakfast-img'>
              <Warning />
            </div>
            <GetBodyInnerHTML content={breakfastDesc} />
          </div>
        </div>
      )}
    </div>
  );
};

export default HotelDownload;
