import React, { ReactNode, useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import './multiselect.scss';

type MultiselectItemsProps = {
  className?: string;
  options: { label: string; value: string | number }[] | [];
  onChange?: (val: any) => void;
  label?: string;
  overrideStrings?: { [key: string]: string } | undefined;
  disableSearch?: boolean;
  valueRenderer?: (...props: any[]) => ReactNode;
  clearIcon?: ReactNode;
  hasSelectAll?: boolean;
  clearItems?: boolean;
  initValues?: { label: string; value: string | number }[];
  selectedIds?: (number | string)[];
};

const MultiselectItems = ({
  className,
  options,
  overrideStrings = { selectSomeItems: 'Bu' },
  label = 'Select',
  disableSearch,
  clearIcon,
  onChange,
  valueRenderer,
  hasSelectAll = true,
  clearItems,
  initValues,
  selectedIds,
}: MultiselectItemsProps) => {
  const [selected, setSelected] = useState<any[]>([]);

  const newClassName = 'multi-select ' + className || '';

  const onMultiSelectChange = (val: any) => {
    setSelected(val);
    onChange && onChange(val);
  };

  useEffect(() => {
    if (clearItems) setSelected([]);
  }, [clearItems]);
  useEffect(() => {
    if (initValues) setSelected(initValues || []);
    else if (selectedIds)
      setSelected(
        selectedIds.map(
          (id) => options.find(({ value }) => +value === +id) || {},
        ),
      );
  }, [initValues, selectedIds, options]);

  return (
    <div className='multi-select-partners'>
      <MultiSelect
        {...{
          options,
          overrideStrings,
          disableSearch,
          valueRenderer,
          hasSelectAll,
        }}
        className={newClassName}
        options={options}
        value={selected}
        onChange={onMultiSelectChange}
        labelledBy={label}
        ClearSelectedIcon={clearIcon}
      />
    </div>
  );
};
export default MultiselectItems;
