import React from 'react';

import Button from '../Button';
import Icon from '../Icon';

type CardProps = {
  className?: string;
  title: string;
  subtitle?: string;
  imgSrc?: string | JSX.Element;
  children?: JSX.Element | string;
  cardHeader?: JSX.Element;
  cardFooter?: JSX.Element;
  hasCancel?: boolean;
  cancelLabel?: string;
  confirmLabel?: string;
  isConfirmDisabled?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
};

const Card = ({
  className,
  title,
  subtitle,
  imgSrc,
  children,
  cardHeader,
  cardFooter,
  hasCancel = true,
  cancelLabel = 'Cancel',
  confirmLabel = 'Accept',
  isConfirmDisabled = false,
  onConfirm,
  onCancel,
}: CardProps) => {
  const newClassName = `card bg-light ${className}`;

  return (
    <div className={newClassName}>
      {imgSrc && (
        <div className='card-img-top'>
          {typeof imgSrc === 'string' ? <Icon icon={imgSrc} /> : imgSrc}
        </div>
      )}
      <div className='card-body'>
        {cardHeader ? (
          cardHeader
        ) : (
          <>
            <h5 className='card-title'>{title}</h5>
            {subtitle && (
              <h6 className='card-subtitle mb-2 text-muted'>{subtitle}</h6>
            )}
          </>
        )}
        {children}
        {cardFooter ? (
          cardFooter
        ) : (
          <div className='d-flex justify-content-end gap-2'>
            {hasCancel && (
              <Button className='btn-danger' onClick={onCancel}>
                {cancelLabel}
              </Button>
            )}
            <Button disabled={isConfirmDisabled} onClick={onConfirm}>
              {confirmLabel}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
