import React, { useEffect, useState } from 'react';

export interface ActualDate {
  title: string;
  date: string;
  id: number;
}
interface NavButtonsProps {
  dates: ActualDate[];
  onNavButtonClick?: (date?: number) => void;
}

const NavButtons = ({ dates, onNavButtonClick }: NavButtonsProps) => {
  const [activeButton, setActiveButton] = useState(0);

  const handleClick = (id: number) => {
    setActiveButton(id);
  };

  useEffect(() => {
    if (onNavButtonClick) onNavButtonClick(dates?.at(activeButton)?.id || 0);
  }, [activeButton]);

  return (
    <nav className='nav-buttons'>
      <ul>
        {dates?.map(({ id, title, date }, idx) => (
          <li key={id}>
            <button
              className={idx === activeButton ? 'active' : ''}
              onClick={() => handleClick(idx)}>
              {title}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavButtons;
