
import Swal from 'sweetalert2'
export function generateAlert(type: string, title:string,message: string) {
    switch (type) {
        case 'error':
            Swal.fire({
                title: title,
                text: message,
                icon: 'error',
                confirmButtonText: 'Ok',
              });
            break;
        case 'warning':
            Swal.fire({
              title: title,
                text: message,
                icon: 'warning',
                confirmButtonText: 'Ok'
              });
            break;
        case 'success':
                Swal.fire({
                  title: title,
                  text: message,
                  icon: 'success',
                  confirmButtonText: 'Ok'
                  });
                  console.log("Generando alertaSUCCESS");
            break;
        case 'info':
            Swal.fire({
              title: title,
              text: message,
              icon: 'info',
              confirmButtonText: 'Ok'
              });
            break;
        default:
            Swal.fire({
              title: title,
              text: message,
              icon: 'question',
              confirmButtonText: 'Ok'
              });
            break;
    }


}