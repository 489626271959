import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { errorAlert } from '../../components/UI/alerts-Service/swalSignInErrorAlert';
import { getDomain } from '../../modules/settings/domain';
import { apiMainURL, appBenefiURL } from '../../modules/settings/environment';
import { toLocaleDate } from '../../components/pages/Lpc/Event/items/schedule/getElementsSchedule';

const AUTHORIZATION = 'authorization';
const AUTH_TOKEN = `Bearer ${
  localStorage.getItem('localToken')?.toString() || ''
}`;
const APP_JSON = 'application/json';
const POST = 'POST';
const GET = 'GET';

const getHeader = (auth: string, token: string, contentType: string) => {
  const header = new Headers();

  header.append(auth, token);
  header.append('Content-Type', contentType);

  return header;
};
const fetchRequestBody = async (endpoint: string, requestOpts: RequestInit) => {
  const response = await fetch(endpoint, requestOpts);
  const body = await response
    .json()
    .catch((error) => console.log('error', error));

  return body;
};
const calRefresh = (time: string) => {
  let now = Date.now();
  let result = (now - +time) / 1000;
  return result > 3500 ? true : false;
};
export const mazPartnersApi = createApi({
  reducerPath: 'mazPartnersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiMainURL,
    prepareHeaders: (headers, { getState }) => {
      if (localStorage.getItem('localToken')) {
        if (
          !sessionStorage.getItem('timeToken') ||
          (sessionStorage.getItem('timeToken') &&
            calRefresh(sessionStorage.getItem('timeToken') || ''))
        ) {
          refreshLocalToken(
            localStorage.getItem('localToken')?.toString() || '',
          );
          sessionStorage.setItem('timeToken', Date.now().toString());
        }
        headers.set(
          'authorization',
          `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
        );
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getTaxonomyById: builder.query<any, string>({
      query: (id) => ({
        url: `/es/jsonapi/taxonomy_term/${id}`,
      }),
    }),
    getUser: builder.query<any, string>({
      query: (sharpId) => ({
        url: `api/authenticated-user`,
        body: {
          sharp: sharpId,
        },
      }),
    }),
    getHomeDefaultContent: builder.query<any, string>({
      query: (id) => ({
        url: `/jsonapi/node/pagina_generica?filter[field_url][value]=/${id}`,
        params: {
          include:
            'field_arguments, field_arguments.field_contenido, field_arguments.field_contenido.field_list, field_arguments.field_contenido.field_list.field_image, field_arguments.field_contenido.field_list.field_video, field_arguments.field_contenido.field_list.field_video.field_media_video_file, field_arguments.field_contenido.field_plan_categoria, field_arguments.field_contenido.field_image, field_arguments.field_contenido.field_card, field_arguments.field_contenido.field_card.field_icono,field_arguments.field_contenido.field_imagen_card, field_arguments.field_contenido.field_imagen_card_2, field_arguments.field_contenido.field_video, field_arguments.field_contenido.field_video.field_media_video_file, field_arguments.field_contenido.field_card',
        },
      }),
    }),
    getDefaultContent: builder.query<any, string>({
      query: (id) => ({
        url: `/jsonapi/node/pagina_generica?filter[field_url][value]=/contenido?${id}`,
        params: {
          include:
            'field_arguments, field_arguments.field_contenido, field_arguments.field_contenido.field_list, field_arguments.field_contenido.field_list.field_image, field_arguments.field_contenido.field_list.field_video, field_arguments.field_contenido.field_list.field_video.field_media_video_file',
        },
      }),
    }),
    getAllCards: builder.query<any, string>({
      query: (idTarjeta) => ({
        url: `/jsonapi/node/tarjeta/${idTarjeta}`,
        params: {
          include:
            'field_imagen_destacada, field_banners, field_banners.field_image',
        },
      }),
    }),
    getAllUsers: builder.query<any, string>({
      query: (idTarjeta) => ({
        url: `/jsonapi/user/user`,
        params: {
          'filter[field_user_partner]': 'partner',
          include:
            'field_user_hobbies.field_hobbie_name,field_user_video,user_picture,field_user_hobbies.field_hobbie_image, field_user_item_1 ,field_user_item_1.field_partner_item_title',
        },
      }),
    }),
    getUsersByLimit: builder.query<
      any,
      {
        limit: number;
        offset: number;
        filter?: string;
        areas?: string;
        bus?: string;
        name?: string;
      }
    >({
      query: ({ limit, offset, filter = undefined, areas, bus, name }) => ({
        url: `/jsonapi/user/user`,
        params: {
          ...(filter && {
            'sort[sort-taxonomia][path]': filter,
            'sort[sort-taxonomia][direction]': 'asc',
          }),
          'filter[field_user_partner]': 'partner',
          'page[limit]': limit,
          'page[offset]': offset,
          ...(areas && {
            'filter[field_area.meta.drupal_internal__target_id]': areas,
          }),
          ...(bus && {
            'filter[field_bu.meta.drupal_internal__target_id]': bus,
          }),
          ...(name && {
            'filter[name][operator]': 'CONTAINS',
            'filter[name][value]': name,
          }),
          include:
            'field_user_hobbies.field_hobbie_name,field_user_video,user_picture,field_user_hobbies.field_hobbie_image, field_user_item_1 ,field_user_item_1.field_partner_item_title, field_bu, field_area',
        },
      }),
    }),
    getPartnerUser: builder.query<any, string>({
      query: (shrapId: string) => ({
        url: `/jsonapi/user/user`,
        params: {
          'filter[drupal_internal__uid]': shrapId,
          include:
            'field_user_hobbies.field_hobbie_name, field_user_video,user_picture,field_user_hobbies.field_hobbie_image, field_user_item_1,field_user_item_1.field_partner_item_title,field_user_gallery, field_user_gallery.field_media_video_file, field_user_gallery.field_media_image, field_reserva_lpc,field_ticket_lpc, field_invitacion_lpc',
        },
      }),
    }),
    getGalleryImages: builder.query<any, string>({
      query: (id: string) => ({
        url: `/jsonapi/node/publicacion_galeria`,
        params: {
          'filter[field_parent.meta.drupal_internal__target_id][value]':
            JSON.parse(id)?.id,
          'filter[field_galeria][value]': JSON.parse(id)?.type,
          'filter[field_fecha.meta.drupal_internal__target_id][value]':
            JSON.parse(id)?.date,
          'page[limit]': JSON.parse(id)?.limit || 24,
          'page[offset]': JSON.parse(id)?.offset || 0,
          include: 'field_imagen',
        },
      }),
    }),
    getGalleryDetail: builder.query<any, string>({
      query: (id: string) => ({
        url: `/jsonapi/node/publicacion_galeria`,
        params: {
          'filter[field_parent.meta.drupal_internal__target_id][value]':
            JSON.parse(id)?.id,
          'filter[id][value]': '5d567933-f27b-4fd9-8756-dee415e8b29a',
          include: 'field_imagen',
        },
      }),
    }),
    getEventById: builder.query<any, string>({
      query: (obj: string) => {
        const { galleryId, eventId } = JSON.parse(obj);

        return {
          url: `/jsonapi/node/publicacion_galeria`,
          params: {
            'filter[field_parent.meta.drupal_internal__target_id][value]':
              galleryId,
            'filter[id]': eventId,
            include: 'field_imagen',
          },
        };
      },
    }),
    getSchedule: builder.query<any, string>({
      query: (id: string) => ({
        url: `/jsonapi/node/evento_calendario`,
        params: {
          'filter[field_parent.meta.drupal_internal__target_id][value]': id,
          sort: 'field_fecha_evento',
          include: 'field_actividades, field_sumario, field_archivo_adjunto',
        },
      }),
    }),
    getSchedulePDF: builder.query<any, string>({
      query: (id: string) => ({
        url: `/socios-lpc/services/schedule`,
      }),
    }),
    getWellnessPDF: builder.query<any, string>({
      query: (id: string) => ({
        url: `/socios-lpc/services/wellness`,
      }),
    }),
    getScheduleByDay: builder.query<any, string>({
      query: (objDate: string) => ({
        url: `/jsonapi/node/evento_calendario`,
        params: {
          'filter[field_parent.meta.drupal_internal__target_id][value]':
            JSON.parse(objDate)?.id,
          'filter[field_fecha_evento][value]': JSON.parse(objDate)?.date,
          include: 'field_actividades',
        },
      }),
    }),
    getCommentsById: builder.query<any, string>({
      query: (id: string) => ({
        url: '/jsonapi/comment/comment',
        params: {
          'filter[entity_id.id]': id,
          include: 'uid',
        },
      }),
    }),
    getGalleryEventLike: builder.query<any, string>({
      query: (id: string) => ({
        url: `/api/${id}/check-favorites?_format=json`,
      }),
    }),
    getAllGalleryLikes: builder.query<any, string>({
      query: () => ({
        url: '/api/publicacion_galeria/favorites?_format=json',
        method: 'POST',
      }),
    }),
    getLikesByType: builder.query<any, string>({
      query: (t) => ({
        url: `/api/${t}/favorites?_format=json`,
        method: 'POST',
      }),
    }),
    getCalculatorSTI: builder.query<any, string>({
      query: (data: string) => ({
        url: `https://api-benefi-dev.somosmaz.com/api/bonus/sti`,
        params: {
          band: JSON.parse(data).band,
          country: JSON.parse(data).country,
        },
      }),
    }),

    getCalculatorCompensation: builder.query<any, string>({
      query: (sharp) => ({
        url: `https://api-benefi-dev.somosmaz.com/api/compensation/ext`,
        params: {
          sharp: sharp,
        },
        headers: {
          'Accept-Language': 'es',
        },
      }),
    }),
    //
    getAllHobbies: builder.query<any, string>({
      query: (sharp) => ({
        url: `/es/jsonapi/taxonomy_term/hobbies`,
        params: {
          include: 'field_hobbie_image',
        },
      }),
    }),
    getAllItems: builder.query<any, string>({
      query: (id) => ({
        url: `/es/jsonapi/taxonomy_term/partner_profile_items`,
      }),
    }),
    getAllBeers: builder.query<any, void>({
      query: () => ({
        url: '/es/jsonapi/taxonomy_term/beers',
        params: {
          include: 'field_logo',
        },
      }),
    }),
    getAllBUs: builder.query<any, void>({
      query: () => ({
        url: '/es/jsonapi/taxonomy_term/bu',
      }),
    }),
    getAllTopicResources: builder.query<any, void>({
      query: () => ({
        url: '/es/jsonapi/taxonomy_term/temas_recursos',
      }),
    }),
    getAllAreas: builder.query<any, void>({
      query: () => ({
        url: '/es/jsonapi/taxonomy_term/areas',
      }),
    }),
    //ENDPOINTS PARTNERS PROFILE
    //     /api/partner_profile/profile/{user}
    // /api/partner_profile/genmaz/{user}
    getPartnerProfileById: builder.query<any, void>({
      query: (global_id) => ({
        url: '/api/partner_profile/profile/' + global_id,
      }),
    }),
    getallArchetypes: builder.query<any, void>({
      query: () => ({
        url: '/jsonapi/taxonomy_term/pp_archetypes',
        params: {
          include: 'field_archetype_image',
        },
      }),
    }),
    getArchetypeById: builder.query<any, string>({
      query: (id: string) => ({
        url: '/jsonapi/taxonomy_term/pp_archetypes',
        params: {
          'filter[drupal_internal__tid]': id,
          include:
            'field_archetype_image, field_contenidos, field_contenidos.field_card,field_contenidos.field_card.field_icono, field_contenidos.field_imagen_card',
        },
      }),
    }),

    getAppsHubApi: builder.query<any, string>({
      query: (id: string) => ({
        url: '/api/get-simplifica-hub',
        // params: {
        //   // 'filter[drupal_internal__tid]':id,
        //   include:
        //     'field_hub_link_image',
        // },
      }),
    }),
    //SQUADS ---------------------------------------->
    getGeneralElementsSquads: builder.query<any, string>({
      query: (id: string) => ({
        url: '/squads/communities',
      }),
    }),
    getElementsSquads: builder.query<any, string>({
      query: () => ({
        url: '/jsonapi/taxonomy_term/comunidades',
        params: {
          include: 'field_icono',
        },
      }),
    }),
    getElementsSquadsById: builder.query<any, string>({
      query: (id: string) => ({
        url: '/jsonapi/taxonomy_term/comunidades',
        params: {
          'filter[drupal_internal__tid]': id,
          include: 'field_icono',
        },
      }),
    }),
    getEventSquads: builder.query<any, string>({
      query: (id) => ({
        url: '/jsonapi/node/evento_squads',
        params: {
          'filter[drupal_internal__nid]': id,
          include:
            'field_banner_squad,field_bu,field_categoria,field_modalidad',
        },
      }),
    }),
    getCalendarEvents: builder.query<any, { page: number; limit?: number }>({
      query: ({ page, limit }) => ({
        url: '/api/squads/calendar',
        method: 'POST',
        params: {
          page,
          ...(limit && { perpage: limit }),
        },
      }),
    }),
    getParnterProfileFilters: builder.query<any, void>({
      query: () => ({
        url: '/api/partner_profile/searchfilters',
        method: 'POST',
      }),
    }),
    getPartnersMetrics: builder.query<any, { date?: string } | void>({
      query: (opts) => {
        console.log(opts?.date);

        return {
          url: '/api/partner_profile/metrics',
          method: 'POST',
          ...(opts && {
            body: new URLSearchParams({
              moment: opts?.date || '',
            }),
          }),
        };
      },
    }),
    getPartnersProfileDates: builder.query<any, { id?: string } | void>({
      query: (opts) => ({
        url: `/api/partner_profile/moments/${opts?.id || ''}`,
        method: 'POST',
      }),
    }),
    //BINNACLE QUERY
    getBinnacleData: builder.query<any, string>({
      query: () => ({
        url: `/es/jsonapi/node/pp_bitacora`,
        params: {
          // 'filter[field_user_partner]': 'partner',
          include:
            'field_arquetipo.field_imagen_bitacora,field_orientacion_de_la_motivaci',
        },
      }),
    }),
    getBinnacleOrientations: builder.query<any, string>({
      query: () => ({
        url: `es/jsonapi/taxonomy_term/pp_bitacora_orientaciones`,
        params: {
          // 'filter[field_user_partner]': 'partner',
          // include:
          //   'field_arquetipo.field_imagen_bitacora,field_orientacion_de_la_motivaci',
        },
      }),
    }),
    getBinnacleArchetype: builder.query<any, string>({
      query: (id) => ({
        url: `api/partner_profile/archetype-max-min/${id}`,
        params: {
          // 'filter[field_user_partner]': 'partner',
          // include:
          //   'field_arquetipo.field_imagen_bitacora,field_orientacion_de_la_motivaci',
        },
      }),
    }),
    getLPCInfographic: builder.query<any, void>({
      query: () => ({
        url: `socios-lpc/services/infographic`,
      }),
    }),
    getGalleryDates: builder.query<any, void>({
      query: () => ({
        url: `es/jsonapi/taxonomy_term/fechas_galeria`,
      }),
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetTaxonomyByIdQuery,
  useGetUserQuery,
  useGetHomeDefaultContentQuery,
  useGetDefaultContentQuery,
  useGetAllCardsQuery,
  useGetAllUsersQuery,
  useGetUsersByLimitQuery,
  useGetGalleryImagesQuery,
  useGetPartnerUserQuery,
  useGetScheduleQuery,
  useGetSchedulePDFQuery,
  useGetWellnessPDFQuery,
  useGetScheduleByDayQuery,
  useGetEventByIdQuery,
  useGetCommentsByIdQuery,
  useGetGalleryEventLikeQuery,
  useGetCalculatorSTIQuery,
  useGetAllGalleryLikesQuery,
  useGetLikesByTypeQuery,
  useGetCalculatorCompensationQuery,
  useGetGalleryDetailQuery,
  useGetAllHobbiesQuery,
  useGetAllItemsQuery,
  useGetAllBeersQuery,
  useGetPartnerProfileByIdQuery,
  useGetallArchetypesQuery,
  useGetArchetypeByIdQuery,
  useGetAppsHubApiQuery,
  useGetElementsSquadsQuery,
  useGetElementsSquadsByIdQuery,
  useGetGeneralElementsSquadsQuery,
  useGetAllAreasQuery,
  useGetAllBUsQuery,
  useGetAllTopicResourcesQuery,
  useGetEventSquadsQuery,
  useGetCalendarEventsQuery,
  useGetParnterProfileFiltersQuery,
  useGetPartnersMetricsQuery,
  useGetPartnersProfileDatesQuery,
  useGetBinnacleDataQuery,
  useGetBinnacleOrientationsQuery,
  useGetBinnacleArchetypeQuery,
  useGetLPCInfographicQuery,
  useGetGalleryDatesQuery,
} = mazPartnersApi;

export function refreshLocalToken(t: string): string {
  /*  console.log("Autorization en refresh:  ",`Bearer ${localStorage.getItem('localToken')?.toString()||''}`);
   */
  var myHeaders = new Headers();
  myHeaders.append(
    'Authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
  );

  var requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
  };

  fetch(`${apiMainURL}/jwt/token?_format=json`, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      if (JSON.parse(result).token) {
        localStorage.setItem(
          'localToken',
          JSON.parse(result).token.toString() || '',
        );
      } else {
        errorAlert();

        setTimeout(() => {
          deleteSession(window.location.href);
        }, 7000);
      }
    })
    .catch((error) => {
      console.log('error', error);
      errorAlert();
      setTimeout(() => {
        deleteSession(window.location.href);
      }, 7000);
    });
  return '';
}
export function deleteSession(lastUrl?: string) {
  let appValue = localStorage.getItem('mobile') ? true : false;
  window.localStorage.clear();
  window.sessionStorage.clear();
  if (
    lastUrl &&
    !lastUrl.includes('callback') &&
    !lastUrl.includes('applogin')
  ) {
    localStorage.setItem('lastUrl', lastUrl);
  }
  if (appValue) localStorage.setItem('mobile', '1');
  appValue
    ? (window.location.href = '/applogin')
    : (window.location.href = '/');
}
export function getAPIToken(
  accessTokenSSO: string,
  expiresInSSO: string,
): string {
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  var raw = JSON.stringify({
    access_token_sso: `${
      sessionStorage.getItem('accessToken')?.toString() || ''
    }`,
  });
  var requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  fetch(`${apiMainURL}/api/v1/auth/token?_format=json`, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      if (JSON.parse(result).token) {
        let tokenLocal = JSON.parse(result).token;
        localStorage.setItem('localToken', tokenLocal);
        if (window.localStorage.getItem('lastUrl')) {
          window.location.href = `${window.localStorage.getItem('lastUrl')}`;
          window.localStorage.removeItem('lastUrl');
        } else {
          window.location.href = '/';
          // window.location.href?.includes('applogin')?window.location.href = '/applogin':window.location.href = '/';
        }
      } else {
        errorAlert();
        setTimeout(() => {
          deleteSession(window.location.href);
        }, 7000);
      }

      // setTimeout(refreshLocalToken(tokenLocal), 3500000);
    })
    .catch((error) => {
      console.log('error', error);
      errorAlert();

      setTimeout(() => {
        deleteSession(window.location.href);
      }, 7000);
    });
  return '';
}
export async function getUserData(url = '', id = '') {
  // Opciones por defecto estan marcadas con un *

  var myHeaders = new Headers();
  myHeaders.append(
    'authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
  );
  var formdata = new FormData();
  formdata.append('sharp', id);

  await fetch(url, {
    method: 'POST',
    headers: myHeaders,
  })
    .then((res) => {
      if (res?.status != 200) {
        deleteSession(window.location.href);
      }
    })
    .catch((error) => {
      console.log('Error:  ', error);
      deleteSession(window.location.href);
    });
  const response = await fetch(url, {
    method: 'POST',
    headers: myHeaders,
  });
  return response.json(); // parses JSON response into native JavaScript objects
}
export const getLikesByType = async (type: string) => {
  const requestOptions: RequestInit = {
    method: POST,
    headers: getHeader(AUTHORIZATION, AUTH_TOKEN, APP_JSON),
    redirect: 'follow',
  };
  const url = new URL(`${apiMainURL}/api/${type}/favorites?_format=json`);

  const res = await fetchRequestBody(`${url}`, requestOptions);

  return res;
};
export const postGalleryEventLike = async (id: string, isLiked: boolean) => {
  const myHeaders = new Headers();
  myHeaders.append(
    'Authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
  );

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
  };

  const response = await fetch(
    `${apiMainURL}/api/${id}/add-to-favorites?_format=json`,
    requestOptions,
  );

  if (response.status === 200)
    return { isFavorite: !isLiked, count: !isLiked === true ? 1 : -1 };
};

export const postGalleryEventComment = async (id: string, commentBody: any) => {
  const myHeaders = new Headers();
  myHeaders.append(
    'Authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
  );
  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: commentBody,
  };

  const response = await fetch(
    `${apiMainURL}/api/save-comment/${id}?_format=json`,
    requestOptions,
  );

  return response;
};

export const getGalleryEventComments = async (id: string) => {
  const myHeaders = new Headers();
  myHeaders.append(
    'Authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
  );
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
  };

  const response = await fetch(
    `${apiMainURL}/jsonapi/comment/comment?filter[entity_id.id]=${id}&include=uid.user_picture`,
    requestOptions,
  ).then((res) => res.json());

  return response;
};
export const getFetchGalleryEventImages = async (
  id: string,
  page: number = 10,
  offset: number = 0,
) => {
  const myHeaders = new Headers();
  myHeaders.append(
    'Authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
  );
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
  };

  const response = await fetch(
    `${apiMainURL}/jsonapi/node/publicacion_galeria?filter[field_parent.meta.drupal_internal__target_id][value]=${id}&filter[field_galeria][value]=Galería&include=field_imagen&page[limit]=${page}&page[offset]=${offset}`,
    requestOptions,
  ).then((res) => res.json());

  return response;
};
export const getFetchGalleryEventImagesFull = async (url: string) => {
  const myHeaders = new Headers();
  myHeaders.append(
    'Authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
  );
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
  };

  const response = await fetch(`${url}`, requestOptions).then((res) =>
    res.json(),
  );

  return response;
};
export const getLPCContentCategories = async () => {
  const requestOptions: RequestInit = {
    method: GET,
    headers: getHeader(AUTHORIZATION, AUTH_TOKEN, APP_JSON),
    redirect: 'follow',
  };

  const response = await fetchRequestBody(
    `${apiMainURL}/jsonapi/taxonomy_term/lpc_content_category`,
    requestOptions,
  );

  return response;
};
export const getLPCContentSections = async (
  // categoryId: string,
  includes: string = '',
  limit?: number,
  // idType: string = 'field_categoria.id',
) => {
  const requestOptions: RequestInit = {
    method: GET,
    headers: getHeader(AUTHORIZATION, AUTH_TOKEN, APP_JSON),
    redirect: 'follow',
  };
  const url = new URL(`${apiMainURL}/jsonapi/node/content_lpc`);
  const params = new URLSearchParams({
    include: includes,
    // [`filter[${idType}]`]: categoryId,
  });

  if (limit) params.append('page[limit]', `${limit}`);

  url.search = `${params}`;
  const response = await fetchRequestBody(`${url}`, requestOptions);

  return response;
};
export const getAdjacentPagesOnAnyGallery = async (
  id: number,
  filters: { field_galeria: string; field_fecha: number; field_parent: number },
  param: string = 'changed',
  order: string = 'ASC',
) => {
  const requestOptions: RequestInit = {
    method: POST,
    headers: getHeader(AUTHORIZATION, AUTH_TOKEN, APP_JSON),
    redirect: 'follow',
  };
  const url = new URL(`${apiMainURL}/api/content-pager/service/${id}`);
  const params = new URLSearchParams({
    filters: JSON.stringify(filters),
    param,
    order,
  });

  url.search = `${params}`;
  const res = await fetchRequestBody(`${url}`, requestOptions);

  return { next: res?.next?.uuid || null, prev: res?.prev?.uuid || null };
};
export const getGenericPageWithBu = async (
  pageUrl: string,
  bu: string,
  includes?: string,
) => {
  const requestOptions: RequestInit = {
    method: GET,
    headers: getHeader(AUTHORIZATION, AUTH_TOKEN, APP_JSON),
    redirect: 'follow',
  };
  const url = new URL(`${apiMainURL}/jsonapi/node/pagina_generica`);
  const params = new URLSearchParams({
    'filter[field_url]': pageUrl,
    'filter[field_bu.entity.name]': bu,
    include: includes || '',
  });

  url.search = `${params}`;
  const response = await fetchRequestBody(`${url}`, requestOptions);

  return response;
};
export const getLoyaltyBenefits = async (
  includes: string,
  {
    limit,
    categoryId,
    bu,
  }: { limit?: number; categoryId?: number; bu: string },
) => {
  const requestOptions: RequestInit = {
    method: GET,
    headers: getHeader(AUTHORIZATION, AUTH_TOKEN, APP_JSON),
    redirect: 'follow',
  };
  const url = new URL(`${apiMainURL}/jsonapi/node/benefits`);
  const params = new URLSearchParams({
    include: includes,
    'filter[field_bu.entity.name]': bu,
  });

  if (categoryId)
    params.append(
      'filter[field_benefit_category.meta.drupal_internal__target_id]',
      `${categoryId}`,
    );
  if (limit) params.append('page[limit]', `${limit}`);

  url.search = `${params}`;
  const response = await fetchRequestBody(`${url}`, requestOptions);

  return response;
};
export const getBenefitInfo = async (id: string, includes: string) => {
  const requestOptions: RequestInit = {
    method: GET,
    headers: getHeader(AUTHORIZATION, AUTH_TOKEN, APP_JSON),
    redirect: 'follow',
  };
  const url = new URL(`${apiMainURL}/jsonapi/node/benefits`);
  const params = new URLSearchParams({ 'filter[id]': id, include: includes });

  url.search = `${params}`;
  const response = await fetchRequestBody(`${url}`, requestOptions);

  return response;
};
export const getUserBySharpId = async (sharpId: number) => {
  const requestOptions: RequestInit = {
    method: GET,
    headers: getHeader(AUTHORIZATION, AUTH_TOKEN, APP_JSON),
    redirect: 'follow',
  };
  const url = new URL(`${apiMainURL}/jsonapi/user/user`);
  const params = new URLSearchParams({
    'filter[field_sharp_id]': `${sharpId}`,
    include:
      'field_favorite_beer,field_favorite_beer.field_logo,field_user_hobbies.field_hobbie_name,field_user_video,field_user_video.field_media_video_file,user_picture,field_user_hobbies.field_hobbie_image,field_user_item_1,field_user_item_1.field_partner_item_title,field_user_gallery,field_user_gallery.field_media_video_file,field_user_gallery.field_media_image, field_banda, field_area, field_bu',
  });

  url.search = `${params}`;

  const res = await fetchRequestBody(`${url}`, requestOptions);

  return res;
};
export const getUsersData = async ({
  limit,
  offset,
  areas,
  bus,
  filter,
  name,
}: {
  limit: number;
  offset: number;
  filter?: string;
  areas?: string;
  bus?: string;
  name?: string;
}) => {
  const requestOptions: RequestInit = {
    method: GET,
    headers: getHeader(AUTHORIZATION, AUTH_TOKEN, APP_JSON),
    redirect: 'follow',
  };

  const url = new URL(`${apiMainURL}/jsonapi/user/user`);
  const params = new URLSearchParams({
    ...(filter && {
      'sort[sort-taxonomia][path]': filter,
      'sort[sort-taxonomia][direction]': 'asc',
    }),
    'filter[field_user_partner]': 'partner',
    'page[limit]': `${limit}`,
    'page[offset]': `${offset}`,
    ...(name && {
      'filter[name][operator]': 'CONTAINS',
      'filter[name][value]': name,
    }),
    include:
      'field_user_hobbies.field_hobbie_name,field_user_video,user_picture,field_user_hobbies.field_hobbie_image, field_user_item_1 ,field_user_item_1.field_partner_item_title, field_bu, field_area',
  });

  if (areas) {
    params.append(
      'filter[a][condition][path]',
      'field_area.meta.drupal_internal__target_id',
    );

    for (const v of areas.split(','))
      params.append('filter[a][condition][value][]', `${v}`);

    params.append('filter[a][condition][operator]', 'IN');
  }
  if (bus) {
    params.append(
      'filter[n][condition][path]',
      'field_bu.meta.drupal_internal__target_id',
    );

    for (const v of bus.split(','))
      params.append('filter[n][condition][value][]', `${v}`);

    params.append('filter[n][condition][operator]', 'IN');
  }

  url.search = `${params}`;

  const res = await fetchRequestBody(`${url}`, requestOptions);

  return res;
};
export const getRequiredItems = async () => {
  const requestOptions: RequestInit = {
    method: GET,
    headers: getHeader(AUTHORIZATION, AUTH_TOKEN, APP_JSON),
    redirect: 'follow',
  };
  const url = new URL(
    `${apiMainURL}/es/jsonapi/taxonomy_term/partner_profile_items`,
  );
  const params = new URLSearchParams({
    'filter[field_required][value]': '1',
  });

  url.search = `${params}`;

  const res = await fetchRequestBody(`${url}`, requestOptions);

  return res;
};
export const getAllHobbiesByPage = async (offset: number = 0) => {
  const requestOptions: RequestInit = {
    method: GET,
    headers: getHeader(AUTHORIZATION, AUTH_TOKEN, APP_JSON),
    redirect: 'follow',
  };
  const url = new URL(`${apiMainURL}/es/jsonapi/taxonomy_term/hobbies`);
  const params = new URLSearchParams({
    'page[offset]': `${offset}`,
    include: 'field_hobbie_image',
  });

  url.search = `${params}`;

  const res = await fetchRequestBody(`${url}`, requestOptions);

  return res;
};
export const postProfileUpdate = async (body: Object) => {
  const requestOptions: RequestInit = {
    method: POST,
    headers: getHeader(AUTHORIZATION, AUTH_TOKEN, APP_JSON),
    redirect: 'follow',
    body: JSON.stringify(body),
  };

  const response = await fetchRequestBody(
    apiMainURL + '/api/profile-maz/update',
    requestOptions,
  );

  return response;
};
export const postProfileDeleteItem = async (body: Object) => {
  const requestOptions: RequestInit = {
    method: POST,
    headers: getHeader(AUTHORIZATION, AUTH_TOKEN, APP_JSON),
    redirect: 'follow',
    body: JSON.stringify(body),
  };

  const response = await fetchRequestBody(
    apiMainURL + '/api/profile-maz/delete',
    requestOptions,
  );

  return response;
};
export const postGenMAZ = async (id: string, opts?: { date?: string }) => {
  const myHeaders = new Headers();
  myHeaders.append(
    'Authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
  );

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    ...(opts?.date && {
      body: new URLSearchParams({
        moment: opts?.date,
      }),
    }),
  };

  const response = await fetch(
    `${apiMainURL}/api/partner_profile/genmaz/` + id,
    requestOptions,
  );
  if (response.status === 200) {
    return response;
  }
};
export const postGeneralPartner = async (
  id: string,
  opts?: { date: string },
) => {
  const myHeaders = new Headers();
  myHeaders.append(
    'Authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
  );

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    ...(opts?.date && {
      body: new URLSearchParams({
        moment: opts?.date,
      }),
    }),
  };

  const response = await fetch(
    `${apiMainURL}/api/partner_profile/profile/${id}`,
    requestOptions,
  );

  if (response.status === 200) {
    return response;
  }
};
export const postGraphics = async (opts: { date?: string } | void) => {
  const myHeaders = new Headers();
  myHeaders.append(
    'Authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
  );

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    ...(opts?.date && {
      body: new URLSearchParams({
        moment: opts?.date,
      }),
    }),
  };

  const response = await fetch(
    `${apiMainURL}/api/partner_profile/graphics`,
    requestOptions,
  );

  if (response.status === 200) {
    return response;
  }
};
export const postOprMyProfile = async (
  id: string,
  opts?: { date?: string },
) => {
  const myHeaders = new Headers();
  myHeaders.append(
    'Authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
  );

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    ...(opts?.date && {
      body: new URLSearchParams({
        moment: opts?.date,
      }),
    }),
  };

  const response = await fetch(
    `${apiMainURL}/api/partner_profile/opr/` + id,
    requestOptions,
  );
  if (response.status === 200) {
    return response;
  }
};

export const postMenuItems = async (): Promise<Response> => {
  const myHeaders = new Headers();
  myHeaders.append(
    'Authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
  );

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
  };

  const response = await fetch(
    `${apiMainURL}/api/partner_profile/searchindex`,
    requestOptions,
  );

  return response;
};
export const postMenuItemsData = async () => {
  const myHeaders = new Headers();
  myHeaders.append(
    'Authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
  );

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
  };

  const response = await fetch(
    `${apiMainURL}/api/partner_profile/searchindex`,
    requestOptions,
  );
  if (response.status === 200) {
    return response;
  }
};
export const postLeaderShip = async (id: string, opts?: { date?: string }) => {
  const myHeaders = new Headers();
  myHeaders.append(
    'Authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
  );

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    ...(opts?.date && {
      body: new URLSearchParams({
        moment: opts?.date,
      }),
    }),
  };

  const response = await fetch(
    `${apiMainURL}/api/partner_profile/leadership/` + id,
    requestOptions,
  );
  if (response.status === 200) {
    return response;
  }
};

export const postPersonality = async (id: string, opts?: { date?: string }) => {
  const myHeaders = new Headers();
  myHeaders.append(
    'Authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
  );

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    ...(opts?.date && {
      body: new URLSearchParams({
        moment: opts?.date,
      }),
    }),
  };

  const response = await fetch(
    `${apiMainURL}/api/partner_profile/personality/` + id,
    requestOptions,
  );
  if (response.status === 200) {
    return response;
  }
};
export const postExHubAccessManagement = async () => {
  const requestOptions: RequestInit = {
    method: POST,
    headers: getHeader(AUTHORIZATION, AUTH_TOKEN, APP_JSON),
    redirect: 'follow',
  };

  const res = await fetchRequestBody(
    `${apiMainURL}/api/get-simplifica-hub`,
    requestOptions,
  );

  return res;
};

// SQUADS
export const postSquads = async () => {
  const myHeaders = new Headers();
  myHeaders.append(
    'Authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
  );

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
  };

  const response = await fetch(
    `${apiMainURL}/squads/communities`,
    requestOptions,
  );
  console.log('RESPONSE:  ', response);
  if (response.status === 200) {
    return response;
  }
};
export const getTaxonomyById = async (id: string) => {
  const requestOptions: RequestInit = {
    method: GET,
    headers: getHeader(AUTHORIZATION, AUTH_TOKEN, APP_JSON),
    redirect: 'follow',
  };
  const url = new URL(`${apiMainURL}/es/jsonapi/taxonomy_term/${id}`);

  const res = await fetchRequestBody(`${url}`, requestOptions);

  return res;
};
export const postGalleryResources = async ({
  page,
  limit = 16,
  cat,
  bu,
  hasLikeFilter,
  idsWithLikes = [],
}: {
  page: number;
  limit?: number;
  bu?: string;
  cat?: string;
  hasLikeFilter?: boolean;
  idsWithLikes?: (string | number)[] | [];
}) => {
  const requestOptions: RequestInit = {
    method: GET,
    headers: getHeader(AUTHORIZATION, AUTH_TOKEN, APP_JSON),
    redirect: 'follow',
  };
  const url = new URL(`${apiMainURL}/jsonapi/node/resource`);
  const params = new URLSearchParams({
    include: 'field_bu,field_miniatura,field_topic',
    'page[limit]': `${limit}`,
    'page[offset]': `${page}`,
  });

  if (hasLikeFilter) {
    params.append('filter[n][condition][path]', 'drupal_internal__nid');

    for (const v of idsWithLikes)
      params.append('filter[n][condition][value][]', `${v}`);

    params.append('filter[n][condition][operator]', 'IN');
  }

  bu && params.append('filter[field_bu.meta.drupal_internal__target_id]', bu);
  cat &&
    params.append('filter[field_topic.meta.drupal_internal__target_id]', cat);

  url.search = `${params}`;

  const res = await fetchRequestBody(`${url}`, requestOptions);

  return res;
};
export const postPresentationById = async ({
  id,
}: {
  id?: number | string;
}) => {
  const requestOptions: RequestInit = {
    method: GET,
    headers: getHeader(AUTHORIZATION, AUTH_TOKEN, APP_JSON),
    redirect: 'follow',
  };
  const url = new URL(`${apiMainURL}/jsonapi/node/resource`);
  const params = new URLSearchParams({
    include:
      'field_bu,field_topic,field_complemento_audio,field_complementos,field_complementos.field_list.field_image,field_recurso,field_recurso.field_media_audio_file,field_recurso.field_media_video_file',
    'filter[drupal_internal__nid]': `${id}`,
  });

  url.search = `${params}`;

  const res = await fetchRequestBody(`${url}`, requestOptions);

  return res;
};
export const postEventsCalendar = async ({
  page,
  limit,
  ...args
}: {
  page: number;
  limit?: number;
  date?: string;
  bu?: string;
  month?: string;
  cat?: string;
}) => {
  const requestOptions: RequestInit = {
    method: POST,
    headers: getHeader(AUTHORIZATION, AUTH_TOKEN, APP_JSON),
    redirect: 'follow',
  };
  const url = new URL(`${apiMainURL}/api/squads/calendar`);
  const params = new URLSearchParams({ page: `${page}` });

  if (limit) params.append('perpage', `${limit}`);

  for (const [k, v] of Object.entries(args)) if (k) params.append(k, `${v}`);

  url.search = `${params}`;

  const res = await fetchRequestBody(`${url}`, requestOptions);

  return res;
};
export const getEventLikes = async (id: number | string) => {
  const requestOptions: RequestInit = {
    method: GET,
    headers: getHeader(AUTHORIZATION, AUTH_TOKEN, APP_JSON),
    redirect: 'follow',
  };
  const url = new URL(`${apiMainURL}/api/${id}/check-favorites`);

  const res = await fetchRequestBody(`${url}`, requestOptions);

  return res;
};
export const getLPCInfographic = async (id: number | string) => {
  const requestOptions: RequestInit = {
    method: GET,
    headers: getHeader(AUTHORIZATION, AUTH_TOKEN, APP_JSON),
    redirect: 'follow',
  };
  const url = new URL(`${apiMainURL}/socios-lpc/services/infographic`);

  const res = await fetchRequestBody(`${url}`, requestOptions);

  return res;
};
export const getAllGalleryLikes = async () => {
  const requestOptions: RequestInit = {
    method: POST,
    headers: getHeader(AUTHORIZATION, AUTH_TOKEN, APP_JSON),
    redirect: 'follow',
  };
  const url = new URL(
    `${apiMainURL}/api/publicacion_galeria/favorites?_format=json`,
  );

  const res = await fetchRequestBody(`${url}`, requestOptions);

  return res;
};
