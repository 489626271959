import React, { useReducer, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
} from 'reactstrap';

import { tYCReducer, tYCInitial } from './termsAndConditionsReducer';
import countries from '../../../data/activeCountries.json';

import './TermsAndConditions.scss';
import { apiMainURL } from '../../../modules/settings/environment';
import { generateAlert } from '../../UI/alerts-Service/alerts-function';
import { deleteSession } from '../../../services/api/api-socios-maz';

const TermsAndConditions = () => {
  const [state, dispatch] = useReducer(tYCReducer, tYCInitial);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const onLinkClick = (value: string): void => dispatch({ type: value });

  const onCheckboxClick = ({ target: { checked } }: { target: any }): void =>
    dispatch({ type: 'CHECKED_COUNTRY_STATUS', checked });

  const toggleDropdown = (): void => setDropdownOpen((prevState) => !prevState);
  function handleSave() {
    var myHeaders: Headers = new Headers();
      myHeaders.append(
        'authorization',
        `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
      );
      var formdata = new FormData();
      formdata.append('sharp', localStorage.getItem('sharp') || '');
      var requestOptions: RequestInit = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
      };

      fetch(`${apiMainURL}/api/legal_save`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          localStorage.setItem(
            'accept-terms',
            JSON.parse(result).legal ? '1' : '0',
          );
          window.location.href = '/home';
        })
        .catch((error) => {
          console.log('error', error);
          generateAlert('error',"Save terms error"," Sharp: "+(localStorage.getItem('sharp')||''))
          setTimeout(() => {
            deleteSession();
          }, 3000);
        });
  }


  return (

    <div className='main-content main-terms'>
      <div className='container-fluid'>
        <div className='w-100 drop-title'>
          <h1 className='text-center'>Welcome to MAZ Partners</h1>
          <h2 className='text-center'>Please, select your country</h2>
        </div>
        <div className='d-flex justify-content-center p-5 w-100'>
          <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle caret>
              {state.country ? state.country : 'Select your country'}
            </DropdownToggle>
            <DropdownMenu className=''>
              {countries.map(({ label, value }) => (
                <DropdownItem
                  key={`dropdown-selector-${label}-${value}`}
                  onClick={() => onLinkClick(value)}>
                  {label}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
        {state.country && (
          <>
            <div id='down' className='t-y-c-checkbox mt-4 ms-3 text-center'>
              <p className='tw'>
                <span className='rounded-circle bg-white me-2 w-auto text-muted p-1'>
                  <i className='bi bi-file-earmark-spreadsheet text-dark' />
                </span>
                Autorizo a <strong>"{state.brewery}"</strong>, a incorporar y procesar mi información personal sensible (fotos, videos) en esta plataforma con el fin de facilitar mi identificación ante los socios actuales y futuros en las compañías vinculadas a nivel mundial a ABI. Conozco que esta autorización es opcional y que, en caso de no otorgarla, mi acceso y uso de la plataforma no se verá afectado. Conozco también que tengo derecho a consultar qué datos tienen sobre mí, solicitar prueba de esta autorización, rectificar, actualizar y eliminar mis datos contactando a <a href="mailto:protecciondedatos@ab-inbev.com.">protecciondedatos@ab-inbev.com.</a> Conoce más en la <a href={state?.url} target="_blank">Política de Privacidad</a>.
              </p>
              <p>
                <span className='rounded-circle bg-white me-2 w-auto text-muted p-1'>
                  <i className='bi bi-file-earmark-spreadsheet text-dark' />
                </span>
                <a href={state.url2} target="_blank">Check terms and conditions.</a>
              </p>
              <Input
                readOnly
                type='checkbox'
                className='me-2'
                checked={state.checked}
                onClick={onCheckboxClick}
              />
              <Label>Accept.</Label>
            </div>
            <div className='t-y-c-next-buttond-flex text-end'>
              <Button className="" disabled={!state.checked} onClick={() => handleSave()}>Next</Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TermsAndConditions;
