import React, { ReactElement, useEffect } from 'react';



const ModalNps = (): ReactElement => {

  const sharp = localStorage.getItem('sharp') || '';

  useEffect(() => {
    if (sharp !== '') {
      window.test(sharp);
    }
  }, []);

  return (
    <div className='modal-nps-poll'>
    </div>
  );
};
export default ModalNps;
