import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { apiMainURL } from '../../../../modules/settings/environment';
import {
  getLikesByType,
  postGalleryResources,
  useGetAllTopicResourcesQuery,
} from '../../../../services/api/api-socios-maz';
import { Resource } from '../Gallery/GalleryReducer';

import Carousel from 'react-multi-carousel';
import GalleryCard from '../Gallery/GalleryCard';

type RelatedContentProps = {
  topic: string;
};

const RelatedContent = ({ topic }: RelatedContentProps) => {
  const { presentationId } = useParams();

  const [relatedResources, setRelatedResources] = useState([]);

  const allCategories = useGetAllTopicResourcesQuery().data;

  useEffect(() => {
    const getData = async () => {
      const likesData = await getLikesByType('resource');
      const related = await postGalleryResources({
        page: 0,
        cat:
          allCategories?.data.find(
            ({ attributes: { name } = { name: '' } }) => name === topic,
          )?.attributes?.drupal_internal__revision_id || '',
      });

      setRelatedResources(
        related?.data
          ?.filter(
            ({
              attributes: { drupal_internal__nid } = {
                drupal_internal__nid: 0,
              },
            }) => drupal_internal__nid !== +(presentationId || ''),
          )
          .map(
            ({
              attributes: { body, title, drupal_internal__nid } = {
                body: { value: '' },
                title: '',
                drupal_internal__nid: 0,
              },
              relationships: { field_miniatura, field_topic } = {
                field_miniatura: { data: { id: '' } },
                field_topic: { data: { id: '' } },
              },
            }: any) => {
              const min = related.included?.filter(
                ({ id = '' }) => id === field_miniatura.data.id,
              )[0]?.attributes?.uri?.url;

              return {
                description: body.value,
                title,
                img: min ? apiMainURL + min : '',
                topic:
                  related.included?.filter(
                    ({ id = '' }) => id === field_topic.data.id,
                  )[0]?.attributes?.name || '',
                id: drupal_internal__nid,
                likeValue:
                  likesData?.findIndex(
                    ({ entity_id = '' }) => +entity_id === drupal_internal__nid,
                  ) !== -1,
              };
            },
          ) || [],
      );
    };

    allCategories && getData();
  }, [topic, allCategories, presentationId]);

  return (
    <>
      <h2>Related Content</h2>
      {relatedResources.length !== 0 ? (
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={3000}
          containerClass='container-with-dots'
          draggable
          infinite
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          responsive={{
            superLargeDesktop: {
              breakpoint: { max: 4000, min: 3000 },
              items: 5,
            },
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 3,
            },
            mobile: {
              breakpoint: {
                max: 576,
                min: 0,
              },
              items: 1,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 576,
              },
              items: 2,
            },
          }}
          shouldResetAutoplay
          slidesToSlide={1}
          swipeable>
          {relatedResources.map((args: Resource) => (
            <GalleryCard key={`related-${args.title}-${args.id}`} {...args} />
          ))}
        </Carousel>
      ) : (
        <div className='w-100'>
          <p className='text-center'>No elements Found.</p>
        </div>
      )}
    </>
  );
};

export default RelatedContent;
