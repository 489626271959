import { Section } from './section.models';

export type Category = { id: string; title: string; description: string };

export type ContentState = {
  categories?: Category[];
  sections?: Section[];
  activeSection?: string | null;
  sectionsToShow?: Section[];
  isLoading?: boolean;
  cards?: {
    title: string;
    desc?: string;
    src: string;
    url: { label: string; src: string };
  }[];
};

export enum ContentActionCases {
  SET_CATEGORIES = 'SET_CATEGORIES',
  INIT_CATEGORIES = 'INIT_CATEGORIES',
  CHANGE_ACTUAL_SECTION = 'CHANGE_ACTUAL_SECTION',
  SET_SECTIONS = 'SET_SECTIONS',
  LOAD_ON = 'LOAD_ON',
  SET_CARDS = 'SET_CARDS',
}

export type ContentAction = {
  type: ContentActionCases;
  payload?: ContentState;
};
