import React from 'react';
import { CardContentProps, CardItemProps } from './detailReducer';

const CardItem = ({ content, icon, label }: CardItemProps) => {
  return (
    <li>
      <div className='d-flex'>
        <span>
          <img src={icon} width='40' alt={`icon-${label}`} />
        </span>
        <span className='card-element-detail'>
          {label}
          <p className='tw mb-1'>{content}</p>
        </span>
      </div>
    </li>
  );
};

const DetailCard = ({ buttonInfo, eventInfo }: CardContentProps) => {
  const { label = '', uri = '' } = buttonInfo;

  return (
    <div className='info-event-detail'>
      <ul>
        {eventInfo.map((p) => (
          <CardItem {...p} />
        ))}
      </ul>
      {label && (
        <div className='link-register'>
          <a
            className='btn btn-primary'
            href={uri}
            target='_blank'
            rel='noreferrer'>
            {label}
          </a>
        </div>
      )}
    </div>
  );
};

export default DetailCard;
