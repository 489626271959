import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { store as storeApp } from '../../../modules/appState/store';

export const NavElementsLPC = (props: any) => {
  const { t, i18n } = useTranslation('global');
  const destinationClassName = 'logo destination lpc-icons mx-auto';
  const eventClassName = 'logo event lpc-icons mx-auto';
  const getReadyClassName = 'logo get-ready lpc-icons mx-auto';

  const indexActived = props?.actived;

  const [activeTab, setActiveTab] = useState('1');
  const [miniatures, setMiniatures] = useState([
    {
      img: getReadyClassName,
      link: '/lpc',
      name: t('lpc-buttons.getReady'),
    },
    {
      img: destinationClassName,
      link: '/lpc/destiny',
      name: t('lpc-buttons.destiny'),
    },
    {
      img: eventClassName,
      link: '/lpc/event',
      name: t('lpc-buttons.event'),
    },
  ]);

  const toggle: any = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  useEffect(() => {
    setMiniatures([
      {
        img: getReadyClassName,
        link: '/lpc',
        name: t('lpc-buttons.getReady'),
      },
      {
        img: destinationClassName,
        link: '/lpc/destiny',
        name: t('lpc-buttons.destiny'),
      },
      {
        img: eventClassName,
        link: '/lpc/event',
        name: t('lpc-buttons.event'),
      },
    ]);
  }, [t]);
  return (
    <Nav className='miniatures-center mt-3' tabs>
      {miniatures.map((min: any, index: number) => {
        if (index == indexActived)
          return (
            <NavItem
              key={index.toString()}
              className='nav-item px-2 px-md-3 circle-item'>
              <Link className={' miniatures-tam'} to={min.link}>
                <div className={min?.img + ' actived'} id='icon-section-lpc' />
                <p className='px-auto txt-actived'>{min?.name}</p>
              </Link>
            </NavItem>
          );
        else
          return (
            <NavItem
              key={index.toString()}
              className='nav-item px-xs-2 px-md-3 circle-item'>
              <Link className={' miniatures-tam'} to={min.link}>
                <div className={min?.img} id='icon-section-lpc' />
                <p className='px-auto'>{min?.name}</p>
              </Link>
            </NavItem>
          );
      })}
    </Nav>
  );
};
