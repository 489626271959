import React from 'react';
import { PDFDownloadLink, Document, Page, View } from '@react-pdf/renderer';
import ReactDOMServer from 'react-dom/server';
import Html from 'react-pdf-html';

const MyComponent = ({ data }: any) => <p>{data}</p>;

const MyDoc = () => {
  const html = ReactDOMServer.renderToStaticMarkup(
    <MyComponent data='Hi again' />,
  );

  return (
    <Document>
      <Page>
        <View style={{ margin: 10 }}>
          <Html>{html}</Html>
        </View>
      </Page>
    </Document>
  );
};

const DownloadTest = () => {
  return (
    <div className='main-content'>
      <PDFDownloadLink document={<MyDoc />} fileName='somename.pdf'>
        {({ blob, url, loading, error }) =>
          loading ? 'Loading document...' : 'Download now!'
        }
      </PDFDownloadLink>
    </div>
  );
};

export default DownloadTest;
