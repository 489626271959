import Swal from 'sweetalert2';

import './customSwal.scss';

export function errorAlert(
  title: string = 'Your session has expired',
  message?: string,
) {
  const text =
    message ||
    'In {{countNumber}} seconds you will be redirected to the start or click on the following button.';
  const swalWithCustomStyles = Swal.mixin({
    customClass: {
      container: 'custom-container',
      popup: 'custom-popup',
      icon: 'custom-icon',
      title: 'custom-title',
      htmlContainer: 'custom-html-container',
      actions: 'custom-actions',
      confirmButton: 'custom-confirm-button',
    },
  });

  const generateNumber = (count = 7) => {
    let timeout;

    if (count > 0) {
      const countNumber = document.getElementById('countNumber');

      if (countNumber)
        countNumber.textContent = text.replace(
          '{{countNumber}}',
          count.toString(),
        );

      timeout = setTimeout(() => {
        generateNumber(count - 1);
      }, 1000);
    } else {
      clearTimeout(timeout);

      timeout = null;
    }
  };

  window.onload = () => generateNumber();

  swalWithCustomStyles
    .fire({
      title: title,
      text: message,
      html: `<div><p id="countNumber" /></div>`,
      icon: 'warning',
      confirmButtonText: 'Sign In',
    })
    .then((res) => {
      if (res.isConfirmed) {
        localStorage.setItem('code_verifier', '');
        window.location.replace('/');
      }
    });
}
