import React, { useState } from 'react'
import { ListRestaurants } from '../../pages/Lpc/Event/items/maps/Restaurants/list-restaurants';
import CarouselView from '../../UI/Carousel/carousel';
import LoadSpinner from '../../UI/LoadSpinner/LoadSpinner';
import Sections from '../../UI/Sections/sections';
import MediaComponent from '../mediaComponent/media-component';
import { CardDefault } from './card-default';
import { GetBodyInnerHTML } from './getDefaultElements';

export const DefaultContentCustom = (props: any) => {
  const { elem } = props;
  const onDownloadButtonClick = (url: string) => {
   
  };
  if (elem)
    return (
      <div>
        <section className='main-content lpc-bg pb-5'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                {elem?.map((el: any, index: number) => {
                  if (el?.type == 'links') {
                    return <Sections key={index} content={el?.content} />;
                  }
                  if (el?.type == 'media') {
                    return <MediaComponent key={index} content={el?.content} />;
                  }
                  if (el?.type == 'text') {
                    return (
                      <div className='text-generic-r my-5'>
                        <GetBodyInnerHTML
                          key={index}
                          texto={el?.content?.value || ''}
                        />
                      </div>
                    );
                  }
                  if (el?.type == 'banner') {
                    return <CarouselView key={index} content={el?.content} />;
                  }
                  if (el?.type == 'title') {
                    switch (el?.content?.type) {
                      case 'Título 1':
                        return (
                          <h1 key={index} className='section-title'>
                            {el?.content?.value || ''}
                          </h1>
                        );
                        break;
                      case 'Título 2':
                        return (
                          <h2 key={index} className='section-title'>
                            {el?.content?.value || ''}
                          </h2>
                        );
                        break;
                      case 'Título 3':
                        return (
                          <h3 key={index} className='section-title'>
                            {el?.content?.value || ''}
                          </h3>
                        );
                        break;
                      case 'Título 4':
                        return (
                          <h4 key={index} className='section-title'>
                            {el?.content?.value || ''}
                          </h4>
                        );
                        break;
                      case 'Título 5':
                        return (
                          <h5 key={index} className='section-title'>
                            {el?.content?.value || ''}
                          </h5>
                        );
                        break;
                    }
                  }
                  if (el?.type == 'separator') {
                    return (
                      <div key={index} style={{ paddingTop: el?.content?.px }}>
                        <p className='oculto'>{'   separator    '}</p>
                      </div>
                    );
                  }
                  if (el?.type == 'card') {
                    return (
                      <div key={index}>
                        <CardDefault idCard={el?.content.idCard}></CardDefault>
                      </div>
                    );
                  }
                  if (el?.type == 'button') {
                    return (
                      <button
                        className='mt-5 px-3 py-2 mx-auto download-button d-flex align-items-center'
                      >
                        <i className='bi bi-file-earmark-arrow-down fs-4 me-2' />
                      <a href={el?.content?.link} target='_blank'>{el?.content?.name}</a>
                      </button>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  else return <LoadSpinner></LoadSpinner>;
}
