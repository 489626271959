import React, { useEffect } from 'react';
import { deleteSession } from '../../../services/api/api-socios-maz';

const LoadSpinner = () => {
  useEffect(() => {
    if (!sessionStorage.getItem('accessToken'))
      setTimeout(function () {
        deleteSession(window.location.href);
      }, 10000);
  }, []);
  return (
    <div className='pointer-l'>
      <section className="overlay-content">
        <div>
          <img
            src={`../../../../resources/loading.gif`}
            alt="spinning"
            width="180"
            className="img-fluid"
          />
        </div>
      </section>
    </div>
  );
};

export default LoadSpinner;
