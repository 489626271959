import React, { useEffect, useMemo, useState } from 'react';
import {
  postGeneralPartner,
  useGetallArchetypesQuery,
  useGetPartnersProfileDatesQuery,
} from '../../../../../../services/api/api-socios-maz';
import { store as storeApp } from '../../../../../../modules/appState/store';
import {
  apiMainURL,
  appURL,
} from '../../../../../../modules/settings/environment';
import { useLocation, useNavigate } from 'react-router-dom';
import { PrintComponent } from '../../../../../UI/Print-component';
import LoadSpinner from '../../../../../UI/LoadSpinner/LoadSpinner';
import { PDFDownloadLink, Document, Page, View } from '@react-pdf/renderer';
import ReactDOMServer from 'react-dom/server';
import Html from 'react-pdf-html';

const MyDoc = (props: any) => {
  const idUser = props?.content?.idUser;
  const data = props?.content?.data;
  const archetypes = props?.content?.archetypes;
  const crumbs = props?.content?.crumbs;
  const mainArq = props?.content?.mainArq;
  const secArch = props?.content?.secArch;
  const records = props?.content?.records;
  const onClick = props?.content?.onClick;

  const html = ReactDOMServer.renderToStaticMarkup(
    <PrintComponent
      idUser={idUser}
      data={data}
      archetypes={archetypes}
      crumbs={crumbs}
      mainArq={mainArq}
      secArch={secArch}
      records={records}
      onClick={onClick}
    />,
    // <h1>HOLA MUNDO</h1>
  );

  return (
    <Document>
      <Page>
        <View style={{ margin: 10 }}>
          <Html>{html}</Html>
        </View>
      </Page>
    </Document>
  );
};

const DownloadTest = (props: any) => {
  console.log('DOWNLOAD TEST PROPS:  ', props);

  const [blob, setBlob] = useState<Blob>();
  const [file, setFile] = useState<boolean>(false);
  const [pdf, setarrayPDF] = useState<any>();
  const navigate = useNavigate();

  const handleClick = () => {
    if (pdf) navigate(`/callbackPDF?pdfData${pdf.join(',')}`);
    setFile(true);
  };
  useEffect(() => {}, []);
  return (
    <div className='main-content'>
      <PDFDownloadLink
        document={<MyDoc content={props} />}
        fileName='somename.pdf'>
        {({ blob, url, loading, error }) => {
          if (blob) {
            setBlob(blob);
            console.log('EJECUTANDO DOWNLOAD', blob);
            const reader = new FileReader();
            reader.onloadend = () => {
              const pdfArrayBuffer = Array.from(
                new Uint8Array(reader.result as ArrayBuffer),
              );
              if (pdfArrayBuffer.join(',').length > 0) {
                setarrayPDF(pdfArrayBuffer);
              }
            };
            reader.readAsArrayBuffer(blob);
          }

          return loading ? 'Loading document...' : 'Download now!';
        }}
      </PDFDownloadLink>
      <button className='btn btn-secondary' onClick={() => handleClick()}>
        Download
      </button>
    </div>
  );
};

export const MyProfile = (props: any) => {
  const location = useLocation();
  const [data, setData] = useState<any>({});
  const archetypesListData = useGetallArchetypesQuery()?.data;
  const archetypesList = getArchetypes(archetypesListData);
  const [user, setUser] = useState(storeApp.getState().AppReducer?.userData);
  const [load, setLoad] = useState(true);
  storeApp.subscribe(() => {
    setUser(storeApp?.getState()?.AppReducer?.userData || '');
  });
  const [actualYear, setActualYear] = useState<string>('');

  function getArchetypes(archData: any): any[] {
    let archeTypes: any[] = [];
    archData?.data?.map((arch: any) => {
      let idImage = arch?.relationships?.field_archetype_image?.data.id || '';
      let imageFile = '';
      archData?.included?.map((inc: any) => {
        if (inc?.id == idImage) {
          archeTypes.push({
            name: arch?.attributes?.name || '',
            image: apiMainURL + inc?.attributes?.uri?.url || '',
            id: arch?.attributes?.drupal_internal__tid || '',
          });
        }
      });
    });

    return archeTypes;
  }

  const dates = useGetPartnersProfileDatesQuery({
    id: user?.drupal_id || '',
  }).data;

  const dropdownItems: any = useMemo(
    () =>
      dates
        ?.filter(
          ({ fecha_lanzamiento = '' }) => !fecha_lanzamiento.startsWith('0000'),
        )
        .map(({ fecha_lanzamiento = '' }) => ({
          value: fecha_lanzamiento,
          label: fecha_lanzamiento.substring(0, 7),
        })) || [],
    [dates],
  );
  const onDropdownClick = (value: string) => {
    setLoad(true);
    setActualYear(value);
  };

  useEffect(() => {
    const getDataProfile = async () => {
      // console.log("DRUPAL ID2 profile 3333  ",user?.drupal_id);
      postGeneralPartner(user?.drupal_id || '', {
        date: actualYear,
      })
        ?.then((response: any) => response.text())
        .then((result) => {
          setData(JSON.parse(result));
          setLoad(false);
        });
    };

    if (user?.drupal_id && user?.drupal_id !== '') getDataProfile();
  }, [actualYear]);

  useEffect(() => {
    setActualYear(dropdownItems[0]?.value || '');
  }, []);
  const crumbs =
    location?.pathname != '/profile/partner-profile'
      ? [
          { path: '/home', label: 'MAZ Partners' },
          { path: '/partners/partner-profile', label: 'Partner profile' },
          { label: 'My Profile' },
        ]
      : [
          { path: '/home', label: 'MAZ Partners' },
          { label: 'Partner Profile' },
        ];

  if (user?.drupal_id) {
    return (
      <div className=''>
        <div className='container-fluid'>
          {/* <DownloadTest 
          idUser={user?.drupal_id || ''}
          data={data}
          archetypes={archetypesList}
          crumbs={crumbs}
          mainArq={data?.dataArchetypes?.data
            ?.sort(function (a: any, b: any) {
              return b?.value - a?.value;
            })
            .slice(0, 1)}
          secArch={data?.dataArchetypes?.data
            ?.sort(function (a: any, b: any) {
              return b?.value - a?.value;
            })
            .slice(1, data?.dataArchetypes?.data?.lenght)}
          records={dropdownItems}
          onClick={onDropdownClick}/> */}
        </div>
        <PrintComponent
          idUser={user?.drupal_id || ''}
          data={data}
          archetypes={archetypesList}
          crumbs={crumbs}
          mainArq={data?.dataArchetypes?.data
            ?.sort(function (a: any, b: any) {
              return b?.value - a?.value;
            })
            .slice(0, 1)}
          secArch={data?.dataArchetypes?.data
            ?.sort(function (a: any, b: any) {
              return b?.value - a?.value;
            })
            .slice(1, data?.dataArchetypes?.data?.lenght)}
          records={dropdownItems}
          onClick={onDropdownClick}
        />

        {load && <LoadSpinner />}
      </div>
    );
  } else return <></>;
};
