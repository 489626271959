import React from 'react';

interface NoDataFoundProps {
  message?: string;
  className?: string;
}

const NoDataFound = ({
  message = 'Data not found.',
  className: newClassName,
}: NoDataFoundProps) => {
  const className = 'fs-2 text-center fw-bolder ' + newClassName;

  return <p {...{ className }}>{message}</p>;
};

export default NoDataFound;
