export enum PresentationTypes {
  PRESENTATION = 'PRESENTATION',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
}

export type ComplementProps = {
  type: 'BANNER' | 'TEXT' | 'HIGHLIGHT';
  content: string;
};

type PresentationState = {
  title: string;
  type: PresentationTypes;
  mediaUrl: string;
  date: string;
  topic: string;
  bus: string;
  description: string;
  audioBanner?: string;
  like: boolean;
  likeCounter: number;
  complements: ComplementProps[];
  isLoading: boolean;
};

export enum PresentationCases {
  SET_INITIAL_DATA = 'SET_INITIAL_DATA',
  SET_LIKE = 'SET_LIKE',
  SET_LOAD = 'SET_LOAD',
}

type PresentationAction = {
  type: PresentationCases;
  payload: {
    title?: string;
    type?: PresentationTypes;
    mediaUrl?: string;
    date?: string;
    topic?: string;
    bus?: string;
    description?: string;
    audioBanner?: string;
    like?: boolean;
    likeCounter?: number;
    complements?: ComplementProps[];
    isLoading?: boolean;
  };
};

const initComplement: ComplementProps = { type: 'BANNER', content: '' };

export const initialPresentationState: PresentationState = {
  title: '',
  type: PresentationTypes.PRESENTATION,
  mediaUrl: '',
  date: '',
  topic: '',
  bus: '',
  description: '',
  audioBanner: '',
  like: false,
  likeCounter: 0,
  complements: [initComplement],
  isLoading: true,
};

export const PresentationReducer = (
  state: PresentationState,
  action: PresentationAction,
) => {
  switch (action.type) {
    case 'SET_INITIAL_DATA': {
      const { payload } = action;

      return {
        ...state,
        title: payload.title || '',
        type: payload.type || PresentationTypes.PRESENTATION,
        mediaUrl: payload.mediaUrl || '',
        date: payload.date || '',
        topic: payload.topic || '',
        bus: payload.bus || '',
        description: payload.description || '',
        audioBanner: payload.audioBanner || undefined,
        like: payload.like || false,
        likeCounter: payload.likeCounter || 0,
        complements: payload.complements || [initComplement],
        isLoading: payload.isLoading || false,
      };
    }

    case 'SET_LIKE': {
      const { payload } = action;

      return {
        ...state,
        like: payload.like || !state.like,
        likeCounter: payload.likeCounter || 0,
      };
    }

    case 'SET_LOAD': {
      const { payload } = action;

      return { ...state, isLoading: payload.isLoading || false };
    }

    default:
      return { ...state };
  }
};
