import React, { memo, ReactChild } from 'react';
import { StepperCard } from '../../../../../models/loyalty.models';
import { GetBodyInnerHTML } from '../../../../Layouts/default-section/getDefaultElements';
import { v4 } from 'uuid';

const StepperCards = ({ items }: { items: StepperCard[] }) => {
  if (!(items && items[0].icon && items[0].title))
    return <p>No items found.</p>;

  const iconsToShow = items.reduce<ReactChild[]>((acc, cur, idx) => {
    const uuid = v4();

    if (idx !== 0)
      acc.push(
        <div className='col line-col'>
          <div
            key={`stepper-bar-${uuid}`}
            className='step d-flex align-items-center h-50'>
            <div className='border line' />
          </div>
        </div>,
      );

    acc.push(
      <div className='col d-flex flex-column'>
        <div
          key={`stepper-icon-${uuid}`}
          className='step circle d-flex justify-content-center'>
          <div className='border rounded-circle overflow-hidden'>
            <img src={cur.icon} alt={cur.title} />
          </div>
        </div>
        <div
          key={`stepper-card-${uuid}`}
          className='mt-3 step circle d-flex flex-column'>
          <p className='stepper-header mb-0 mx-auto'>{cur.title}</p>
          <p className='stepper-body mx-auto'>
            <GetBodyInnerHTML content={cur.description} />
          </p>
        </div>
      </div>,
    );

    return acc;
  }, []);

  return (
    <div className='loyalty-stepper'>
      <div className='row mx-auto w-100'>{iconsToShow}</div>
    </div>
  );
};

export default memo(StepperCards);
