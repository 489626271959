type reducerState = {
  showPercentages: boolean;
  showBonuses: boolean;
  activePos: number;
  bonusValue:string
};

type reducerAction = {
  type: string;
  payload?: any;
};

export const initialState: reducerState = {
  showPercentages: false,
  showBonuses: false,
  activePos: 0,
  bonusValue:'0'
};

export const reducer = (state: reducerState, action: reducerAction) => {
  switch (action.type) {
    case 'SHOW_PERCENTAGES':
      return {
        showPercentages: !state.showPercentages,
        showBonuses: false,
        activePos: 0,
        bonusValue: state.bonusValue
      };

    case 'SHOW_RECEIVE':
      return {
        showBonuses: !state.showBonuses,
        showPercentages: false,
        activePos: 0,
        bonusValue: state.bonusValue

      };

    case 'SET_BONUS':
      const { payload } = action;
      let activePos;
      let bonusValue=payload;
      switch (payload) {
        case 'cash':
          activePos = 1;
          break;

        case 'mix':
          activePos = 2;
          break;

        case 'shares':
          activePos = 3;
          break;

        default:
          activePos = 0;
          break;
      }

      return { ...state, bonusValue, activePos };

    default:
      return initialState;
  }
};
