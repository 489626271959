import { Link } from 'react-router-dom';

export const SolveLinks = (props: any) => {
  const extern = /^(ftp|http|https):\/\/[^ "]+$/.test(props.url);
  const card = (
    <>
      <img src={props.img} className='img-fluid h-100 w-100' alt='wellness-section' />
      <div className='card-content'>
        <h5 className='card-title sect-card'>{props.title}</h5>
        <p className='card-text sect-card'>{props.description}</p>
      </div>
    </>
  );

  if (extern) {
    return (
      <div className='h-100 w-100'>
        {props.isDisabled ? (
          card
        ) : (
          <a target='_blank' href={props.url || ''} rel='noreferrer' className='h-100'>
            {card}
          </a>
        )}
      </div>
    );
  } else {
    return (
      <div className='h-100 w-100'>
        {props.isDisabled ? (
          card
        ) : (
          <Link to={props.url ||''} className='h-100'>{card}</Link>
        )}
      </div>
    );
  }
};

export const ButtonLink = (props: any) => {
  const extern = /^(ftp|http|https):\/\/[^ "]+$/.test(props.url);
  if (extern) {
    return (
      <div>
        <button className='btn'>
          <a target='_blank' href={props.url || ''}>
            {props.name}
          </a>
        </button>
      </div>
    );
  } else {
    return (
      <div>
        <button className='btn btn-secondary subscription'>
          <Link to={props.url}>{props.name}</Link>
        </button>
      </div>
    );
  }
};
