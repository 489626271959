import React from 'react';

import { ReactComponent as Plane } from '../../../../../assets/LPC/GetReady/Get ready.svg';
import { GetBodyInnerHTML } from '../../../../Layouts/default-section/getDefaultElements';
import { useNavigate } from 'react-router';

import './GetReady.scss';

interface GetReadyProps {
  title?: string;
  desc?: string;
  src?: string;
  label?: string;
  solutions?: {
    title: string;
    desc: string;
  }[];
}

const GetReady = ({
  title = 'Get Ready',
  desc,
  label = 'Download the infographic',
  src,
  solutions,
}: GetReadyProps) => {
  const navigate = useNavigate();

  const downloadDocument = () => {
    if (localStorage.getItem('mobile') === '1')
      navigate(`/download-pdf-link?${src}`);
    else window.open(src);
  };

  return (
    <div className='container-fluid'>
      <div className='get-ready-info'>
        <div className='summary'>
          <div className='desktop-icon'>
            <Plane />
          </div>
          <div className='summary-body'>
            <div className='title'>
              <h1>{title}</h1>
            </div>
            <GetBodyInnerHTML content={desc} />
            <button onClick={downloadDocument} disabled={!src}>
              {label}
            </button>
          </div>
        </div>
        <h2>Emergency Contacts</h2>
        <div className='solutions-list'>
          {solutions?.map(({ desc, title }) => (
            <div key={`solution-${title}`} className='solution'>
              <h3>{title}</h3>
              <GetBodyInnerHTML content={desc} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GetReady;
