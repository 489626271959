import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './carrouselcircle.scss';
import { Link, useLocation } from 'react-router-dom';
import { apiMainURL } from '../../../modules/settings/environment';
import { v4 } from 'uuid';
export const CarouselCircle = (props: any) => {
  const { items } = props;

  const location = useLocation();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      additionalTransfrom={0}
      arrows
      autoPlaySpeed={3000}
      centerMode={false}
      className=''
      containerClass='container-with-dots'
      dotListClass=''
      draggable
      focusOnSelect={false}
      infinite
      itemClass=''
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={{
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5,
        },
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024,
          },
          items: 5,
          partialVisibilityGutter: 40,
        },
        mobile: {
          breakpoint: {
            max: 576,
            min: 0,
          },
          items: 1,
          partialVisibilityGutter: 30,
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 576,
          },
          items: 2,
          partialVisibilityGutter: 30,
        },
      }}
      rewind={false}
      rewindWithAnimation={false}
      rtl={false}
      shouldResetAutoplay
      showDots={false}
      sliderClass=''
      slidesToSlide={1}
      swipeable>
      {items?.map((item: any, indexItem: number) => {
        const uuid = v4();

        return (
          <div className='card' key={uuid}>
            <Link to={'details?' + item?.id}>
              <div className='card-icon'>
                <img
                  className='img-fluid'
                  src={apiMainURL + item?.icon}
                  alt='Card image cap'
                  width='160'
                />
              </div>
              <div className='card-body'>
                <div className='card-title fw-bolder'>{item?.name || ''}</div>
              </div>
            </Link>
          </div>
        );
      })}
    </Carousel>
  );
};
