import React from 'react';

import './Backdrop.css';

type BackdropProps = {
  onClick?: () => void;
};

const Backdrop = ({ onClick }: BackdropProps) => {
  return <div {...{ onClick }} className='backdrop w-100 vh-100' />;
};

export default Backdrop;
