import React from 'react'
import { useNavigate } from 'react-router';
import { useGetHomeDefaultContentQuery } from '../../../../../services/api/api-socios-maz';
import { GetBodyInnerHTML, getDefaultContent } from '../../../../Layouts/default-section/getDefaultElements';
import BreadCrumb from '../../../../UI/BreadCrumb';

export const WhatYouCanFind = () => {
    const dataapi = useGetHomeDefaultContentQuery('profile/what-you-can-find').data;
    const elems: any = getDefaultContent(dataapi);
    
    return (
        <div className='main-content'>
            <WhatYouCanFindRender data={elems} />
        </div>
    )
}


export const WhatYouCanFindRender = (props: any) => {
    const crumbs = [
        { path: '/home', label: 'MAZ Partners' },
        { label: 'What you can find' },
    ];
    const { data } = props;
    const navigate = useNavigate();
    const onGoToProfileButtonClick = () => {
        navigate('/partners/partner-profile');
    };
    return (
        <div className="container-fluid">
        <BreadCrumb {...{ crumbs }} />

            <div className='row my-5'>
                <div className="col-12">
                    <h1 className='text-center mb-5'> {data?.title}</h1>
                </div>
                <div className="col-12 col-md-6">
                    <div className='text-content'>
                        <div className="text-center text-md-start">
                            <span className="text-gold font-normal fs-2">{data?.content[0]?.content[0]?.title}</span>
                        </div>
                        <div className='my-3'>
                            <GetBodyInnerHTML
                                content={data?.content[0]?.content[0]?.description}></GetBodyInnerHTML>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="img-hc ms-auto">
                        <img
                            src={data?.content[0]?.content[0]?.src || ''}
                            className='img-fluid'
                        />
                    </div>

                </div>
            </div>
            <div className='row my-5'>
                <div className="col-12 col-md-6 order-1 order-md-0">
                    <div className="img-hc text-start">
                        <img
                            src={data?.content[1]?.content[0]?.src || ''}
                            className='img-fluid'
                        />
                    </div>
                </div>
                <div className="col-12 col-md-6 order-0 order-md-1">
                    <div className='text-content'>
                        <div className="text-center text-md-start">
                            <span className="text-gold font-normal fs-2">{data?.content[1]?.content[0]?.title}</span>
                        </div>
                        <div className='my-3'>
                            <GetBodyInnerHTML
                                content={data?.content[1]?.content[0]?.description}></GetBodyInnerHTML>
                        </div>
                    </div>
                </div>

            </div>
           {/* <div className="section-gray "> */}
           <div className=" ">
           <div className='col-md-12 my-4 '>
                <div className="text-center text-md-start">
                    <span className="text-gold font-normal fs-2">{data?.content[2]?.content?.value || ''}</span>
                </div>
            </div>
            <div className="row">
                {data?.content[3]?.type == 'cardsGroup' &&
                    data?.content[3]?.content?.map((card: any, indexCard:number) => {
                        return (
                            <div className='col-sm-4' key={indexCard}>
                                <div className='row align-items-center'>
                                    <div className='col-4 col-md-12 text-content py-4'>
                                        <div className='icon-boxtext-partner text-center mb-3'>
                                            <img
                                                src={card?.src}
                                                width='150'
                                                className='img-fluid text-center'
                                            />
                                        </div>
                                    </div>
                                    <div className="col-8 col-md-12">
                                        <GetBodyInnerHTML
                                            content={card?.description}></GetBodyInnerHTML>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
           </div>
            <div className='text-end mb-5'>
                <button
                    className='btn login-without btn-secondary mt-5'
                    onClick={onGoToProfileButtonClick}>
                    {'Learn more about profiles >'}
                </button>
            </div>

        </div>
    )
}
