import React, { useEffect, useState } from 'react';
import { store } from '../../../../modules/appState/store';
import {
  getGenericPageWithBu,
  useGetElementsSquadsQuery,
} from '../../../../services/api/api-socios-maz';
import {
  getDefaultContent,
  GetBodyInnerHTML,
} from '../../../Layouts/default-section/getDefaultElements';
import BreadCrumb from '../../../UI/BreadCrumb';
import CarouselView from '../../../UI/Carousel/carousel';
import { CarouselCircle } from '../../../UI/CarouselCircle';
import LoadSpinner from '../../../UI/LoadSpinner/LoadSpinner';
import './Communities.scss';

interface buttonLink {
  name: string;
  link: string;
}

interface card {
  icon: string;
  name: string;
  description: string;
  link: buttonLink;
  id: string;
}

export const CommunitiesIndex = () => {
  const [dataToShow, setDataToShow] = useState<{
    title?: string;
    content: { type?: string; content?: any }[];
  }>({ content: [{ content: [] }] });
  const [bu, setBu] = useState('');

  const elems = getCards(useGetElementsSquadsQuery('')?.data);

  function getCards(data: any): card[] {
    let items: card[] = [];
    data?.data?.filter((el: any) => {
      let iconId = el?.relationships?.field_icono?.data?.id || '';
      data?.included.filter((inc: any) => {
        if (inc?.id == iconId) {
          items.push({
            icon: inc?.attributes?.uri?.url || '',
            name: el?.attributes?.name || '',
            description: el?.attributes?.description?.value || '',
            link: {
              name: el?.attributes?.field_link_externo?.title || '',
              link: el?.attributes?.field_link_externo?.uri || '',
            },
            id: el?.attributes?.drupal_internal__revision_id || '',
          });
        }
      });
    });
    return items;
  }
  const crumbs = [
    { path: '/home', label: 'MAZ Partners' },
    { path: '/culture-squads', label: 'Culture Squads' },
    { label: 'Partners Clubs' },
  ];

  useEffect(() => {
    const getData = async () => {
      const bu = store.getState().AppReducer.userData.bu;

      if (bu)
        setDataToShow(
          getDefaultContent(
            await getGenericPageWithBu(
              '/squads/communities',
              bu,
              'field_arguments,field_arguments.field_contenido,field_arguments.field_contenido.field_list,field_arguments.field_contenido.field_list.field_image',
            ),
          ),
        );
    };

    if (!store.getState().AppReducer.userData.bu) {
      const unSub = store.subscribe(() => getData());

      return unSub;
    }

    getData();
  }, []);
  useEffect(() => {}, [bu]);

  if (dataToShow && dataToShow.title)
    return (
      <div className='main-content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <BreadCrumb {...{ crumbs }} />
            </div>
          </div>
        </div>
        {dataToShow?.content[0]?.type === 'banner' && (
          <div className=''>
            <div className='carousel-communities'>
              <CarouselView content={dataToShow?.content[0]?.content} />
            </div>
          </div>
        )}
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              {dataToShow?.content[1]?.type === 'title' && (
                <div>
                  <h3 className='section-title'>
                    <strong>
                      {dataToShow?.content[1]?.content?.value || ''}
                    </strong>
                  </h3>
                </div>
              )}
              {dataToShow?.content[2]?.type === 'text' && (
                <div className='text-center'>
                  <div className='inner-html-text-center'>
                    <GetBodyInnerHTML
                      content={dataToShow?.content[2]?.content?.value || ''}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className='col-12'>
              <div className='carousel-circle position-relative'>
                {elems && <CarouselCircle items={elems} />}
              </div>
            </div>
          </div>
        </div>
        {dataToShow?.content[4]?.type == 'horizontal_card_textos' && (
          <div className='section-gray-v2 rounded-0'>
            <div className='container-fluid'>
              <div className='row communities-description'>
                <div className='col-md-6'>
                  <GetBodyInnerHTML
                    content={dataToShow?.content[4]?.content?.primaryText || ''}
                  />
                </div>
                <div className='col-md-6 tw'>
                  <GetBodyInnerHTML
                    content={
                      dataToShow?.content[4]?.content?.secondaryText || ''
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  else return <LoadSpinner />;
};
