import React from 'react';

type PartnersMetricsTableProps = {
  metrics?: [{ band: string; avg: number }];
};

const PartnersMetricsTable = ({
  metrics = [{ band: '', avg: 0 }],
}: PartnersMetricsTableProps) => {
  const rows = metrics.map(({ band = '', avg = 0 }) => ({
    band,
    rate: avg,
  }));

  return (
    <>
      <p className='tw mb-2 text-center'>TO by Band</p>
      <div className='my-auto'>
        <table>
          <tr>
            <th>Band</th>
            <th>TO Rate</th>
          </tr>
          {rows.map(({ band = '', rate = '' }) => (
            <tr>
              <td>{band}</td>
              <td>{rate}%</td>
            </tr>
          ))}
        </table>
      </div>
    </>
  );
};

export default PartnersMetricsTable;
