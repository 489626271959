import React from 'react';
import { Link } from 'react-router-dom';

export type Crumb = {
  path?: string;
  onClick?: () => void;
  label: string | number;
};
type BreadCrumbProps = {
  crumbs: Crumb[];
};

const BreadCrumb = ({ crumbs = [] }: BreadCrumbProps) => {
  return (
    <nav aria-label='breadcrumb'>
      <ol className='breadcrumb'>
        {crumbs.map(({ path, label, onClick }) => (
          <li
            key={`breadcrumb-${path}-${label}`}
            className={`breadcrumb-item ${!path && 'active'}`}>
            {path ? (
              <Link to={path} onClick={onClick}>
                {label}
              </Link>
            ) : (
              label
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default BreadCrumb;
