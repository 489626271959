import React from 'react';

import { ReactComponent as Welcome } from '../../../../assets/Binnacle/Welcome.svg';

import './BinnacleHeader.scss';

const BinnacleHeader = () => {
  return (
    <div className='row binnacle-header-desc'>
      <div className='col-md-5 binnacle-header-img'>
        <Welcome />
      </div>
      <div className='col-md-6'>
        <h1>Focus your efforts and strengthen your skills</h1>
        <p>
          Customize your learning and deepening paths to be a true
          macro-entrepreneurial leader
        </p>
      </div>
    </div>
  );
};

export default BinnacleHeader;
