import React from 'react';

import DescriptionItems from './DescriptionItems';
import FavoriteList from './FavoriteList';

import './Base.scss';
import LPC from './LPC';

type BaseProps = {
  user: any;
  hasEdit?: boolean;
  onEdit?: () => void;
};

const Base = ({ user, hasEdit = true, onEdit }: BaseProps) => {
  return (
    <>
      <div className='row row-cols-1'>
        <div className='col col-lg-5'>
          <div className='mx-auto overflow-hidden partner-img'>
            <img src={user.img} alt={`user-img-${user.img}`} />
          </div>
        </div>
        <div className='col col-lg-7'>
          <h1 className='user-name-title'>
            {user.name} - {user.nick}
            {hasEdit && (
              <span className='ms-3 fs-2'>
                <i className='bi bi-pencil' onClick={onEdit} />
              </span>
            )}
          </h1>
          <p className='user-basic-info pos'>{user.position}</p>
          <p className='user-basic-info general mb-4'>
            {user.bu} / {user.area}
          </p>
          <h6 className='your-data'>Your Data</h6>
          <div className='row row-cols-2 mb-3'>
            <div className='col'>
              <p className='gold-text'>Mail</p>
              <p className='mail'>{user.email}</p>
            </div>
            <div className='col'>
              <p className='gold-text'>ID Number</p>
              <p className='mail'>{user.idNumber}</p>
            </div>
          </div>
          <LPC />
          {/* <div className='mb-3'>
            <FavoriteList
              sectionName='My favorite beer is'
              favorites={user.beers}
              emptyMessage='No favorite beers.'
            />
          </div>
          <FavoriteList
            sectionName='My Hobbies'
            favorites={user.hobbies}
            emptyMessage='No favorite hobbies.'
            isSvg={true}
          /> */}
        </div>
      </div>
      {/* <div className='description-items'>
        <div>
          <DescriptionItems items={user.items} />
        </div>
      </div>
      <div className='tips'>
        {user.video && (
          <>
            <h2 className='text-center mb-4'>In Five Years...</h2>
            <div className='overflow-hidden partners-tips-video'>
              <video src={user.video} loop muted autoPlay controls></video>
            </div>
          </>
        )}
      </div> */}
    </>
  );
};

export default Base;
