import React, { useRef } from 'react';
import { PartnerProfileRender } from '../../pages/profile/Partners-profile/Partner-profile';
import { useReactToPrint } from 'react-to-print';
export const PrintComponent = (props) => {
  console.log("PROPS EN PRINT COMPONENT:  ",props);
  const {
    idUser,
    data,
    archetypes,
    crumbs,
    mainArq,
    secArch,
    records,
    onClick,
  } = props;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle:
      'Profile: ' + data?.dataArchetypes?.username || 'MAZ PARTNERS',
  });
  var styleOverrides = {
    pagebre: ' auto',
  };

    return (
      <div className=''>
        <PartnerProfileRender
          {...{ records, onClick }}
          idUser={idUser}
          data={data}
          archetypes={archetypes}
          crumbs={crumbs}
          mainArq={mainArq}
          secArch={secArch}
        />
      </div>
    );

};
