import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LeaderShip } from '.';
import { store as storeApp } from '../../../../../../../modules/appState/store';
import {
  postGeneralPartner,
  postLeaderShip,
  postPersonality,
} from '../../../../../../../services/api/api-socios-maz';
import { LeaderShipPrint } from './LeaderShipPrint';
import { LeaderShipPrintTeam } from './LeaderShipPrintTeam';

export const LeaderShipData = (props: any) => {
  const { restricted, printFlag, team, hasFilters, date } = props;
  const location = useLocation();

  const [user, setUser] = useState(storeApp.getState().AppReducer?.userData);

  const [data, setData] = useState({});
  const [title, setTitle] = useState('');
  const [data2, setData2] = useState({});
  const [personality, setPersonality] = useState({});
  storeApp.subscribe(() => {
    setUser(storeApp?.getState()?.AppReducer?.userData || '');
  });

  useEffect(() => {
    if (user?.drupal_id && user?.drupal_id != '') {
      getDataProfile();
      getDataPersonality();
    }
  }, [user, title, date]);

  const getDataProfile = async () => {
    // //TODO: Id
    // console.log("DRUPAL ID2 LEADERSHIP :  ", user?.drupal_id);
    let idUser2 = '';
    if (
      (location?.pathname == '/partners/partner-profile/detail' ||
        location?.pathname == '/partners/team-profiles/detail') &&
      location?.search?.split('?')[1]
    ) {
      idUser2 = location?.search?.split('?')[1];
    } else {
      idUser2 = user?.drupal_id || '';
    }
    const response = await postLeaderShip(idUser2 || '', { date })
      ?.then((response: any) => response.text())
      .then((result) => {
        setData(JSON.parse(result));
      });
    const response2 = await postGeneralPartner(idUser2 || '', { date })
      ?.then((response: any) => response.text())
      .then((result2) => {
        setData2(JSON.parse(result2));
      });
  };
  const getDataPersonality = async () => {
    //TODO: Id
    let idUser2 = '';
    if (
      (location?.pathname == '/partners/partner-profile/detail' ||
        location?.pathname == '/partners/team-profiles/detail') &&
      location?.search?.split('?')[1]
    ) {
      idUser2 = location?.search?.split('?')[1];
    } else {
      idUser2 = user?.drupal_id || '';
    }
    if (
      location?.pathname == '/partners/partner-profile/detail' ||
      location?.pathname == '/partners/partner-profile/my-partner-profile' ||
      location?.pathname == '/profile/partner-profile'
    ) {
      setTitle('Leadership Style');
    }

    postPersonality(idUser2 || '', { date })?.then(async (response: any) => {
      if (response) response.json().then((res: any) => setPersonality(res));
    });
  };

  if (user?.drupal_id != '' && data && data2 && personality)
    return (
      <div className=''>
        {!printFlag && (
          <LeaderShip
            idUser={user?.drupal_id}
            data={data}
            dataExt={data2}
            personality={personality}
            restricted={restricted}
            title={title}
          />
        )}
        {printFlag && !team && (
          <LeaderShipPrint
            idUser={user?.drupal_id}
            data={data}
            dataExt={data2}
            personality={personality}
            restricted={restricted}
            title={title}
          />
        )}
        {printFlag && team && (
          <LeaderShipPrintTeam
            idUser={user?.drupal_id}
            data={data}
            dataExt={data2}
            personality={personality}
            restricted={restricted}
            title={title}
          />
        )}
      </div>
    );
  else return <></>;
};
