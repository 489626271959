import counterReducer from '../counter/couterSlice';
import { combineReducers, configureStore, createStore } from '@reduxjs/toolkit';
import type { PreloadedState } from '@reduxjs/toolkit';
import { mazPartnersApi } from '../../../services/api/api-socios-maz';
import { number } from 'yup';

export interface dataBonus {
  bonus: number;
  currency: string;
}
export interface infoUser {
  name: string;
  expat: boolean;
  external: boolean;
  gender: string;
  email: string;
  bu: string;
  birth_date: string;
  company_name: string;
  sharp: string;
  original_hire_date: string;
  position_band: string;
  position_name: string;
  user_image: string;
  drupal_id: string;
}
const initialValue = {
  image: '',
  dark: true,
  lang: 'en',
  bonus: {
    bonus: 0,
    currency: '-',
    tas: '0',
  },
  userData: {
    name: '',
    expat: false,
    external: false,
    gender: 'M',
    email: '',
    bu: '',
    birth_date: '',
    company_name: '',
    global_id: '',
    original_hire_date: '',
    position_band: '',
    position_name: '',
    user_image: '',
    drupal_id: '',
    rol: 'SD',
  },
};
//CAMBIO DE STATE STATUS ACTUALIZAR ESTADO STATE STORE
const mainReducer = (state = initialValue, action: any) => {
  if (!action) return state;
  else {
    let pl = action?.payload;
    switch (action?.type) {
      case 'LANG':
        return {
          image: state.image,
          dark: state.dark,
          lang: state?.lang == 'es' ? 'en' : 'es',
          bonus: state?.bonus,
          userData: state?.userData,
        };
        break;
      case 'IMAGE':
        return {
          image: pl,
          dark: state.dark,
          lang: state.lang,
          bonus: state?.bonus,
          userData: state?.userData,
        };
        break;
      case 'BONUS':
        return {
          image: state?.image,
          dark: state.dark,
          lang: state.lang,
          bonus: {
            bonus: pl?.bonus,
            currency: pl?.currency,
            tas: pl?.tas,
          },
          userData: state?.userData,
        };
        break;
      case 'DARK':
        if (!state?.dark) {
          document.documentElement.style.setProperty('--dark-color', '#FFFFFF');
          document.documentElement.style.setProperty(
            '--dark-color-yellow',
            '#000000',
          );
          document.documentElement.style.setProperty(
            '--gold-black-color',
            '#383838',
          );
          document.documentElement.style.setProperty('--filter-gold', 'none');
          document.documentElement.style.setProperty(
            '--filter-gold-color',
            'invert(85%) sepia(41%) saturate(2180%) hue-rotate(351deg) brightness(92%) contrast(94%)',
          );
          document.documentElement.style.setProperty('--gold-color', '#E5B611');
          document.documentElement.style.setProperty(
            '--border-color',
            '#000000',
          );
          document.documentElement.style.setProperty(
            '--gold-color-v2',
            'transparent',
          );
          document.documentElement.style.setProperty(
            '--gray-color-v2-inverse',
            '#383838',
          );
          document.documentElement.style.setProperty(
            '--gold-white-color',
            '#E5B611',
          );
          document.documentElement.style.setProperty(
            '--gold-gray-color',
            '#6A6A6A',
          );
          document.documentElement.style.setProperty('--text-color', '#000000');
          document.documentElement.style.setProperty(
            '--gold-gray-color',
            '#bebebe',
          );
          document.documentElement.style.setProperty(
            '--gray-light-color',
            '#6A6A6A',
          );
          document.documentElement.style.setProperty(
            '--gray-light-dark-color',
            '#ffffff',
          );
          document.documentElement.style.setProperty(
            '--dark-gray-color',
            '#6A6A6A',
          );
          document.documentElement.style.setProperty('--gray-color', '#383838');
          document.documentElement.style.setProperty(
            '--gray-color-v2',
            '#383838',
          );
          document.documentElement.style.setProperty(
            '--gray-color-v2-inverse',
            '#6A6A6A',
          );
          document.documentElement.style.setProperty(
            '--fill-black-color',
            ' invert(0)',
          );
        } else {
          document.documentElement.style.setProperty('--dark-color', '#000000');
          document.documentElement.style.setProperty(
            '--dark-color-yellow',
            '#ecd70d',
          );
          document.documentElement.style.setProperty(
            '--gold-black-color',
            '#f5e003',
          );
          document.documentElement.style.setProperty(
            '--border-color',
            '#ecd70d',
          );

          document.documentElement.style.setProperty(
            '--filter-gold',
            'invert(100%) sepia(81%) saturate(6913%) hue-rotate(343deg) brightness(92%) contrast(101%)',
          );
          document.documentElement.style.setProperty(
            '--filter-gold-color',
            'invert(64%) sepia(86%) saturate(450%) hue-rotate(12deg) brightness(110%) contrast(105%)',
          );
          document.documentElement.style.setProperty('--gold-color', '#f5e003');
          document.documentElement.style.setProperty(
            '--gold-color-v2',
            '#f5e003',
          );
          document.documentElement.style.setProperty(
            '--gold-white-color',
            '#f5e003',
          );
          document.documentElement.style.setProperty(
            '--gold-gray-color',
            '#f5e003',
          );
          document.documentElement.style.setProperty('--text-color', '#FFFFFF');
          document.documentElement.style.setProperty(
            '--gold-gray-color',
            '#f5e003',
          );
          document.documentElement.style.setProperty(
            '--gray-light-color',
            '#000000',
          );
          document.documentElement.style.setProperty(
            '--gray-light-dark-color',
            '#383838',
          );
          document.documentElement.style.setProperty(
            '--dark-gray-color',
            '#383838',
          );
          document.documentElement.style.setProperty('--gray-color', '#000000');
          document.documentElement.style.setProperty(
            '--gray-color-v2',
            '#6A6A6A',
          );
          document.documentElement.style.setProperty(
            '--fill-black-color',
            ' invert(1)',
          );
        }

        return {
          image: state.image,
          dark: !state.dark,
          lang: state.lang,
          bonus: state?.bonus,
          userData: state?.userData,
        };
        break;
      case 'USER':
        return {
          image: state?.image,
          dark: state.dark,
          lang: state.lang,
          bonus: state?.bonus,
          userData: pl,
        };
        break;
      default:
        return state;
        break;
    }
  }
};

const rootReducer = combineReducers({
  [mazPartnersApi.reducerPath]: mazPartnersApi.reducer,
  AppReducer: mainReducer,
});
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
    getDefaultMiddleware().concat(mazPartnersApi.middleware),
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
      getDefaultMiddleware().concat(mazPartnersApi.middleware),
    preloadedState,
  });
};
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
