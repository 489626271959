import React, { useEffect } from 'react'

export const DownloadLink = () => {
    useEffect(() => {
        setTimeout(() => {
            console.log("Download Link")
            window.history.back();
        }, 1000);
    }, []);
  return (
    <div>DownloadLink</div>
  )
}
