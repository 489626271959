import React, { useState } from 'react';
import HeartLike from '../../../../UI/HeartLike';

const Card = () => {
  const [hasLike, setHasLike] = useState(false);

  const onLikeClick = () => {
    setHasLike((prevLike) => !prevLike);
  };

  return (
    <div className='col'>
      <div className='card-container'>
        <div className='card-img-container'>
          <img src='' alt='card img' />
        </div>
        <div className='card-desc-container'>
          <div className='d-flex justify-content-between card-desc-header'>
            <h6 className='header-category'>Category</h6>
            <HeartLike isLiked={hasLike} onClick={onLikeClick} />
          </div>
          <div>
            <h4>Resource name</h4>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt nonumy et…
            </p>
          </div>
          <div className='d-flex card-footer'>
            <div className='item'>
              <span></span>
              <h4>Article</h4>
            </div>
            <div className='separator' />
            <div className='item'>
              <span></span>
              <h4>Time</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
