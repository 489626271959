import React from 'react';

import BasicBarChart from '../../../../../../UI/chartJs/BasicBarChart';

import { assignSecondColor } from '.';

type MetricsLCMProps = {
  metrics?: {
    [key: string]: { capability: string; total: number; avg: number }[];
  };
};

const MetricsLCM = ({
  metrics = { II: [{ capability: '', total: 0, avg: 0 }] },
}: MetricsLCMProps) => {
  const labels = [];
  const data = [];

  const assignLCMData = (lcm: string, color: string, data: number[]) => ({
    label: lcm,
    backgroundColor: color,
    hoverBackgroundColor: color,
    hoverBorderColor: color,
    data,
  });

  for (const [idb, [band, values]] of Object.entries(metrics).entries()) {
    labels.push(band);

    for (const [idx, value] of (values as any[]).entries()) {
      const pos = data.findIndex(({ label }) => label === value.capability);

      if (pos >= 0) data[pos].data.push(value.avg);
      else
        data.push(
          assignLCMData(value.capability, assignSecondColor(idx + 1), [
            ...Array(idb).fill(null),
            value.avg,
          ]),
        );
    }
  }

  return (
    <>
      <p className='tw mb-3 text-center'>LCM Score Per Capability</p>
      <div className='mx-1 mx-sm-4'>
        <BasicBarChart
          {...{ labels }}
          datasets={data}
          stepSize={0.15}
          min={3}
        />
      </div>
    </>
  );
};

export default MetricsLCM;
