import React, { useState } from 'react'
import { useNavigate } from 'react-router';
import { store } from '../../../../modules/appState/store';
import { postGeneralPartner } from '../../../../services/api/api-socios-maz';
import InformationModal from '../../../UI/InformationModal';
import {ReactComponent as NoInformation} from '../../../../assets/general/NoInformation.svg'

export const NoData = (props:any) => {
    const {title, description, buttonText}=props;
  let showModal=true;
  const navigate = useNavigate();

  
  const onConfirmModalClick = () => {
   
        navigate('/home');

  };
  const cardFooter = (
    <div className='d-flex card-button'>
      <button className='mx-auto' onClick={onConfirmModalClick}>
        Ready, got it
      </button>
    </div>
  );
    return (
        <div className='main-content'>
            <div className="container-fluid">
                {/* <h1>{title}</h1>
                <p>{description}</p>
                <button >{buttonText}</button> */}
                  {showModal && (
          <InformationModal
            {...{ cardFooter }}
            onConfirm={onConfirmModalClick}
            title={title||'Sorry, it is not possible to enter the Partner Profile section.'}
            message={description||'If you want to know your indicators and your archetype, we invite you to participate in our survey, which will be carried out in December.'}
            imgSrc={<NoInformation className='no-info-card-svg' />}
          />
        )}
            </div>
        </div>
    )
}
