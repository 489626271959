type SelectorValues = {
  label: string;
  value: string | number;
  isSelected?: boolean;
};

type GalleryFiltersState = {
  all: boolean;
  like: boolean;
  categories: SelectorValues[];
  bus: SelectorValues[];
};

export enum GalleryFiltersCases {
  SET_ALL_VALUE = 'SET_ALL_VALUE',
  SET_DROPDOWN_VALUES = 'SET_DROPDOWN_VALUES',
  CHANGE_SELECTORS = 'CHANGE_SELECTORS',
  SWITCH_LIKE = 'SWITCH_LIKE',
}

type GalleryFiltersAction = {
  type: GalleryFiltersCases;
  payload?: {
    all?: boolean;
    key?: 'bus' | 'categories';
    value?: SelectorValues[] | [];
    allBus?: SelectorValues[] | [];
    allCategories?: SelectorValues[] | [];
  };
};

export const initialGalleryFiltersState: GalleryFiltersState = {
  all: true,
  like: false,
  categories: [{ label: '0', value: 0 }],
  bus: [
    { label: '0', value: 0 },
    { label: '1', value: 1 },
  ],
};

export const GalleryFiltersReducer = (
  state: GalleryFiltersState,
  action: GalleryFiltersAction,
) => {
  const { type } = action;

  switch (type) {
    case 'SET_ALL_VALUE': {
      const { payload } = action;

      const hasAll = payload?.all;

      return {
        ...state,
        ...(hasAll && {
          bus: state.bus.map((v) => ({ ...v, isSelected: false })),
          categories: state.categories.map((v) => ({
            ...v,
            isSelected: false,
          })),
        }),
        all: hasAll || false,
      };
    }

    case 'SET_DROPDOWN_VALUES': {
      const { payload } = action;

      return {
        ...state,
        bus: payload?.allBus || [],
        categories: payload?.allCategories || [],
      };
    }

    case 'SWITCH_LIKE': {
      const { like } = state;

      return { ...state, like: !like };
    }

    case 'CHANGE_SELECTORS': {
      const { payload } = action;
      const key = payload?.key || 'bus';
      const newSelectors = state[key].map((values) => ({
        ...values,
        isSelected:
          payload?.value &&
          payload.value.findIndex(({ value }) => value === values.value) !== -1,
      }));
      const all =
        state[key === 'bus' ? 'categories' : 'bus'].concat(newSelectors);
      const allLength = all.length;
      const allSelected = all.filter(({ isSelected }) => isSelected).length;

      return {
        ...state,
        [key]: newSelectors,
        all: allLength === allSelected || allSelected === 0,
      };
    }

    default:
      return { ...state };
  }
};
