import React, { useEffect, useRef, useState } from 'react';
import BreadCrumb from '../../../../../UI/BreadCrumb';
import { Accordion } from '../../../../../UI/Accordion/Accordion';
import HorizontalBar from '../../../../../UI/chartJs/horizontalBarChart';
import { store as storeApp } from '../../../../../../modules/appState/store';
import {
  postGraphics,
  useGetallArchetypesQuery,
  useGetPartnersMetricsQuery,
} from '../../../../../../services/api/api-socios-maz';
import LoadSpinner from '../../../../../UI/LoadSpinner/LoadSpinner';
import { getArchetypes } from '../../../Partners-profile/Partner-profile';
import PartnersMetrics from './PartnersMetrics';
import ArchetypeCarousel from '../../../Partners-profile/Archetypes-details/ArchetypeCarousel';
import { useReactToPrint } from 'react-to-print';
import { OverViewPrint } from './over-view-print';
import { FormText } from 'reactstrap';
import { capitalizeFirstLetter } from '../../../../../../services/services';

const date = '2022-07-01';

export const OverviewProfile = React.forwardRef((props: any, ref: any) => {
  const [user, setUser] = useState(storeApp.getState().AppReducer?.userData);
  const [data, setData] = useState<any>({});
  const [arrayCanvas, setArrayCanvas] = useState<any[]>([]);
  const componentRef = useRef(null);
  const archetypesListData = useGetallArchetypesQuery()?.data;
  const archetypesList = getArchetypes(archetypesListData);
  const [graphDataFunc, setGraphDataFunc] = useState<any>(null);
  const [graphDataBu, setGraphDataBu] = useState<any>(null);
  const [showGraph, setShowGraph] = useState<boolean>(false);
  const metrics = useGetPartnersMetricsQuery({ date }).data;

  const crumbs = [
    { path: '/home', label: 'MAZ Partners' },
    { path: '/partners/partner-profile', label: 'Partner Profile' },
    { label: 'Overview' },
  ];
  storeApp.subscribe(() => {
    setUser(storeApp?.getState()?.AppReducer?.userData || '');
  });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Over view MAZ PARTNERS',
  });
  useEffect(() => {
    if (arrayCanvas.length == 0) saveCanvasImages();
    if (!showGraph)
      setTimeout(() => {
        setShowGraph(true);
      }, 1000);
    getDataProfile();
  }, [arrayCanvas, showGraph]);
  function saveCanvasImages() {
    setTimeout(() => {
      let dataCanvas: HTMLCollectionOf<HTMLCanvasElement> =
        document.getElementsByTagName('canvas');
      let arrayImagesCanvas: any[] = [];
      for (const image of dataCanvas) {
        const destinationCanvas = document.createElement('canvas');
        destinationCanvas.width = image.width;
        destinationCanvas.height = image.height;

        const ctx = destinationCanvas.getContext('2d');

        if (ctx) {
          //create a rectangle with the desired color
          ctx.fillStyle = '#383838';
          ctx.fillRect(0, 0, image.width, image.height);

          //draw the original canvas onto the destination canvas
          ctx.drawImage(image, 0, 0);
          arrayImagesCanvas.push(
            destinationCanvas?.toDataURL('image/jpeg', 3.0),
          );
          setArrayCanvas(arrayImagesCanvas);
        }
      }
    }, 4000);
  }
  const getDataProfile = () => {
    // console.log("DRUPAL ID2 profile 3333  ",user?.drupal_id);
    postGraphics({ date })
      ?.then((response: any) => response?.json())
      .then((res) => {
        setData(res);
        setGraphDataFunc(getDataGraph(res?.functions));
        setGraphDataBu(getDataGraph(res?.bus));
      })
      .catch((e) => console.log(e));
  };
  if (user?.drupal_id != '' && data && graphDataFunc && graphDataBu) {
    return (
      <div className='main-content main-profile-overview' id='top'>
        <div className='hidden'>
          <OverViewPrint
            ref={componentRef}
            canvas={arrayCanvas}
            metrics={metrics}
          />
        </div>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <BreadCrumb {...{ crumbs }} />
            </div>
            <div className='col-12 mt-4'>
              <div className='section-title text-bold fs-4 mb-4'>Overview</div>
              <button
                className='container-fluid btn btn-primary btn-reports btn-dr'
                onClick={handlePrint}>
                Download my Report
              </button>
              <div className='text-content text-center my-4'>
                In this section you will find general metrics of the area
                extracted from the OPR and general visualizations about the
                archetypes that make up your team. This information will allow
                you to better understand the characteristics of your team and
                evolve together.
              </div>
              <div className='col-12 mb-4'>
                <div className='p-1 section-dark-gray metrics-accordion'>
                  <div className='row'>
                    <div className='col-md-11 mx-auto'>
                      <div className='accordion-partner'>
                        <Accordion
                          title='Zone Partners Metrics'
                          subtitle='Time Period: 2022/07'
                          show={showGraph}>
                          <PartnersMetrics {...{ date }} />
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='section-dark-gray metrics-accordion p-3 mb-4'>
                <div className='row'>
                  <div className='col-md-10 mx-auto'>
                    <div className='accordion-partner'>
                      {graphDataBu && data?.archetypes?.labels && (
                        <Accordion title={' Archetypes BU'} show={showGraph}>
                          <HorizontalBar
                            dataInput={graphDataBu}
                            labels={data?.bus?.labels?.map((label: string) =>
                              capitalizeFirstLetter(label || ''),
                            )}></HorizontalBar>
                        </Accordion>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='section-dark-gray metrics-accordion p-3 mb-4'>
                <div className='row'>
                  <div className='col-md-10 mx-auto'>
                    <div className='accordion-partner'>
                      {graphDataFunc && data?.archetypes?.labels && (
                        <Accordion title={'Shape by Function'} show={showGraph}>
                          <HorizontalBar
                            dataInput={graphDataFunc}
                            labels={data?.functions?.labels?.map(
                              (e: string) => {
                                const f = e.charAt(0).toUpperCase();

                                if (e === 'DTC') return e;

                                return f + e.toLowerCase().slice(1);
                              },
                            )}></HorizontalBar>
                        </Accordion>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {archetypesList && <ArchetypeCarousel archetypes={archetypesList} />}
      </div>
    );
  } else return <LoadSpinner />;
});

function getDataGraph(dataGraphs: any) {
  let colors = [
    'rgba(229,182,59)',
    'rgba(246,246,246)',
    'rgba(224,138,45)',
    'rgba(190,190,190)',
    'rgba(106,106,106)',
    'rgba(229,182,59)',
    'rgba(80,79,79)',
    'rgba(245,224,3)',
    'rgba(255,235,196)',
    'rgba(36,36,36)',
    'rgba(241, 196, 15, 1)',
    'rgba(255, 255, 255, 1)',
    'rgba(98, 101, 103, 1)',
    'rgba(250, 229, 211, 1)',
  ];
  let data: any[] = [];
  if (dataGraphs?.datasets?.length > 0) {
    dataGraphs?.datasets?.filter((ds: any) => {
      let secondaryData: any[] = [];
      if (ds?.data) {
        ds?.data?.map((d: any) => {
          secondaryData.push(d || 0);
        });
      } else {
        ds?.datasets?.map((d: any) => {
          secondaryData.push(d || 0);
        });
      }

      let c = colors?.pop();
      data.push({
        label: capitalizeFirstLetter(ds?.label) + '   ' || '',
        data: secondaryData || 0,
        backgroundColor: c,
        fillColor: c,
      });
    });
  }
  return data;
}
