import React from 'react'
import { date } from 'yup';
import Schedule from '../../../schedule/index';

export const ListRestaurants = (props: any) => {
    const { locations } = props;
    return (
        <div className='row pb-5'>
            {locations?.map((a: any, index: number) => {
                if (index == 0) {
                    return (
                        <div className='row pb-3' key={index}>
                            <div className="row pt-5 pb-4 first-title">
                                <div className='col-4 text-center'>
                                    Restaurant
                                </div>
                                <div className='col-4 text-center'>

                                    Schedule
                                </div>
                                <div className='col-2 text-center'>Dress Code</div>
                                <div className='col-2 text-center'>Included in the Package</div>
                            </div>
                            <div className='col-12 col-md-4 text-center'>
                                <div className="titles-mb-restaurants pb-3 first-ft-title">   Restaurant</div>
                                <p className='tw'>{a?.name}</p>
                            </div>
                            <div className='col-6  col-md-4 text-center'>
                                <div className="titles-mb-restaurants pb-3 first-ft-title">   Schedule</div>
                                <p className='tw'>{new Date(a?.opening*1000).getUTCHours()>9?new Date(a?.opening*1000).getUTCHours():'0'+new Date(a?.opening*1000).getUTCHours()}:{new Date(a?.opening*1000).getUTCMinutes()>9?new Date(a?.opening*1000).getUTCMinutes():'0'+new Date(a?.opening*1000).getUTCMinutes()}  to  {new Date(a?.closing*1000).getUTCHours()>9?new Date(a?.closing*1000).getUTCHours():'0'+new Date(a?.closing*1000).getUTCHours()}:{new Date(a?.closing*1000).getUTCMinutes()>9?new Date(a?.closing*1000).getUTCMinutes():'0'+new Date(a?.closing*1000).getUTCMinutes()}</p>

                            </div>
                            <div className='col-6  col-md-2 text-center tw'>
                                <div className="titles-mb-restaurants pb-3 first-ft-title">   Dress Code</div>
                                <p> {a?.formal ? 'Formal' : 'informal'}</p>
                            </div>
                            <div className='col-12  col-md-2 text-center tw'>
                                <div className="titles-mb-restaurants pb-3 first-ft-title">   Included in the Package</div>
                                <p>  {a?.included ?<i className="bi bi-check-lg icon-check-rest"></i> : <i className="bi bi-x-lg icon-x-rest"></i>}</p></div>
                            <hr />
                        </div>
                    )
                }
                else
                    return (
                        <div className='row pb-3' key={index}>


                            <div className='col-12  col-md-4 text-center'>
                                <div className="titles-mb-restaurants pb-3">   Restaurant</div>
                                <p className='tw'>{a?.name}</p>
                            </div>
                            <div className='col-6  col-md-4 text-center'>
                                <div className="titles-mb-restaurants  pb-3">   Schedule</div>
                                <p className='tw'>{new Date(a?.opening*1000).getUTCHours()>9?new Date(a?.opening*1000).getUTCHours():'0'+new Date(a?.opening*1000).getUTCHours()}:{new Date(a?.opening*1000).getUTCMinutes()>9?new Date(a?.opening*1000).getUTCMinutes():'0'+new Date(a?.opening*1000).getUTCMinutes()}  to  {new Date(a?.closing*1000).getUTCHours()>9?new Date(a?.closing*1000).getUTCHours():'0'+new Date(a?.closing*1000).getUTCHours()}:{new Date(a?.closing*1000).getUTCMinutes()>9?new Date(a?.closing*1000).getUTCMinutes():'0'+new Date(a?.closing*1000).getUTCMinutes()}</p>

                            </div>
                            <div className='col-6  col-md-2 text-center'>
                                <div className="titles-mb-restaurants  pb-3">Dress Code</div>
                                <p className='tw'> {a?.formal ? 'Formal' : 'informal'}</p>
                            </div>
                            <div className='col-12  col-md-2 text-center tw'>
                                <div className="titles-mb-restaurants  pb-3">Included in the Package</div>
                                <p>{a?.included ? <i className="bi bi-check-lg icon-check-rest"></i> : <i className="bi bi-x-lg icon-x-rest"></i>}</p>
                            </div>
                            <hr />
                        </div>
                    );
            })}
        </div>
    );
}
