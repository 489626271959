import React from "react";

import {Bar} from 'react-chartjs-3';




export default function UserBarDashboard(props:any){
   
    const {labels, value,custom}=props;
    const options=getOptions(custom);
    const data = {
        // labels: ['January'],
        labels: labels,
        datasets: [
            {
                label: 'Value',
                backgroundColor: '#F5E003',
                borderWidth: 0,
                hoverBackgroundColor: '#F5E003',
                hoverBorderColor: '#F5E003',
                // data: [5, 20],
                data: ((value%2)==0)?[value, value]:[value, (value%2)+10],
            }
        ],
    
    };

        return (
            <div className="barchart-box">
                <Bar data={data} width={100} height={150} options={options} />
            </div>
        );
};
function getOptions(custom: any) {
    
    if(custom){
        return {
            legend: {
                display: false,
                position: "top",
            },
            scales: {
                borderColor:'rgba(255,255,255,0.8)',
                xAxes: [{
                    gridLines: {
                        display:false,
        
                    },
                    barThickness: 14,
                    ticks: {
                        
                        fontColor: '#fff',
                    },
                }
                ],
                yAxes: [{
                    gridLines: {
                        color: [
                            "rgba(255,255,255,0.8)",
                        ],
                        zeroLineColor: 'rgba(255,255,255,0.8)',
                    },
                    ticks: {
                        min: custom?.min,
                        max: custom?.max,
                        stepSize: custom?.stepSize,
                        fontColor: '#fff',
                        callback: function (value: any, index: any, ticks: any) {
                            if(custom?.percentage)
                                return value + '%';
                            else return value;
                        },
                    },
                }]
        
            },
        
        
        };
    }
    else{

        return {
            legend: {
                display: false,
                position: "top",
            },
            scales: {
                borderColor:'#fff',
                xAxes: [{
                    gridLines: {
                        color: "rgba(255,255,255,0)",
                        zeroLineColor: "rgba(255,255,255,0)",
        
                    },
                    barThickness: 14,
                    ticks: {
        
                        fontColor: '#fff',
                    },
                }
                ],
                yAxes: [{
                    gridLines: {
                        color: [
                            "rgba(255,255,255,1)",
                            "#FFF"
                        ],
                        zeroLineColor: '#FFF',
                    },
                    ticks: {
                        min: 0,
                        stepSize: 10,
                        fontColor: '#fff',
                        callback: function (value: any, index: any, ticks: any) {
                         return value;
                        },

                    },
                }]
        
            },
        
        
        };
    }

    
}

