import React from 'react';

import { Link } from 'react-router-dom';

import './Partner.scss';

type PartnerProps = {
  onClick?: () => void;
  userImage: string;
  name: string;
  lastName?: string;
  BU: string;
  id: string;
  position: string;
  mail: string;
  isFullCol?: boolean;
  phone:string
};

const Partner = ({
  onClick,
  userImage,
  name,
  lastName,
  BU,
  id,
  position,
  mail,
  isFullCol = false,
  phone
}: PartnerProps) => {
  const fullName = `${name} ${lastName}`;

  const partnerClass = `collaborator-photo border border-3 overflow-hidden card ${
    isFullCol ? 'full-col-card' : ''
  }`;
console.log("Datos:   phone: ",phone)
  return (
    <div {...{ onClick }} className={partnerClass}>
      {/* <Link to={`/meet-the-partners/${id}`} state={{ userImage, fullName, BU }}> */}
      <img
        className='position-absolute img-fluid'
        src={userImage}
        alt={fullName}
      />
      <div className='d-flex flex-column card-content'>
        <h5 className='card-title mb-2 text-truncate'>{name}</h5>
        {lastName && (
          <h5 className='card-title mb-2 text-truncate'>{lastName}</h5>
        )}
        <div className='bg-transparent mb-auto'>
          <p className='user-card-description'>{BU}</p>
          <p className='hidden-description'>{position}</p>
        </div>
        <p className='text-lowercase hidden-description'>{mail}</p>
      {phone && (
          <p className='hidden-description'>{phone}</p>
      )}
      </div>
      {/* </Link> */}
    </div>
  );
};

export default Partner;
