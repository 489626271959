export const tYCInitial = { url: '', url2:'', country: '', brewery: '' };

export const tYCReducer = (
  state: any,
  action: { type: string; checked?: boolean },
) => {
  const { type } = action;
  console.log("TYC REDUCER:  ", action);
  switch (type.toUpperCase()) {
    case 'CHECKED_COUNTRY_STATUS':
      return { ...state, checked: action.checked };

    case 'MEXICO':
      return { url: 'https://api-socios-maz-dev.somosmaz.com/sites/default/files/2022-05/POLITICA-DE-PROTECCION-DE-DATOS-PERSONALES-MEXICO.pdf', url2:'https://api-socios-maz-dev.somosmaz.com/sites/default/files/2022-05/AP-SimplificaHub-WHA-20082021.pdf',country: type, brewery: "Grupo Modelo", checked: false };
    case 'HONDURAS':
      return { url: 'https://api-socios-maz.somosmaz.com/sites/default/files/2023-04/Honduras%20-%20PPDP%20MAZ%20SOCIOS.pdf', url2:'https://api-socios-maz.somosmaz.com/sites/default/files/2023-04/Honduras%20-%20Autorizacion%20De%20Registro%20Fijacio%C3%ACn%20y%20Uso%20de%20Imagen%20MAZ%20SOCIOS.pdf', country: type, brewery: "ABI: Anheuser-Busch InBev", checked: false };
    case 'EL SALVADOR':
      return { url: 'https://api-socios-maz.somosmaz.com/sites/default/files/2022-05/POL%C3%8DTICA%20DE%20PROTECCI%C3%93N%20DE%20DATOS%20PERSONALES%20-%20El%20Salvador%20%28NEW%29.pdf', url2:'https://api-socios-maz.somosmaz.com/sites/default/files/2023-04/ElSalvador%20-%20Autorizacion%20De%20Registro%20Fijacio%C3%ACn%20y%20Uso%20de%20Imagen%20MAZ%20SOCIOS.pdf', country: type, brewery: "La Constancia", checked: false };
    case 'COLOMBIA':
      return { url: 'https://api-socios-maz.somosmaz.com/sites/default/files/2022-11/POLI%CC%81TICA%20DE%20PROTECCIO%CC%81N%20DE%20DATOS%20PERSONALES%20-%20MAZ.pdf', url2:'https://api-socios-maz.somosmaz.com/sites/default/files/2022-11/POLI%CC%81TICA%20DE%20PROTECCIO%CC%81N%20DE%20DATOS%20PERSONALES%20-%20MAZ.pdf', country: type, brewery: "Bavaria", checked: false };
    case 'ECUADOR':
      return { url: 'https://api-socios-maz.somosmaz.com/sites/default/files/2023-04/Ecuador%20-%20PPDP%20MAZ%20SOCIOS.pdf', url2:'https://api-socios-maz.somosmaz.com/sites/default/files/2022-10/Ecuador%20-%20Autorizacion%20De%20Registro%20Fijacion%20y%20Uso%20de%20Imagen%20MAZ%20SOCIOS.pdf',country: type, brewery: "Cervecería Nacional", checked: false };
    case 'PERÚ':
      return { url: 'https://www.backus.pe/abilegal/politica-deproteccion-de-datos-personales', url2:'https://www.backus.pe/abilegal/politica-deproteccion-de-datos-personales', country: type, brewery: "Backus", checked: false };
    case 'CAC - REPUBLICA DOMINICANA':
      return { url: 'https://api-socios-maz.somosmaz.com/sites/default/files/2022-10/RepDominicana-PPDP%20MAZ%20SOCIOS.pdf', url2:'https://api-socios-maz.somosmaz.com/sites/default/files/2022-10/RepDominicana%20-%20Autorizacion%20De%20Registro%20Fijacion%20y%20Uso%20de%20Imagen%20MAZSOCIOS.pdf', country: type, brewery: "ABI: Anheuser-Busch InBev", checked: false };
    case 'CAC - PANAMA':
      return { url: 'https://api-socios-maz.somosmaz.com/sites/default/files/2022-10/Panam%C3%A1%20-%20PPDP%20MAZ%20SOCIOS.pdf', url2:'https://api-socios-maz.somosmaz.com/sites/default/files/2022-10/Panama%20-%20Autorizacion%20De%20Registro%20Fijacion%20y%20Uso%20de%20Imagen%20MAZ%20SOCIOS.pdf', country: type, brewery: "ABI: Anheuser-Busch InBev", checked: false };
    case 'CAC - COSTA RICA':
      return { url: 'https://irp-cdn.multiscreensite.com/4a6b17db/files/uploaded/06_Poli%CC%81tica%20Global%20de%20Privacidad%20de%20Datos.pdf', url2:'https://api-socios-maz-dev.somosmaz.com/sites/default/files/2022-05/AP-SimplificaHub-WHA-20082021.pdf', country: type, brewery: "ABI: Anheuser-Busch InBev", checked: false };
    case 'CAC - GUATEMALA':
      return { url: 'https://api-socios-maz.somosmaz.com/sites/default/files/2022-10/Guatemala%20-%20PPDP%20MAZ%20SOCIOS.pdf', url2:'https://api-socios-maz.somosmaz.com/sites/default/files/2022-10/Guatemala%20-%20Autorizacio%C3%ACn%20De%20Registro%20Fijacio%C3%ACn%20y%20Uso%20de%20Imagen%20MAZ%20SOCIOS.pdf', country: type, brewery: "ABI: Anheuser-Busch InBev", checked: false };

    default:
      return { url: '#', country: type, checked: false };
  }
};
