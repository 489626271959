import React, { useCallback, useEffect, useReducer } from 'react';
import { ReactComponent as MazResources } from '../../../../assets/Resources/Maz resources.svg';

import GalleryFilters from './GalleryFilters';
import BreadCrumb from '../../../UI/BreadCrumb';
import LoadSpinner from '../../../UI/LoadSpinner/LoadSpinner';
import GalleryCard from './GalleryCard';

import {
  getLikesByType,
  postGalleryResources,
  useGetHomeDefaultContentQuery,
} from '../../../../services/api/api-socios-maz';
import {
  GalleryCases,
  GalleryReducer,
  initialGalleryState,
  Resource,
} from './GalleryReducer';
import { GetBodyInnerHTML } from '../../../Layouts/default-section/getDefaultElements';
import { apiMainURL } from '../../../../modules/settings/environment';

import './MazGallery.scss';

const MazGallery = () => {
  const [
    {
      description,
      subtitle,
      title,
      resources,
      filters,
      isLoading,
      hasNext,
      hasPrev,
      page,
      hasLikeFilter,
    },
    dispatch,
  ] = useReducer(GalleryReducer, initialGalleryState);

  const pageData = useGetHomeDefaultContentQuery('maz-resources').data;

  const crumbs = [
    { path: '/', label: 'MAZ Partners' },
    { label: 'MAZ Resources' },
  ];

  const onFiltersChange = useCallback((bu: string, cat: string) => {
    dispatch({
      type: GalleryCases.SET_FILTERS,
      payload: { filters: { bu, cat } },
    });
  }, []);
  const onFilterByLike = useCallback((like: boolean) => {
    dispatch({
      type: GalleryCases.IS_SEARCHING_BY_LIKE,
      payload: { hasLikeFilter: like },
    });
  }, []);
  const onPageChange = (c: 'next' | 'prev') => {
    dispatch({
      type: GalleryCases.CHANGE_PAGE,
      payload: { page: c === 'next' ? page + 1 : page - 1 },
    });
  };

  useEffect(() => {
    if (pageData)
      dispatch({
        type: GalleryCases.SET_BASIC_DATA,
        payload: {
          title: pageData?.included?.filter(
            (
              { type, attributes: { field_tipo_titulo: titulo } } = {
                type: '',
                attributes: { field_tipo_titulo: '' },
              },
            ) => type === 'paragraph--titulos' && titulo === 'Título 1',
          )[0]?.attributes?.field_title,
          description: pageData?.included?.filter(
            ({ type = '' }) => type === 'paragraph--paragraph_texto',
          )[0]?.attributes?.field_texto?.value,
          subtitle: pageData?.included?.filter(
            (
              { type, attributes: { field_tipo_titulo: titulo } } = {
                type: '',
                attributes: { field_tipo_titulo: '' },
              },
            ) => type === 'paragraph--titulos' && titulo === 'Título 2',
          )[0]?.attributes?.field_title,
        },
      });
  }, [pageData]);
  useEffect(() => {
    const getData = async () => {
      dispatch({
        type: GalleryCases.SET_LOADING,
        payload: { isLoading: true },
      });

      const likesData = await getLikesByType('resource');
      const resources = await postGalleryResources({
        page,
        bu: filters.bu,
        cat: filters.cat,
        hasLikeFilter,
        idsWithLikes:
          hasLikeFilter && likesData.map(({ entity_id = '' }) => entity_id),
      });

      dispatch({
        type: GalleryCases.SET_RESOURCES,
        payload: {
          resources: resources.data.map(
            ({
              attributes: { body, title, drupal_internal__nid } = {
                body: { value: '' },
                title: '',
                drupal_internal__nid: 0,
              },
              relationships: { field_miniatura, field_topic } = {
                field_miniatura: { data: { id: '' } },
                field_topic: { data: { id: '' } },
              },
            }: any) => {
              const min = resources.included?.filter(
                ({ id = '' }) => id === field_miniatura.data.id,
              )[0]?.attributes?.uri?.url;

              return {
                description: body.value,
                title,
                img: min ? apiMainURL + min : '',
                topic:
                  resources.included?.filter(
                    ({ id = '' }) => id === field_topic.data.id,
                  )[0]?.attributes?.name || '',
                id: drupal_internal__nid,
                likeValue:
                  likesData?.findIndex(
                    ({ entity_id = '' }) => +entity_id === drupal_internal__nid,
                  ) !== -1,
              };
            },
          ),
          hasPrev: 'prev' in resources?.links,
          hasNext: 'next' in resources?.links,
        },
      });
      dispatch({
        type: GalleryCases.SET_LOADING,
        payload: { isLoading: false },
      });
    };

    getData();
  }, [filters, page, hasLikeFilter]);

  return (
    <div className='main-content'>
      {isLoading && <LoadSpinner />}
      <div className='container-fluid'>
        <BreadCrumb {...{ crumbs }} />
      </div>
      <div className='section-gray-partners light-gray'>
        <div className='container-fluid'>
          <div className='row py-3 mx-2 gray-container'>
            <div className='col-sm-6 my-sm-4'>
              <h1 className='mb-3'>{title}</h1>
              <h4>{subtitle}</h4>
              <div className='tw maz-g-description'>
                <GetBodyInnerHTML content={description} />
              </div>
            </div>
            <div className='col-sm-6 d-flex maz-g-logo'>
              <MazResources />
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='m-4 mt-5'>
          <GalleryFilters {...{ onFiltersChange, onFilterByLike }} />
          <div className='row row-cols-1 row-cols-sm-3 row-cols-lg-4 maz-g-cards'>
            {resources.length !== 0 ? (
              resources.map((args: Resource) => (
                <div key={`card-${args.id}-${args.title}`} className='col mt-4'>
                  <GalleryCard {...args} />
                </div>
              ))
            ) : (
              <div className='w-100'>
                <p className='text-center'>No elements Found.</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='maz-g-pagination'>
        <button
          className={`${!hasPrev && 'disabled'}`}
          onClick={() => onPageChange('prev')}>
          Previous
        </button>
        <p>{page + 1}</p>
        <button
          className={`${!hasNext && 'disabled'}`}
          onClick={() => onPageChange('next')}>
          Next
        </button>
      </div>
    </div>
  );
};

export default MazGallery;
