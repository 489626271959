import React from 'react'
import { Link } from 'react-router-dom'
import { useGetAllCardsQuery } from '../../../services/api/api-socios-maz';
import { getImage } from './getDefaultElements';

interface card {
    idCard: string
}
export function CardDefault(props: card) {
    const data = useGetAllCardsQuery(props?.idCard)?.data;
    const card = getCard(props?.idCard, data);
    function getCard(id: string, card: any): any {
        let imageId = card?.data?.relationships?.field_imagen_destacada?.data?.id;
        let imageFile = getImage(imageId, card?.included)
        return {
            image: imageFile || '',
            id: id || '',
            title: card?.data?.attributes?.title || ''
        }
    }
    if (card)
        return (
            <div className="my-4 col-sm-12 col-md-6 col-lg-6 col-xl-4 ml-auto">
                <div className="card cards-pillars text-white ">
                    <Link to={`/pillars/details?${card?.id}`}>
                        <img
                            src={card?.image}
                            className="img-card"
                            alt="wellness-section"
                        />
                        <div className="card-content">
                            <h5 className="card-title">{card?.title}</h5>
                        </div>
                    </Link>
                </div>
            </div>
        )
    else
        return <></>
}
