import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { store as storeApp } from '../../../../../../../modules/appState/store';
import { postMenuItemsData } from '../../../../../../../services/api/api-socios-maz';
import { GetBodyInnerHTML } from '../../../../../../Layouts/default-section/getDefaultElements';
import NoDataFound from '../../../../../../Layouts/NoDataFound';
import AccordionItem from '../../../../../../UI/Accordion/index';
import { RangeValue } from '../../../../../../UI/Range-value';
import { TabsCategory } from '../../index';

interface element {
  name: string;
  partnerValue: string;
  teamValue: string;
  description: string;
}
interface groupElements {
  name: string;
  partnerValue: string;
  teamValue: string;
  child: element[];
  description: string;
}
export const LeaderShip = (props: any) => {
  const [role, setRole] = useState<Array<string>>([]);
  const [data2, setData2] = useState<any>(undefined);

  const { data, personality, dataExt, restricted, title } = props;
  const [elems, setElems] = useState<groupElements[]>([]);
  const [team, setTeam] = useState<boolean>(true);
  const [teamSlider, setTeamSllider] = useState<boolean>(true);
  const [indexState, setIndex] = useState<number[]>(
    [...Array(5)].map((x: number, indexX: number) => indexX),
  );
  const [flagRender, setflagRender] = useState<boolean>(true);
  function inicFilters(p: any) {
    let array: number[] = [];
    let inn = 0;
    Object?.entries(p?.data || []).map((i: any, indexArray: number) => {
      inn = inn + 1;
      array.push(inn);
    });
    setflagRender(false);
    setIndex(array);
    setflagRender(true);
  }

  const getDataProfile = async () => {
    if (data2) {
      const resData = await postMenuItemsData()
        ?.then((response: any) => response.text())
        .then((result) => {
          setData2(JSON.parse(result));
          setRole([JSON.parse(result)?.role]);
        });
    }
  };

  function handleEvent(day: any) {
    if (!indexExist(day)) {
      setflagRender(false);
      setIndex([...indexState, day]);
      setflagRender(true);
    } else {
      setflagRender(false);
      removeIndex(day);
      setflagRender(true);
    }
  }
  function removeIndex(i: number): void {
    let array: number[] = [];
    indexState.filter((elem: any) => {
      if (elem != i) {
        array.push(elem);
      }
    });
    setIndex(array);
  }

  function indexExist(i: number): boolean {
    let exist = false;
    indexState.filter((elem: any) => {
      if (elem == i) {
        exist = true;
      }
    });
    return exist;
  }
  useEffect(() => {
    getDataProfile();
    let group: groupElements[] = [];
    let childs: element[] = [];
    if (personality?.data) {
      Object?.entries(personality?.data).map((i: any, indexItem: number) => {
        childs = [];
        if (i[1]) {
          Object?.entries(i[1]).map((i2: any, indexItem: number) => {
            if (i2[0] != '' && i2[0] != 'name') {
              if (i2[1]?.name && i2[1]['Partner'])
                childs.push({
                  name: i2[1]?.name || '',
                  partnerValue: i2[1]['Partner'] || '',
                  teamValue: i2[1]['Team'] || '',
                  description: i2[1]['description'] || '',
                });
            }
          });
          group.push({
            name: i[1]['name'] || '',
            partnerValue: i[1]['Partner'] || '',
            teamValue: i[1]['Team'] || '',
            child: childs,
            description: i[1]['description'] || '',
          });
        }
      });
      setElems(group);
    }
  }, [data, personality, indexState]);

  return (
    <div className='row'>
      <div className='col-12'>
        <div className='text-content py-4 text-center'>
          Your leadership style will depend on factors such as the team’s
          physiognomy, the psychometric profile, or the company’s culture.
          Understand which style your team perceives in you and which one you
          consider you have yourself.
        </div>
        {title != '' && (
          <div className='my-md-5 my-3'>
            <h1 className='text-center'>Leadership Style</h1>
          </div>
        )}
      </div>
      <div className='col-12 mb-4'>
        <div className='desc-partner-view '>
          <div className='text-gold fs-4'>Partner View</div>
          {data?.tipo_lider && data?.tipo_lider?.name ? (
            <>
              <div className='text-gold fs-4 mt-3 text-bold'>
                {data?.tipo_lider?.name || ''}
              </div>
              <div className=''>
                <div className='text-content text-bold mb-2'>
                  {data?.tipo_lider?.subtitulo || ''}
                </div>
                <span className='p-generic'>
                  <GetBodyInnerHTML
                    content={
                      data?.tipo_lider?.description || ''
                    }></GetBodyInnerHTML>
                </span>
              </div>
              <div className='bg-gold-color section-list-partner d-md-none d-block'>
                <div className='desc-partner-view'>
                  <div className='text-black text-bold pt-2 te'>
                    <GetBodyInnerHTML
                      content={
                        data?.tipo_lider?.complemento || ''
                      }></GetBodyInnerHTML>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <NoDataFound />
          )}
        </div>
        <div className='desc-team-view'>
          <div className='text-gold fs-4'>Team View</div>
          {data?.tipo_lider_equipo && data?.tipo_lider_equipo?.name ? (
            <>
              <div className='text-gold fs-4 mt-3 text-bold'>
                {data?.tipo_lider_equipo?.name || ''}
              </div>
              <div className=''>
                <div className='text-content text-bold mb-2'>
                  {data?.tipo_lider_equipo?.subtitulo || ''}
                </div>
                <span className='p-generic'>
                  <GetBodyInnerHTML
                    content={
                      data?.tipo_lider_equipo?.description || ''
                    }></GetBodyInnerHTML>
                </span>
              </div>
              <div className='bg-gold-color section-list-partner d-md-none d-block'>
                <div className='desc-partner-view'>
                  <div className='text-black text-bold pt-2 te'>
                    <GetBodyInnerHTML
                      content={
                        data?.tipo_lider_equipo?.complemento || ''
                      }></GetBodyInnerHTML>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <NoDataFound />
          )}
        </div>
        <div className='bg-gold-color section-list-partner d-md-block d-none'>
          <div className='desc-partner-view'>
            <div className='text-black text-bold'>
              <GetBodyInnerHTML
                content={
                  data?.tipo_lider?.complemento || ''
                }></GetBodyInnerHTML>
            </div>
          </div>
          <div className='desc-team-view'>
            <div className='text-black text-bold'>
              <GetBodyInnerHTML
                content={
                  data?.tipo_lider_equipo?.complemento || ''
                }></GetBodyInnerHTML>
            </div>
          </div>
        </div>
      </div>
      {props?.data && props?.data?.list && (
        <div className='col-12 col-md-10 mx-auto'>
          <div className='accordion-partner'>
            <AccordionItem items={props?.data?.list}></AccordionItem>
          </div>
        </div>
      )}
      {data?.graphic_code ? (
        <div className='col-12 '>
          <div className='section-dark-gray border-0 py-4'>
            <div className='row align-items-center'>
              <div className='col-lg-8 col-md-6'>
                {/* TODO */}
                <div className='p-3 ps-md-5'>
                  <img
                    src={`data:image/jpg;charset=utf8;base64,${data.graphic_code}`}
                    className='img-fluid'
                    alt='SymLog'
                  />
                </div>
              </div>
              <div className='col-lg-4 col-md-6 d-md-block d-none'>
                <div className='items-plane-graph'>
                  <div>
                    <span></span>
                  </div>{' '}
                  <div>How your team perceives you</div>
                </div>
                <div className='items-plane-graph'>
                  <div>
                    <span className='items-text'></span>
                  </div>{' '}
                  <div>How do you perceive yourself?</div>
                </div>
                <div className='items-plane-graph'>
                  <div>
                    <span className='items-text2'></span>
                  </div>{' '}
                  <div>Your ideal according to the position</div>
                </div>
              </div>
            </div>
          </div>
          <div className='d-md-none d-block mx-auto mt-4'>
            <div className='items-plane-graph'>
              <div className='items-plane-color'>
                <span></span>
              </div>{' '}
              <div className='text-content'>How your team perceives you</div>
            </div>
            <div className='items-plane-graph'>
              <div className='items-plane-color'>
                <span className='items-text'></span>
              </div>{' '}
              <div className='text-content'>How do you perceive yourself?</div>
            </div>
            <div className='items-plane-graph'>
              <div className='items-plane-color'>
                <span className='items-text2'></span>
              </div>{' '}
              <div className='text-content'>
                Your ideal according to the position
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NoDataFound />
      )}
      {((restricted &&
        !role.includes('directivo') &&
        !role.includes('super_directivo')) ||
        !restricted) && (
        <div className='col-12'>
          {elems?.length > 0 && (
            <div>
              <div className='text-content content-leadership text-center'>
                Next, you will find the personality features that may impact
                your leadership style, which ones you consider to have, to what
                degree and how your team perceives them in you.
                <span className='text-bold'>
                  {' '}
                  Hide or enable the graphs by using the following buttons.
                </span>
              </div>

              <div className='section-dark-gray section-partner-owner mt-5'>
                {/*
                        Tabs
                    */}
                <div className='row'>
                  {elems && elems.length > 0 && flagRender && (
                    <div className='col-12 text-center mb-5'>
                      <div className='btns-views personality-buttons'>
                        {elems?.map((el: any, elIndex: number) => {
                          return (
                            <div
                              className='btns-views-items buttonCategory'
                              key={elIndex}>
                              {indexExist(elIndex) && (
                                <button
                                  id='btn-date-schedule'
                                  className={
                                    'btn btn-outline-orange btn-outline-orange-hover'
                                  }
                                  onClick={() => {
                                    handleEvent(elIndex);
                                  }}>
                                  {el?.name}
                                </button>
                              )}
                              {!indexExist(elIndex) && (
                                <button
                                  id='btn-date-schedule'
                                  className={'btn btn-outline-orange'}
                                  onClick={() => {
                                    handleEvent(elIndex);
                                  }}>
                                  {el?.name}
                                </button>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  <div className='col-md-6 text-md-end text-center'>
                    <div className='ind-partner mb-md-0 mb-3'>
                      <span></span>
                      Partner View
                    </div>
                  </div>
                  <div className='col-md-6 text-md-start text-center mb-3 mb-md-0'>
                    {teamSlider && (
                      <div className='ind-team'>
                        <span></span>
                        Team View
                      </div>
                    )}
                  </div>
                  {flagRender &&
                    elems?.map((el: any, index: number) => {
                      if (indexExist(index)) {
                        return (
                          <div className='col-md-6' key={index}>
                            <div className='main-progress-bar horizontal-progress-bar'>
                              <div
                                className='title-progress-bar text-gold text-lg-end text-center '
                                data-tip
                                data-for={'items-gm' + index}>
                                {el?.name || ''}
                              </div>
                              {el?.description != '' && (
                                <ReactTooltip
                                  id={'items-gm' + index}
                                  className='ttp-w'>
                                  <strong>{el?.name + ':  '}</strong>
                                  <div className='tw'>
                                    <GetBodyInnerHTML
                                      content={
                                        el?.description
                                      }></GetBodyInnerHTML>
                                  </div>
                                </ReactTooltip>
                              )}
                              <RangeValue
                                partnerValue={el?.partnerValue || '0'}
                                teamValue={el?.teamValue || '0'}
                                team={teamSlider}></RangeValue>
                              {el?.child?.map((e: any, indexE: number) => {
                                return (
                                  <div key={indexE}>
                                    <div
                                      className='title-progress-bar text-lg-end text-center'
                                      data-tip
                                      data-for={
                                        'items-ls' +
                                        (index +
                                          '' +
                                          (indexE + index) +
                                          '' +
                                          indexE)
                                      }>
                                      {e?.name || ''}
                                    </div>

                                    {e?.description != '' && (
                                      <ReactTooltip
                                        id={
                                          'items-ls' +
                                          (index +
                                            '' +
                                            (indexE + index) +
                                            '' +
                                            indexE)
                                        }
                                        className='ttp-w'>
                                        <strong>{e?.name + ':  '}</strong>

                                        <div className='tw'>
                                          <GetBodyInnerHTML
                                            content={
                                              e?.description
                                            }></GetBodyInnerHTML>
                                        </div>
                                      </ReactTooltip>
                                    )}
                                    <RangeValue
                                      partnerValue={e?.partnerValue || '0'}
                                      teamValue={e?.teamValue || '0'}
                                      team={teamSlider}></RangeValue>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      }
                    })}
                  {/* <div className="col-md-6">
                                <div className="main-progress-bar horizontal-progress-bar">
                                    <div className="title-progress-bar">Openness</div>
                                    <RangeValue partnerValue={80} teamValue={70}></RangeValue>
                                </div>
                            </div> */}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
