import React, { useState, useEffect } from 'react';
import { useRef } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Carousel, CarouselControl, CarouselItem } from 'reactstrap';

import { apiMainURL } from '../../../../modules/settings/environment';

import {
  useGetEventByIdQuery,
  useGetGalleryEventLikeQuery,
  postGalleryEventLike,
  getAdjacentPagesOnAnyGallery,
} from '../../../../services/api/api-socios-maz';
import BreadCrumb from '../../../UI/BreadCrumb';

import Comments from './Comments';

import './EventDescription.scss';

const EventDescription = () => {
  const { id, eventId } = useParams();
  const newMessageRef = useRef<HTMLTextAreaElement>(null);

  const navigate = useNavigate();
  const items: any[] = [];
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const type = urlParams.get('type');
  const offset = urlParams.get('offset');

  const [liked, setLiked] = useState({ isFavorite: false, count: 0 });
  const [nearIds, setNearIds] = useState({ next: '', prev: '' });

  const slashDate = (date: string | number) =>
    new Date(date).toISOString().split('T')[0].split('-').join('/');

  const eventData = useGetEventByIdQuery(
    JSON.stringify({ galleryId: id, eventId }),
  ).data;
  const heartIconClassNames = `logo ${
    liked.isFavorite ? 'heart-fill bouncy' : 'heart'
  } social-icons mx-auto mt-2`;

  let eventDate;
  let title: string = '';
  let drupalId: string = '';
  let summary = '';

  if (eventData) {
    items.push(`${apiMainURL}${eventData?.included[0].attributes.uri.url}`);
    title = eventData.data[0].attributes.title;
    summary = eventData?.data[0]?.attributes?.body?.summary || '';
    eventDate = slashDate(eventData.data[0].attributes.changed);
    drupalId = eventData.data[0].attributes.drupal_internal__nid;
  }

  const likeData = useGetGalleryEventLikeQuery(drupalId!);
  const crumbs = [
    { path: '/home', label: 'MAZ Partners' },
    { path: '/lpc', label: 'LPC' },
    {
      path: `/lpc/11/gallery?type=${type}&offset=${offset}?11`,
      label: type || '',
    },
    { label: title },
  ];

  // useEffect(() => {
  //   const getData = async () => {
  //     // const adjacentEvents = await getAdjacentPagesOnAnyGallery(
  //     //   +drupalId,
  //     //   type || '',
  //     // );
  //     // setNearIds(adjacentEvents);
  //   };

  //   if (drupalId) getData();
  // }, [drupalId, type]);

  useEffect(() => {
    if (likeData.isSuccess)
      setLiked({
        isFavorite: likeData.data.isFavorite,
        count: +likeData.data.count,
      });
  }, [likeData]);

  const next = () => {
    navigate(`/lpc/11/gallery/${nearIds.next}?type=${type}&offset=${offset}`);
  };
  const previous = () => {
    navigate(`/lpc/11/gallery/${nearIds.prev}?type=${type}&offset=${offset}`);
  };
  const onLikeClick = async () => {
    const response = await postGalleryEventLike(drupalId, liked.isFavorite);

    setLiked((prevState) => ({
      isFavorite: response?.isFavorite!,
      count: prevState.count + (response?.count || 0),
    }));
  };
  const onSocialIconClick = () => {
    const ref = newMessageRef.current;

    ref && ref.focus();
  };

  const slides = items.map((item: any) => {
    return (
      <CarouselItem key={`carousel-${Math.random()}`}>
        <img src={item} alt={item} width='100%' height='750' />
      </CarouselItem>
    );
  });

  return (
    <div className='main-content lpc-bg'>
      <div className='container-fluid'>
        <BreadCrumb {...{ crumbs }} />
        <h1 className='text-center mb-4'>{title}</h1>
        {items && items.length > 0 && (
          <Carousel
            className='event-description-carousel mx-auto'
            {...{ next, previous }}
            activeIndex={0}
            interval={false}>
            {slides}
            {nearIds.prev && (
              <CarouselControl
                direction='prev'
                directionText='<'
                onClickHandler={previous}
              />
            )}
            {nearIds.next && (
              <CarouselControl
                direction='next'
                directionText='>'
                onClickHandler={next}
              />
            )}
          </Carousel>
        )}
        <div className='d-flex'>
          <div className='w-75 me-auto'>
            <p className='mb-1 text-muted'>{eventDate}</p>
            <p className='tw'>{summary}</p>
          </div>
          <div className='d-flex justify-content-end align-items-start gap-4 text-center'>
            <div className='col'>
              <div className={heartIconClassNames} onClick={onLikeClick} />
              <p className='tw'>{liked.count} likes</p>
            </div>

            {/* <div className='col'>
              <i className="bi bi-download fs-2 dwl-img" onClick={onDownload} ></i>
              <p className='tw'>Download</p>
            </div> */}
            <div
              className='logo message social-icons mt-2'
              onClick={onSocialIconClick}
            />
          </div>
        </div>
        {eventId && drupalId && (
          <Comments {...{ drupalId, eventId, newMessageRef }} />
        )}
      </div>
    </div>
  );
};

export default EventDescription;
