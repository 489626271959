import { Link } from 'react-router-dom';

export const SolveLinks = (props: any) => {
    const { url, name, classname } = props;
    const extern = /^(ftp|http|https):\/\/[^ "]+$/.test(url);
    if (extern) {
        return (
            <div>
                <a target="_blank" href={url || ''} className={classname || ''}>
                    {name || ''}
                </a>
            </div>
        );
    } else {
        let url2=url.split('internal:')[1]||'';
        return (
            <Link to={url2 || ('')}
                className={classname || ''}
            >
                {name}
            </Link>
        );
    }
};