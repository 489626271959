import React, { ReactElement, useEffect, useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { Direction } from 'reactstrap/types/lib/Dropdown';
import { v4 } from 'uuid';

import './Dropdown.scss';

type MazDropdownProps = {
  items: { label: string; value: string | number }[];
  direction?: Direction;
  className?: string;
  onClick?: (v: string) => void;
};

const MazDropdown = ({
  className = '',
  direction = 'down',
  items,
  onClick,
}: MazDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownSelection, setDropdownSelection] = useState('0000-00');

  const actualClassName = 'maz-dropdown' + className;

  const toggle = () => setIsOpen((prevOpen) => !prevOpen);

  const onItemClick = (v: any) => {
    setDropdownSelection(v.label);
    onClick && onClick(v.value);
  };

  const Items = items.reduce<ReactElement[]>((acc, cur, idx) => {
    const uid = v4();

    acc.push(
      <DropdownItem
        key={`item-${cur.label}-${uid}`}
        onClick={() => onItemClick(cur)}>
        {cur.label}
      </DropdownItem>,
    );

    idx < items.length - 1 && acc.push(<DropdownItem key={`item2-${uid}`} divider />);

    return acc;
  }, []);

  useEffect(() => {
    setDropdownSelection(items[0]?.label || '-');
  }, [items]);

  return (
    <Dropdown {...{ direction, isOpen, toggle }} className={actualClassName}>
      <DropdownToggle>
       <div className="">
       {dropdownSelection}
        <i className='bi bi-chevron-down' />
       </div>
      </DropdownToggle>
      <DropdownMenu>{Items}</DropdownMenu>
    </Dropdown>
  );
};

export default MazDropdown;
