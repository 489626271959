import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../../UI/BreadCrumb';
import CarouselView from '../../../UI/Carousel/carousel';
import { Link, useLocation } from 'react-router-dom';
import { useGetElementsSquadsByIdQuery } from '../../../../services/api/api-socios-maz';
import LoadSpinner from '../../../UI/LoadSpinner/LoadSpinner';
import { GetBodyInnerHTML } from '../../../Layouts/default-section/getDefaultElements';
import { apiMainURL } from '../../../../modules/settings/environment';
import { SolveLinks } from '../../../UI/solve-Links/solveLinks';
interface buttonLink {
  name: string;
  link: string;
}

interface card {
  icon: string;
  name: string;
  description: string;
  link: buttonLink;
  id: string;
}
export const CommunitiesDetails = (props: any) => {
  const location = useLocation();

  const item = getDetailElement(
    useGetElementsSquadsByIdQuery(location.search.split('?')[1] || '')?.data,
  );
  function getDetailElement(data: any): card {
    let items: any;
    data?.data?.filter((el: any) => {
      let iconId = el?.relationships?.field_icono?.data?.id || '';
      data?.included.filter((inc: any) => {
        if (inc?.id == iconId) {
          items = {
            icon: inc?.attributes?.uri?.url || '',
            name: el?.attributes?.name || '',
            description: el?.attributes?.description?.value || '',
            link: {
              name: el?.attributes?.field_link_externo?.title || '',
              link: el?.attributes?.field_link_externo?.uri || '',
            },
            id: el?.attributes?.drupal_internal__revision_id || '',
          };
          return items;
        }
      });
    });
    return items;
  }

  const crumbs = [
    { path: '/home', label: 'MAZ Partners' },
    { path: '/culture-squads', label: 'Culture Squads' },
    { path: '/partners-clubs', label: 'Partners Clubs' },
    { label: item?.name },
  ];

  if (item && item?.name)
    return (
      <div className='main-content club-detail'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <BreadCrumb {...{ crumbs }} />
            </div>
          </div>
        </div>
        <div className='container-fluid mt-4'>
          <div className='row'>
            <div className='col-md-6 text-center'>
              <div className='detail-icon'>
                <img
                  className='img-fluid'
                  src={apiMainURL + item?.icon}
                  alt='Card cap'
                  width='360'
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div>
                <h1>
                  <strong>{item?.name || ''}</strong>
                </h1>
              </div>
              <div className='text-content'>
                <GetBodyInnerHTML content={item?.description || ''} />
              </div>
              {/* <div className="mt-4">
                            <h4 className="section-title text-start">
                                Team Dynamics
                            </h4>
                            <div className="text-content">
                                <ul className="list-detail">
                                    <li><span>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</span></li>
                                    <li><span>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</span></li>
                                    <li><span>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</span></li>
                                </ul>
                            </div>
                        </div> */}
              <div className='my-5 text-center'>
                {/* <Link
                  to={item?.link?.link || 'communities'}
                  className='btn btn-primary fw-bold'>
                  {item?.link?.name}
                </Link> */}
                <SolveLinks
                  url={item?.link?.link || ''}
                  classname='btn btn-primary fw-bold'
                  name={item?.link?.name}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  else return <LoadSpinner />;
};
