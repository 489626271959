import React, { useState } from 'react';
import { store } from '../../../../modules/appState/store';
import {
  useGetHomeDefaultContentQuery,
  useGetLPCInfographicQuery,
  useGetPartnerUserQuery,
} from '../../../../services/api/api-socios-maz';
import { getDefaultContent } from '../../../Layouts/default-section/getDefaultElements';
import LoadSpinner from '../../../UI/LoadSpinner/LoadSpinner';
import { useFormik } from 'formik';
import { apiMainURL } from '../../../../modules/settings/environment';
import { useToasts } from 'react-toast-notifications';
import { useNavigate } from 'react-router';
import LandingHeader from './LandingHeader';
import Letter from './Letter';
import EventInfo from './EventInfo';
import Memories from './Memories';

import './LPC.scss';
import GetReady from './GetReady/indext';
import RoundedCardsSection from './RoundedCardsSection';

export function getPartnerData(data: any) {
  let fileTicket = '',
    fileInvitacion = '',
    fileReserva = '';
  if (data?.data) {
    data?.data?.map((user: any) => {
      if (user?.relationships?.field_reserva_lpc?.data) {
        fileReserva = getFile(
          user?.relationships?.field_reserva_lpc?.data?.id || '',
          data?.included,
        );
      }
      if (user?.relationships?.field_ticket_lpc?.data) {
        fileTicket = getFile(
          user?.relationships?.field_ticket_lpc?.data?.id || '',
          data?.included,
        );
      }
      if (user?.relationships?.field_invitacion_lpc?.data) {
        fileInvitacion = getFile(
          user?.relationships?.field_invitacion_lpc?.data?.id || '',
          data?.included,
        );
      }
    });
    return {
      ticketPDF: fileTicket != '' ? apiMainURL + fileTicket : '',
      hotelPDF: fileReserva != '' ? apiMainURL + fileReserva : '',
      invPDF: fileInvitacion != '' ? apiMainURL + fileInvitacion : '',
    };
  }
}
function getFile(id: string, includes: any): string {
  let url = '';
  includes?.map((inc: any) => {
    if (inc?.id == id) url = inc?.attributes?.uri?.url || '';
  });
  return url;
}

export const LPC_Component_GetReady = () => {
  const storeApp = store;
  const [user2, setUser2] = useState({
    image: storeApp.getState().AppReducer,
  });

  const crumbs = [{ path: '/home', label: 'MAZ Partners' }, { label: 'LPC' }];

  const dataapi = useGetHomeDefaultContentQuery('lpc/get-ready').data;
  // const nodeId = dataapi?.data[0]?.attributes?.drupal_internal__nid;

  const elem: any = getDefaultContent(dataapi)?.content;
  const infographic = useGetLPCInfographicQuery().data;

  // const cardsContent = elem?.at(1)?.content;
  const marqueeContent =
    elem?.find(({ type = '' }) => type === 'text')?.content?.value || '';
  const letterContent = elem?.find(
    ({ type = '' }) => type === 'horizontalCards',
  )?.content[0];
  const memories = elem?.find(
    ({ type = '' }) => type === 'horizontal_card_video',
  )?.content;
  const memoriesContent = {
    title: memories?.title || '',
    desc: memories?.description || '',
    video: memories?.video,
  };
  const eventInfoContent = elem?.find(
    ({ type = '' }) => type === 'cardsGroup',
  )?.content;
  const infographicContent = elem?.filter(
    ({ type = '' }) => type === 'cardsGroup',
  )[1]?.content;
  const getReadyContent = {
    title: infographicContent?.at(0)?.title,
    desc: infographicContent?.at(0)?.description,
    label: infographicContent?.at(0)?.label,
    src: infographic?.fileRouthe,
    solutions: infographicContent
      ?.slice(1)
      ?.map(({ title = '', description = '' }) => ({
        title,
        desc: description,
      })),
  };

  storeApp.subscribe(() => {
    setUser2({ image: storeApp.getState().AppReducer });
  });
  const userCompleted = getPartnerData(
    useGetPartnerUserQuery(user2?.image?.userData?.drupal_id || '')?.data,
  );

  if (elem) {
    return (
      <div className='main-content lpc-bg'>
        <LandingHeader
          {...{ crumbs }}
          img={elem[0]?.content[0].src.src}
          desc={marqueeContent}
        />
        <EventInfo
          content={eventInfoContent}
          hotelPDF={userCompleted?.hotelPDF}
          ticketPDF={userCompleted?.ticketPDF}
          invPDF={userCompleted?.invPDF}
        />
        <RoundedCardsSection />
        <GetReady {...getReadyContent} />
        <Letter {...letterContent} />
        <Memories {...memoriesContent} />
      </div>
    );
  } else return <LoadSpinner></LoadSpinner>;
};

export const LpcGetReady = (props: any) => {
  const navigate = useNavigate();

  const { addToast } = useToasts();
  const { dataUser } = props;
  const { documents } = props;
  const contact: string = '5215546364367';

  // const contact:string='573108250562';
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: (values) => {
      var myHeaders = new Headers();
      myHeaders.append('Accept', 'application/vnd.api+json');
      myHeaders.append(
        'Authorization',
        'Bearer ' + localStorage.getItem('localToken'),
      );
      var formdata = new FormData();
      formdata.append('node', props?.id);
      formdata.append('preferences', values?.email);

      var requestOptions: RequestInit = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
      };
      if (values.email.trim() != '') {
        fetch(apiMainURL + '/api/store-preferencias', requestOptions)
          .then((response) => response.text())
          .then((result) => {
            addToast('Message sent. ', {
              appearance: 'warning',
              autoDismiss: true,
              transitionDuration: 1000,
            });
            // `/${domainCountry.color}/favicon.svg`
            window.open(
              `https://wa.me/${contact}?text=${encodeURI(
                `*MAZ-Partners App*. \n*Usuario*: ${dataUser?.name}  \n*Message:* ${values?.email}`,
              )}`,
              '_blank',
            );
          })
          .catch((error) => {
            console.log('error', error);
            addToast('Message not sent. ', {
              appearance: 'error',
              autoDismiss: true,
              transitionDuration: 1000,
            });
          });
      } else {
      }
    },
  });
  function downloadDocument(url: string) {
    if (localStorage.getItem('mobile') == '1')
      navigate(`/download-pdf-link?${url}`);
    else window.open(url);
  }

  return (
    <div>
      <div className='col-12 pb-5'>
        {/* <CarouselView content={props?.data[0]?.content}></CarouselView> */}
        {/* <NavElementsLPC actived={0}></NavElementsLPC> */}
        {/* <div className='carousel-rounded pb-5'>
          <div className=' my-4'>
            <div className='row'>
              <div className='col-11 mx-auto position-relative'>
                <RoundedCarouselCards
                  items={props?.data[1]?.content?.map((el: any) => {
                    return {
                      src: el?.image || '',
                      description: el?.description,
                      link: el?.link || '',
                      title: el?.title || '',
                    };
                  })}></RoundedCarouselCards>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className='row'>
          <div className='col col-12 col-md-6 col-lg-4 text-center'>
            <div className='row'>
              <div className='col col-md-4'>
                <div className='logo restrictions icons-ready' />
              </div>
              <div className='col col-md-8'>
                <div className='text-content tw'>
                  <p className='text-center'>
                    <h3 className='fw-7'>From August</h3>{' '}
                    <h1 className='fw-7'>08 to 11</h1>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='col col-12 col-md-6 col-lg-4  text-center vertical-separator'>
            <div className='row'>
              <div className='col col-md-4'>
                {documents?.ticketPDF != '' && (
                  <div className='logo departures icons-ready' />
                )}
              </div>
              <div className='col col-md-8'>
                {documents?.ticketPDF != '' && (
                  <div className='text-content tw'>
                    <p className='text-center'>
                      <h3 className='fw-7'>México City</h3>
                      <button
                        className='btn btn-primary btn-reports btn-dr px-4'
                        onClick={() => downloadDocument(documents?.ticketPDF)}>
                        {' '}
                        <i className='bi bi-download'></i> Your Ticket
                      </button>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='col col-12 col-md-6 col-lg-4  text-center'>
            <div className='row'>
              <div className='col col-md-4'>
                {documents?.hotelPDF !== '' && (
                  <div className='logo comfort  icons-ready' />
                )}
              </div>
              <div className='col col-md-8'>
                {documents?.hotelPDF !== '' && (
                  <div className='text-content tw'>
                    <p className='text-center'>
                      <h3 className='fw-7'>México Hotel</h3>
                      <button
                        className='btn btn-primary btn-reports btn-dr px-4'
                        onClick={() => downloadDocument(documents?.hotelPDF)}>
                        {' '}
                        <i className='bi bi-download'></i> Your Reservation
                      </button>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div> */}

        <div className='row pt-5'>
          <div className='col col-12 col-md-5 gx-5'>
            <div className='logo departures icons-ready ' />
            <p className='subtitle-lpc-media '>
              Memories of an event without precedents
            </p>
            <p className='title-lpc-media'>LPC 2023</p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipiscing elit mollis
              lacus aliquam, cras conubia laoreet ut ante morbi hac odio
              sodales, donec ridiculus dignissim sapien habitant justo hendrerit
              dictumst natoque.
            </p>
          </div>
          <div className='col col-12 col-md-7 text-center'>
            <video
              className=' w-100 media-lpc-landing'
              src='https://api-socios-maz-dev.somosmaz.com/sites/default/files/2022-09/Partnersprofile_c%281%29.mp4'
              controls></video>
          </div>
        </div>
        {/* <h3 className='section-title mt-5 mb-4'>
          {props?.data[1]?.content?.value}
        </h3> */}
        {/* <div className='text-content text-center tw'>
          <GetBodyInnerHTML
            content={props?.data[3]?.content?.value}></GetBodyInnerHTML>
        </div> */}
        {/* <div className='col-12 mt-5'>
          <div className='row pt-3'>
            <div className='col-md-3'>
              <div className='logo restrictions icons-ready' />
            </div>
            <div className='col-md-9'>
              <h3 className=''> General Information</h3>
              <form onSubmit={formik.handleSubmit}>
                <label className='tw mb-2' htmlFor='email'>
                  If you have any doubts regarding event logistics, please leave
                  us a message here or contact Paola Bautista -
                  <a
                    href='mailto:paola.bautista@ab-inbev.com'
                    className='text-white'>
                    paola.bautista@ab-inbev.com
                  </a>{' '}
                  or +5215546364367
                </label>
                <textarea
                  id='email'
                  name='email'
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                <br />
                <div className='text-end '>
                  <button className='btn btn-primary mt-1' type='submit'>
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className='row pt-5 align-items-center'>
            <div className='col-md-3'>
              <div className='logo tickets icons-ready' />
            </div>
            <div className='col-md-9'>
              <h3> {props?.data[4]?.content?.value}</h3>
              <div className='text-content tw'>
                <GetBodyInnerHTML
                  content={props?.data[5]?.content?.value}></GetBodyInnerHTML>
              </div>
            </div>
          </div>
          <div className='row pt-5 align-items-center'>
            <div className='col-md-3'>
              <div className='logo things  icons-ready' />
            </div>
            <div className='col-md-9'>
              <h3> {props?.data[6]?.content?.value}</h3>
              <div className='text-content tw'>
                <GetBodyInnerHTML
                  content={props?.data[7]?.content?.value}></GetBodyInnerHTML>
              </div>
            </div>
          </div>
          <div className='row pt-5 align-items-center'>
            <div className='col-md-3'>
              <div className='logo arrivals icons-ready' />
            </div>
            <div className='col-md-9'>
              <h3> {props?.data[8]?.content?.value}</h3>
              <div className='text-content tw'>
                <GetBodyInnerHTML
                  content={props?.data[9]?.content?.value}></GetBodyInnerHTML>
              </div>
            </div>
          </div>
          <div className='row pt-5 align-items-center'>
            <div className='col-md-3'>
              <div className='logo comfort  icons-ready' />
            </div>
            <div className='col-md-9'>
              <h3> {props?.data[10]?.content?.value}</h3>
              <div className='text-content tw'>
                <GetBodyInnerHTML
                  content={props?.data[11]?.content?.value}></GetBodyInnerHTML>
              </div>
            </div>
          </div>
          <div className='row pt-5 align-items-center'>
            <div className='col-md-3'>
              <div className='logo departures icons-ready' />
            </div>
            <div className='col-md-9'>
              <h3> {props?.data[12]?.content?.value}</h3>
              <div className='text-content tw'>
                <GetBodyInnerHTML
                  content={props?.data[13]?.content?.value}></GetBodyInnerHTML>
              </div>
            </div>
          </div>
        </div> */}
        {/* <Sections content={props?.data[3]?.content}></Sections>  */}
      </div>
    </div>
  );
};
