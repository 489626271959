import React, { useCallback, useState } from 'react';
import NoDataFound from '../../../../../../Layouts/NoDataFound';
import { EmployeeData } from '../../../../admin-partner-profile/general-items/My-profile/employee-data';

type EmployeeDataItemProps = {
  date?: string;
};

const EmployeeDataItem = ({ date }: EmployeeDataItemProps) => {
  const [hasData, setHasData] = useState(true);

  const verifyData = useCallback((v: boolean) => {
    setHasData(v);
  }, []);

  return hasData ? (
    <>
      <div className='text-content py-4 text-center page-break '>
      </div>
      <EmployeeData {...{ date }} hasAccordion={false} hasData={verifyData} />
    </>
  ) : (
    <NoDataFound />
  );
};

export default EmployeeDataItem;
