import React from 'react';
import './BinnacleTensions.scss';

import { ReactComponent as Singular } from '../../../../../assets/Binnacle/Singular.svg';
import { ReactComponent as Plural } from '../../../../../assets/Binnacle/Plural.svg';

const BinnacleTensions = (props: any) => {
  const { tensionesIndividuales, tensionesEquipo } = props;
  return (
    <div className='my-2 mx-3 mx-md-0 my-md-4 binnacle-tensions'>
      <h2 className='text-center tw'>Tensiones</h2>
      <div className='row'>
        <div className='col-md-6 row'>
          <div className='col-6 mb-3 mb-md-0 px-0 d-flex flex-column'>
            <Singular />
            <h3>Individuales</h3>
          </div>
          <div className='col-6'>
            <ul>
              {tensionesIndividuales?.map((tensionIndividual: any, indexTi: number) => {
                return (
                  <li key={indexTi}>{tensionIndividual}</li>
                )
              })}
            </ul>
          </div>
        </div>
        <div className='col-md-6 row'>
          <div className='col-6 px-0 d-flex flex-column'>
            <Plural />
            <h3>Hacia el equipo</h3>
          </div>
          <div className='col-6'>
            <ul>
              {tensionesEquipo?.map((tensionEquipo: any, indexTe: number) => {
                return (
                  <li key={indexTe}>{tensionEquipo}</li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BinnacleTensions;
