import React, { useEffect, useReducer } from 'react';
import { useParams } from 'react-router';

import BreadCrumb from '../../../UI/BreadCrumb';
import HeartLike from '../../../UI/HeartLike';

import 'react-multi-carousel/lib/styles.css';
import './MazPresentation.scss';
import {
  getEventLikes,
  postGalleryEventLike,
  postPresentationById,
} from '../../../../services/api/api-socios-maz';
import {
  ComplementProps,
  initialPresentationState,
  PresentationCases,
  PresentationReducer,
  PresentationTypes,
} from './PresentationReducer';
import MainResourceManager from './MainResourceManager';
import { GetBodyInnerHTML } from '../../../Layouts/default-section/getDefaultElements';
import { apiMainURL } from '../../../../modules/settings/environment';
import RelatedContent from './RelatedContent';
import Complement from './Complement';
import LoadSpinner from '../../../UI/LoadSpinner/LoadSpinner';

const MazPresentation = () => {
  const { presentationId } = useParams();

  const [
    {
      title,
      type,
      mediaUrl,
      date,
      topic,
      bus,
      description,
      audioBanner,
      like,
      likeCounter,
      complements,
      isLoading,
    },
    dispatch,
  ] = useReducer(PresentationReducer, initialPresentationState);

  const crumbs = [
    { path: '/', label: 'MAZ Partners' },
    { path: '/maz-resources', label: 'MAZ Resources' },
    { label: title },
  ];

  const onLikeClick = async () => {
    await postGalleryEventLike(`${presentationId}`, like);

    dispatch({
      type: PresentationCases.SET_LIKE,
      payload: { likeCounter: like ? likeCounter - 1 : likeCounter + 1 },
    });
  };

  useEffect(() => {
    const getData = async () => {
      dispatch({
        type: PresentationCases.SET_LOAD,
        payload: { isLoading: true },
      });

      const res = await postPresentationById({ id: presentationId });
      const likes = await getEventLikes(presentationId || 0);
      const att = res?.data[0]?.attributes || {
        title: '',
        field_google_slide: null,
        changed: '',
        body: { value: '' },
      };
      const rels = res?.data[0]?.relationships || {
        field_recurso: {},
        field_complemento_audio: {},
      };
      const inc = res?.included || [];
      const hasSlides = att.field_google_slide !== null;
      const topic = inc.find(
        ({ type = '' }) => type === 'taxonomy_term--temas_recursos',
      )?.attributes?.name;
      const { AUDIO, PRESENTATION, VIDEO } = PresentationTypes;
      const aBanner = inc.find(
        ({ id = '' }) => id === rels.field_complemento_audio?.data?.id || '',
      )?.attributes?.uri?.url;

      dispatch({
        type: PresentationCases.SET_INITIAL_DATA,
        payload: {
          title: att.title,
          type: hasSlides
            ? PRESENTATION
            : (rels.field_recurso?.data?.type || '') === 'media--video'
            ? VIDEO
            : AUDIO,
          mediaUrl: hasSlides
            ? att.field_google_slide?.uri || ''
            : (rels.field_recurso?.data?.type || '') === 'media--video'
            ? apiMainURL +
              inc.find(
                ({ id: idF = '' }) =>
                  idF ===
                    inc.find(
                      ({ id: idFile = '' }) =>
                        idFile ===
                        inc.find(
                          ({ id = '' }) =>
                            id === rels.field_recurso?.data?.id || '',
                        )?.id,
                    )?.relationships?.field_media_video_file?.data?.id || '',
              )?.attributes?.uri?.url
            : apiMainURL +
              inc.find(
                ({ id: idFile = '' }) =>
                  idFile ===
                  inc.find(
                    ({ id = '' }) => id === rels.field_recurso?.data?.id || '',
                  )?.relationships?.field_media_audio_file?.data?.id,
              )?.attributes?.uri?.url,
          date: new Date(att.changed).toLocaleDateString('en-GB', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
          }),
          topic,
          bus: inc
            .filter(({ type = '' }) => type === 'taxonomy_term--bu')
            ?.map(({ attributes: { name } = { name: '' } }) => name)
            .join(', '),
          description: att.body?.value,
          audioBanner: aBanner && apiMainURL + aBanner,
          like: likes.isFavorite,
          likeCounter: +likes.count,
          complements: rels?.field_complementos?.data?.map(({ id = '' }) => {
            const e = inc.find(({ id: idF = '' }) => idF === id);

            if (e.type !== 'paragraph--paragraph_banners')
              return {
                type:
                  e.type === 'paragraph--contenido_destacado'
                    ? 'HIGHLIGHT'
                    : 'TEXT',
                content: e?.attributes?.field_texto?.value || '',
              };

            return {
              type: 'BANNER',
              content:
                apiMainURL +
                inc.find(
                  ({ id: idF = '' }) =>
                    inc.find(
                      ({ id = '' }) =>
                        e.relationships?.field_list?.data[0]?.id === id,
                    )?.relationships?.field_image?.data?.id === idF,
                )?.attributes?.uri?.url,
            };
          }),
          isLoading: false,
        },
      });
    };

    getData();
  }, [presentationId]);

  return (
    <div className='main-content'>
      <div className='container-fluid'>
        {isLoading && <LoadSpinner />}
        <BreadCrumb {...{ crumbs }} />
        <h1 className='text-center mb-4'>{title}</h1>
        <div className='maz-p-media'>
          <MainResourceManager {...{ type, audioBanner }} src={mediaUrl} />
        </div>
        <div className='maz-p-description'>
          <div className='d-flex justify-content-between maz-p-general'>
            <h6 className='mt-auto mb-3'>{date}</h6>
            <HeartLike
              isLiked={like}
              onClick={onLikeClick}
              hasLikesCounter
              howManyLikes={likeCounter}
            />
          </div>
          <h6 className='subtitle mb-0'>
            {topic} - {bus}
          </h6>
          <div className='me-sm-5'>
            <GetBodyInnerHTML content={description} />
          </div>
        </div>
      </div>
      <div className='maz-p-complement'>
        {complements.map((args: ComplementProps) => (
          <Complement
            key={`complement-${args.type}-${args.content}`}
            {...args}
          />
        ))}
      </div>
      <div className='container-fluid'>
        <div className='maz-p-separator' />
        <div className='maz-p-related-c'>
          <RelatedContent {...{ topic }} />
        </div>
      </div>
    </div>
  );
};

export default MazPresentation;
