import React from 'react';
import { GetBodyInnerHTML } from '../../../Layouts/default-section/getDefaultElements';
import { ComplementProps } from './PresentationReducer';

const Complement = ({ type, content }: ComplementProps) => {
  switch (type) {
    case 'HIGHLIGHT':
      return (
        <div className='text-banner'>
          <div className='container-fluid'>
            <div className='mx-4'>
              <GetBodyInnerHTML {...{ content }} />
            </div>
          </div>
        </div>
      );

    case 'TEXT':
      return (
        <div className='container-fluid'>
          <div className='me-sm-5 ms-sm-4 ps-sm-2 my-2'>
            <GetBodyInnerHTML {...{ content }} />
          </div>
        </div>
      );

    case 'BANNER':
      return (
        <div className='container-fluid'>
          <img src={content} alt='maz-p-banner' />
        </div>
      );
  }
};

export default Complement;
