import { useFormik } from 'formik';
import { mkdirSync } from 'fs';
import React, { useState } from 'react';
import InfoSection from './InfoSection';

import { ReactComponent as Clock } from '../../../../../assets/AboutMeAtABI/STI/Clock.svg';
import { Help } from './Percentages';

const Ammounts = (props: any) => {
  const [month, setMonth] = useState(12);

  let dollarUSLocale = Intl.NumberFormat('en-US');
  const inputLimits = { min: 1, max: 12 };
  const { mrs, jobBand, currency } = props;
  const stringCurrency = currency?.toString();
  const formik1 = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: (values) => {},
  });
  const handleOnChangeTDR = (event: any) => {
    // setIt(event?.target?.value);
    if (localStorage.getItem('tdr')) {
      localStorage.removeItem('tdr');
    }
    localStorage.setItem('tdr', event?.target?.value);
  };

  const onMonthChange = (e: any) => {
    const actualInput = Number(e.target.value);

    setMonth(Math.max(inputLimits.min, Math.min(inputLimits.max, actualInput)));

    if (actualInput <= inputLimits.max && actualInput >= inputLimits.min)
      formik1.handleChange(e);
  };

  return (
    <>
      <div className='floating-label-wrap py-2 position-relative mb-3'>
        <div className='gap-3 content-time'>
          <Clock />
          <form className='me-auto' onChange={handleOnChangeTDR}>
            {/* <label htmlFor="email">Texto del label</label> */}
            <div className='d-flex my-1'>
              <input
                id='field-1'
                name='months'
                type='number'
                placeholder='12'
                className='floating-label-field bg-transparent border-0 shadow-none me-2'
                value={month}
                onChange={onMonthChange}
                min={`${inputLimits.min}`}
                max={`${inputLimits.max}`}
              />
              <p className='month'>{`month${month !== 1 ? 's' : ''}`}</p>
            </div>
          </form>
          <div className='my-auto'>
            <Help
              id='btn-2'
              text='Is the % of time that the employee occupied a position within the year.'
              title='Time dedication rate'
            />
          </div>
        </div>
        <label
          htmlFor='field-1'
          className='floating-label position-absolute top-0 px-1'>
          Time Dedication Rate
        </label>
      </div>
      <InfoSection
        label='MRS (Annual Amount)'
        value={`$${dollarUSLocale?.format(mrs)} ${
          stringCurrency ? stringCurrency.toUpperCase() : ''
        }`}
        descriptionMessage="MRS: An employee's Job Band (on 30 September during the appraisal year) determines their variable compensation level, being Market Reference Salary (MRS) and Job Band Multiplier (bonus percentage).."
        descriptionTitle='Total Achievement Score'
      />
      <InfoSection
        label='Job Band Multiplier'
        value={`${jobBand}%`}
        descriptionMessage="An employee's Job Band (on 30 September during the appraisal year) determines their variable compensation level, being Market Reference Salary (MRS) and Job Band Multiplier (bonus percentage).."
        descriptionTitle='Job Band Multiplier'
      />
    </>
  );
};

export default Ammounts;
