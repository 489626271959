import React from 'react';
import { ReactComponent as Home } from '../assets/lateralMenu/Home.svg';
import { ReactComponent as WhatsUpWithMe } from '../assets/lateralMenu/what_up_with_me.svg';
// import { ReactComponent as LPC } from '../assets/lateralMenu/lpc.svg';
import { ReactComponent as LPC } from '../assets/lateralMenu/LPC (1).svg';
import { ReactComponent as PartnerProfile } from '../assets/lateralMenu/PartnerProfile.svg';
import { ReactComponent as BusinessResults } from '../assets/lateralMenu/Business results.svg';
import { ReactComponent as Support } from '../assets/lateralMenu/Support.svg';

type itemsProps = {
  url: string;
  Icon: React.FunctionComponent;
  label: string;
  position: number;
  hasNotPPInfo?: boolean;
  outsideURL?: boolean;
}[];

export const items: itemsProps = [
  { url: '/home', Icon: Home, label: 'Home', position: 1 },
  {
    url: '/about-me-at-abi',
    Icon: WhatsUpWithMe,
    label: 'About me at ABI',
    position: 5,
  },
  { url: '/lpc', Icon: LPC, label: 'LPC', position: 4 },
  {
    url: '/partners/partner-profile',
    Icon: PartnerProfile,
    label: 'Partner Profile',
    position: 2,
  },
  {
    url: '/business-results',
    Icon: BusinessResults,
    label: 'Business Results',
    position: 3,
  },
  {
    url: 'https://soporte.somosmaz.com/pqr/Mazpartners',
    Icon: Support,
    label: 'Support',
    position: 6,
    outsideURL: true,
  },
  // {
  //   url: '/calendar',
  //   Icon: Calendar,
  //   label: 'Calendar',
  //   position: 6,
  // },
  // {
  //   url: '/culture-squads',
  //   Icon: CultureSquads,
  //   label: 'Culture Squads',
  //   position: 6,
  // },
];
