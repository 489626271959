import React from 'react';
import { useGetPartnersMetricsQuery } from '../../../../../../../services/api/api-socios-maz';

import PartnersMetricsTable from './PartnersMetricsTable';
import SingleMetrics from './SingleMetrics';
import PartnersLevelMetrics from './PartnersLevelMetrics';
import MetricsDoughnut from './MetricsDoughnut';
import LoadSpinner from '../../../../../../UI/LoadSpinner/LoadSpinner';
import MetricsLevelBands from './MetricsLevelBands';
import MetricsOPR from './MetricsOPR';
import MetricsEngagement from './MetricsEngagement';
import MetricsLCM from './MetricsLCM';
import MetricsTargetAchievement from './MetricsTargetAchievement';

import './PartnersMetrics.scss';
import SingleMetricsPrint from './SingleMetricsPrint';

type PartnersMetricsProps = {
  date?: string;
};

export const romanBandOrder = ['0', 'I', 'II', 'III', 'IV', 'V'];
export const assignSecondColor = (level: string | number) => {
  switch (+level) {
    case 2:
      return '#000';

    case 3:
      return '#bebebe';

    case 4:
      return '#e08a2d';

    default:
      return '#f5e003';
  }
};

const PartnersMetricsPrint = ({ date }: PartnersMetricsProps) => {
  const metrics = useGetPartnersMetricsQuery({ date }).data;

  const singleMetrics = {
    total: metrics?.total,
    dIAvg: metrics?.dyi_avg,
    engAvg: metrics?.engagement_avg,
    mgrEffAvg: metrics?.manager_effecti_avg,
    tgtAchAvg: metrics?.target_ach_avg,
  };

  return (
    <div className='section-dark-gray d-flex flex-column align-items-center partners-metrics'>
      {!metrics && <LoadSpinner />}
      <SingleMetricsPrint {...singleMetrics} />
    </div>
  );
};

export default PartnersMetricsPrint;
