import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Input } from 'reactstrap';
import { postMenuItemsData } from '../../../../../../services/api/api-socios-maz';
import BreadCrumb from '../../../../../UI/BreadCrumb';
import LoadSpinner from '../../../../../UI/LoadSpinner/LoadSpinner';
import { PartnerProfileSuperExec } from '../Partner-profile-exec';

export const TeamProfiles = () => {
  const [data2, setData2] = useState<any>(undefined);
  const [load, setLoad] = useState(false);
  const location = useLocation();

  const crumbs = [
    { path: '/home', label: 'MAZ Partners' },
    { label: 'Team profiles' },
  ];
  const data = useMemo(() => postMenuItemsData(), []);

  useEffect(() => {
    const getDataProfile = async () => {
      setLoad(true);

      const response = await data;

      if (response && response?.status && response?.status === 200) {
        setData2(await response?.json());
        setLoad(false);
      }
    };

    getDataProfile();
  }, [location, data]);

  return (
    <div className='main-content'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <BreadCrumb {...{ crumbs }} />
          </div>
          {data2?.relateds?.users && (
            <TeamProfilesRender users={data2?.relateds?.users} />
          )}
          {!data2?.relateds?.users && <LoadSpinner></LoadSpinner>}
        </div>
      </div>
    </div>
  );
};

export const TeamProfilesRender = (props: any) => {
  const location = useLocation();
  const { users } = props;
  function sortUsers(users: any) {
    let usersArray: any[] = [];
    Object?.entries(users).forEach((us: any, indexItem: number) => {
      usersArray.push(us[1]);
    });
    return usersArray.sort(function (a, b) {
      if (a?.name > b?.name) return 1;
      if (a?.name < b?.name) return -1;
      return 0;
    });
  }

  const users2 = sortUsers(users);
  // const usersFiltered=filteredUsers(users);

  useEffect(() => {
    getDataUrl();
  }, [location]);

  function filteredUsers(users: any) {
    let usersFiltereds: any[] = [];
    users?.filter((user: any, indexUser: number) => {});
  }

  const getDataUrl = (): object[] => {
    let filtersObjects: object[] = [];
    if (location?.search != '') {
      let params = location?.search?.split('?')[1]?.split('&');
      params?.map((p: string) => {
        let nameFilter = p?.split('=')[0] || '';
        let valuesFilter = p?.split('=')[1] || '';
        let values: string[] = [];
        valuesFilter?.split('+')?.map((value: any) => {
          values.push(value);
        });
        filtersObjects.push({
          nameFilter: nameFilter || '',
          values: values || [],
        });
      });
    }
    return filtersObjects;
  };
  const crumbs = [
    { path: '/home', label: 'MAZ Partners' },
    { label: 'Team profiles' },
  ];

  return (
    <div className='col-12 mt-5'>
      {users && (
        <PartnerProfileSuperExec
          crumbs={crumbs}
          link={'/partners/team-profiles/detail'}
          usersData={users2}
          title={'Team Profiles'}
          description={
            'Use the filters in the menu and the search engine to find out the results your team obtained in terms of leadership, as well as the owner entrepreneur attributes they possess.\n' +
            'Click on each one to access the full report.'
          }
        />
      )}
      {!users && <LoadSpinner></LoadSpinner>}
    </div>
  );
};
