export type Resource = {
  img: string;
  topic: string;
  title: string;
  description: string;
  id: number;
  likeValue: boolean;
};

type GalleryState = {
  title: string;
  subtitle: string;
  description: string;
  resources: Resource[] | [];
  filters: {
    bu: string;
    cat: string;
  };
  isLoading: boolean;
  page: number;
  hasNext: boolean;
  hasPrev: boolean;
  hasLikeFilter: boolean;
  hasLikes: boolean;
};

export enum GalleryCases {
  SET_BASIC_DATA = 'SET_BASIC_DATA',
  SET_RESOURCES = 'SET_RESOURCES',
  SET_FILTERS = 'SET_FILTERS',
  SET_LOADING = 'SET_LOADING',
  CHANGE_PAGE = 'CHANGE_PAGE',
  IS_SEARCHING_BY_LIKE = 'IS_SEARCHING_BY_LIKE',
  DISABLE_LIKE_BUTTON = 'DISABLE_LIKE_BUTTON',
}

type GalleryAction = {
  type: GalleryCases;
  payload: {
    title?: string;
    subtitle?: string;
    description?: string;
    resources?: Resource[];
    filters?: { bu: string; cat: string };
    isLoading?: boolean;
    page?: number;
    hasNext?: boolean;
    hasPrev?: boolean;
    hasLikeFilter?: boolean;
    hasLikes?: boolean;
  };
};

export const initialGalleryState = {
  title: '',
  subtitle: '',
  description: '',
  resources: [
    { img: '', topic: '', title: '', description: '', id: 0, likeValue: false },
  ],
  filters: { bu: '', cat: '' },
  isLoading: true,
  page: 0,
  hasNext: true,
  hasPrev: false,
  hasLikeFilter: false,
  hasLikes: false,
};

export const GalleryReducer = (state: GalleryState, action: GalleryAction) => {
  const { type } = action;

  switch (type) {
    case 'SET_BASIC_DATA': {
      const { payload } = action;

      return {
        ...state,
        title: payload.title || '',
        subtitle: payload.subtitle || '',
        description: payload.description || '',
      };
    }

    case 'SET_RESOURCES': {
      const { payload } = action;

      return {
        ...state,
        resources: payload.resources || [],
        hasPrev: payload.hasPrev || false,
        hasNext: payload.hasNext || false,
        hasLikes: payload.hasLikes || false,
      };
    }

    case 'SET_FILTERS': {
      const { payload } = action;

      return { ...state, filters: payload.filters || { bu: '', cat: '' } };
    }

    case 'SET_LOADING': {
      const { payload } = action;

      return { ...state, isLoading: payload.isLoading || false };
    }

    case 'CHANGE_PAGE': {
      const { payload } = action;

      return { ...state, page: payload.page || 0 };
    }

    case 'IS_SEARCHING_BY_LIKE': {
      const { payload } = action;

      return {
        ...state,
        page: 0,
        hasLikeFilter: payload.hasLikeFilter || false,
      };
    }

    case 'DISABLE_LIKE_BUTTON': {
      const { payload } = action;

      return { ...state, hasLike: payload.hasLikes || false };
    }

    default:
      return { ...state };
  }
};
