import React, { ChangeEvent, useState } from 'react';
import { Help } from './Percentages';

type InputRangeSliderProps = {
  min: number;
  max: number;
  onInputChange: (element: ChangeEvent) => void;
  inputValue: any;
  onChange: (event: any) => void;
  percentage: number;
  label: string;
  helpMessage?: string;
  helpLabel?: string;
};

const InputRangeSlider = ({
  inputValue,
  max,
  min,
  onChange,
  onInputChange,
  percentage,
  label,
}: InputRangeSliderProps) => {
  const [backgroundSize, setBackgroundSize] = useState(0);

  const style = {
    backgroundSize: `${((backgroundSize - min) * 100) / (max - min)}% 100%`,
  };

  const onInputSliderChange = (e: ChangeEvent<HTMLInputElement>) => {
    setBackgroundSize(+e.target.value);
    onInputChange(e);
  };

  return (
    <div className='floating-label-wrap py-2 position-relative my-3'>
      <div className='gap-2 content-time align-self-center form-card position-relative'>
        <form {...{ onChange }} className='ms-2 range-slider'>
          <div className='d-flex flex-column my-1 w-100'>
            <input
              {...{ max, min, style }}
              id='sop'
              name='sop'
              type='range'
              className='form-range calculator-range'
              step='1'
              onChange={onInputSliderChange}
              defaultValue={inputValue}
            />
            <div className='limits-indicators d-flex justify-content-between'>
              <span className='font-weight-bold'>{min}%</span>
              <span className='font-weight-bold'>{max}%</span>
            </div>
          </div>
        </form>
        <p className='fw-bolder fs-4 my-auto percentage-chosen'>
          {percentage}%
        </p>
        <div className='my-auto'>
          <Help
            text='= The Time Dedication rate is the % of time that the employee occupied a position within the year.'
            title='Time dedication rate:'
          />
        </div>
      </div>
      <label
        htmlFor='field-1'
        className='floating-label position-absolute top-0 px-1'>
        {label}
      </label>
    </div>
  );
};

export default InputRangeSlider;
