import React from 'react';
import { Link } from 'react-router-dom';
import { compensationData } from '../../../../../models/calculator.model';

export const Percentages = (props: any) => {
  const currency = props?.currency;
  const bonus: compensationData[] = props?.bonus;
  const total = getTotal(bonus);
  function getTotal(bonus: any) {
    let t = 0;
    bonus?.map((b: any) => {
      t += b?.value?.result;
    });
    return t;
  }
  return (
    <div>
      {bonus?.map((b: any, bi: number) => {
        return (
          <div
            className='calculation-info d-flex justify-content-between fw-bolder my-1'
            key={bi}>
            <p>{b?.name}</p>
            <p>
              ${b?.value?.result.toFixed(2)} {currency}
            </p>
          </div>
        );
      })}
      <button className='border-0 rounded-pill py-1 px-3 calculator-button'>
        <Link to='/about-me-at-abi/sti-bonus'> Calculate STI Bonus</Link>
      </button>
      <div className='mx-2 mt-4 px-3 py-2 rounded-3 calc-total'>
        <p className='fs-4 mb-0'>Total Annual</p>
        <p className='fs-2 mb-0'>
          ${total.toFixed(2)} {currency}
        </p>
      </div>
    </div>
  );
};
