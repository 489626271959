import React, { useEffect, useState } from 'react';
import { useGetHomeDefaultContentQuery } from '../../../../../../../services/api/api-socios-maz';
import { DefaultContentCustom } from '../../../../../../Layouts/default-section/default-custom-page';
import {
  GetBodyInnerHTML,
  getDefaultContent,
} from '../../../../../../Layouts/default-section/getDefaultElements';
import BreadCrumb from '../../../../../../UI/BreadCrumb';
import { NavElementsLPC_Maps } from '../../../../../../UI/Buttons-LPC-Maps';
import CarouselView from '../../../../../../UI/Carousel/carousel';
import LoadSpinner from '../../../../../../UI/LoadSpinner/LoadSpinner';
import { ListRestaurants } from './list-restaurants';

export const RestaurantsMapsComponent = () => {
  const dataapi = useGetHomeDefaultContentQuery(
    'lpc/events/maps/restaurants',
  ).data;
  // const nodeId = dataapi?.data[0]?.attributes?.drupal_internal__nid;

  // console.log("NODO ID:   ", nodeId)
  const elem: any = getDefaultContent(dataapi)?.content;

  const locations = getLocations(elem);
  function getLocations(elem: any[]) {
    let e: any[] = [];
    let l: any[] = [];
    elem?.map((x) => {
      if (x?.type == 'location') {
        l.push(x?.content);
      } else {
        e.push(x?.content);
      }
    });

    return l;
  }
  useEffect(() => {}, []);
  if (elem && locations)
    return (
      <RestaurantsMaps cont={elem} locations={locations}></RestaurantsMaps>
    );
  else return <></>;
};

export const RestaurantsMaps = (props: any) => {
  const { cont, locations } = props;
  const crumbs = [
    { path: '/home', label: 'MAZ Partners' },
    { path: '/lpc', label: 'LPC' },
    { label: 'Restaurants' },
  ];

  if (cont)
    return (
      <div className='main-content lpc-bg pb-5'>
        <div className='container-fluid'>
          <div className='col-12'>
            <BreadCrumb {...{ crumbs }} />
            {/* <h1 className='section-title'>{cont[0]?.content?.value}</h1> */}
            {/* <GetBodyInnerHTML content={cont[1]?.content?.value}></GetBodyInnerHTML> */}
            {/* <CarouselView content={cont[0]?.content}></CarouselView> */}
            <NavElementsLPC_Maps actived={1}></NavElementsLPC_Maps>
            <DefaultContentCustom elem={cont}></DefaultContentCustom>
            <ListRestaurants locations={locations}></ListRestaurants>
          </div>
        </div>
      </div>
    );
  else return <LoadSpinner></LoadSpinner>;
};
