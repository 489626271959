import React from 'react';
import '../../../../../scss/custom/_main.scss';
import './printStyles.css';
import { GenMAZData } from './Categories/GenMaz-item/GenMazData';
import { LeaderShipData } from './Categories/LeaderShip-item/LeaderShipData';
import './Filters.scss';
import { apiMainURL } from '../../../../../modules/settings/environment';

let imgs: any = [];

const filters = [
  { name: 'Owner Entrepreneur Attributes', status: false },
  { name: 'Leadership Style', status: false },
  { name: 'Employee Data', status: false },
];
interface IProps {
  onClickAll?: () => void;
  all?: boolean;
  onClick?: (e: any) => void;
  filters?: { name: string; status: boolean }[];

  imgs?: {
    author: string;
    tag: string;
    src: string;
  }[];
}

const Filters = ({ onClickAll, all, onClick, filters }: IProps) => (
  <form className='items_list list-partners'>
    <ul className='items'>
      {/* <li onClick={onClickAll}>
          <input type="checkbox" checked={all} />
          <label htmlFor="all">All</label>
        </li> */}
      {filters?.map((filter: any, i: number) => (
        <li key={i} data-index={i} onClick={onClick}>
          <input id={filter.name} type='checkbox' checked={filter.status} />
          <label htmlFor={filter.name}>{filter.name}</label>
        </li>
      ))}
    </ul>
  </form>
);

export const Cards = (props: any) => {
  const { imgs, printFlag, hasFilters, date } = props;
  return (
    <div>
      {imgs?.map((elem: any, idElem: number) => {
        switch (elem?.tag) {
          case 'Macro Entrepreneur Attributes':
            return (
              <div className='' key={idElem} >
                <GenMAZData {...{ date }} printFlag={true} />
                <LeaderShipData
                  {...{ date }}
                  key={idElem}
                  printFlag={true}></LeaderShipData>
              </div>
            );
            break;
          case 'Leadership Style':
            <div className='' key={idElem}>
              <GenMAZData {...{ date }}  printFlag={true} />
              <LeaderShipData
                {...{ date, hasFilters }}
                key={idElem}
                printFlag={true}></LeaderShipData>
            </div>;
            return;
            break;
          default:
            return (
              <div>
                <h1></h1>
              </div>
            );
            break;
        }
      })}
    </div>
  );
};

type MyProps = {
  // using `interface` is also ok
  imgs: any;
  printFlag: boolean;
  hasFilters?: boolean;
  date?: string;
};
export class TabsCategory extends React.Component<MyProps> {
  state = {
    imgs,
    filters,
    all: true,
  };

  componentDidMount() {
    const { filters, all } = this.state;

    filters.forEach((filter) => {
      filter.status = false;
    });

    filters[0].status = true;
    this.setState({
      filters,
    });
  }
  componentDidUpdate() {}
  setFilter = (e: any) => {
    e.preventDefault();
    const { filters, all } = this.state;

    const { index } = e.currentTarget.dataset;
    filters.forEach((filter) => {
      filter.status = false;
    });

    filters[index].status = !filters[index].status;
    this.setState({
      filters,
    });

    this.updateFilters();
    this.updateImgs();
  };

  setAll = () => {
    const { filters } = this.state;

    filters.forEach((filter) => {
      filter.status = false;
    });

    this.setState({
      all: true,
      filters,
    });
  };

  updateFilters() {
    const allFiltersTrue = filters.every((filter) => filter.status === true);
    const allFiltersFalse = filters.every((filter) => filter.status === false);

    if (allFiltersTrue || allFiltersFalse) {
      this.setAll();
    } else {
      this.setState({
        all: false,
      });
    }
  }

  updateImgs() {
    const { filters, all } = this.state;
    let newImgs: any[] = [];
    let a = 0;
    imgs = this.props.imgs;
    imgs.forEach((img: any, imgKey: number) => {
      filters.forEach((filter, filterKey) => {
        if (img.name == filter.name && filter.status == true) {
          newImgs[a] = img;
          a++;
        }
      });
    });

    this.setState({
      imgs: newImgs,
    });
  }
  render() {
    const { filters, all } = this.state;
    const { imgs, hasFilters, date } = this.props;

    return (
      <div className='row'>
        <div className='col-12'>
          {hasFilters && (
            <Filters
              onClickAll={this.setAll}
              all={all}
              onClick={this.setFilter}
              filters={filters}
            />
          )}
          {all ? (
            <Cards
              {...{ hasFilters, date }}
              key={imgs[0].name}
              imgs={[imgs[0]]}
              print={this.props?.printFlag}
            />
          ) : (
            <Cards
              {...{ date }}
              key={imgs[1].name}
              imgs={this.state.imgs}
              print={this.props?.printFlag}
            />
          )}
        </div>
      </div>
    );
  }
}
export function getArchetypes(archData: any): any[] {
  let archeTypes: any[] = [];
  // name: 'MagicianArray',
  // image: 'https://api-socios-maz-dev.somosmaz.com/sites/default/files/2022-07/Captura%20de%20Pantalla%202022-07-22%20a%20la%28s%29%2014.48.18%20%281%29.png',
  // id: '1'
  archData?.data?.map((arch: any) => {
    let idImage = arch?.relationships?.field_archetype_image?.data.id || '';
    let imageFile = '';
    archData?.included?.map((inc: any) => {
      if (inc?.id == idImage) {
        archeTypes.push({
          name: arch?.attributes?.name || '',
          image: apiMainURL + inc?.attributes?.uri?.url || '',
          id: arch?.attributes?.drupal_internal__tid || '',
        });
      }
    });
  });

  return archeTypes;
}
