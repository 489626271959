import React, { useEffect, useState } from 'react';

import './Calculator.scss';
import { compensationData } from '../../../../../models/calculator.model';
import { store as storeApp } from '../../../../../modules/appState/store';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { Percentages } from './percentages';
import { useGetCalculatorCompensationQuery } from '../../../../../services/api/api-socios-maz';
import { GetBodyInnerHTML } from '../../../../Layouts/default-section/getDefaultElements';

//Parser Library
const FormulaParser = require('hot-formula-parser').Parser;
const parser = new FormulaParser();

const Calculator = (props: any) => {
  const { data, cont, currency } = props;
  const [user, setUser] = useState(storeApp?.getState()?.AppReducer?.userData);
  const [bonus, setBonus] = useState<compensationData[]>([])
  let compensationData: compensationData[] = [];
  const d = useGetCalculatorCompensationQuery('32186830')?.data?.data;

  useEffect(() => {
    setBonus(getBonusData(d, 4000000));

  }, [])



  storeApp.dispatch({
    type: 'USER',
    payload: user,
  });
  const handleOnChange = (event: any) => {
    let val=event?.target?.value;
  
   val==''? setBonus(getBonusData(d, parseInt('0'))): setBonus(getBonusData(d, parseInt(event?.target?.value)));
   
   
  };
  const formik = useFormik({
    initialValues: {
      salary: '',
    },
    onSubmit: values => {
   
    },
  });
  function getBonusData(data: any, salary: any): compensationData[] {

    compensationData = [];
    data?.map((elem: any, index: number) => {
      if (elem?.type == 'REGULAR') {
        parser.setVariable('MONTHLY_SALARY', salary);
        let start: Date = new Date(user?.original_hire_date);
        let actual: Date = new Date();
        let dif = (Math.abs(actual.getTime() - start.getTime()) * 0.00000000038).toFixed(0);
        parser.setVariable('MONTHS_SERVED', dif);
        let keys: string[] = Object.keys(elem?.variables);
        keys?.map((k: string, indexK: number) => {
          // console.log("cContenido de Key ",keys[indexK],":   ",elem?.variables[k]);
          parser.setVariable(keys[indexK], elem?.variables[k]);
        });
        let result: number = parser.parse(elem?.formula);
        compensationData.push({
          name: elem?.name || '',
          description: elem?.help || '',
          value: result
        });
        //  console.log("agregando bono!:   ", compensationData);

      }
    });
    return compensationData;
  
  }
  function handleInput() {
    storeApp.dispatch({
    });
  }

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-6">
          
            <h2 className='section-title'>Concepts</h2>
            {bonus?.map((bn: any, ibn:number) => {
              return (
                <div className='row' key={ibn}>
                
                  <div className='col-12'>
                    <div className='text-justify'>
                      <h5 className='section-title tl'>{bn?.name}</h5>

                      <p> {bn?.description}</p>
                    </div>
                  </div>
                </div>
              )
            })}
         
        </div>
        <div className="col-12 col-md-6">
          <div className='bg-comp-bonus border border-2'>
            <div className='floating-label-wrap py-2 position-relative mb-3'>
              <div className='gap-3 content-time'>
                <i className='bi bi-cash-stack fs-4 ms-1' />
                <p className='fs-4 mb-0'>$</p>
                <form onChange={handleOnChange}>
                  <input
                    type='text'
                    className='floating-label-field bg-transparent border-0 w-100 shadow-none'
                    id='salary'
                    name='salary'
                    placeholder={`4000000 `+currency}
                    onChange={formik.handleChange}
                    value={formik.values.salary}
                  />
                </form>

              </div>
              <label
                htmlFor='field-1'
                className='floating-label position-absolute top-0 px-1'>
                Enter your month base salary
              </label>
            </div>
            <Percentages bonus={bonus} currency={currency}></Percentages>
          </div>
        </div>
      </div>

      {/* {bonus?.map((b: any) => {
        return (
          <div className='calculation-info d-flex justify-content-between fw-bolder border border-top-0 border-end-0 border-start-0 border-bottom-1 my-1'>
            <p>{b?.name}</p>
            <p>${b?.value?.result.toFixed(2)}</p>
          </div>
        )
      })}
      <button className='border-0 rounded-pill py-1 px-3 calculator-button'>
        <Link to='/whats-up-with-me/sti-bonus'> Calculate STI Bonus</Link>
      </button>
      <div className='mx-2 mt-4 px-3 py-2 rounded-3 calc-total'>
        <p className='fs-4 mb-0'>Total Annual</p>
        <p className='fs-2 mb-0'>${total.toFixed(2)}</p>
      </div> */}
    </>
  );
};

export default Calculator;
