import React from 'react';

import { ReactComponent as MaintenanceScreen } from '../../../assets/Maintenance/MaintenanceScreen.svg';

import './Maintenance.scss';

const Maintenance = () => {
  return (
    <div className='main-content'>
      <div className='container-fluid d-flex'>
        <div className='maintenance-container col'>
          <MaintenanceScreen />
          <p className='mt-4 mb-3 maintenance-information-block'>
            The website is under maintenance
          </p>
          <div className='maintenance-description mb-2'>
            <p>We apologize for any inconvenience caused.</p>
            <p>We are improving our website.</p>
          </div>
          <p className='maintenance-coming-soon'>We are coming soon.</p>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
