import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getDomain } from '../../../modules/settings/domain';
import {
  useGetDefaultContentQuery,
  useGetHomeDefaultContentQuery,
} from '../../../services/api/api-socios-maz';
import CarouselView from '../../UI/Carousel/carousel';
import LoadSpinner from '../../UI/LoadSpinner/LoadSpinner';
import Sections from '../../UI/Sections/sections';
import MediaComponent from '../mediaComponent/media-component';

import { CardDefault } from './card-default';
import { GetBodyInnerHTML, getDefaultContent } from './getDefaultElements';

export const Default = (props: any) => {
  const id = props?.id;
  const defaultData = useGetHomeDefaultContentQuery(id)?.data;
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );

  const elem: any = getDefaultContent(defaultData);
  if (defaultData?.data && elem?.content)
    return (
      <div>
        <section className='main-content'>
          <div className='container-fluid'>
            <nav aria-label='breadcrumb'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to='/home'>{domainData.name}</Link>
                </li>
                {elem?.breadcrumb?.map((el: any, i: number) => {
                  if (el?.name != 'Home') {
                    if (i < elem?.breadcrumb?.length) {
                      return (
                        <li className='breadcrumb-item' key={i}>
                          <Link to={el?.path || '/'}> {el?.name || ''}</Link>
                        </li>
                      );
                    }
                  }
                })}
                <li className='breadcrumb-item active'>{elem?.title || ''}</li>
              </ol>
            </nav>
            <h3 className='section-title'>{elem?.title || ''}</h3>
            <div className='row'>
              <div className='col-12'>
                {elem?.content?.map((el: any, index: number) => {
                  if (el?.type == 'links') {
                    return <Sections key={index} content={el?.content} />;
                  }
                  if (el?.type == 'media') {
                    return <MediaComponent key={index} content={el?.content} />;
                  }
                  if (el?.type == 'text') {
                    return (
                      <div className='text-generic-r my-5'>
                        <GetBodyInnerHTML
                          key={index}
                          content={el?.content?.value || ''}
                        />
                      </div>
                    );
                  }
                  if (el?.type == 'banner') {
                    return <CarouselView key={index} content={el?.content} />;
                  }
                  if (el?.type == 'title') {
                    switch (el?.content?.type) {
                      case 'Título 1':
                        return (
                          <h1 key={index} className='section-title'>
                            {el?.content?.value || ''}
                          </h1>
                        );
                        break;
                      case 'Título 2':
                        return (
                          <h2 key={index} className='section-title'>
                            {el?.content?.value || ''}
                          </h2>
                        );
                        break;
                      case 'Título 3':
                        return (
                          <h3 key={index} className='section-title'>
                            {el?.content?.value || ''}
                          </h3>
                        );
                        break;
                      case 'Título 4':
                        return (
                          <h4 key={index} className='section-title'>
                            {el?.content?.value || ''}
                          </h4>
                        );
                        break;
                      case 'Título 5':
                        return (
                          <h5 key={index} className='section-title'>
                            {el?.content?.value || ''}
                          </h5>
                        );
                        break;
                    }
                  }
                  if (el?.type == 'separator') {
                    return (
                      <div key={index} style={{ paddingTop: el?.content?.px }}>
                        <p className='oculto'>{'   separator    '}</p>
                      </div>
                    );
                  }
                  if (el?.type == 'card') {
                    return (
                      <div key={index}>
                        <CardDefault idCard={el?.content.idCard}></CardDefault>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  else return <LoadSpinner></LoadSpinner>;
};
