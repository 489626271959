import React, { useId } from 'react';

import { RestaurantProps } from './Restaurants';
import { GetBodyInnerHTML } from '../../../../Layouts/default-section/getDefaultElements';

const Restaurant = ({
  desc,
  // hasBreakFast,
  reservation,
  title,
  img,
  reserveLabel = 'Reserve Here',
}: RestaurantProps) => {
  const id = useId();

  return (
    <div key={id} className='restaurant'>
      <img src={img} alt={`restaurant-${id}`} />
      <div className='information'>
        <h3>{title}</h3>
        {/* {hasBreakFast && (
          <div className='breakfast-included'>Breakfast Included</div>
        )} */}
        <div className='description'>
          <GetBodyInnerHTML content={desc} />
        </div>
        {reservation && (
          <a href={reservation} target='_blank' rel='noreferrer'>
            {reserveLabel}
          </a>
        )}
      </div>
    </div>
  );
};

export default Restaurant;
