import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { store as storeApp} from "../../../modules/appState/store";

export const NavElementsLPC_Maps = (props: any) => {
  const { t, i18n } = useTranslation('global');

  const indexActived = props?.actived;

  const [activeTab, setActiveTab] = useState('1');
  const [miniatures, setMiniatures] = useState([
    // { link: '/lpc/events/maps/event-halls', name: t("lpc-maps-buttons.event-halls") },
    {link: '/lpc/events/maps/restaurants', name: t("lpc-maps-buttons.restaurants")},
    // { link: '/lpc/events/maps/relaxation', name: t("lpc-maps-buttons.relaxation") },
    // { link: '/lpc/events/maps/sports', name: t("lpc-maps-buttons.sports") },
  ]);
 

  const toggle: any = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  useEffect(() => {
    setMiniatures([
      // { link: '/lpc/events/maps/event-halls', name: t("lpc-maps-buttons.event-halls") },
      {link: '/lpc/events/maps/restaurants', name: t("lpc-maps-buttons.restaurants")},
      // { link: '/lpc/events/maps/relaxation', name: t("lpc-maps-buttons.relaxation") },
   
    ])
  }, [t]);
  return (
    <Nav className='miniatures-center mt-3' tabs>
      {miniatures.map((min: any, index: number) => {
        if (index == indexActived)
          return (
            <NavItem key={index.toString()} className="nav-item circle-item mb-2">
              <Link
                className={" miniatures-tam px-3 buttonCategory"}
                to={min.link}
              >
                <button className='btn  btn-outline-orange btn-outline-orange-hover txt-actived'>{min?.name}</button>
              </Link>
            </NavItem>
          );
        else
          return (
            <NavItem key={index.toString()} className="nav-item circle-item mb-2">
              <Link
                className={" miniatures-tam px-3 buttonCategory"}
                to={min.link}
              >
               <button className='btn btn-outline-orange btn-outline-orange-hover'>{min?.name}</button>
              </Link>
            </NavItem>
          );
      })}
    </Nav>
  )
}
