import React from 'react';
import { Help } from './Percentages';

type TotalValueProps = {
  className?: string;
  label: string;
  billingCode?: string;
  amount: number | string;
  currency: string;
  descriptionTitle: string;
  descriptionMessage: string;
};

const TotalValue = ({
  amount,
  currency,
  descriptionMessage,
  descriptionTitle,
  label,
  className = '',
  billingCode = '$',
}: TotalValueProps) => {
  const actualClassName =
    'mt-4 py-2 rounded-3 position-relative floating-label-wrap ' + className;

  return (
    <div className={actualClassName}>
      <label className='floating-label position-absolute top-0 px-1'>
        {label}
      </label>
      <div className='calc-total'>
        <div className='row'>
          <div className='col-10 p-0 overflow-visible text-break'>
            <span className='calc-total-num'>
              {`${billingCode} ${amount} ${currency}`}
            </span>
          </div>
          <div className='col-2 px-0 my-auto d-flex justify-content-end'>
            <Help
              id='btn-6'
              text={descriptionMessage}
              title={descriptionTitle}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalValue;
