import React from 'react';
import { Carouselhorizontal } from '../../../../UI/Carousel-Horizontal';

type ArchetypeCarouselProps = {
  archetypes: any[];
};

const ArchetypeCarousel = ({ archetypes }: ArchetypeCarouselProps) => {
  return (
    <div className='section-gray-partners mt-5'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 text-center pt-5'>
            <h1>Archetypes</h1>
          </div>
          <div className='col-11 mx-auto py-5 carouselhorizontal position-relative'>
            <Carouselhorizontal items={archetypes}></Carouselhorizontal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArchetypeCarousel;
