import React, { useEffect, useState } from 'react';
import Line from '../../../../../../UI/chartJs/LineChart';
import Bar from '../../../../../../UI/chartJs/barChart';
import DashboardDoughnut from '../../../../../../UI/chartJs/doughnut';
import HorizontalBar from '../../../../../../UI/chartJs/horizontalBar2Chart';
import { postOprMyProfile } from '../../../../../../../services/api/api-socios-maz';
import { store as storeApp } from '../../../../../../../modules/appState/store';
import LoadSpinner from '../../../../../../UI/LoadSpinner/LoadSpinner';
import { useLocation } from 'react-router-dom';
import { Accordion } from '../../../../../../UI/Accordion/Accordion';
import { v4 } from 'uuid';
import ReactTooltip from 'react-tooltip';

type EmployeeDataProps = {
  hasAccordion?: boolean;
  hasData?: (a: boolean) => void;
  date?: string;
};

export const EmployeeData = ({
  hasAccordion = true,
  hasData,
  date,
}: EmployeeDataProps) => {
  const location = useLocation();

  const [user, setUser] = useState(storeApp.getState().AppReducer?.userData);
  const [data, setData] = useState({});
  const [line, setDataLine] = useState<any>(undefined);
  const [employee, setEmployee] = useState<boolean>(true);

  storeApp.subscribe(() => {
    setUser(storeApp?.getState()?.AppReducer?.userData || '');
  });

  useEffect(() => {
    if (user?.drupal_id && user?.drupal_id != '') getDataProfile();
  }, [user, employee, date]);

  const getDataProfile = async () => {
    let idUser2 = '';
    if (
      (location?.pathname == '/partners/partner-profile/detail' ||
        location?.pathname == '/partners/team-profiles/detail') &&
      location?.search?.split('?')[1]
    ) {
      idUser2 = location?.search?.split('?')[1];
    } else {
      idUser2 = user?.drupal_id || '';
    }

    postOprMyProfile(idUser2 || '', { date })
      ?.then((response: any) => (response ? response.text() : null))
      .then((result) => {
        if (result) {
          setEmployee(true);
          setDataLine(getDataLine(JSON.parse(result)?.target_achievement));
          setData(JSON.parse(result));
        } else {
          setEmployee(false);
        }
      })
      .catch((e) => {
        console.log('ERRORRRRR:   ', e);
      });
  };

  useEffect(() => {
    hasData && hasData(employee);
  }, [employee, hasData]);

  if (data)
    if (hasAccordion)
      return (
        <div>
          {employee && (
            <>
              {' '}
              <div className='accordion-partner content-graphics-partner'>
                <Accordion title={'Employee Data'} trigger={() => trigger}>
                  <EmployeeDataRender {...{ data, line, date }} />
                </Accordion>
              </div>
            </>
          )}
        </div>
      );
    else {
      return (
        <div className='accordion-partner content-graphics-partner'>
          <EmployeeDataRender {...{ data, line, date }} />
        </div>
      );
    }
  else {
    return <LoadSpinner />;
  }
};

export const EmployeeDataRender = (props: any) => {
  const { data, line, date } = props;

  const newDate = date?.substring(0, 7) || '';

  const fixDigits = (v: number) => 1 * +v.toFixed(2);

  const dream = fixDigits(+data?.leadership?.data['Dream Big']);
  const develop = fixDigits(+data?.leadership?.data['Develop People']);
  const culture = fixDigits(+data?.leadership?.data['Live our Culture']);

  const hasEng = data?.engagement && data?.engagement !== 'NA';
  const hasMng = data?.manager && data?.manager !== 'NA';

  const uuid = v4();

  if (line)
    return (
      <div className='section-dark-gray border-gold p-4 mb-4'>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='box-profile-executives pb-0'>
              <div className='tw text-bold text-center fs-4 pt-2 pb-3 pb-md-5'>
                360 Leadership Capability Assessment
              </div>
              {data?.leadership?.total ? (
                <div className='row align-content-center'>
                  <div className='col-md-5 text-center'>
                    <span className='fs-4 font-normal text-yellow'>Total</span>
                    <div className='fs-1 fw-bold number-count'>
                      {data?.leadership?.total}
                    </div>
                  </div>
                  <div className='col-md-7'>
                    {data?.leadership?.data['Dream Big'] &&
                      data?.leadership?.data['Develop People'] &&
                      data?.leadership?.data['Live our Culture'] && (
                        <div className='pe-md-3'>
                          Breakdown
                          <HorizontalBar {...{ dream, develop, culture }} />
                        </div>
                      )}
                  </div>
                </div>
              ) : (
                <p className='text-center fw-bolder'>
                  The information for 2023 will be available from March.
                </p>
              )}
            </div>
          </div>
          <div className='col-lg-6 text-center box-border-sm'>
            <div className='row align-items-end'>
              <div className='col-md-5 pe-md-0'>
                <div className='tw text-bold fs-4 pt-2 pb-3 pb-md-5'>
                  Engagement 
                </div>
                <span className='fs-4 font-normal text-yellow'>
                  For {newDate}
                </span>
                <div
                  {...(+data?.engagement === 0 && {
                    'data-tip': true,
                    'data-for': `engagement-${uuid}`,
                  })}
                  className='fs-1 fw-bold number-count'>
                  {hasEng ? data?.engagement : null}
                </div>
                {+data?.engagement === 0 && (
                  <ReactTooltip id={`engagement-${uuid}`} className='ttp-w'>
                    <p className='tw fw-normal text-start'>
                      This result is because you have less than 5 people in your
                      organization
                    </p>
                  </ReactTooltip>
                )}
              </div>
              <div className='col-md-7 ps-md-0'>
                <div className='tw text-bold fs-4 pt-2 pb-3 pb-md-5'>
                  Manager Effectiveness
                </div>
                <span className='fs-4 font-normal text-yellow'>
                  For {newDate}
                </span>
                <div
                  {...(+data?.manager === 0 && {
                    'data-tip': true,
                    'data-for': `manager-${uuid}`,
                  })}
                  className='fs-1 fw-bold number-count'>
                  {hasMng ? data?.manager : null}
                </div>
                {+data?.manager === 0 && (
                  <ReactTooltip id={`manager-${uuid}`} className='ttp-w'>
                    <p className='tw fw-normal text-start'>
                      This result is because you have less than 5 people in your
                      organization
                    </p>
                  </ReactTooltip>
                )}
              </div>
              {!(hasEng && hasMng) && (
                <div className='col-12 mt-3'>
                  <p className='text-center fw-bolder'>
                    The information for 2023 will be available from December
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='section-border-top'>
          <div className='row'>
            <div className='col-lg-6 col-md-12 text-center'>
              <div className='box-profile-executives px-3'>
                <div className='graphic-target-box'>
                  <div className='tw text-bold fs-4 pt-0 pb-3 pb-md-5'>
                    Target Achievement
                  </div>
                  {line?.data?.length !== 0 ? (
                    <Line
                      labels={line?.labels}
                      dataArray={line?.data}
                      max={100}
                    />
                  ) : (
                    <p className='text-center fw-bolder'>
                      The information for 2023 will be available from December
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-12'>
              <div className='row align-items-center'>
                <div className='col-12 text-center mb-3'>
                  <div className='tw text-bold fs-4  pt-3 pb-3'>
                    Diversity & Inclusion
                  </div>
                </div>
                {data?.diversity?.inclusion ? (
                  <>
                    <div className='col-lg-3 col-md-4 col-6'>
                      <div>
                        {data?.diversity?.inclusion ? (
                          <Bar
                            labels={['Inclusive']}
                            value={data?.diversity?.inclusion || 0}
                            custom={{
                              min: 0,
                              max: 4,
                              stepSize: 1,
                              percentage: false,
                            }}></Bar>
                        ) : null}
                      </div>
                    </div>

                    <div className='col-lg-3 col-md-4 col-6'>
                      <div>
                        {data?.diversity?.dyiindex ? (
                          <Bar
                            labels={['D&I Index']}
                            value={data?.diversity?.dyiindex || 0}
                            custom={{
                              min: 0,
                              max: 100,
                              stepSize: 20,
                              percentage: true,
                            }}></Bar>
                        ) : null}
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-4 col-sm-7 mx-auto'>
                      <div className='dashboard-doughnut-box'>
                        <div className='text-yellow mb-1 mt-1'>
                          All Direct Reports
                        </div>
                        <div className='p-2'>
                          {data?.diversity?.female && data?.diversity?.male ? (
                            <DashboardDoughnut
                              labels={['Female', 'Male']}
                              data={[
                                data?.diversity?.female || '',
                                data?.diversity?.male || '',
                              ]}
                            />
                          ) : null}
                        </div>
                        <div className='tw mt-3'>
                          Direct reports brought into the team by current
                          manager
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <p className='text-center fw-bolder'>
                    The information for 2023 will be available from December
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  else return <></>;
};

function getDataLine(ta: any): any {
  let labels: any[] = [];
  let data: any[] = [];
  if (ta)
    Object?.entries(ta).forEach((us: any, indexItem: number) => {
      labels.push(us[0]);
      data.push(us[1]);
    });
  return {
    labels: labels,
    data: data,
  };
}
function getOnlyBarElements(ta: any): any {
  let labels: any[] = [];
  let data: any[] = [];
  if (ta)
    Object?.entries(ta).forEach((us: any, indexItem: number) => {
      labels.push(us[0]);
      data.push(us[1]);
    });
  return {
    label: labels,
    data: data,
  };
}

const trigger = () => {};
