import React from "react";
import ReactDOM from "react-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { setupStore } from "./modules/appState/store";
import { domain_page, getDomain } from './modules/settings/domain';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import global_en from "./modules/translations/en/global.json";
import global_es from "./modules/translations/es/global.json";
import { ToastProvider } from "react-toast-notifications";
import { generateAlert } from './components/UI/alerts-Service/alerts-function';

const store = setupStore();
const handleFavicon = () => {
  const domainCountry = (domain_page(window.location.hostname + ''));
  const appName: any = getElement('appName');
  const domainData = (getDomain(window.location.hostname + ''));

  appName.content = domainData.name || '';
  document.title = domainData.name || 'MAZ PARTNERS';
  const desc = getElement('description');
  const manifest = getElement('manifest').href =`./modules/manifest/${domainCountry.color}/icon.svg`;
  console.log("Ruta del icono manifest:   ",`./modules/manifest/${domainCountry.color}/icon.svg`)
  if (desc && desc.content) {
    getElement('description').content = domainData.description || '';
  }
  getElement('favicon').href = `../public/resources/favicon-link.png`;
  const favicon1: any = getElement('favicon'); // Accessing favicon element
  favicon1.href =`../public/resources/favicon-link.png`;
  const favicon2: any = getElement('favicon'); // Accessing favicon element
  favicon2.href =`../public/resources/favicon-link.png`;
};
i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    },
  },
});

function getElement(id: string): any {
  return document.getElementById(id);
}
ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <ToastProvider>
        <App />
      </ToastProvider>
    </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
