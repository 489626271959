import React, { useEffect, useState } from 'react';
import {
  generateCodeChallenge,
  generateState,
} from '../../../../modules/Auth/auth';
import { domain_page, getDomain } from '../../../../modules/settings/domain';
import {
  appURL,
  client_id,
  ssoURL,
} from '../../../../modules/settings/environment';
import LoadSpinner from '../../../UI/LoadSpinner/LoadSpinner';

import '../Landing.scss';

const LandingUserWithSessionApp = () => {
  const [domain, setDomain] = useState(
    domain_page(window.location.hostname + ''),
  );
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );

  useEffect(() => {
    localStorage.setItem('mobile','1');
    login();
  }, []);
  const login = () => {
    if (!localStorage.getItem('code_verifier')) {
      var codeChallenge = '';
      var state = generateState();

      generateCodeChallenge().then((res) => {
        codeChallenge = res;
        let args = new URLSearchParams({
          response_type: 'code',
          client_id: client_id,
          code_challenge_method: 'S256',
          code_challenge: codeChallenge,
          redirect_uri: `${appURL}/auth/callback`,
          state: state,
          scope: 'openid',
        });

        localStorage.setItem('args', args.toString());
        localStorage.setItem('appURL', window.location.hostname.toString());
        localStorage.setItem(
          'redirectSinSession',
          `${window.location.hostname}/auth/callback`,
        );
        if (!window.localStorage.getItem('lastUrl') && !window.location.href.includes('applogin'))
          window.localStorage.setItem('lastUrl', window.location.href);
        else {
        }
        window.location.href = `${ssoURL}/oauth/authorize?` + args.toString();
      });
    } else {
    }
  };

  return (
    <div className='withOut'>
     <LoadSpinner/>
    </div>
  );
};
export default LandingUserWithSessionApp;
