import React, { useEffect, useState } from 'react';
import { SolveLinks } from '../../Links/solve_links';

export default function SectionsById(props: any) {
  const {data,id}=props;

  return (
    <div className="row col-12">
      {data?.map((el: any, index: number) => (
        <div key={index} className={'section-card my-4 col-sm-6 col-lg-' + 12 / el.column}>
          <div className="card text-white card-item-section">
            <SolveLinks
              url={el.link+'?'+id}
              img={el.image}
              title={el.title}
              description={el.description}
              id={el.id || ''}
            />
          </div>
          {/* <div className={loading ? "mostrar" : "ocultar"}>
            <LoadSpinner />
          </div> */}
        </div>
      ))}
    </div>
  );
}
