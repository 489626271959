import React, { useEffect } from 'react'
import { appURL } from '../../../modules/settings/environment';

export const CallbackPDF = () => {
    useEffect(() => {
        setTimeout(() => {
            window.location.href = `${appURL}/partners/partner-profile`;
        }, 3000);
    }, []);
    return (
        <></>
    )
}
