import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { apiMainURL } from '../../../../modules/settings/environment';
import { getBenefitInfo } from '../../../../services/api/api-socios-maz';
import { GetBodyInnerHTML } from '../../../Layouts/default-section/getDefaultElements';
import BreadCrumb from '../../../UI/BreadCrumb';

import './Benefit.scss';
import LoadSpinner from '../../../UI/LoadSpinner/LoadSpinner';

const Benefit = () => {
  const { benefitId } = useParams();
  const { t } = useTranslation('global');

  const [benefit, setBenefit] = useState({
    name: '',
    title: '',
    img: '',
    subtitle: '',
    description: '',
    steps: [{ idx: 0, body: '' }],
  });

  const crumbs = [
    { path: '/home', label: 'MAZ Partners' },
    { path: '/about-me-at-abi', label: t('about-me-at-abi.title') },
    { label: benefit.name },
  ];

  useEffect(() => {
    const getData = async () => {
      const { data, included } = await getBenefitInfo(
        benefitId || '',
        'field_image, field_contenido_numerado',
      );

      if (data) {
        const title = data[0]?.attributes?.title;
        const htmlValue = data[0]?.attributes?.body?.value;
        const steps = included
          .filter(
            ({ type }: { type: string }) =>
              type === 'paragraph--columnas_numeradas',
          )
          .map((step: any) => ({
            idx: step?.attributes?.field_numero,
            body: step?.attributes?.field_descripcion_sumario?.value,
          }))
          .sort((a: any, b: any) => a.idx - b.idx);

        setBenefit({
          name: title,
          title,
          img: `${apiMainURL}${included[0]?.attributes?.uri?.url}`,
          subtitle: data[0]?.attributes?.field_sub_title,
          description: htmlValue,
          steps,
        });
      }
    };

    getData();
  }, [benefitId]);

  return (
    <div className='main-content'>
      {!benefit.title && <LoadSpinner />}
      <div className='container-fluid'>
        <BreadCrumb {...{ crumbs }} />
        <h1 className='text-center mb-4'>{benefit.title}</h1>
        <div className='mx-auto overflow-hidden benefit-img'>
          <img src={benefit.img} alt={`benefit-${benefit.name}`} />
        </div>
        <h2 className='mb-2'>{benefit.subtitle}</h2>
        <div className='mb-4'>
          <GetBodyInnerHTML content={benefit.description} />
        </div>
        <div className='row row-cols-1 row-cols-md-3 mx-1 mb-4'>
          {benefit.steps.map(({ idx, body }: { idx: number; body: string }) => (
            <div key={`benefit-step-${body}-${idx}`} className='col d-flex'>
              <h2 className='me-3'>{idx}</h2>
              <GetBodyInnerHTML content={body} />
            </div>
          ))}
        </div>
        {/* <div className='d-flex'>
          <button className='mx-auto px-4 py-2 redeem-benefit'>
            Redeem Benefit
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Benefit;
