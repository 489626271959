import React, { memo, useEffect, useReducer } from 'react';
import './tabsitems.scss';
import ContentEvent from './ContentEvent';
import { postEventsCalendar } from '../../../services/api/api-socios-maz';
import {
  TabsItemsCases,
  tabsItemsInitialState,
  tabsItemsReducer,
} from './tabsItemsReducer';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import { Paginator } from '../Paginator';
import { FilterProps } from '../../pages/Culture-squads/Calendar/calendarReducer';
import { getDate } from '../../../tools/getShortMonthDayDate';

type TabsItemsProps = {
  filters?: FilterProps;
  howManyItems?: number;
};

const TabsItems = ({ filters, howManyItems = 6 }: TabsItemsProps) => {
  const [state, dispatch] = useReducer(tabsItemsReducer, tabsItemsInitialState);

  const onChangeTab = (tab: string) => {
    dispatch({
      type: TabsItemsCases.CHANGE_ACTIVE_TAB,
      payload: { activeTab: tab },
    });
  };
  const onPageChange = ({ selected }: { selected: number }) => {
    dispatch({
      type: TabsItemsCases.CHANGE_PAGE,
      payload: { page: selected },
    });
  };

  const { activeTab, pageManager } = state;

  useEffect(() => {
    const getData = async () => {
      dispatch({
        type: TabsItemsCases.SWITCH_LOAD,
        payload: { isLoading: true },
      });

      const eventsData = await postEventsCalendar({
        page: +pageManager.actual,
        limit: howManyItems,
        ...(!filters?.month ? { date: activeTab } : { month: filters.month }),
        ...(filters?.bu && { bu: filters.bu }),
        ...(filters?.cat && { cat: filters.cat }),
      });

      if (eventsData) {
        const { pager, events } = eventsData;

        dispatch({
          type: TabsItemsCases.SET_INITIAL_INFO,
          payload: {
            events:
              events?.map((evt: any) => {
                const startDate = getDate(evt.startDate);
                const endDate = evt.endDate && getDate(evt.endDate, startDate);

                return {
                  id: evt.id,
                  date: `${startDate}${endDate ? ` to ${endDate}` : ''}`,
                  name: evt.title,
                  description: evt.summary,
                  specificData: {
                    modality: {
                      icon: evt.modality.icon,
                      label: evt.modality.label,
                    },
                    location: {
                      icon: '../../../../resources/archetype/icons/icons-location.svg',
                      label: evt.location,
                    },
                    category: {
                      icon: evt.category.icon,
                      label: evt.category.label,
                    },
                  },
                };
              }) || [],
            pager: {
              actual: pager.actual,
              items: pager.items,
              next: pager.next,
              prev: pager.prev,
              total: pager.total,
              totalPages: pager.totalpages,
            },
          },
        });
      }

      dispatch({
        type: TabsItemsCases.SWITCH_LOAD,
        payload: { isLoading: false },
      });
    };

    getData();
  }, [pageManager.actual, activeTab, howManyItems, filters]);

  return (
    <div className='tabs-calendar'>
      {!filters?.month && (
        <ul className='nav nav-tabs mt-4' id='myTab' role='tablist'>
          <li className='nav-item' role='presentation'>
            <button
              className='nav-link active'
              id='month-tab'
              data-bs-toggle='tab'
              type='button'
              role='tab'
              aria-controls='contact'
              aria-selected='false'
              onClick={() => onChangeTab('month')}>
              Month
            </button>
          </li>
          <li className='nav-item' role='presentation'>
            <button
              className='nav-link'
              id='week-tab'
              data-bs-toggle='tab'
              type='button'
              role='tab'
              aria-controls='profile'
              aria-selected='false'
              onClick={() => onChangeTab('week')}>
              Week
            </button>
          </li>
          <li className='nav-item' role='presentation'>
            <button
              className='nav-link'
              id='today-tab'
              data-bs-toggle='tab'
              type='button'
              role='tab'
              aria-controls='home'
              aria-selected='true'
              onClick={() => onChangeTab('day')}>
              Today
            </button>
          </li>
        </ul>
      )}
      <div className='tab-content' id='myTabContent'>
        <div
          className='tab-pane fade show active'
          id='today'
          role='tabpanel'
          aria-labelledby='today-tab'>
          {state.events.length > 0 ? (
            state.events.map((props) => (
              <ContentEvent
                key={`content-event-${props.id}-${props.name}`}
                {...props}
              />
            ))
          ) : (
            <div className='text-center mt-4 mb-5'>
              <h4 className='fw-bold'>There are no events.</h4>
              <p className='text-center fw-semibold'>
                Try using another filters in your search.
              </p>
            </div>
          )}
        </div>
        {+pageManager.totalPages > 0 && (
          <div className='mt-4 tabs-items-pagination'>
            <Paginator
              {...{ onPageChange }}
              pageCount={+pageManager.totalPages + 1}
            />
          </div>
        )}
      </div>
      {state.isLoading && <LoadSpinner />}
    </div>
  );
};
export default memo(TabsItems);
