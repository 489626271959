import React, { useId } from 'react';
import { GetBodyInnerHTML } from '../../../Layouts/default-section/getDefaultElements';

type CommentCardProps = {
  name: string;
  description: string;
};

const CommentCard = ({ name, description }: CommentCardProps) => {
  const id = useId();

  return (
    <div key={`comment-${name}-${id}`} className='col-md-6'>
      <div className='box-comments-partners'>
        <div className='tw mb-3'>
          <GetBodyInnerHTML content={description} />
        </div>
        <span>{name}</span>
      </div>
    </div>
  );
};

export default CommentCard;
