import React from 'react';

import './BigImage.scss';

type BigImageProps = { src: string; className?: string };

const BigImage = ({ src, className = '' }: BigImageProps) => {
  const actualClassName = 'mb-4 overflow-hidden big-img ' + className;

  return (
    <div className={actualClassName}>
      <img {...{ src }} alt={src} />
    </div>
  );
};

export default BigImage;
