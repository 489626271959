import React from 'react';
import { romanBandOrder } from '.';

import UserBarDashboard from '../../../../../../UI/chartJs/LineChart';

type MetricsTargetAchievementProps = {
  title: string;
  metrics?: { band: string; avg: number }[];
};

const MetricsTargetAchievement = ({
  title,
  metrics = [{ band: '', avg: 0 }],
}: MetricsTargetAchievementProps) => {
  const labels = romanBandOrder;
  const values = romanBandOrder.map((v) => {
    const res = metrics?.find(({ band = '' }) => `${band}` === v);

    return res?.avg || 0;
  });

  return (
    <>
      <p className='tw mb-3 text-center'>{title}</p>
      <div className='mx-1 mx-sm-4'>
        <UserBarDashboard
          labels={labels}
          dataArray={values}
          hasYGridLines={true}
          fill={true}
        />
      </div>
    </>
  );
};

export default MetricsTargetAchievement;
