import { connect } from 'react-redux';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { store as storeApp } from '../../../modules/appState/store';
import { apiMainURL } from '../../../modules/settings/environment';
import { domain_page } from '../../../modules/settings/domain';
import {
  deleteSession,
  getUserData,
} from '../../../services/api/api-socios-maz';
import { TranslateOptions } from '../../../modules/translations/translateOptions';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LPCHeaderLogo } from '../../../assets/LPC/HeaderLogo.svg';

import './MenuHeader.scss';

const Menu = ({ list }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isLPC = location.pathname.split('/')[1] === 'lpc';
  const isDark = storeApp.getState().AppReducer.dark;

  const sharp = localStorage.getItem('sharp') || '';

  const [user2, setUser2] = useState(
    storeApp?.getState()?.AppReducer?.image || '',
  );
  const [dark, setDark] = useState(
    storeApp?.getState()?.AppReducer?.dark || false,
  );

  storeApp.subscribe(() => {
    setUser2(storeApp?.getState()?.AppReducer?.image || '');
    setDark(storeApp?.getState()?.AppReducer?.dark || false);
  });

  const [domain, setDomain] = useState(
    domain_page(window.location.hostname + ''),
  );
  const { t, i18n } = useTranslation('global');

  function handleSearch() {}
  function handleDarkMode() {
    storeApp.dispatch({
      type: 'DARK',
    });
  }

  useEffect(() => {
    handleDarkMode();
    getInfoUser();
  }, []);

  const onProfileImgClick = () => {
    navigate(`/profile`);
  };
  const getInfoUser = async () => {
    if (storeApp?.getState()?.AppReducer?.userData?.name == '') {
      await getUserData(`${apiMainURL}/api/authenticated-user`, '')
        .then((data) => {
          let d_id = data?.status?.drupal_id || '';
          localStorage.setItem('drupal_id', d_id);
          storeApp.dispatch({
            type: 'IMAGE',
            payload: data?.profile?.user_image,
          });

          storeApp.dispatch({
            type: 'USER',
            payload: {
              birth_date: data?.profile?.birth_date,
              bu: data?.profile?.bu,
              company_name: data?.profile?.company_name,
              email: data?.profile?.email,
              expat: data?.profile?.expat,
              external: data?.profile?.external,
              gender: data?.profile?.gender,
              name: data?.profile?.first_name,
              original_hire_date: data?.profile?.original_hire_date,
              position_band: data?.profile?.position_band,
              position_name: data?.profile?.position_name,
              global_id: data?.profile?.global_id,
              user_image: data?.profile?.user_image,
              drupal_id: d_id,
              rol: data?.status?.roles,
            },
          });
        })
        .catch((error) => {
          console.log('error', error);
          deleteSession(window.location.href);
        });
    }
  };
  return (
    <>
      <header className='header' id='header'>
        <div className='container-fluid'>
          <div className='row align-items-center justify-content-between'>
            <div className='col text-center'>
              <span className='logo-header'>
                <Link to='/home'>
                  {!isLPC ? (
                    <img
                      src={`../../../../customs/${domain.color}/logo_menu_white.svg`}
                      className='img-fluid'
                      alt='LOGO MAZ'
                    />
                  ) : (
                    <LPCHeaderLogo />
                  )}
                </Link>
              </span>
            </div>
            {/*
            <div className='col-auto search_top'>
              <Link to='/search'>
                {' '}
                <div className='logo search partners-glass' />
              </Link>
            </div>
*/}

            <div className='col-auto d-none d-md-block notification-icon'>
              {/* <div className='icon-notifications'>
                <i className=''>
                  <img
                    src={`../../../../resources/menu_lateral/notification.svg`}
                    width='20'
                  />
                </i>
                <span>{3}</span>
              </div> */}
              <NotificationsComponent />
            </div>
            <div className='col-auto'>
              {user2 && (
                <div className='img-perfil' onClick={onProfileImgClick}>
                  {/* <div className='img-perfil' > */}

                  <img src={user2} alt='' />
                </div>
              )}
            </div>
            <div className='col-auto d-none d-md-block'>
              {!isLPC && (
                <button
                  type='button'
                  className='btn px-0 pt-3'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  title={t('menu.tooltip-message')}>
                  <input
                    id='toggle'
                    className='toggle'
                    type='checkbox'
                    onClick={() => {
                      handleDarkMode();
                    }}
                  />
                </button>
              )}
            </div>
            {/*            <div className="col-auto">
              <TranslateOptions></TranslateOptions>
            </div>*/}
          </div>
        </div>
      </header>
    </>
  );
};

function mapStateToProps(state: any) {
  return { list: state.items };
}
export default Menu;

export const NotificationsComponent = () => {
  return (
    <div>
      <notifications-button-component></notifications-button-component>
    </div>
  );
};
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'notifications-button-component': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}
