import React, { useEffect, useRef, useState } from 'react';

type AudioProps = { src: string };

const Audio = ({ src }: AudioProps) => {
  const [input, setInput] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const audioRef = useRef<HTMLAudioElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const onInputChange = (e: any) => {
    setInput(e.target.value);

    if (audioRef.current?.currentTime)
      audioRef.current.currentTime =
        (e.target.value * audioRef.current.duration) / 100;
  };
  const onControlClick = () => {
    setIsPlaying((prevPlay) => !prevPlay);
  };
  const onPlaying = (e: any) => {
    const currentTime = e.target.currentTime;
    const duration = e.target.duration;

    setInput((currentTime / duration) * 100);
  };

  useEffect(() => {
    if (isPlaying) {
      audioRef.current?.play();
    } else {
      audioRef.current?.pause();
    }
  }, [isPlaying]);

  return (
    <>
      <audio {...{ src }} ref={audioRef} onTimeUpdate={onPlaying} autoPlay />
      <div className='player-container'>
        <div onClick={onControlClick}>
          {!isPlaying ? (
            <i className='bi bi-caret-right' />
          ) : (
            <i className='bi bi-pause' />
          )}
        </div>
        <div className='mx-3 my-auto w-100'>
          <input
            value={input}
            style={{ backgroundSize: `${input}% 100%` }}
            ref={inputRef}
            type='range'
            name='maz-p-audio'
            id='maz-p-audio'
            onChange={onInputChange}
          />
        </div>
      </div>
    </>
  );
};

export default Audio;
