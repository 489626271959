import React, { useEffect } from 'react';
import InfographicStepper from '../InfographicStepper';
import './BinnaclePartnerInfo.scss';
import { GetBodyInnerHTML } from '../../../../Layouts/default-section/getDefaultElements';

const BinnaclePartnerInfo = (props: any) => {
  const { orientationTaxonomy, orientationData, motivacion, miedoa, puntoDebil } = props;
  const stepsData = getsteps(orientationData, orientationTaxonomy);
  function getsteps(orientationData: any[], orientationTaxonomy: any[]) {
    let stepsArray: any[] = [];
    orientationTaxonomy?.map((ot: any) => {
      let existOrientation = false;
      orientationData?.map((od: any) => {
        if (ot?.id == od.id) {
          existOrientation = true
          return true;
        }
      });
      stepsArray.push({
        label: ot?.attributes?.name || '',
        isActive: existOrientation
      })
    });
    return stepsArray;
  }
  useEffect(() => {

  }, [orientationTaxonomy, orientationData, motivacion, miedoa, puntoDebil, stepsData]);
  return (
    <div className='row mt-3 ps-2 py-3 p-md-4 binnacle-partner-info'>
      <div className='col-md-4 tw pe-md-4'>
        <h2>Orientación de la motivación</h2>
      </div>
      <div className='col-md-8'>
        <InfographicStepper steps={stepsData} />
      </div>
      <div className='d-flex flex-md-column col-md-4 mt-2 mt-md-4 binnacle-partner-feeling'>
        <h2 className='text-md-center'>Motivación</h2>
        {motivacion && (
          <GetBodyInnerHTML content={motivacion} />
        )}
      </div>
      <div className='d-flex flex-md-column col-md-4 mt-2 mt-md-4 binnacle-partner-feeling'>
        <h2 className='text-md-center'>Miedo a</h2>
        {miedoa && (
          <GetBodyInnerHTML content={miedoa} />
        )}
      </div>
      <div className='d-flex flex-md-column col-md-4 mt-2 mt-md-4 binnacle-partner-feeling'>
        <h2 className='text-md-center'>Punto Débil</h2>
        {puntoDebil && (
          <GetBodyInnerHTML content={puntoDebil} />
        )}

      </div>
    </div>
  );
};

export default BinnaclePartnerInfo;
