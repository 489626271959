import React from 'react';

import { ReactComponent as Liked } from '../../../assets/general/Like1.svg';
import { ReactComponent as Like } from '../../../assets/general/Like2.svg';

type HeartLikeProps = {
  isLiked: boolean;
  onClick?: () => void;
  hasLikesCounter?: boolean;
  howManyLikes?: number;
};

const HeartLike = ({
  isLiked = false,
  onClick,
  hasLikesCounter = false,
  howManyLikes = 0,
}: HeartLikeProps) => {
  const onLikeClick = () => {
    onClick && onClick();
  };

  return (
    <div className='d-flex flex-column heart-like'>
      <div className='mx-auto' onClick={onLikeClick}>
        {isLiked ? <Like /> : <Liked />}
      </div>
      {hasLikesCounter && <h5 className='text-center'>{howManyLikes} likes</h5>}
    </div>
  );
};

export default HeartLike;
