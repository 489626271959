import React from 'react';

import { ReactComponent as Recommendations } from '../../../../../assets/LPC/Destiny/Recommendations.svg';
import { GetBodyInnerHTML } from '../../../../Layouts/default-section/getDefaultElements';

import './GeneralRecommendations.scss';

interface GeneralRecommendationsProps {
  desc: string;
  secondDesc?: string;
}

const GeneralRecommendations = ({
  desc,
  secondDesc,
}: GeneralRecommendationsProps) => {
  return (
    <div className='general-recommendation'>
      <div className='container-fluid'>
        <Recommendations />
        <div className='recommendations'>
          <h3>General Recommendations</h3>
          <div className='rec-row'>
            <div>
              <GetBodyInnerHTML content={desc} />
            </div>
            {secondDesc && (
              <div>
                <GetBodyInnerHTML content={secondDesc} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralRecommendations;
