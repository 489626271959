import React from 'react';
import { Doughnut } from 'react-chartjs-3';

type UserDashboardProps = {
  labels: string[];
  data: number[];
  backgroundColor?: string[];
  hoverBackgroundColor?: string[];
  hasLineLabel?: boolean;
};

export default function UserDashboard({
  labels,
  data,
  backgroundColor = ['#BEBEBE', '#F5E003'],
  hoverBackgroundColor,
  hasLineLabel = false,
}: UserDashboardProps) {
  const DoughData = {
    // labels: ["Female", "Male"],
    labels,
    datasets: [
      {
        // data: [20, 80],
        data,
        backgroundColor,
        hoverBackgroundColor: hoverBackgroundColor || backgroundColor,
        borderWidth: 0,
      },
    ],
  };

  const options = {
    legend: {
      display: !hasLineLabel,
      position: 'right',
      labels: {
        fontColor: '#FFF',
        fontSize: 14,
        usePointStyle: true,
        pointStyle: 'circle',
        padding: 15,
      },
    },
    layout: {
      ...(hasLineLabel && { padding: 15 }),
    },
  };

  const doughnutLabelsLine = {
    id: 'doughnutLabelsLine',
    afterDraw: (chart: any) => {
      const { ctx, width, height } = chart;

      for (const [idds, dataset] of chart.data.datasets.entries())
        for (const [iddp, datapoint] of chart
          .getDatasetMeta(idds)
          .data.entries()) {
          const { x, y } = datapoint.tooltipPosition();
          const w = '#fff';

          const halfWidth = width / 2;
          const halfHeight = height / 2;

          const xLine = x >= halfWidth ? x + 5 : x - 5;
          const yLine = y >= halfHeight ? y + 15 : y - 15;

          ctx.beginPath();
          ctx.moveTo(x, y);
          ctx.lineTo(x, yLine);
          ctx.lineTo(xLine, yLine);
          ctx.strokeStyle = w;
          ctx.stroke();

          const textByPos = chart.data.labels[iddp];
          const percentByPos = dataset.data[iddp];

          const textXPosition = x >= halfWidth ? 'left' : 'right';
          const wsByPos = x >= halfWidth ? 5 : -5;

          ctx.textAlign = textXPosition;
          ctx.font = '12px';
          ctx.textBaseline = 'middle';
          ctx.fillStyle = w;
          ctx.fillText(textByPos, xLine + wsByPos, yLine - 5);

          ctx.fillStyle = '#ebda0f';
          ctx.font = 'bold 12px';
          ctx.fillText(`${percentByPos}%`, xLine + wsByPos, yLine + 7);
        }
    },
  };

  const activePlugins = [...(hasLineLabel ? [doughnutLabelsLine] : [])];

  return (
    <div style={{ aspectRatio: '500/110' }} id="canvas_dona">
      <Doughnut data={DoughData} options={options} plugins={activePlugins} />
    </div>
  );
}
