import React from 'react';

type PartnersLevelMetricsProps = {
  metrics?: [{ level: number; avg: 0 }];
};

const PartnersLevelMetrics = ({
  metrics = [{ level: 0, avg: 0 }],
}: PartnersLevelMetricsProps) => {
  const levels = [...metrics]
    .sort((a: { level: number }, b: { level: number }) => a.level - b.level)
    .map(({ level = '', avg = 0 }) => ({
      label: `L${level}`,
      value: avg,
    }));

  levels.sort(({ value: vA }, { value: vB }) => vB - vA);

  const lastPercent = (levels[2]?.value || 0) + (levels[3]?.value || 0);
  const actLevels = levels.map(({ label, value }) => ({
    label,
    value: `${value}%`,
  }));
  const noneP = '0%';
  const lastFirstHeight = (100 * (levels[2]?.value || 0)) / lastPercent || 0;
  const lastHeight = 100 - lastFirstHeight;

  return (
    <>
      <p className='tw mb-3 text-center'>Partners By Level</p>
      <div className='levels-metrics'>
        <div
          className='levels l3'
          style={{ width: actLevels[0]?.value || '100%' }}>
          {actLevels[0]?.label || 'L'}
          <span>{actLevels[0]?.value || noneP}</span>
        </div>
        <div className='levels l2' style={{ width: actLevels[1]?.value }}>
          {actLevels[1]?.label || 'L'}
          <span>{actLevels[1]?.value || noneP}</span>
        </div>
        {lastFirstHeight !== 0 && (
          <div className='levels p-0'>
            <div className='levels-2'>
              <div
                className='levels l4'
                style={{ height: `${lastFirstHeight}%` }}>
                <div className='p-3'></div>
                <div className='text'>
                  {actLevels[2]?.label || 'L'}
                  <span>{actLevels[2]?.value || noneP}</span>
                </div>
              </div>
              {lastHeight !== 0 && (
                <div className='levels l1' style={{ height: `${lastHeight}%` }}>
                  <div className='p-3'></div>
                  <div className='text'>
                    {actLevels[3]?.label || 'L'}
                    <span>{actLevels[3]?.value || noneP}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PartnersLevelMetrics;
