import React, { useEffect, useState } from 'react'
import { Accordion } from './Accordion'

/**
 * bootstrap css added to public/index.html header
 * Repo: https://github.com/awran5/react-bootstrap-accordion
 */

export default function AccordionItem(props: any) {
    const { items, title="Learn about the different leadership styles" } = props;
    const [data, setData] = useState<object[]>([]);
    const trigger=()=> {
      
    }
    useEffect(() => {
      let itemsData:object[]=[];
        Object?.entries(items).map((i: any, indexItem: number) => {
          
            itemsData.push({
                name: i[0] + '',
                text:  i[1]
            });
        });
        setData(itemsData);
    }, [items])
    return (
        <div className="App">
            <Accordion  {...{title}} trigger={()=>trigger}>
                <div className="row">
                    {data?.map((item: any, indexItem: number) => {
                        return (
                            <div className="col-md-5 mx-auto text-center text-md-start" key={indexItem}>
                                <p className='text-gold fs-5 text-bold text-center text-md-start mb-0'> {item?.name}</p>
                                <div className="mb-3">
                                   <p className="text-center text-md-start"> {item?.text}</p>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </Accordion>
        </div>
    )
}
