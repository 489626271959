import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useGetCalculatorCompensationQuery,
  useGetCalculatorSTIQuery,
  useGetHomeDefaultContentQuery,
} from '../../../../services/api/api-socios-maz';
import {
  GetBodyInnerHTML,
  getDefaultContent,
} from '../../../Layouts/default-section/getDefaultElements';
import CarouselView from '../../../UI/Carousel/carousel';
import LoadSpinner from '../../../UI/LoadSpinner/LoadSpinner';
import Sections from '../../../UI/Sections/sections';
import Calculator from './Calculator';
import { appBenefiURL } from '../../../../modules/settings/environment';
import { store as storeApp } from '../../../../modules/appState/store';
import { setFlagsFromString } from 'v8';
import { compensationData } from '../../../../models/calculator.model';
import BreadCrumb from '../../../UI/BreadCrumb';
const FormulaParser = require('hot-formula-parser').Parser;
const parser = new FormulaParser();

export const CompensationSimulatorComponent = () => {
  const [user, setUser] = useState(storeApp?.getState()?.AppReducer?.userData);
  storeApp.subscribe(() => {
    setUser(storeApp?.getState()?.AppReducer?.userData);
  });

  let compensationData: compensationData[] = [];
  const d = useGetCalculatorCompensationQuery('32186830')?.data?.data;
  const dataapi = useGetHomeDefaultContentQuery(
    'about-me-at-abi/compensation-simulator',
  ).data;
  const elem: any = getDefaultContent(dataapi)?.content;

  let dollarUSLocale = Intl.NumberFormat('en-US');
  // TODO: const sharp=(localStorage.getItem('sharp')||'').toString();

  // const nodeId = dataapi?.data[0]?.attributes?.drupal_internal__nid;
  // console.log("NODO ID:   ", nodeId)

  const sharp = 32186830;
  function queryCompensation() {
    var myHeaders = new Headers();
    myHeaders.append('Accept-Language', 'es');
    myHeaders.append('Content-Type', 'application/json');

    var requestOptions: RequestInit = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch(appBenefiURL + '/api/compensation/ext?sharp=' + sharp, requestOptions)
      .then((response) => response.text())
      .then((result) => {})
      .catch((error) => console.log('error', error));
  }

  if (elem && d)
    return (
      <CompensationSimulator
        cont={elem}
        data={d}
        user={user}></CompensationSimulator>
    );
  else return <LoadSpinner></LoadSpinner>;
};

export const CompensationSimulator = (props: any) => {
  const { t } = useTranslation('global');
  const { cont } = props;

  const currency = useGetCalculatorSTIQuery(
    // TODO JSON.stringify({
    //   band: props?.user?.position_band
    //   country: props?.user?.bu?.toString()?.toLowerCase()
    // }),
    JSON.stringify({
      // band: user?.position_band,
      band: 'II-A',
      country: props?.user?.bu?.toString().toLowerCase(),
    }),
  )?.data?.data?.currency;
  const crumbs = [
    { path: '/home', label: 'MAZ Partners' },
    { path: '/about-me-at-abi', label: t('about-me-at-abi.title') },
    { label: t('about-me-at-abi.compensation-bonus.title') },
  ];

  useEffect(() => {}, []);
  if (currency)
    return (
      <div>
        <div className='main-content'>
          <div className='container-fluid'>
            <div className='col-12'>
              <BreadCrumb {...{ crumbs }} />
              <h1 className='section-title'>{cont[0]?.content?.value}</h1>
              <GetBodyInnerHTML
                content={cont[1]?.content?.value}></GetBodyInnerHTML>
              <Calculator currency={currency} />
              <h3 className='section-title'>{cont[2]?.content?.value}</h3>
              <CarouselView content={cont[3]?.content} />
            </div>
          </div>
        </div>
      </div>
    );
  else return <></>;
};
