import React, { useState } from 'react'
import BreadCrumb from "../../../../../UI/BreadCrumb";
import { Accordion } from "../../../../../UI/Accordion/Accordion";
import { Input } from "reactstrap";
import ReactTooltip from "react-tooltip";
import { GetBodyInnerHTML } from "../../../../../Layouts/default-section/getDefaultElements";
import { RangeValue } from "../../../../../UI/Range-value";
import { Link } from "react-router-dom";
import { useGetallArchetypesQuery } from '../../../../../../services/api/api-socios-maz';
import { getArchetypes } from '../../../Partners-profile/Partner-profile';
import LoadSpinner from '../../../../../UI/LoadSpinner/LoadSpinner';

export const ArchetypesDefinition = () => {
    const crumbs = [
        { path: '/home', label: 'MAZ Partners' },
        { path: '/partners/partner-profile', label: 'Partners' },
        { label: 'Archetypes definition' },
    ];
    const archetypesListData = useGetallArchetypesQuery()?.data;
    const archetypesList = getArchetypes(archetypesListData);

    if (archetypesList)
        return (
            <div className='main-content main-profile-executives' id="top">
                <div className="container-fluid" >
                    <div className="row">
                        <div className="col-12">
                            <BreadCrumb {...{ crumbs }} />
                        </div>
                        <div className="col-12 mt-4">
                            <div className="section-title text-bold fs-4 mb-4">
                                Archetypes definition
                            </div>
                            <div className="text-content text-center my-4">
                                In this section you will find descriptions of all 5 archetypes that represent us and some recommendations for your development.<br />
                                Use this information to create your PDP.
                            </div>

                            <div className="section-gray-v2 py-5 px-3 p-md-5 mb-4">
                                <div className="row">
                                    {
                                        archetypesList?.map((arch: any, indexArch: number) => {
                                            return (
                                                <div className="col-lg-4 col-sm-6 mb-4" key={indexArch}>
                                                    <Link to={'/partners/archetypes-definition/archetype-detail?' + (arch?.id || '')}  className="text-decoration-none">
                                                        <div className="archetypes-card mx-4">
                                                            <div className="card">
                                                                <img className="card-img-top card-img-archetypes" src={arch?.image} alt="Card image cap" />
                                                                <div className="card-body  archetype-body">
                                                                    <p className="card-text tc">{arch?.name}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>

                                            )
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    else return <LoadSpinner />
}


