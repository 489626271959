import React, { useEffect, useReducer, useState } from 'react';
import { store } from '../../../../../modules/appState/store';
import { apiMainURL } from '../../../../../modules/settings/environment';
import { generateAlert } from '../../../../UI/alerts-Service/alerts-function';
import { initialState, reducer } from './calculatorReducer';
import { Help } from './Percentages';
import TotalValue from './TotalValue';

export const FullCash = () => {
  const dollarUSLocale = Intl.NumberFormat('de-DE');
  const storeApp = store;
  const [bonus, setBonus] = useState({
    bonus: storeApp.getState().AppReducer?.bonus?.bonus,
    currency: storeApp.getState().AppReducer?.bonus?.currency,
  });
  const [values, setValues] = useState({
    total_anual_value: '0',
  });
  function queryBonus() {
    var myHeaders = new Headers();
    myHeaders.append(
      'Authorization',
      `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
    );
    myHeaders.append('Content-Type', 'application/json');

    var raw = JSON.stringify({
      type: 1,
      gross_bonus: dollarUSLocale.format(bonus?.bonus),
    });

    var requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(`${apiMainURL}/api/get-bonus-election`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let b = JSON.parse(result);

        setBonus({
          bonus: b?.total_anual_value,
          currency: bonus?.currency,
        });
      })
      .catch((error) => {
        console.log('error', error);
        generateAlert(
          'error',
          'Error GetBonus.',
          'FullCash get-bonus-election',
        );
      });
  }
  // storeApp.subscribe(() => {

  //   setBonus({
  //     bonus: storeApp.getState().AppReducer?.bonus?.bonus,
  //     currency: storeApp.getState().AppReducer?.bonus?.currency
  //   });
  //   queryBonus();

  // });
  useEffect(() => {
    if (bonus?.bonus) {
      queryBonus();
    }
  }, []);

  return (
    <TotalValue
      label='Total Annual Value (Gross)'
      amount={Intl.NumberFormat()
        .format(Math.floor(bonus?.bonus))
        .replace(',', '.')}
      currency={bonus?.currency}
      descriptionMessage='Total Annual Value (Gross)FullCash description pendient.'
      descriptionTitle='Total Annual Value (Gross)'
    />
  );
};
