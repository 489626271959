import React, { useEffect, useState } from 'react';

import HeartLike from '../../../UI/HeartLike';
import { GetBodyInnerHTML } from '../../default-section/getDefaultElements';

import './ContentCard.scss';

type ContentCardProps = {
  img?: string;
  category?: string;
  title?: string;
  content?: string;
  onImageClick?: () => void;
  onTitleClick?: () => void;
  hasLike?: boolean;
  likeValue?: boolean;
  hasHtmlDescription?: boolean;
  onLikeEvent?: (like: boolean) => void;
};

const ContentCard = ({
  img,
  category,
  title,
  content,
  onImageClick,
  hasLike = true,
  likeValue = false,
  hasHtmlDescription = false,
  onLikeEvent,
  onTitleClick,
}: ContentCardProps) => {
  const [isLikeClicked, setIsLikeClicked] = useState(false);

  const onLikeClick = () => {
    setIsLikeClicked((prevLike) => {
      onLikeEvent && onLikeEvent(!prevLike);

      return !prevLike;
    });
  };

  useEffect(() => {
    setIsLikeClicked(likeValue);
  }, [likeValue]);

  return (
    <div className='card content-card border'>
      <div className='overflow-hidden text-center content-img'>
        <img
          src={img}
          className='card-img-top'
          alt={`presentation-${category}-${title}`}
          onClick={onImageClick && onImageClick}
        />
      </div>
      <div className='card-body'>
        <div className='d-flex justify-content-between mb-1'>
          <h5 className='mt-auto mb-1 category'>{category}</h5>
          {hasLike && (
            <HeartLike isLiked={isLikeClicked} onClick={onLikeClick} />
          )}
        </div>
        <h5 className='card-title' onClick={onTitleClick}>
          {title}
        </h5>
        {hasHtmlDescription ? (
          <div className='card-desc'>
            <GetBodyInnerHTML content={content} />
          </div>
        ) : (
          <p className='card-desc'>{content}</p>
        )}
      </div>
    </div>
  );
};

export default ContentCard;
