import React from 'react'
import { Default } from './default';
import { useLocation } from 'react-router-dom';

export const PaginaGenerica = () => {
  const location = useLocation();
  let params = location?.search?.split('?')[1];

    const id= 'contenido?'+params;
  return (
    <Default id={id}/>
  )
}
