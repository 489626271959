import React, { useState } from 'react';
import { Carousel, CarouselIndicators } from 'reactstrap';
import Item from './Item';
import { CarouselHeaderProps } from './CarouselHeader';

import './CarouselHeader.scss';

const CarouselHeader = ({ items }: CarouselHeaderProps) => {
  const [index, setIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () =>
    !animating && setIndex(index === items.length - 1 ? 0 : index + 1);
  const previous = () =>
    !animating && setIndex(index === 0 ? items.length - 1 : index - 1);
  const goToIndex = (newIndex: any) => !animating && setIndex(newIndex);

  const slides = items.map((props) => <Item {...props} />);

  return (
    <div>
      <Carousel
        activeIndex={index}
        next={next}
        previous={previous}
        interval='50000'
        key={Math.random()}
        ride='carousel'
        className='destination-header'>
        <CarouselIndicators
          items={items}
          activeIndex={index}
          onClickHandler={goToIndex}
          key={Math.random()}
        />
        {slides}
      </Carousel>
    </div>
  );
};

export default CarouselHeader;
