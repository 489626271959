import {
  LoyaltyAction,
  LoyaltyPayload,
  LoyaltyState,
  StepperCard,
  Category,
  Banner,
} from '../../../../models/loyalty.models';
import { Section as SectionModel } from '../../../../models/section.models';

const initialStepperCards: StepperCard[] = [
  { title: '', description: '', icon: '' },
];
export const initialSections: SectionModel[] = [
  {
    title: '',
    hasShowButton: false,
    cardsInfo: [{ category: '', content: '', id: '', img: '', title: '' }],
  },
];
export const initialCategories: Category[] = [{ id: '', nid: 0, title: '' }];
export const initialBanner: Banner = { alt: '', img: '', outerURL: '' };
export const initialState: LoyaltyState = {
  title: '',
  subtitle: '',
  description: '',
  loyaltyImgs: [''],
  loyaltyStepperCards: initialStepperCards,
  banner: initialBanner,
  categories: initialCategories,
  sections: [],
  activeSection: null,
  isLoading: true,
  bu: '',
};
const emptyPayload: LoyaltyPayload = {
  title: '',
  subtitle: '',
  description: '',
  loyaltyImgs: [''],
  loyaltyStepperCards: initialStepperCards,
  banner: initialBanner,
  categories: initialCategories,
  sections: initialSections,
  activeSection: null,
  isLoading: true,
  bu: '',
};

export const loyaltyReducer = (
  state: LoyaltyState,
  action: LoyaltyAction,
): LoyaltyState => {
  const { payload, type } = action;

  switch (type) {
    case 'INIT_BASE_PAGE': {
      const {
        title,
        subtitle,
        description,
        loyaltyImgs,
        loyaltyStepperCards,
        categories,
        sections,
        banner,
        isLoading,
      } = payload || emptyPayload;

      for (const section of sections || initialSections)
        section.hasShowButton = section.cardsInfo?.length === 4 || false;

      return {
        ...state,
        title: title || 'Title',
        subtitle: subtitle || 'Subtitle',
        description: description || 'No description.',
        loyaltyImgs: loyaltyImgs || ['No images for carrousel'],
        loyaltyStepperCards: loyaltyStepperCards || initialStepperCards,
        categories: categories || initialCategories,
        sections: sections || [],
        banner: banner || initialBanner,
        isLoading: isLoading || false,
      };
    }

    case 'SET_SECTIONS': {
      const { sections } = payload || emptyPayload;

      for (const section of sections || initialSections)
        section.hasShowButton = section.cardsInfo?.length === 4 || false;

      return {
        ...state,
        sections: sections ?? initialSections,
        isLoading: false,
      };
    }

    case 'SET_CATEGORIES': {
      const { categories } = payload || emptyPayload;

      return {
        ...state,
        categories: categories ?? initialCategories,
        isLoading: false,
      };
    }

    case 'CHANGE_ACTUAL_SECTION': {
      const { activeSection } = payload || initialState;

      return { ...state, activeSection: activeSection ?? null };
    }

    case 'SET_LOAD': {
      const { isLoading } = payload || emptyPayload;

      return { ...state, isLoading: isLoading || true };
    }

    case 'ON_BU_LOAD': {
      const { bu } = payload || emptyPayload;

      return { ...state, bu: bu || '' };
    }

    default: {
      return initialState;
    }
  }
};
