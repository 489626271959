import React from 'react';

import { useNavigate } from 'react-router';
import { GetBodyInnerHTML } from '../../../Layouts/default-section/getDefaultElements';
import { ReactComponent as Dates } from '../../../../assets/LPC/GetReady/Dates.svg';
import { ReactComponent as Hotel } from '../../../../assets/LPC/GetReady/Hotel.svg';
import { ReactComponent as Letter } from '../../../../assets/LPC/GetReady/Immigration Letter.svg';
import { ReactComponent as Wellness } from '../../../../assets/LPC/GetReady/Wellness Activities.svg';

interface EventInfoProps {
  hotelPDF?: string;
  ticketPDF?: string;
  invPDF?: string;
  content: {
    description?: string;
    label?: string;
    title?: string;
    src?: string;
    link?: string;
  }[];
}

const EventInfo = ({
  hotelPDF = '',
  ticketPDF = '',
  invPDF = '',
  content,
}: EventInfoProps) => {
  const navigate = useNavigate();
  const dates = content.find(
    ({ title = '' }) => title === 'Dates',
  )?.description;
  const ticket = content.find(({ title = '' }) => title === 'Ticket');
  const hotel = content.find(({ title = '' }) => title === 'Hotel');
  const inv = content.find(({ title = '' }) => title === 'Invitation');
  const wellness = content.find(({ title = '' }) => title === 'Wellness');
  const ticketLabel = ticket?.label || 'Your Ticket';
  const hotelLabel = hotel?.label || 'Your Reservation';
  const invLabel = inv?.label || 'Download you Invitation';
  const wellnessLabel = wellness?.label || 'Your Reservation';
  const ticketMessage = ticketPDF
    ? 'Download your ticket to'
    : 'Download your ticket soon';
  const hotelMessage = hotelPDF ? 'Download your hotel reservation' : 'Soon';
  const invMessage = invPDF
    ? 'Download the immigration letter'
    : "In your case you don't need";
  const wellnessMessage = wellness?.link
    ? 'Register for'
    : 'Register here soon';
  const wellnessPDF = wellness?.link;

  const downloadDocument = (url: string) => {
    if (localStorage.getItem('mobile') === '1')
      navigate(`/download-pdf-link?${url}`);
    else window.open(url);
  };
  const openURL = (url: string) => {
    console.log('URL FORMS:  ', `https:${url}`);
    if (localStorage.getItem('mobile') === '1')
      navigate(`/forms-link-lpc?https://${url}`);
    else window.open(url, '_blank');
  };

  const largerText =
    'text-center h-100 d-flex flex-column destination-lpc-tickets';
  let hotelDesc = largerText;
  let ticketDesc = largerText;
  let invDesc = largerText;
  let wellnessDesc = largerText;

  if (ticket?.description && ticket?.description?.length > 25) {
    ticketDesc = largerText + ' larger-text';
  }
  if (hotel?.description && hotel?.description?.length > 20) {
    hotelDesc = largerText + ' larger-text';
  }
  if (inv?.description && inv?.description?.length > 20) {
    invDesc = largerText + ' larger-text';
  }
  if (wellness?.description && wellness?.description?.length > 20) {
    wellnessDesc = largerText + ' larger-text';
  }

  return (
    <div className='container-fluid event-info-container'>
      <div className='row gap-3 gap-md-0'>
        <div className='col col-12 col-md-6 col-lg-4 text-center d-lg-flex flex-column justify-content-center event-b-bottom'>
          <div className='row'>
            <div className='col col-4 px-md-0 event-info-icons'>
              <Dates />
            </div>
            <div className='col col-8 px-md-0 d-flex flex-column flex-md-row justify-content-center align-items-center'>
              <div className='text-center event-lpc-dates'>
                <GetBodyInnerHTML content={dates} />
              </div>
            </div>
          </div>
        </div>
        <div className='col col-12 col-md-6 col-lg-4 text-center d-lg-flex align-content-center pb-3 pb-md-0 x-separator event-b-bottom'>
          <div className='row'>
            <div className='col col-4 event-info-icons'>
              <img src={ticket?.src} alt='Event place (city).' />
            </div>
            <div className='col col-8 d-md-flex'>
              <div className='text-content'>
                <div className={ticketDesc}>
                  <span>{ticketMessage}</span>
                  <GetBodyInnerHTML content={ticket?.description} />
                  <button
                    onClick={() => downloadDocument(ticketPDF)}
                    disabled={!ticketPDF}>
                    <i className='bi bi-download' /> {ticketLabel}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col col-12 col-md-6 col-lg-4 text-center d-lg-flex align-content-center'>
          <div className='row'>
            <div className='col col-4 px-md-0 event-info-icons'>
              <Wellness />
            </div>
            <div className='col col-8 px-md-0 d-md-flex'>
              <div className='text-content'>
                <div className={wellnessDesc}>
                  <span>{wellnessMessage}</span>
                  <GetBodyInnerHTML content={wellness?.description} />
                  <button
                    onClick={() => wellnessPDF && openURL(wellnessPDF)}
                    disabled>
                    {wellnessLabel}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='event-separator' />
      <div className='row justify-content-center gap-3 gap-md-0 invitation-section'>
        <div className='col-12 col-md-6 col-lg-5 border-start-0 pb-3 pb-md-0 x-separator event-b-bottom'>
          <div className='row h-100'>
            <div className='col col-4 event-info-icons'>
              <Letter />
            </div>
            <div className='col col-8 d-md-flex'>
              <div className='text-content'>
                <div className={invDesc}>
                  <span>{invMessage}</span>
                  <GetBodyInnerHTML content={inv?.description} />
                  <button
                    onClick={() => downloadDocument(invPDF)}
                    disabled={!invPDF}>
                    <i className='bi bi-download' /> {invLabel}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-12 col-md-6 col-lg-5 d-flex'>
          <div className='row'>
            <div className='col col-4 px-md-0 event-info-icons'>
              <Hotel />
            </div>
            <div className='col col-8 px-md-0 d-md-flex'>
              <div className='text-content'>
                <div className={hotelDesc}>
                  <span>{hotelMessage}</span>
                  <GetBodyInnerHTML content={hotel?.description} />
                  {hotelPDF && (
                    <button
                      onClick={() => downloadDocument(hotelPDF)}
                      disabled={!hotelPDF}>
                      <i className='bi bi-download' /> {hotelLabel}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventInfo;
