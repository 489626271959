import React from 'react';

import SingleLineGraph from '../../../../../../UI/Graphics/SingleLineGraph';

type EngProps = { band: string; total: number; avg: number }[];

type MetricsEngagementProps = {
  metrics?: { eng: EngProps; dyi: EngProps; mrg: EngProps };
};

const engProps = [{ band: '', total: 0, avg: 0 }];

const MetricsEngagement = ({
  metrics = { eng: engProps, dyi: engProps, mrg: engProps },
}: MetricsEngagementProps) => {
  const labels = metrics.eng.map(({ band = '' }) => band);

  const data = [
    {
      label: 'Eng',
      color: '#f5e003',
      value: metrics.eng.map(({ avg = 0 }) => avg),
    },
    {
      label: 'D&I',
      color: '#bebebe',
      value: metrics.dyi.map(({ avg = 0 }) => avg),
    },
    {
      label: 'Mgr Effec',
      color: '#e5b611',
      value: metrics.mrg.map(({ avg = 0 }) => avg),
    },
  ];

  return (
    <>
      <SingleLineGraph
        {...{ labels, data }}
        title='Engagement By Band'
        hasLegend
      />
    </>
  );
};

export default MetricsEngagement;
