import React, { useEffect, useState } from 'react';
import { store } from '../../../modules/appState/store';

import './Icon.scss';

type IconProps = {
  icon: string;
  isSvg?: boolean;
};

const Icon = ({ icon, isSvg = false }: IconProps) => {
  const [className, setClassName] = useState(
    `${isSvg && !store.getState().AppReducer.dark && 'to-white'}`,
  );

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setClassName(
        `${isSvg && !store.getState().AppReducer.dark && 'to-white'}`,
      );
    });

    return () => {
      unsubscribe();
    };
  }, [isSvg]);

  return <img {...{ className }} src={icon} alt='favorite-icon' />;
};

export default Icon;
