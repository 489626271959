import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetHomeDefaultContentQuery } from '../../../services/api/api-socios-maz';
import {
  GetBodyInnerHTML,
  getDefaultContent,
} from '../../Layouts/default-section/getDefaultElements';
import BreadCrumb from '../../UI/BreadCrumb';
import LoadSpinner from '../../UI/LoadSpinner/LoadSpinner';
import Sections from '../../UI/Sections/sections';

import ReactGoogleSlides from 'react-google-slides';

import './BusinessResults.scss';
import { Flywheel } from './Bussiness-results/Flywheel';
import { NavElementsCorporate } from '../../UI/Buttons-Corporate';
import { Townhalls } from './Bussiness-results/Townhalls';

export const BusinessResults = () => {
  const { t } = useTranslation('global');

  const linkPpt =
    'https://docs.google.com/presentation/d/1RmUDp3UNQOkeZ_7gM4P4ErFZFMD3QCYT2iiy7K7XFE4/edit?usp=sharing';
  const linkPpt2 =
    'https://docs.google.com/presentation/d/1G9bgbrbTPHLQ9AC3dA0BrWCX5dUcOPAvAUNzZhBT56I/edit?usp=sharing';
  const crumbs = [
    { path: '/home', label: 'MAZ Partners' },
    { label: t('corporate.business-results') },
  ];

  const dataapi = useGetHomeDefaultContentQuery('corporate').data;

  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [activeButton, setActiveButton] = useState(0);

  useEffect(() => {
    const getData = async () => {
      const cont: any = getDefaultContent(dataapi)?.content;

      setDescription(cont[0]?.content?.value || '');
      setIsLoading(false);
    };

    setIsLoading(true);
    if (dataapi) getData();
  }, [dataapi]);

  const onNavChange = (idx: number) => {
    setActiveButton(idx);
  };

  return isLoading ? (
    <LoadSpinner />
  ) : (
    <div className='main-content pb-5'>
      <div className='container-fluid pb-5'>
        <div className='col-12'>
          <BreadCrumb {...{ crumbs }} />
          <h3 className='section-title'>{t('corporate.business-results')}</h3>
          <div className='my-3 corporate'>
            <GetBodyInnerHTML
              // cont[0]?.content?.value
              content={description}></GetBodyInnerHTML>
          </div>
        </div>
        <div className='my-3'>
          <NavElementsCorporate {...{ onNavChange }} />
        </div>
        {activeButton === 0 ? (
          <Townhalls isLoading={setIsLoading} />
        ) : (
          <Flywheel isLoading={setIsLoading} />
        )}
      </div>
    </div>
  );
};
