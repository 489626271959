import React, { useEffect, useState } from 'react';

import {
  GetBodyInnerHTML,
  getDefaultContent,
} from '../../../../Layouts/default-section/getDefaultElements';
import { useNavigate } from 'react-router';
import { ReactComponent as Hotel } from '../../../../../assets/LPC/GetReady/Hotel.svg';
import { ReactComponent as Letter } from '../../../../../assets/LPC/GetReady/Immigration Letter.svg';
import {
  useGetHomeDefaultContentQuery,
  useGetPartnerUserQuery,
} from '../../../../../services/api/api-socios-maz';
import { getPartnerData } from '../../../Lpc/GetReady/lpc';

import './LPC.scss';
import { store } from '../../../../../modules/appState/store';

const LPC = () => {
  const [user2, setUser2] = useState({
    image: store.getState().AppReducer,
  });

  const navigate = useNavigate();
  const userCompleted = getPartnerData(
    useGetPartnerUserQuery(user2?.image?.userData?.drupal_id || '')?.data,
  );
  const dataapi = useGetHomeDefaultContentQuery('lpc/get-ready').data;

  const elem: any = getDefaultContent(dataapi)?.content;

  const content = elem?.find(({ type = '' }) => type === 'cardsGroup')?.content;
  const { ticketPDF = '', hotelPDF = '', invPDF = '' } = userCompleted || {};

  const downloadDocument = (url: string) => {
    if (localStorage.getItem('mobile') === '1')
      navigate(`/download-pdf-link?${url}`);
    else window.open(url);
  };

  const ticket = content?.find(({ title = '' }) => title === 'Ticket');
  const hotel = content?.find(({ title = '' }) => title === 'Hotel');
  const inv = content?.find(({ title = '' }) => title === 'Invitation');
  const ticketLabel = ticket?.label || 'Your Ticket';
  const hotelLabel = hotel?.label || 'Your Reservation';
  const invLabel = inv?.label || 'Download you Invitation';
  const ticketMessage = ticketPDF
    ? 'Download your ticket to'
    : 'Download your ticket soon';
  const hotelMessage = hotelPDF ? 'Download your hotel reservation' : 'Soon';
  const invMessage = invPDF
    ? 'Download and print your letter'
    : "In your case you don't need";
  const largerText =
    'text-center h-100 d-flex flex-column destination-lpc-tickets';
  let hotelDesc = largerText;
  let ticketDesc = largerText;
  let invDesc = largerText;

  if (ticket?.description && ticket?.description?.length > 25) {
    ticketDesc = largerText + ' larger-text';
  }
  if (hotel?.description && hotel?.description?.length > 20) {
    hotelDesc = largerText + ' larger-text';
  }
  if (inv?.description && inv?.description?.length > 20) {
    invDesc = largerText + ' larger-text';
  }

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setUser2({ image: store.getState().AppReducer });
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className='profile-lpc event-info-container'>
      <h2>LPC</h2>
      <div className='row'>
        <div className='col col-12 col-lg-6 text-center d-lg-flex align-content-center right-separator'>
          <div className='row'>
            <div className='col col-4 px-md-0 event-info-icons'>
              <img src={ticket?.src} alt='Event place (city).' />
            </div>
            <div className='col col-8 px-md-0 d-md-flex'>
              <div className='text-content'>
                <div className={ticketDesc}>
                  <span>{ticketMessage}</span>
                  <GetBodyInnerHTML content={ticket?.description} />
                  <button
                    onClick={() => downloadDocument(ticketPDF)}
                    disabled={!ticketPDF}>
                    <i className='bi bi-download' /> {ticketLabel}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col col-12 col-lg-6 mt-3 pt-3 mt-lg-0 pt-lg-0 text-center d-lg-flex align-content-center'>
          <div className='row'>
            <div className='col col-4 px-md-0 event-info-icons'>
              <Letter />
            </div>
            <div className='col col-8 px-md-0 d-md-flex'>
              <div className='text-content'>
                <div className={invDesc}>
                  <span>{invMessage}</span>
                  <GetBodyInnerHTML content={inv?.description} />
                  <button
                    onClick={() => downloadDocument(invPDF)}
                    disabled={!invPDF}>
                    <i className='bi bi-download' /> {invLabel}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col col-12 col-lg-6 mt-3 pt-3 text-center d-lg-flex align-content-center top-separator'>
          <div className='row'>
            <div className='col col-4 px-md-0 event-info-icons'>
              <Hotel />
            </div>
            <div className='col col-8 px-md-0 d-md-flex'>
              <div className='text-content'>
                <div className={hotelDesc}>
                  <span>{hotelMessage}</span>
                  <GetBodyInnerHTML content={hotel?.description} />
                  <button
                    onClick={() => downloadDocument(hotelPDF)}
                    disabled={!hotelPDF}>
                    <i className='bi bi-download' /> {hotelLabel}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LPC;
