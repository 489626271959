import React, { memo } from 'react';
import Partner from './Partner';

type PartnersProps = {
  users: any;
  gridType?: boolean;
};

const Partners = ({ users, gridType = true }: PartnersProps) => {
  const partnersClass = gridType
    ? 'row row-cols-2 row-cols-md-3 row-cols-lg-4 mt-5'
    : 'd-flex flex-column';
console.log("PARTNERS:  ",users)
  return (
    <div className={partnersClass}>
      {users.map(
        ({
          user_image = '',
          bu = '',
          first_name = '',
          last_name = '',
          sharpId = '',
          position = '',
          mail = '',
          phone=''
        }) => (
          <div
            key={`partner-${bu}-${first_name}-${last_name}`}
            className='col-sm mb-4 mb-xl-5'>
            <Partner
              {...{ position, mail }}
              id={sharpId}
              userImage={user_image}
              name={first_name}
              lastName={last_name}
              BU={bu.toUpperCase()}
              phone={phone}
              isFullCol={!gridType}
            />
          </div>
        ),
      )}
    </div>
  );
};

export default memo(Partners);
