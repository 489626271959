import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDomain } from '../../../modules/settings/domain';

const FooterTerms = (props: any) => {
    const [domainData, setDomainData] = useState(
        getDomain(window.location.hostname + ''),
    );
    const { t, i18n } = useTranslation('global');
    useEffect(() => {

    }, [t]);
    return (
        <div className=''>
            <footer>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-12 text-center">
                            <img src="../../../customs/main-maz/logo.png" className='icon-logo' alt="" />
                        </div>
                        {/* <div className='col-4'>
                    <h5 className='section-title'>{t('footer.follow')}</h5>
                    <div className='row networks'>
                        <div className='col inz d-flex align-items-center my-2'>
                            <div className='logo facebook m-auto mt-auto' />
                        </div>
                        <div className='col inz d-flex align-items-center my-2'>
                            <div className='logo linkedin m-auto mt-auto' />
                        </div>
                        <div className='col inz d-flex align-items-center my-2'>
                            <div className='logo twitter m-auto mt-auto' />
                        </div>
                        <div className='col inz d-flex align-items-center my-2'>
                            <div className='logo youtube m-auto mt-auto' />
                        </div>
                    </div>
                </div>
                         <div className="col-4">
                    <ul className='list-links-footer'>
                        <li className='text-content mx-auto'>Link 1</li>
                        <li className='text-content'>Link 2</li>
                        <li className='text-content'>Link 3</li>
                        <li className='text-content'>Link 4</li>
                    </ul>
                </div>
                <a
                    target="_blank"
                    href={domainData.policy}
                >
                    Política de privacidad
                </a>
                <a
                    target="_blank"
                    href={domainData.terms}
                    className=""
                >
                    Términos y condiciones
                </a> */}
                </div>
            </div>
        </footer>
    </div>
  );
};
export default FooterTerms;
