import React, { useCallback, useEffect, useReducer } from 'react';
import { useGetHomeDefaultContentQuery } from '../../../../services/api/api-socios-maz';
import { GetBodyInnerHTML } from '../../../Layouts/default-section/getDefaultElements';
import BreadCrumb from '../../../UI/BreadCrumb';
import TabsItems from '../../../UI/TabsItems';
import CalendarDropdown from './CalendarDropdown';
import CommentCard from './CommentCard';
import {
  CalendarCases,
  calendarInitialState,
  calendarReducer,
} from './calendarReducer';

export const CalendarIndex = () => {
  const [state, dispatch] = useReducer(calendarReducer, calendarInitialState);

  const genericData = useGetHomeDefaultContentQuery('squads/calendar').data;

  useEffect(() => {
    if (genericData) {
      const { data, included } = genericData;
      const { attributes } = data[0];
      const description = included.filter(
        ({ type = '' }) => type === 'paragraph--paragraph_texto',
      )[0]?.attributes?.field_texto?.value;
      const comments = included
        ?.filter(({ type }: any) => type === 'paragraph--card')
        ?.map(
          ({
            attributes = {
              field_title: '',
              field_descripcion_sumario: { value: '' },
            },
          }) => ({
            name: attributes?.field_title || '',
            description: attributes?.field_descripcion_sumario?.value || '',
          }),
        );

      dispatch({
        type: CalendarCases.SET_INITIAL_INFO,
        payload: { title: attributes.title, description, comments },
      });
    }
  }, [genericData]);

  const crumbs = [
    { path: '/home', label: 'MAZ Partners' },
    { label: 'Calendar' },
  ];
  const dropdownsInit = [
    { label: 'Bu', value: 'bu', name: 'bu' },
    { label: 'Event Type', value: 'categoria_evento_squads', name: 'cat' },
    { label: 'Time Period', value: 'date', name: 'month' },
  ];

  const onDropdownChange = useCallback(
    (e: { name: string; values: { value: string }[] | [] }) => {
      dispatch({
        type: CalendarCases.LOAD_FILTERS,
        payload: {
          keyFilter: e.name,
          value: e.values.map(({ value }) => value).join(','),
        },
      });
    },
    [],
  );

  return (
    <div className='main-content'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <BreadCrumb {...{ crumbs }} />
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <div>
              <h3 className='section-title'>{state.title}</h3>
            </div>
            <div className='inner-html-text-center'>
              <GetBodyInnerHTML content={state.description} />
            </div>
          </div>
          <div className='col-12'>
            <div className='row justify-content-center mt-4'>
              {dropdownsInit.map((props) => (
                <CalendarDropdown
                  {...props}
                  key={`calendar-dropdown-${props.value}`}
                  onChange={onDropdownChange}
                />
              ))}
            </div>
          </div>
          <div className='col-12'>
            <TabsItems filters={state.actualFilter} />
          </div>
        </div>
      </div>
      <div className='section-dark-gray '>
        <div className='container-fluid'>
          <div className='row pb-5 '>
            <div className='col-12 mt-4'>
              <div className='section-title'>
                <h4>Comments from other Partners</h4>
              </div>
            </div>
            {state.comments.map((props) => (
              <CommentCard
                key={`comment-card-${props.name}-${Math.random()}`}
                {...props}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
