import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './carrouselcards.scss';
import { Link, useLocation } from 'react-router-dom';
import { GetBodyInnerHTML } from '../../Layouts/default-section/getDefaultElements';
export const CarouselCards = (props: any) => {
  const { items } = props;
  const location = useLocation();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      additionalTransfrom={0}
      arrows
      autoPlaySpeed={3000}
      centerMode={false}
      className=''
      containerClass='container-with-dots'
      dotListClass=''
      draggable
      focusOnSelect={false}
      infinite
      itemClass=''
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={{
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5,
        },
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024,
          },
          items: 3,
          partialVisibilityGutter: 40,
        },
        mobile: {
          breakpoint: {
            max: 576,
            min: 0,
          },
          items: 1,
          partialVisibilityGutter: 30,
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 576,
          },
          items: 2,
          partialVisibilityGutter: 30,
        },
      }}
      rewind={false}
      rewindWithAnimation={false}
      rtl={false}
      shouldResetAutoplay
      showDots={false}
      sliderClass=''
      slidesToSlide={1}
      swipeable>
      {items?.map((item: any, itemIndex: number) => {
        return (
          <div className='card mx-auto' key={itemIndex}>
            {/* <span className="text-comming">
                Comming Soon
            </span> */}
            <Link to={item?.link || ''}>
              <div className='icon-squads'>
                <img
                  className='img-fluid'
                  src={item?.src || ''}
                  alt={'Card image cap-' + itemIndex}
                />
              </div>
              <div className='card-body'>
                <div className='card-title'>{item?.title || ''}</div>
                <p className='card-text'>
                  <GetBodyInnerHTML content={item?.description} />{' '}
                </p>
              </div>
            </Link>
          </div>
        );
      })}
    </Carousel>
  );
};
