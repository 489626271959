import React from 'react';
import BreadCrumb, { Crumb } from '../../../../UI/BreadCrumb';

import './LandingHeader.scss';
import { GetBodyInnerHTML } from '../../../../Layouts/default-section/getDefaultElements';

interface LandingHeaderProps {
  img: string;
  desc: string;
  crumbs: Crumb[];
}

const LandingHeader = ({ crumbs, img, desc }: LandingHeaderProps) => {
  return (
    <>
      <div className='header-lpc-banner'>
        <img src={img} alt='banner' />
        <div className='container-fluid'>
          <BreadCrumb {...{ crumbs }} />
        </div>
        <div className='marquee'>
          <div className='marquee-inner'>
            <GetBodyInnerHTML content={desc} />
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingHeader;
