import { activity, summary, event } from '../../../../../../models/lpc.model';
import { apiMainURL } from '../../../../../../modules/settings/environment';

export function getEventData(sections: any): event[] {
  let actividades: activity[] = [];
  let summary: summary[] = [];
  let events: event[] = [];
  let days: string[] = [];

  sections?.data?.map((event: any, indexevent: number) => {
    actividades = [];
    summary = [];
    let pdfFile;

    event?.relationships?.field_actividades?.data?.map((activity: any) => {
      sections?.included?.map((inc: any) => {
        if (
          inc?.type == 'paragraph--actividad_evento' &&
          inc?.id == activity?.id
        ) {
          actividades.push({
            date: toLocaleDate(inc?.attributes?.created),
            hour: inc?.attributes?.field_hora_actividad?msToTime(inc?.attributes?.field_hora_actividad):null,
            hourEnd: inc?.attributes?.field_hora_final?msToTime(inc?.attributes?.field_hora_final):null,
            description: inc?.attributes?.field_descripcion_actividad || '',
            site: inc?.attributes?.field_lugar_evento || '',
            category: inc?.attributes?.field_categoria || '',
            comments: inc?.attributes?.field_comentario|| '',
            dressCode: inc?.attributes?.field_dress_code?.value|| '',
          });
        }
      });
    });
    event?.relationships?.field_sumario?.data?.map((sum: any) => {
      sections?.included?.map((inc: any) => {
        if (inc?.type == 'paragraph--sumario_evento' && inc?.id == sum?.id) {
          summary.push({
            description:
              inc?.attributes?.field_descripcion_sumario?.value || '',
            title: inc?.attributes?.field_titulo_del_sumario || '',
            category: inc?.attributes?.field_categoria || '',
          });
        }
      });
    });

    const adjFile = event.relationships.field_archivo_adjunto;

    if (adjFile.data)
      pdfFile = sections.included.filter(
        ({ type, id }: { type: string; id: string }) =>
          type === 'file--file' && id === adjFile.data.id,
      )[0].attributes;

    events.push({
      activities: actividades,
      summary: summary,
      title: event?.attributes?.title || '',
      date: event?.attributes?.field_fecha_evento || '',
      pdf: adjFile.data && {
        fileMIME: pdfFile.filemime,
        fileName: pdfFile.filename,
        url: `${apiMainURL}${pdfFile.uri.url}`,
      },
    });
    days.push(event?.attributes?.field_fecha_evento);
  });
  return events;
}
export function toLocaleDate(date: string): string {
  const options: Intl.DateTimeFormatOptions = {
    //Typescript ways of adding the type
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  return new Date(date).toLocaleDateString([], options) || '';
}
export function msToTime(duration: number): object {
  var milliseconds = Math.floor((duration % 1000) / 100),
    seconds = Math.floor(duration % 60),
    minutes = Math.floor((duration / 60) % 60),
    hours = Math.floor((duration / (60 * 60)) % 24);
  let hours2 = hours < 10 ? '0' + hours : hours;
  let minutes2 = minutes < 10 ? '0' + minutes : minutes;
  let seconds2 = seconds < 10 ? '0' + seconds : seconds;
  let completed = hours2 + ':' + minutes2;
  let hora: object = {
    hours: hours2,
    minutes: minutes2,
  };
  return hora;
}
