import React, { useCallback, useEffect, useState } from 'react';
import { store } from '../../../modules/appState/store';
import { apiMainURL } from '../../../modules/settings/environment';
import { getUserBySharpId } from '../../../services/api/api-socios-maz';
import BreadCrumb, { Crumb } from '../../UI/BreadCrumb';
import LoadSpinner from '../../UI/LoadSpinner/LoadSpinner';

import Base from './Base';
import CropModal from './Base/CropModal';
import Update from './Update';

const Profile = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [user, setUser] = useState({
    name: '',
    nick: '',
    position: '',
    zone: '',
    beer: '',
    area: '',
    img: 'https://i.ibb.co/mN8C46R/perfil-default.webp',
    bu: '',
    idNumber: '',
    email: '',
    hobbies: [{ icon: undefined, name: '' }],
    beers: [{ icon: undefined, name: '' }],
    items: [{ name: '', description: '' }],
    video: '',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [crumbs, setCrumbs] = useState<Array<Crumb>>([
    { path: '/home', label: 'MAZ Partners' },
    { label: isUpdating ? 'Update' : 'Profile' },
  ]);
  const [activeUser, setActiveUser] = useState<any>(
    store.getState().AppReducer.userData,
  );

  const getData = useCallback(async () => {
    const userInfo = await getUserBySharpId(+activeUser.global_id);

    if (userInfo && userInfo.data && userInfo.data.length !== 0) {
      const {
        data: [{ attributes }],
      } = userInfo;
      const relVideoId =
        userInfo.data[0].relationships.field_user_gallery.data.filter(
          (e: any) => e.type === 'media--video',
        )[0]?.id;

      setUser({
        img: store.getState().AppReducer.image,
        name: activeUser.name
          .split(' ')
          .map(
            (word: string) =>
              word[0].toUpperCase() + word.substring(1).toLowerCase(),
          )
          .join(' '),
        nick: attributes.field_user_nickname,
        position: attributes.field_user_position,
        zone: attributes.timezone,
        beer: attributes.field_user_favorite_beer,
        area:
          userInfo.included?.filter(({ type }: { type: string }) =>
            type.endsWith('areas'),
          )[0]?.attributes?.name || '',
        bu:
          userInfo.included?.filter(({ type }: { type: string }) =>
            type.endsWith('bu'),
          )[0]?.attributes?.name || '',
        idNumber: activeUser.global_id,
        email: attributes.mail || 'No Email',
        hobbies: userInfo.included
          .filter(
            ({ type }: { type: string }) => type === 'taxonomy_term--hobbies',
          )
          .map(
            ({
              attributes,
              relationships,
            }: {
              attributes: any;
              relationships: { [key: string]: any };
            }) => {
              const img = userInfo.included.filter(
                ({ id }: { id: string }) =>
                  id === relationships.field_hobbie_image.data?.id,
              )[0]?.attributes.uri.url;

              return {
                id: attributes.drupal_internal__tid,
                icon: img && apiMainURL + img,
                name: attributes.name,
              };
            },
          ),
        beers: userInfo.included
          .filter(
            ({ type }: { type: string }) => type === 'taxonomy_term--beers',
          )
          .map(
            ({
              attributes,
              relationships,
            }: {
              attributes: any;
              relationships: { [key: string]: any };
            }) => {
              const img = userInfo.included.filter(
                ({ id }: { id: string }) =>
                  id === relationships.field_logo.data?.id,
              )[0]?.attributes.uri.url;

              return {
                id: attributes.drupal_internal__tid,
                icon: img && apiMainURL + img,
                name: attributes.name,
              };
            },
          ),
        items: userInfo.included
          .filter(({ type }: { type: string }) =>
            type.startsWith('paragraph--partner_item'),
          )
          .map((e: any) => {
            const itemInfo = e.attributes;
            const titleInfo = userInfo.included.filter(
              ({ id }: { id: string }) =>
                id === e.relationships.field_partner_item_title.data?.id || '',
            )[0].attributes;

            return {
              titleId: titleInfo.drupal_internal__tid,
              itemId: itemInfo.drupal_internal__id,
              name: titleInfo.name,
              description: itemInfo.field_partner_item_description.value,
            };
          }),
        video: relVideoId
          ? apiMainURL +
              userInfo.included.filter(
                ({ id: videoId }: { id: string }) =>
                  videoId ===
                    userInfo.included.filter(
                      ({ id }: { id: string }) => id === relVideoId || '',
                    )[0]?.relationships.field_media_video_file.data.id || null,
              )[0]?.attributes?.uri.url || ''
          : '',
      });
    }

    setIsLoading(false);
  }, [activeUser]);

  useEffect(() => {
    store.subscribe(() => {
      setActiveUser(store.getState().AppReducer.userData);
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);

    getData();
  }, [getData]);
  useEffect(() => {
    setCrumbs((prevCrumbs) => {
      const newCrumbs = [...prevCrumbs];

      if (isUpdating)
        newCrumbs.splice(
          1,
          1,
          {
            path: '/profile',
            onClick: () => {
              setIsUpdating(false);
            },
            label: 'Profile',
          },
          { label: 'Update' },
        );
      else newCrumbs.splice(1, 2, { label: 'Profile' });

      return newCrumbs;
    });
  }, [isUpdating]);

  const onEdit = () => {
    setIsUpdating(true);
  };
  const toggleModal = () => {
    setIsModalOpen((prevModalOpen) => !prevModalOpen);
  };
  const onCloseUpdate = () => {
    setIsUpdating(false);
    getData();
  };

  return (
    <div className='main-content'>
      <div className='container-fluid'>
        <BreadCrumb {...{ crumbs }} />
        <CropModal {...{ isModalOpen, toggleModal }} />
        {isUpdating ? (
          <Update
            {...{ user }}
            onChangeImage={toggleModal}
            closeUpdate={onCloseUpdate}
          />
        ) : (
          <Base {...{ onEdit, user }} />
        )}
        {isLoading && <LoadSpinner />}
      </div>
    </div>
  );
};

export default Profile;
