export const getDate = (date: string, lastDate?: string) => {
  const startDate = new Date(date.replace(/-/g, '/'));
  const day = startDate.toLocaleDateString('en', {
    day: '2-digit',
  });

  let month = startDate.toLocaleDateString('en', {
    month: 'short',
  });

  month = month.charAt(0).toUpperCase() + month.slice(1);

  const hasSameMonth = lastDate && lastDate.slice(0, 3) === month;

  return !hasSameMonth ? `${month}. ${day}` : day;
};
