import React from 'react';

import { ReactComponent as NoInformation } from '../../../../../assets/general/NoInformation.svg';
import InformationModal from '../../../../UI/InformationModal';

type NoInformationModalProps = {
  toggle: (v: boolean) => void;
};

const NoInformationModal = ({ toggle }: NoInformationModalProps) => {
  const onConfirmModalClick = () => {
    toggle(false);
  };

  const cardFooter = (
    <div className='d-flex card-button'>
      <button className='mx-auto' onClick={onConfirmModalClick}>
        Ready, got it
      </button>
    </div>
  );

  return (
    <InformationModal
      {...{ cardFooter }}
      onConfirm={onConfirmModalClick}
      title='Sorry, it is not possible to enter the Partner Profile section.'
      message='If you want to know your indicators and your archetype, we invite you to participate in our survey, which will be carried out in December.'
      imgSrc={<NoInformation className='no-info-card-svg' />}
    />
  );
};

export default NoInformationModal;
