import React, { useState } from 'react';
import { store } from '../../../../modules/appState/store';
import { useGetHomeDefaultContentQuery } from '../../../../services/api/api-socios-maz';
import {
  GetBodyInnerHTML,
  getDefaultContent,
} from '../../../Layouts/default-section/getDefaultElements';
import CarouselView from '../../../UI/Carousel/carousel';
import LoadSpinner from '../../../UI/LoadSpinner/LoadSpinner';
import Sections from '../../../UI/Sections/sections';
import { Default } from '../../../Layouts/default-section/default';
import { NavElementsLPC } from '../../../UI/Buttons-LPC';
import SectionsById from '../../../UI/Sections/SectionsById/sections';
import BreadCrumb from '../../../UI/BreadCrumb';

export const LPC_Component_Event = () => {
  const storeApp = store;

  const dataapi = useGetHomeDefaultContentQuery('lpc/event').data;
  const elem: any = getDefaultContent(dataapi)?.content;

  const nidEvent = getNidEvent(dataapi);
  function getNidEvent(dataapi: any): any {
    return dataapi && dataapi?.data[0]
      ? dataapi?.data[0]?.attributes?.drupal_internal__nid
      : null;
  }

  // const nidEvent=dataapi[0]?.attributes?.drupal_internal__nid;
  // console.log("NID EVENTO:   ",nidEvent)

  if (elem) {
    return (
      <div className='main-content lpc-bg'>
        <div className='container-fluid'>
          {elem && <LpcEvent data={elem} id={nidEvent}></LpcEvent>}
        </div>

        {/* <button className='btn btn-secondary' onClick={()=>handleEvent()}>Store</button> */}
      </div>
    );
  } else return <LoadSpinner></LoadSpinner>;
};

export const LpcEvent = (props: any) => {
  const id = props?.id;
  const crumbs = [
    { path: '/home', label: 'MAZ Partners' },
    { path: '/lpc', label: 'LPC' },
    { label: 'Event' },
  ];

  return (
    <div className=''>
      <div className='col-12  pb-5'>
        <BreadCrumb {...{ crumbs }} />
        <CarouselView content={props?.data[0]?.content}></CarouselView>
        <NavElementsLPC actived={2}></NavElementsLPC>
        <div className='pt-5'>
          <h3 className='section-title'>{props?.data[1]?.content?.value}</h3>
        </div>

        <div className='text-content tw'>
          <GetBodyInnerHTML
            content={props?.data[2]?.content?.value}></GetBodyInnerHTML>
        </div>
        <SectionsById data={props?.data[3]?.content} id={id}></SectionsById>
      </div>
    </div>
  );
};
