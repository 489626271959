import React, { memo, useEffect, useState } from 'react';
import { store } from '../../../../modules/appState/store';
import { getTaxonomyById } from '../../../../services/api/api-socios-maz';
import MultiselectItems from '../../../UI/Multiselect';

type CalendarDropdownProps = {
  label: string;
  value: string;
  name?: string;
  onChange?: (val: any) => void;
};

const CalendarDropdown = ({
  value,
  label,
  name,
  onChange,
}: CalendarDropdownProps) => {
  const [options, setOptions] = useState([{ label: '', value: '' || 0 }]);
  const [init, setInit] = useState<
    { label: string; value: string | number }[] | undefined
  >();

  useEffect(() => {
    const getData = async () => {
      if (value !== 'date') {
        const data = await getTaxonomyById(value);

        if (data)
          setOptions(
            data.data?.map(
              ({ attributes = { drupal_internal__tid: 0, name: '' } }) => ({
                label: attributes.name,
                value: attributes.drupal_internal__tid,
              }),
            ),
          );
      } else
        setOptions(
          [...Array(12).keys()].map((idx) => {
            const number = idx + 1;

            let name = new Date(0, number, 0).toLocaleDateString('en', {
              month: 'long',
            });

            name = name.charAt(0).toUpperCase() + name.slice(1);

            return { label: name, value: number };
          }),
        );
    };

    getData();
  }, [value]);

  useEffect(() => {
    const getData = async () => {
      const itemFound = options.find(
        ({ label }) => label === store.getState().AppReducer.userData.bu,
      );

      if (itemFound) {
        setInit([itemFound]);
        onChange && onChange({ name: 'bu', values: [itemFound] });
      }
    };

    if (!store.getState().AppReducer.userData.bu) {
      const unSub = store.subscribe(() => getData());

      return unSub;
    }

    getData();
  }, [options, onChange]);

  const onDropdownChange = (e: any) => {
    onChange && onChange({ name, values: e });
  };

  return (
    <div className='col-md-3'>
      <MultiselectItems
        {...{ options }}
        onChange={onDropdownChange}
        overrideStrings={{ selectSomeItems: label }}
        valueRenderer={() => label}
        initValues={init}
      />{' '}
    </div>
  );
};

export default memo(CalendarDropdown);
