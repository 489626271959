import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { store as storeApp } from '../../../modules/appState/store';
import { ReactComponent as FlywheelLogo } from '../../../assets/corporate/flywheel.svg';
import { ReactComponent as TownhallsLogo } from '../../../assets/corporate/townhalls-1.svg';

type NavElementsCorporateProps = {
  onNavChange: (i: number) => void;
};

export const NavElementsCorporate = ({
  onNavChange,
}: NavElementsCorporateProps) => {
  const { t, i18n } = useTranslation('global');

  const [activeTab, setActiveTab] = useState(0);

  const miniatures = [
    {
      logo: TownhallsLogo,
      name: t('corporate-buttons.Townhalls'),
    },
    {
      logo: FlywheelLogo,
      name: t('corporate-buttons.Flywheel'),
    },
  ];

  const toggle = (tab: number) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      onNavChange(tab);
    }
  };

  return (
    <Nav className='miniatures-center mt-3' tabs>
      {miniatures.map((min: any, index: number) => {
        const actButton = index === activeTab;

        return (
          <NavItem
            key={index.toString()}
            className='nav-item px-3 circle-item'
            onClick={() => toggle(index)}>
            <div className='d-flex lpc-icons'>
              <min.logo
                className={`mx-auto ${
                  actButton ? 'svg-logo-active' : 'svg-logo'
                }`}
              />
            </div>
            <p
              className={`px-auto mt-1 text-center ${
                actButton && 'txt-navs-corporate'
              }`}>
              {min?.name}
            </p>
          </NavItem>
        );
      })}
    </Nav>
  );
};
