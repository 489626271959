import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { store as storeApp } from '../../../../../modules/appState/store';
import {
  postGeneralPartner,
  useGetallArchetypesQuery,
  useGetPartnerProfileByIdQuery,
} from '../../../../../services/api/api-socios-maz';
import {
  GetBodyInnerHTML,
  getImage,
} from '../../../../Layouts/default-section/getDefaultElements';
import { RangePercentage } from '../../../../UI/Range-percentage';
import { RangeValue } from '../../../../UI/Range-value';
import { GenMAZ } from './Categories/GenMaz-item';
import { GenMAZData } from './Categories/GenMaz-item/GenMazData';
import { LeaderShip } from './Categories/LeaderShip-item';
import { LeaderShipData } from './Categories/LeaderShip-item/LeaderShipData';
import './Filters.scss';
import { apiMainURL } from '../../../../../modules/settings/environment';
import BreadCrumb from '../../../../UI/BreadCrumb';
import LoadSpinner from '../../../../UI/LoadSpinner/LoadSpinner';
import { capitalizeFirstLetter } from '../../../../../services/services';
import { PrintComponent } from '../../../../UI/Print-component';
import { MyProfile } from '../../admin-partner-profile/general-items/My-profile';
import { PartnerProfileRenderPrint } from './ProfilePrint';
import { useReactToPrint } from 'react-to-print';
import EmployeeDataItem from './Categories/EmployeeDataItem';
import ArchetypeCarousel from '../Archetypes-details/ArchetypeCarousel';
import MazDropdown from '../../../../UI/Dropdown';
import ProfileDropdown from '../../ProfileDropdown';
import { useLocation } from 'react-router';
import MyEvolution from './Categories/MyEvolution/MyEvolution';
import apps from '../../../../../data/tabsCategoryPartnerProfileApps.json';
import PPNavBar from '../../../../UI/PPNavBar';
import html2pdf from 'html2pdf.js';
import { Base64 } from 'js-base64';

export const PartnerProfile = React.forwardRef(() => {
  const [user, setUser] = useState(storeApp.getState().AppReducer?.userData);
  const [data, setData] = useState<any>({});

  const archetypesListData = useGetallArchetypesQuery()?.data;
  const archetypesList = getArchetypes(archetypesListData);

  // console.log('Listado archetypes:  ', archetypesListData);
  storeApp.subscribe(() => {
    setUser(storeApp?.getState()?.AppReducer?.userData || '');
  });
  const onClick = () => {
    console.log('Callback onclick');
  };

  useEffect(() => {
    if (user?.drupal_id && user?.drupal_id != '') getDataProfile();
  }, [user]);

  const getDataProfile = async () => {
    // console.log("DRUPAL ID2 profile 3333  ",user?.drupal_id);
    const response = await postGeneralPartner(user?.drupal_id || '')
      ?.then((response: any) => response.text())
      .then((result) => {
        // console.log('result:   ', result);
        setData(JSON.parse(result));
      });
  };

  // const pData=useGetPartnerProfileByIdQuery(user?.drupal_id);

  // console.log("Data del endpoint:  ",pData);
  const crumbs = [
    { path: '/home', label: 'MAZ Partners' },
    { label: 'Partner Profile' },
  ];
  if (user?.drupal_id != '' && data) {
    return (
      <PartnerProfileRender
        idUser={user?.drupal_id}
        data={data}
        archetypes={archetypesList}
        mainArq={data?.dataArchetypes?.data?.reverse().slice(0, 1)}
        secArch={data?.dataArchetypes?.data
          ?.slice(1, data?.dataArchetypes?.data?.lenght)
          ?.reverse()}
        crumbs={crumbs}
        onClick={onClick}
      />
    );
  } else return <></>;
});

export const PartnerProfileRender = React.forwardRef((props: any, ref: any) => {
  const {
    idUser,
    data,
    archetypes,
    crumbs,
    mainArq,
    secArch,
    records = [],
    onClick,
  } = props;
  console.log('PROOPS PartnerProfileRender', props);
  // const navigate = useNavigate();
  const navigate = useNavigate();

  const arqData = data?.dataArchetypes;
  const componentRef = useRef(null);
  const location = useLocation()?.pathname;
  console.log('Location:  ', location);
  const [date, setDate] = useState('');
  function Uint8ArrayToBase64(uint8Array: any) {
    let binaryString = '';
    for (let i = 0; i < uint8Array.length; i++) {
      binaryString += String.fromCharCode(uint8Array[i]);
    }
    return Base64.btoa(binaryString);
  }
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle:
      'Partner Profile Report                 -          These are your results for: ' +
        date || ' MAZ PARTNERS',
    onAfterPrint: async () => {
      let document = componentRef.current;
      //
      // if (localStorage.getItem('mobile')=='1') {
      if (true) {
        console.log('current:  ', document);
        html2pdf()
          .from(document)
          .outputPdf()
          .then((pdf: any) => {
            // Convert PDF to Blob
            console.log('PDF:  ', pdf);
            const blob = new Blob([pdf], { type: 'application/pdf' });

            const reader = new FileReader();
            reader.onloadend = () => {
              const pdfArrayBuffer = Array.from(
                new Uint8Array(reader.result as ArrayBuffer),
              );
              if (pdfArrayBuffer.join(',').length > 0) {
                let b64 = Uint8ArrayToBase64(pdfArrayBuffer.join(','));
                console.log('BASE 64:  ', b64);
                // navigate(`/callbackPDF?pdfData${pdfArrayBuffer.join(',')}`);
              }
            };
            reader.readAsArrayBuffer(blob);
            blob.text().then((pdf2: any) => {
              console.log('Blob pdf:  ', pdf2);
            });

            // Do whatever you need with the Blob
            // For example, you can save it or use it for further processing
            console.log(blob);
          });
      }

      return;
    },
  });
  const toDataInterpretation = () => {
    // location == '/profile/partner-profile'
    //   ? navigate(`/profile/data-interpretation`)
    //   : navigate(`/partners/data-interpretation`);
  };
  const onDropdownClick = (v: string) => {
    onClick && onClick(v);
    setDate(v);
  };

  useEffect(() => {
    setDate(records[0]?.value || '');
  }, [records]);

  if (idUser != '' && arqData && mainArq && secArch) {
    return (
      <div className='main-content'>
        <div className='hidden'>
          <PartnerProfileRenderPrint
            {...{ date }}
            idUser={idUser}
            data={data}
            archetypes={archetypes}
            crumbs={crumbs}
            mainArq={mainArq}
            secArch={secArch}
            ref={componentRef}
          />
        </div>
        <div className='container-fluid'>
          <BreadCrumb {...{ crumbs }} />

          {/* <PPNavBar isProfileActive /> */}

          {records.length !== 0 && (
            <div className=''>
              <ProfileDropdown {...{ onDropdownClick, records }} />
            </div>
          )}
          <div className='row pt-5'>
            <div className='col-sm-5 ps-5 '>
              <div className=' img-perfil-archetype'>
                <img
                  src={arqData?.archetype?.image || ''}
                  className='img-fluid'
                />
                <div className='photo-archetype'>
                  <img
                    src={
                      arqData?.userpicture ||
                      '../../../../resources/perfil-default.png'
                    }
                    className='img-fluid'
                  />
                </div>
              </div>
            </div>
            <div className='col-sm-7 text-center text-md-start'>
              <div>
                <div className='text-content '>
                  <strong>Hi {arqData?.username}</strong>
                </div>
                <div className='subtitle-archetype'>
                  {arqData?.archetype?.name || ''}
                </div>
              </div>
              <div className=' p-generic'>
                <GetBodyInnerHTML
                  content={
                    arqData?.archetype?.descripcion || ''
                  }></GetBodyInnerHTML>
                <div className='text-gold fs-5 text-bold py-4'>
                  <GetBodyInnerHTML
                    content={
                      arqData?.archetype?.complemento || ''
                    }></GetBodyInnerHTML>
                </div>
              </div>
              <div className='row pb-5'>
                <div className='col-12 col-md-6 mb-3 mb-sm-0'>
                  <button
                    className='container-fluid btn btn-primary btn-reports btn-di'
                    onClick={toDataInterpretation}>
                    Data interpretation
                  </button>
                </div>
                <div className='col-12 col-md-6'>
                  <button
                    className='container-fluid btn btn-primary btn-reports btn-dr'
                    onClick={handlePrint}>
                    Download my Report
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='section-gray-partners py-5'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <h2 className='text-center mb-4'>
                  The composition of this archetype
                </h2>
              </div>
              <div className='col-sm-4 col-md-3 mx-auto mb-4 link-arch'>
                <Link
                  to={
                    '/profile/partner-profile/archetype-detail?' +
                    mainArq[0]?.tid
                  }>
                  <div className='box-content-barprogress'>
                    <div className='icon-boxtext-partner'>
                      <img
                        src={
                          mainArq[0]?.face ||
                          `../../../../resources/lpc/event/active-morning.svg`
                        }
                        className='img-fluid'
                      />
                    </div>
                    <div className='bar-progress'>
                      <div className='tw mb-1'>
                        {capitalizeFirstLetter(mainArq[0]?.name || '')}
                      </div>
                      <RangePercentage
                        percentage={parseFloat(mainArq[0]?.value)?.toFixed(
                          2,
                        )}></RangePercentage>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='col-12'>
                <div className='row'>
                  {secArch?.map((itemsArq: any, itemsArqIndex: number) => {
                    return (
                      <div
                        className='col-sm-4 col-md-3 link-arch'
                        key={itemsArqIndex}>
                        <Link
                          to={
                            '/profile/partner-profile/archetype-detail?' +
                            itemsArq?.tid
                          }>
                          <div className='box-content-barprogress'>
                            <div className='icon-boxtext-partner'>
                              <img
                                src={
                                  itemsArq?.face ||
                                  `../../../../resources/lpc/event/active-morning.svg`
                                }
                                className='img-fluid'
                              />
                            </div>
                            <div className='my-3 bar-progress'>
                              <div className='tw'>
                                {capitalizeFirstLetter(itemsArq?.name || '')}
                              </div>
                              <RangePercentage
                                percentage={
                                  parseFloat(itemsArq?.value).toFixed(2) || '0'
                                }></RangePercentage>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>

                <div className='text-center mt-5'>
                  You can click on each archetype to obtain more information
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid'>
          <TabsCategory {...{ date }} imgs={apps}></TabsCategory>
        </div>
        <ArchetypeCarousel {...{ archetypes }} />
      </div>
    );
  } else return <LoadSpinner></LoadSpinner>;
});

let imgs: any = [];

const filters = [
  { name: 'Macro Entrepreneur Attributes', status: false },
  { name: 'Leadership Style', status: false },
  { name: 'Employee Data', status: false },
  // { name: 'My Evolution', status: false },
];
interface IProps {
  onClickAll?: () => void;
  all?: boolean;
  onClick?: (e: any) => void;
  filters?: { name: string; status: boolean }[];

  imgs?: {
    author: string;
    tag: string;
    src: string;
  }[];
}

const Filters = ({ onClickAll, all, onClick, filters }: IProps) => (
  <form className='items_list list-partners'>
    <ul className='items'>
      {/* <li onClick={onClickAll}>
          <input type="checkbox" checked={all} />
          <label htmlFor="all">All</label>
        </li> */}
      {filters?.map((filter: any, i: number) => (
        <li key={i} data-index={i} onClick={onClick}>
          <input id={filter.name} type='checkbox' checked={filter.status} />
          <label htmlFor={filter.name}>{filter.name}</label>
        </li>
      ))}
    </ul>
  </form>
);

export const Cards = (props: any) => {
  const { imgs, date } = props;

  return (
    <div>
      {imgs?.map((elem: any, idElem: number) => {
        switch (elem?.tag) {
          case 'Macro Entrepreneur Attributes':
            return <GenMAZData {...{ date }} key={idElem} />;
            break;
          case 'Leadership Style':
            return <LeaderShipData {...{ date }} key={idElem}></LeaderShipData>;
            break;

          case 'Employee Data':
            return <EmployeeDataItem {...{ date }} />;

          case 'My Evolution':
            return <MyEvolution />;

          default:
            return (
              <div>
                <h1>DEFAULT PAGE</h1>
              </div>
            );
        }
      })}
    </div>
  );
};

type MyProps = {
  // using `interface` is also ok
  imgs: any;
  date?: string;
  hasFilters?: boolean;
  printFlag?: boolean;
};
export class TabsCategory extends React.Component<MyProps> {
  state = {
    imgs,
    filters,
    all: true,
  };
  componentDidMount() {
    const { filters, all } = this.state;

    filters.forEach((filter) => {
      filter.status = false;
    });

    filters[0].status = true;
    this.setState({
      filters,
    });
  }
  componentDidUpdate() {}
  setFilter = (e: any) => {
    e.preventDefault();
    const { filters, all } = this.state;
    const { index } = e.currentTarget.dataset;
    filters.forEach((filter) => {
      filter.status = false;
    });

    filters[index].status = !filters[index].status;
    this.setState({
      filters,
    });

    this.updateFilters();
    this.updateImgs();
  };

  setAll = () => {
    const { filters } = this.state;

    filters.forEach((filter) => {
      filter.status = false;
    });

    this.setState({
      all: true,
      filters,
    });
  };

  updateFilters() {
    const allFiltersTrue = filters.every((filter) => filter.status === true);
    const allFiltersFalse = filters.every((filter) => filter.status === false);

    if (allFiltersTrue || allFiltersFalse) {
      this.setAll();
    } else {
      this.setState({
        all: false,
      });
    }
  }

  updateImgs() {
    const { filters, all } = this.state;
    let newImgs: any[] = [];
    let a = 0;
    imgs = this.props.imgs;
    imgs.forEach((img: any, imgKey: number) => {
      filters.forEach((filter, filterKey) => {
        if (img.name == filter.name && filter.status == true) {
          newImgs[a] = img;
          a++;
        }
      });
    });

    this.setState({
      imgs: newImgs,
    });
  }
  render() {
    const { filters, all } = this.state;
    const { imgs, date } = this.props;

    return (
      <div className='row'>
        <div className='col-12'>
          <Filters
            onClickAll={this.setAll}
            all={all}
            onClick={this.setFilter}
            filters={filters}
          />
          {all ? (
            <Cards {...{ date }} key={imgs[0].name} imgs={[imgs[0]]} />
          ) : (
            <Cards {...{ date }} key={imgs[1].name} imgs={this.state.imgs} />
          )}
        </div>
      </div>
    );
  }
}
export function getArchetypes(archData: any): any[] {
  let archeTypes: any[] = [];
  // name: 'MagicianArray',
  // image: 'https://api-socios-maz-dev.somosmaz.com/sites/default/files/2022-07/Captura%20de%20Pantalla%202022-07-22%20a%20la%28s%29%2014.48.18%20%281%29.png',
  // id: '1'
  archData?.data?.map((arch: any) => {
    let idImage = arch?.relationships?.field_archetype_image?.data.id || '';
    let imageFile = '';
    archData?.included?.map((inc: any) => {
      if (inc?.id == idImage) {
        archeTypes.push({
          name: arch?.attributes?.name || '',
          image: apiMainURL + inc?.attributes?.uri?.url || '',
          id: arch?.attributes?.drupal_internal__tid || '',
        });
      }
    });
  });

  return archeTypes;
}
