import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { getUserBySharpId } from '../../../../services/api/api-socios-maz';
import { store } from '../../../../modules/appState/store';
import { apiMainURL } from '../../../../modules/settings/environment';

import LoadSpinner from '../../../UI/LoadSpinner/LoadSpinner';
import BreadCrumb from '../../../UI/BreadCrumb';
import Base from '../../ProfileConfig/Base';

import './PartnerDetails.scss';

const PartnerDetails = () => {
  const { id: partnerId } = useParams();
  const navigate = useNavigate();

  const [user, setUser] = useState({
    name: '',
    nick: '',
    position: '',
    zone: '',
    beer: '',
    area: '',
    img: 'https://i.ibb.co/mN8C46R/perfil-default.webp',
    bu: '',
    idNumber: '',
    email: '',
    hobbies: [{ icon: undefined, name: '' }],
    beers: [{ icon: undefined, name: '' }],
    items: [{ name: '', description: '' }],
    video: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [crumbs, setCrumbs] = useState<Array<{ path?: string; label: string }>>(
    [{ path: '/', label: '' }],
  );
  const sameUser =
    store.getState().AppReducer.userData.global_id === +(partnerId || '');

  useEffect(() => {
    const getData = async () => {
      // userData.global_id
      const userInfo = await getUserBySharpId(+(partnerId || ''));

      if (userInfo && userInfo.data && userInfo.data.length !== 0) {
        const {
          data: [{ attributes }],
        } = userInfo;

        setCrumbs([
          { path: '/home', label: 'MAZ Partners' },
          {
            path: '/meet-the-partners?limit=16&offset=0',
            label: 'Meet The Partners',
          },
          { label: attributes.display_name },
        ]);
        const relVideoId =
          userInfo.data[0].relationships.field_user_gallery.data.filter(
            (e: any) => e.type === 'media--video',
          )[0]?.id;

        setUser({
          img:
            apiMainURL +
              userInfo.included.filter(
                ({ id }: { id: string }) =>
                  id ===
                    userInfo.data[0].relationships.user_picture?.data?.id || '',
              )[0]?.attributes.uri.url || '',
          name: attributes.display_name,
          nick: attributes.field_user_nickname || '',
          position: attributes.field_user_position || '',
          zone: attributes.timezone || '',
          beer: attributes.field_user_favorite_beer || '',
          area:
            userInfo.included?.filter(({ type }: { type: string }) =>
              type.endsWith('areas'),
            )[0]?.attributes?.name || '',
          bu:
            userInfo.included?.filter(({ type }: { type: string }) =>
              type.endsWith('bu'),
            )[0]?.attributes?.name || '',
          idNumber: partnerId || '',
          email: attributes.mail || 'No email',
          hobbies: userInfo.included
            .filter(
              ({ type }: { type: string }) => type === 'taxonomy_term--hobbies',
            )
            .map(
              ({
                attributes,
                relationships,
              }: {
                attributes: any;
                relationships: { [key: string]: any };
              }) => {
                const img = userInfo.included.filter(
                  ({ id }: { id: string }) =>
                    id === relationships.field_hobbie_image.data?.id,
                )[0]?.attributes.uri.url;

                return {
                  id: attributes.drupal_internal__tid,
                  icon: img && apiMainURL + img,
                  name: attributes.name,
                };
              },
            ),
          beers: userInfo.included
            .filter(
              ({ type }: { type: string }) => type === 'taxonomy_term--beers',
            )
            .map(
              ({
                attributes,
                relationships,
              }: {
                attributes: any;
                relationships: { [key: string]: any };
              }) => {
                const img = userInfo.included.filter(
                  ({ id }: { id: string }) =>
                    id === relationships.field_logo.data?.id,
                )[0]?.attributes.uri.url;

                return {
                  id: attributes.drupal_internal__tid,
                  icon: img && apiMainURL + img,
                  name: attributes.name,
                };
              },
            ),
          items: userInfo.included
            .filter(({ type }: { type: string }) =>
              type.startsWith('paragraph--partner_item'),
            )
            .map((e: any) => {
              const itemInfo = e.attributes;
              const titleInfo = userInfo.included.filter(
                ({ id }: { id: string }) =>
                  id === e.relationships.field_partner_item_title.data?.id,
              )[0].attributes;

              return {
                titleId: titleInfo.drupal_internal__tid,
                itemId: itemInfo.drupal_internal__id,
                name: titleInfo.name,
                description: itemInfo.field_partner_item_description.value,
              };
            }),
          video: relVideoId
            ? apiMainURL +
                userInfo.included.filter(
                  ({ id: videoId }: { id: string }) =>
                    videoId ===
                      userInfo.included.filter(
                        ({ id }: { id: string }) => id === relVideoId || '',
                      )[0]?.relationships.field_media_video_file.data.id ||
                    null,
                )[0]?.attributes?.uri.url || ''
            : '',
        });
      }

      setIsLoading(false);
    };

    setIsLoading(true);
    getData();
  }, [partnerId]);

  const onEdit = () => {
    navigate('/profile');
  };

  if (!isLoading)
    return (
      <div className='main-content'>
        <div className='container-fluid'>
          <BreadCrumb {...{ crumbs }} />
          <Base {...{ user, onEdit }} hasEdit={sameUser} />
        </div>
      </div>
    );
  else return <LoadSpinner />;
};

export default PartnerDetails;
