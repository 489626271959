import { fontLPC, fontMAZ } from "./environment";


interface Map {
  [key: string]: string | undefined;
}

//CONFIGURE DEFAULT
let objectCustom = {
  color: 'main-maz',
  fonts: fontMAZ,
};
function setObjectCustom(color: string, font: string): any {
  return {
    color: color,
    fonts: font,
  };
}

interface Domain {
  policy: string,
  terms: string,
  name: string,
  title: string,
  wellcome: string,
  linkSupport: string,
  description: string,
  gaCode: string,
}
//CONFIGURE CUSTOM COLORS && FONTS
export const domain_page = (str: string) => {
  
  if (str.includes('lpc')) {
    return setObjectCustom('main-lpc', fontLPC);
  }
  else {
    return setObjectCustom('main-maz', fontMAZ);
  }
};
export const getDomain = (str: string): Domain => {
  localStorage.setItem('str', str);
  if (str.includes('lpc')) {

    localStorage.setItem('appName', 'LPC-Event');
    return {
      policy: 'https://api-beerambassador.somosmaz.com/sites/default/files/2021-11/080921%20T%C3%A9rminos%20y%20Condiciones%20EMBAJADORES%20MODELO.pdf',
      terms: 'https://api-beerambassador.somosmaz.com/sites/default/files/2021-11/080921%20T%C3%A9rminos%20y%20Condiciones%20EMBAJADORES%20MODELO.pdf',
      name: 'LPC EVENT',
      title: 'LPC',
      wellcome: 'Te damos la bienvenida',
      linkSupport: 'https://forms.office.com/Pages/ResponsePage.aspx?id=GUvwznZ3lEq4mzdcd6j5NsdUdCsBLUdBqkxYWsb-alhUMDlCNTNVMVlXNkZNWVlFV0VSMEwzNDNURy4u',
      description: '',
      gaCode: 'GTM-N8KJNN2',
    };
  }
  else {
    localStorage.setItem('appName', 'MAZ PARTNERS');
    return {
      policy: 'https://api-beerambassador.somosmaz.com/sites/default/files/2021-11/080921%20T%C3%A9rminos%20y%20Condiciones%20EMBAJADORES%20MODELO.pdf',
      terms: 'https://api-beerambassador.somosmaz.com/sites/default/files/2021-11/080921%20T%C3%A9rminos%20y%20Condiciones%20EMBAJADORES%20MODELO.pdf',
      name: 'MAZ PARTNERS',
      title: 'SOCIOS MAZ',
      wellcome: 'Te damos la bienvenida',
      linkSupport: 'https://forms.office.com/Pages/ResponsePage.aspx?id=GUvwznZ3lEq4mzdcd6j5NsdUdCsBLUdBqkxYWsb-alhUMDlCNTNVMVlXNkZNWVlFV0VSMEwzNDNURy4u',
      description: '',
      gaCode: 'GTM-N8KJNN2',
    };
  }
};

export const equalURL = (str: string, str2: string) => {
  var str1 = str.substring(0, str.length - 1);
  return new RegExp(str1, 'i').test(str2);
};
