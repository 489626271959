import React from 'react';

import './MyEvolution.scss';
import SingleLineGraph from '../../../../../../UI/Graphics/SingleLineGraph';

const exampleData = [
  {
    label: '1',
    value: [3.0, 4.8, 4.9],
  },
  {
    label: '2',
    value: [3.7, 4.3, 3.5],
  },
];
const exampleLabels = ['2022-07', '2023-01', '2023-07'];

const MyEvolution = () => {
  return (
    <div className='evolution-body'>
      <p className='evolution-desc'>
        Learn more about the attributes of the macro entrepreneur that we want
        to strengthen in our people. Remember there is no magic formula, this
        are just a few traits that help deliver consistent growth.
      </p>
      <section className='graph-content'>
        <h3>Your Macro Entrepreneur Attributes Evolution</h3>
        <div
          style={{
            display: 'flex',
            gridTemplateRows: '1fr 1fr',
            maxWidth: 100 + '%',
            flexWrap: 'wrap',
            marginBottom: '30px',
          }}>
          <div
            className='col-md-6 text-md-end text-center'
            style={{
              width: 50 + '%',
              paddingRight: '5px',
            }}>
            <div
              className={
                // items[0]?.partnerValue ? 'ind-partner' : 'invisible'
                'ind-partner'
              }>
              <span></span>
              Partner View
            </div>
          </div>
          <div
            className='col-md-6 text-md-start text-center mb-3 mb-md-0 '
            style={{
              width: 50 + '%',
              paddingLeft: '5px',
            }}>
            <div
              className={
                // items[0]?.teamValue ? 'ind-team' : 'invisible'
                'ind-team'
              }>
              <span></span>
              Team View
            </div>
          </div>
        </div>
        <div className='row row-cols-md-2'>
          <div className='col graph-cell'>
            <SingleLineGraph
              data={exampleData}
              labels={exampleLabels}
              title='Risk Tolerance'
              hasLegend={false}
              max={5}
              titleClassName='graph-title'
              stepSize={1}
            />
          </div>
          <div className='col graph-cell'>
            <SingleLineGraph
              data={exampleData}
              labels={exampleLabels}
              title='Human Centricity'
              hasLegend={false}
              max={5}
              titleClassName='graph-title'
              stepSize={1}
            />
          </div>
          <div className='col graph-cell'>
            <SingleLineGraph
              data={exampleData}
              labels={exampleLabels}
              title='Efficiency'
              hasLegend={false}
              max={5}
              titleClassName='graph-title'
              stepSize={1}
            />
          </div>
          <div className='col graph-cell'>
            <SingleLineGraph
              data={exampleData}
              labels={exampleLabels}
              title='Inclusion'
              hasLegend={false}
              max={5}
              titleClassName='graph-title'
              stepSize={1}
            />
          </div>
          <div className='col graph-cell'>
            <SingleLineGraph
              data={exampleData}
              labels={exampleLabels}
              title='Creativity'
              hasLegend={false}
              max={5}
              titleClassName='graph-title'
              stepSize={1}
            />
          </div>
          <div className='col graph-cell'>
            <SingleLineGraph
              data={exampleData}
              labels={exampleLabels}
              title='Agility'
              hasLegend={false}
              max={5}
              titleClassName='graph-title'
              stepSize={1}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default MyEvolution;
