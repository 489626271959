import React from 'react';

import './Restaurants.scss';
import Restaurant from './Restaurant';
import { RestaurantsProps } from './Restaurants';
import { v4 } from 'uuid';

const Restaurants = ({ restaurants }: RestaurantsProps) => {
  return (
    <div className='container-fluid'>
      <div className='destination-restaurants'>
        <h1>Restaurants</h1>
        {restaurants.map((props) => {
          const uuid = v4();

          return <Restaurant key={uuid} {...props} />;
        })}
      </div>
    </div>
  );
};

export default Restaurants;
