import React from 'react';
import { GetBodyInnerHTML } from '../../../Layouts/default-section/getDefaultElements';
import { ReactComponent as Content } from '../../../../assets/LPC/Content/Content.svg';

interface DescriptionProps {
  desc: string;
}

const Description = ({ desc }: DescriptionProps) => {
  return (
    <div className='lpc-content-description'>
      <div className='container-fluid'>
        <div className='svg-container'>
          <Content />
        </div>
        <GetBodyInnerHTML content={desc} />
      </div>
    </div>
  );
};

export default Description;
