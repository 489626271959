import React, { useEffect, useState } from 'react';
import {
  generateCodeChallenge,
  generateState,
} from '../../../../modules/Auth/auth';
import { domain_page, getDomain } from '../../../../modules/settings/domain';
import {
  appURL,
  client_id,
  ssoURL,
} from '../../../../modules/settings/environment';

import '../Landing.scss';

const LandingUserWithSession = () => {
  const [domain, setDomain] = useState(
    domain_page(window.location.hostname + ''),
  );
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  useEffect(() => {
    if(localStorage.getItem('mobile')){
      window.location.href = '/applogin';
    }
    if (localStorage.getItem('code_verifier')) {
      window.location.href = '/home';
    }
  });
  const login = () => {
    if (!localStorage.getItem('code_verifier')) {
      var codeChallenge = '';
      var state = generateState();

      generateCodeChallenge().then((res) => {
        codeChallenge = res;
        let args = new URLSearchParams({
          response_type: 'code',
          client_id: client_id,
          code_challenge_method: 'S256',
          code_challenge: codeChallenge,
          redirect_uri: `${appURL}/auth/callback`,
          state: state,
          scope: 'openid',
        });

        localStorage.setItem('args', args.toString());
        localStorage.setItem('appURL', window.location.hostname.toString());
        localStorage.setItem(
          'redirectSinSession',
          `${window.location.hostname}/auth/callback`,
        );
        if (!window.localStorage.getItem('lastUrl'))
          window.localStorage.setItem('lastUrl', window.location.href);
        else {
        }
        window.location.href = `${ssoURL}/oauth/authorize?` + args.toString();
      });
    } else {
    }
  };
  return (
    <div className='withOut'>
      {/*  <h3 className="section-title">Diversidad</h3> */}
      <div className='row align-items-center'>
        <div className='col-lg-6 order-2 order-lg-1'>
          <div className='imgLanding text-black'>
            <img
              src={`../../../../resources/without-image.png`}
              className='img-fluid landing-image'
              alt='landing'
            />
          </div>
        </div>
        <div className='col-lg-6 order-1 order-lg-2'>
          <div className='logSection'>
            {/*            <img
              className=" img-fluid logo_without"
              src={`../../../../customs/${domain.color}/logo.png`}
            />*/}

            <div className='title-without'>
              <h1 className='big-text'>
                For us, <span>partners</span> of the most amazing zone.
              </h1>
              <div className='hr'></div>
            </div>
            <h2 className='subtitle-without'>
              Discover an exclusive experience for our{' '}
              <strong>MAZpartners</strong>
            </h2>
            <div className='text-end my-3'>
              <button
                className='btn login-without btn-secondary'
                onClick={login}>
                I want to login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LandingUserWithSession;
