import React, { useEffect, useState } from 'react';
import { Bar, HorizontalBar } from 'react-chartjs-3';
import { capitalizeFirstLetter } from '../../../services/services';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
const newLegendClickHandler = function (e: any) {
  console.log('HOOVER:  ', e);
};
const footer = (tooltipItems: any) => {
  let sum = 0;
  console.log('FOOTER:   ', tooltipItems);
  // tooltipItems.forEach(function(tooltipItem) {
  //   sum += tooltipItem.parsed.y;
  // });
  return 'Sum: ' + sum;
};
const optionsMobile = {
  tooltips: {
    callbacks: {
      label: (item: any, data: any) => {
        let index = item?.datasetIndex;
        return (
          data.datasets[index]?.label +
          ': ' +
          parseFloat(item?.value || '0')?.toFixed(2) +
          '%'
        );
      },
      title: (item: any, data: any) => {
        return capitalizeFirstLetter(item[0]?.label);
      },
    },
  },
  indexAxis: 'y' as const,
  plugins: {
    title: {
      display: true,
    },
  },
  legend: {
    display: false,
    position: 'bottom',
    labels: {
      padding: 0,
      fontColor: '#FFF',
      usePointStyle: false,
      pointStyle: 'line',
    },
  },
  responsive: true,

  scales: {
    xAxes: [
      {
        gridLines: {
          color: 'rgba(255,255,255,0.6)',
          zeroLineColor: 'rgba(255,255,255,0.6)',
          tickMarkLength: 1,
        },
        stacked: true,
        ticks: {
          fontColor: '#fff',
          fontSize: '9',
          padding: 10,
          suggestedMax: 100,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: false,
        },
        barThickness: 5,
        stacked: true,
        ticks: {
          fontColor: '#fff',
          stepSize: 25,
          max: 100,
          fontSize: '8',
          padding: -5,
          callback: function (value: any, index: any, ticks: any) {
            if (value == 'NA') return 'NoData';
            else return value + ' %';
          },
        },
      },
    ],
  },
  labels: {
    fontColor: '#FFF',
  },
};
const optionsWeb = {
  axis: 'Y',
  tooltips: {
    callbacks: {
      label: (item: any, data: any) => {
        let index = item?.datasetIndex;
        return (
          data.datasets[index]?.label +
          ': ' +
          parseFloat(item?.value || '0')?.toFixed(2) +
          '%'
        );
      },
      title: (item: any, data: any) => {
        return capitalizeFirstLetter(item[0]?.label);
      },
    },
  },

  animations: {
    animations: {
      radius: {
        duration: 400,
        easing: 'linear',
        loop: (context: any) => context.active,
      },
    },
  },
  plugins: {
    title: {
      display: true,
    },
  },

  legend: {
    display: false,
    position: 'right',
    labels: {
      padding: 15,
      fontColor: '#FFF',
      usePointStyle: true,
      pointStyle: 'circle',
    },
  },
  responsive: true,
  scales: {
    xAxes: [
      {
        gridLines: {
          color: 'rgba(255,255,255,0.6)',
          zeroLineColor: 'rgba(255,255,255,0.6)',
          tickMarkLength: 0,
        },
        stacked: true,
        barThickness: 50,
        ticks: {
          fontColor: '#fff',
          padding: 10,

          callback: function (value: any, index: any, ticks: any) {
            return value;
          },
          max: 100,
          stepSize: 25,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          color: 'rgba(255,255,255,0.6)',
          zeroLineColor: 'rgba(255,255,255,0.6)',
          tickMarkLength: 0,
        },
        barThickness: 10,
        stacked: true,
        ticks: {
          stepSize: 25,
          max: 100,
          fontColor: '#fff',
          fontSize: '12',
          callback: function (value: any, index: any, ticks: any) {
            if (value == 'NA') return 'NoData';
            else return value + ' %';
          },
        },
      },
    ],
  },
  labels: {
    fontColor: '#FFF',
  },
};
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function UserHorizontalBar(props: any) {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  const [dimensioned, setDimensioned] = useState(getWindowDimensions());
  const [flag, setflag] = useState(true);
  const [options, setOptions] = useState<any>(null);

  function handleResize() {
    setWindowDimensions(getWindowDimensions());
  }
  window.addEventListener('resize', handleResize);
  const elem = () => {};

  useEffect(() => {
    if (windowDimensions?.width < 575) {
      setflag(false);
      setOptions(optionsMobile);
      setTimeout(() => {
        setflag(true);
      }, 100);
    } else {
      setflag(false);
      setOptions(optionsWeb);
      setTimeout(() => {
        setflag(true);
      }, 100);
    }
  }, []);

  const { dataInput, labels, print } = props;
  const data = {
    labels: labels?.map((label:string)=>{ return label?.toLowerCase()?.charAt(0)?.toUpperCase()+ label?.toLowerCase()?.slice(1)}),
    datasets: dataInput,
    categoryPercentage: 1,
    barPercentage: 1,
  };

  if (flag)
    return (
      <div>
        <HorizontalBarChartRender data={data} options={options} print={print}/>
      </div>
    );
  else return <LoadSpinner />;
}

export const HorizontalBarChartRender = (props: any) => {
  const { options, data, print} = props;

  return (
    <div>
      {/* <HorizontalBar data={data} options={options} /> */}
     {!print && <Bar options={options} data={data} />}

      <div className='row justify-content-md-center mt-3 ms-1'>
        {data?.datasets?.map((d: any, dIndex: number) => {
          return (
            <div className='col-6 col-md-auto' key={dIndex}>
              <div className='legend-items'>
                <div>
                  <span style={{ backgroundColor: d?.backgroundColor }}></span>
                </div>
                <div>{d?.label || ''}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
