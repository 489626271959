import React from 'react';
import { apiMainURL } from '../../../modules/settings/environment';

import './Gallery.scss';
import GalleryItem from './GalleryItem';

interface GalleryProps {
  title?: string;
  desc?: string;
  galleryObjects?: {
    date: string;
    title: string;
    url: string;
    id: string;
    drupalId: string;
    hasLike: boolean;
  }[];
  parentId?: number;
  actualDateId?: number;
}

const Gallery = ({ galleryObjects, parentId, actualDateId }: GalleryProps) => {
  return (
    <div className='gallery'>
      <div className='gallery-objects-container'>
        {galleryObjects &&
          galleryObjects.map(({ title, url, id, drupalId, hasLike }) => (
            <GalleryItem
              {...{ title, id, drupalId, hasLike }}
              key={drupalId}
              src={`${apiMainURL}${url}`}
              parent={parentId?.toString() || ''}
              date={actualDateId?.toString() || ''}
            />
          ))}
      </div>
    </div>
  );
};

export default Gallery;
