import React, { useEffect, useState } from 'react';

import {
  getAdjacentPagesOnAnyGallery,
  getEventLikes,
  postGalleryEventLike,
  useGetEventByIdQuery,
} from '../../../../../services/api/api-socios-maz';
import { apiMainURL } from '../../../../../modules/settings/environment';
import { Link, useSearchParams } from 'react-router-dom';

import HeartLike from '../../../../UI/HeartLike';

import './ImageView.scss';

interface ImageViewProps {
  id: string;
  actualDate: string;
  reload: () => void;
}

const bearer = localStorage.getItem('localToken')?.toString() || '';

const ImageView = ({ id, actualDate, reload }: ImageViewProps) => {
  const [searchParams] = useSearchParams();

  const [nearIds, setNearIds] = useState({ next: '', prev: '' });
  const [liked, setLiked] = useState({ isFavorite: false, count: 0 });

  const idDate = searchParams.get('idDate');
  const idParent = searchParams.get('idParent');

  const eventData = useGetEventByIdQuery(
    JSON.stringify({ galleryId: idParent?.toString(), eventId: id }),
  ).data;
  const drupalId = eventData?.data[0]?.attributes?.drupal_internal__nid;

  const next = `/lpc/gallery?id=${nearIds.next}&idDate=${idDate}&idParent=${idParent}`;
  const previous = `/lpc/gallery?id=${nearIds.prev}&idDate=${idDate}&idParent=${idParent}`;
  const handleLikeClick = async () => {
    const response = await postGalleryEventLike(drupalId, liked.isFavorite);

    setLiked((prevState) => ({
      isFavorite: response?.isFavorite!,
      count: prevState.count + (response?.count || 0),
    }));
    reload();
  };
  const item = `${apiMainURL}${eventData?.included?.at(0).attributes.uri.url}`;
  const dateFormatted = new Date(actualDate).toLocaleDateString('en-GB', {
    timeZone: 'UTC',
  });

  const onDownload = async () => {
    const header = new Headers();

    header.append('authorization', `Bearer ${bearer}`);

    const requestOptions: RequestInit = {
      method: 'GET',
      headers: header,
    };
    const image = await fetch(
      item + `?${new Date().getTime()}`,
      requestOptions,
    );
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    const link = document.createElement('a');
    link.href = imageURL;
    link.download =
      eventData?.data[0]?.attributes?.title.replace(' ', '-') ||
      'gallery-image';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const getData = async () => {
      const adjacentEvents = await getAdjacentPagesOnAnyGallery(+drupalId, {
        field_galeria: 'Galería',
        field_fecha: +(idDate || ''),
        field_parent: +(idParent || ''),
      });
      setNearIds(adjacentEvents);
    };

    getData();
  }, [idDate, idParent, drupalId]);
  useEffect(() => {
    const getData = async () => {
      const likeData = await getEventLikes(drupalId);

      setLiked({
        isFavorite: likeData?.isFavorite || false,
        count: +likeData?.count || 0,
      });
    };

    getData();
  }, [drupalId]);

  return (
    <>
      <div className='image-shadow-box' />
      <div className='main-content image-view-content'>
        <div className='container-fluid'>
          <Link to='/lpc/gallery'>
            <i className='bi bi-x' />
          </Link>
          <div className='img-vw-carousel'>
            <Link className={!nearIds.prev ? 'disabled' : ''} to={previous}>
              <i className='bi bi-chevron-left' />
            </Link>
            <img src={item} alt='Loading...' />
            <Link className={!nearIds.next ? 'disabled' : ''} to={next}>
              <i className='bi bi-chevron-right' />
            </Link>
          </div>
          <div className='img-vw-desc'>
            <div className='date'>
              <h6>{dateFormatted}</h6>
            </div>
            <div className='buttons'>
              <HeartLike
                isLiked={liked.isFavorite}
                onClick={handleLikeClick}
                howManyLikes={liked.count}
                hasLikesCounter
              />
              <button onClick={onDownload}>
                <i className='bi bi-download' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageView;
