import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Carousel, CarouselControl, CarouselItem } from 'reactstrap';
import { apiMainURL } from '../../../../modules/settings/environment';
import { getLPCContentSections } from '../../../../services/api/api-socios-maz';
import { GetBodyInnerHTML } from '../../../Layouts/default-section/getDefaultElements';
import BreadCrumb from '../../../UI/BreadCrumb';

import './ContentPresentation.scss';

type ContentPresentationState = {
  title: string;
  date: string;
  resume: string;
  body: string;
  imgs: { src: string }[];
};

const ContentPresentation = () => {
  const { presentationId } = useParams();

  const [presentation, setPresentation] = useState<ContentPresentationState>({
    title: '',
    date: '',
    resume: '',
    body: '',
    imgs: [{ src: '' }],
  });

  const items = [
    {
      altText: 'Slide 1',
      caption: 'Slide 1',
      key: 1,
      src: 'https://picsum.photos/id/123/1200/600',
    },
    {
      altText: 'Slide 2',
      caption: 'Slide 2',
      key: 2,
      src: 'https://picsum.photos/id/456/1200/600',
    },
    {
      altText: 'Slide 3',
      caption: 'Slide 3',
      key: 3,
      src: 'https://picsum.photos/id/678/1200/600',
    },
  ];
  const crumbs = [
    { path: '/home', label: 'MAZ Partners' },
    { path: '/lpc', label: 'LPC' },
    { path: '/lpc/content', label: 'Content LPC 2022' },
    { label: presentation.title },
  ];

  const [animating, setAnimating] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const next = () => {
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  // useEffect(() => {
  //   const getData = async () => {
  //     const {
  //       data: [data],
  //       ...otherData
  //     } = await getLPCContentSections(
  //       presentationId || '',
  //       'field_categoria, field_imagen_destacada, field_imagenes_presentacion',
  //       undefined,
  //       'id',
  //     );

  //     setPresentation({
  //       title: `${data.attributes.title} - ${
  //         otherData.included.filter(
  //           ({ type }: { type: string }) =>
  //             type === 'taxonomy_term--lpc_content_category',
  //         )[0].attributes.name
  //       }`,
  //       date: new Date(data.attributes.changed).toLocaleDateString('en-GB', {
  //         year: '2-digit',
  //         month: '2-digit',
  //         day: '2-digit',
  //       }),
  //       resume: data.attributes.body.summary,
  //       body: data.attributes.body.value,
  //       imgs: otherData.included
  //         .filter(({ id }: { id: string }) =>
  //           [
  //             data.relationships.field_imagen_destacada.data.id,
  //             ...data.relationships.field_imagenes_presentacion.data.map(
  //               ({ id }: { id: string }) => id,
  //             ),
  //           ].includes(id as never),
  //         )
  //         .map((e: any) => ({ src: `${apiMainURL}${e.attributes.uri.url}` })),
  //     });
  //   };

  //   getData();
  // }, [presentationId]);

  const slides = presentation.imgs.map(({ src }: { src: string }) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={`carousel-${Math.random()}`}>
        <img className='carousel-img' src={src} alt={src} />
      </CarouselItem>
    );
  });

  return (
    <div className='main-content lpc-bg'>
      <div className='container-fluid'>
        <BreadCrumb {...{ crumbs }} />
        <h1 className='text-center mb-4'>{presentation.title}</h1>
        <Carousel
          className='event-description-carousel mx-auto'
          {...{ next, previous, activeIndex }}
          interval={false}>
          {slides}
          <CarouselControl
            direction='prev'
            directionText='<'
            onClickHandler={previous}
          />
          <CarouselControl
            direction='next'
            directionText='>'
            onClickHandler={next}
          />
        </Carousel>
        <p className='mt-4 text-muted presentation-date'>{presentation.date}</p>
        <p className='mb-0 fw-bolder'>{presentation.resume}</p>
        {<GetBodyInnerHTML content={presentation.body} />}
        <div className='d-flex justify-content-end'>
          <a className='download-link px-3 py-1' href='#presentation'>
            <span>
              <i className='bi bi-file-earmark-arrow-down me-2' />
            </span>
            Download The Presentation
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContentPresentation;
