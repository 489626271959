import React from 'react';

import './InfographicStepper.scss';

type InfographicStepperProps = {
  steps: { label: string; isActive: boolean }[];
};

const InfographicStepper = ({ steps }: any) => {
  return (
    <div className='row px-lg-5 binnacle-stepper'>
      {steps.map(({ label = '', isActive = false }, index: number) => {
        const activeClass = isActive && 'active';

        return (
          <div className='col px-0 binnacle-step' key={index}>
            <div {...{ ...(isActive && { className: activeClass as string }) }}>
              <span className='m-auto'>{label}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default InfographicStepper;
