import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { store as storeApp } from '../../../../../../../modules/appState/store';
import { postMenuItemsData } from '../../../../../../../services/api/api-socios-maz';
import { GetBodyInnerHTML } from '../../../../../../Layouts/default-section/getDefaultElements';
import NoDataFound from '../../../../../../Layouts/NoDataFound';
import AccordionItem from '../../../../../../UI/Accordion/index';
import { RangeValue } from '../../../../../../UI/Range-value';
import { TabsCategory } from '../../index';

interface element {
  name: string;
  partnerValue: string;
  teamValue: string;
  description: string;
}
interface groupElements {
  name: string;
  partnerValue: string;
  teamValue: string;
  child: element[];
  description: string;
}
export const LeaderShipPrint = (props: any) => {
  const [role, setRole] = useState<Array<string>>([]);
  const [data2, setData2] = useState<any>(undefined);

  const { data, personality, dataExt, restricted, title, hasFilters } = props;
  const [elems, setElems] = useState<groupElements[]>([]);
  const [team, setTeam] = useState<boolean>(true);
  const [teamSlider, setTeamSllider] = useState<boolean>(true);
  const [indexState, setIndex] = useState<number[]>(
    [...Array(5)].map((x: number, indexX: number) => indexX),
  );
  const [flagRender, setflagRender] = useState<boolean>(true);
  function inicFilters(p: any) {
    let array: number[] = [];
    let inn = 0;
    Object?.entries(p?.data || []).map((i: any, indexArray: number) => {
      inn = inn + 1;
      array.push(inn);
    });
    setflagRender(false);
    setIndex(array);
    setflagRender(true);
  }

  const getDataProfile = async () => {
    if (data2) {
      const resData = await postMenuItemsData()
        ?.then((response: any) => response.text())
        .then((result) => {
          setData2(JSON.parse(result));
          setRole([JSON.parse(result)?.role]);
        });
    }
  };

  function handleEvent(day: any) {
    if (!indexExist(day)) {
      setflagRender(false);
      setIndex([...indexState, day]);
      setflagRender(true);
    } else {
      setflagRender(false);
      removeIndex(day);
      setflagRender(true);
    }
  }
  function removeIndex(i: number): void {
    let array: number[] = [];
    indexState.filter((elem: any) => {
      if (elem != i) {
        array.push(elem);
      }
    });
    setIndex(array);
  }

  function indexExist(i: number): boolean {
    let exist = false;
    indexState.filter((elem: any) => {
      if (elem == i) {
        exist = true;
      }
    });
    return exist;
  }
  useEffect(() => {
    getDataProfile();
    let group: groupElements[] = [];
    let childs: element[] = [];
    if (personality?.data) {
      Object?.entries(personality?.data).map((i: any, indexItem: number) => {
        childs = [];
        if (i[1]) {
          Object?.entries(i[1]).map((i2: any, indexItem: number) => {
            if (i2[0] != '' && i2[0] != 'name') {
              if (i2[1]?.name && i2[1]['Partner'])
                childs.push({
                  name: i2[1]?.name || '',
                  partnerValue: i2[1]['Partner'] || '',
                  teamValue: i2[1]['Team'] || '',
                  description: i2[1]['description'] || '',
                });
            }
          });
          group.push({
            name: i[1]['name'] || '',
            partnerValue: i[1]['Partner'] || '',
            teamValue: i[1]['Team'] || '',
            child: childs,
            description: i[1]['description'] || '',
          });
        }
      });
      setElems(group);
    }
  }, [data, personality, indexState]);
  return (
    <div className='row print-margin'>
      <div
        className='col-12'
        style={{
          pageBreakBefore: 'always',
          pageBreakInside: 'auto',
        }}>
        {title != '' && (
          <div className='my-md-5 my-3'>
            <h1 className='text-center'>Leadership Style</h1>
          </div>
        )}
        <div className='text-content py-4 text-center'>
          Your leadership style will depend on factors such as the team’s
          physiognomy, the psychometric profile, or the company’s culture.
          Understand which style your team perceives in you and which one you
          consider you have yourself.
        </div>
      </div>
      <div className='col-12 mb-4'>
        <div className='desc-partner-view '>
          <div className='text-gold fs-4'>Partner View</div>
          {data?.tipo_lider && data?.tipo_lider?.name ? (
            <>
              <div className='text-gold fs-4 mt-3 text-bold'>
                {data?.tipo_lider?.name || ''}
              </div>
              <div className=''>
                <div className='text-content text-bold mb-2'>
                  {data?.tipo_lider?.subtitulo || ''}
                </div>
                <span className='p-generic'>
                  <GetBodyInnerHTML
                    content={
                      data?.tipo_lider?.description || ''
                    }></GetBodyInnerHTML>
                </span>
              </div>
              <div className='bg-gold-color section-list-partner d-md-none d-block'>
                <div className='desc-partner-view'>
                  <div className='text-black text-bold pt-2 te'>
                    <GetBodyInnerHTML
                      content={
                        data?.tipo_lider?.complemento || ''
                      }></GetBodyInnerHTML>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <NoDataFound />
          )}
        </div>
        <div
          className='desc-team-view'
          style={{
            pageBreakAfter: 'always',
          }}>
          <div className='text-gold fs-4'>Team View</div>
          {data?.tipo_lider_equipo && data?.tipo_lider_equipo?.name ? (
            <>
              <div className='text-gold fs-4 mt-3 text-bold'>
                {data?.tipo_lider_equipo?.name || ''}
              </div>
              <div className=''>
                <div className='text-content text-bold mb-2'>
                  {data?.tipo_lider_equipo?.subtitulo || ''}
                </div>
                <span className='p-generic'>
                  <GetBodyInnerHTML
                    content={
                      data?.tipo_lider_equipo?.description || ''
                    }></GetBodyInnerHTML>
                </span>
              </div>
              <div className='bg-gold-color section-list-partner d-md-none d-block '>
                <div className='desc-team-view'>
                  <div className='text-black text-bold'>
                    <GetBodyInnerHTML
                      content={
                        data?.tipo_lider_equipo?.complemento || ''
                      }></GetBodyInnerHTML>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <NoDataFound />
          )}
        </div>
        <div className='bg-gold-color section-list-partner d-md-block d-none'>
          <div className='desc-partner-view'>
            <div className='text-black text-bold'>
              <GetBodyInnerHTML
                content={
                  data?.tipo_lider?.complemento || ''
                }></GetBodyInnerHTML>
            </div>
          </div>
          <div className='desc-team-view'>
            <div className='text-black text-bold'>
              <GetBodyInnerHTML
                content={
                  data?.tipo_lider_equipo?.complemento || ''
                }></GetBodyInnerHTML>
            </div>
          </div>
        </div>
      </div>
      {props?.data && props?.data?.list && (
        <div className='col-12 col-md-10 mx-auto'>
          <div className='accordion-partner'>
            <AccordionItem items={props?.data?.list} title=' '></AccordionItem>
          </div>
        </div>
      )}
      {data?.graphic_code ? (
        <div className='col-12'>
          <div className='border-0 py-4'>
            <div className='row align-items-center'>
              <div className='mb-5'>
                {/* TODO */}

                <img
                  src={`data:image/jpg;charset=utf8;base64,${data.graphic_code}`}
                  className='img-fluid'
                  alt='SymLog'
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  gridTemplateRows: '1fr 1fr',
                  maxWidth: 100 + '%',
                  flexWrap: 'wrap',
                }}>
                <div
                  className='items-plane-graph'
                  style={{
                    width: 33 + '%',
                  }}>
                  <div className='items-plane-color'>
                    <span></span>
                  </div>{' '}
                  <p className='text-content'>How your team perceives you*</p>
                </div>
                <div
                  className='items-plane-graph'
                  style={{
                    width: 33 + '%',
                  }}>
                  <div className='items-plane-color'>
                    <span className='items-text'></span>
                  </div>{' '}
                  <p className='text-content'>How do you perceive yourself?</p>
                </div>
                <div
                  className='items-plane-graph'
                  style={{
                    width: 33 + '%',
                  }}>
                  <div className='items-plane-color'>
                    <span className='items-text2'></span>
                  </div>{' '}
                  <p className='text-content'>
                    Your ideal according to the position
                  </p>
                </div>
                <p className='my-3 text-content'>
                  * Each of the circles of your team’s perception corresponds to
                  a person who evaluated you.
                </p>
              </div>
            </div>
          </div>
          <div className='row new-page no-top-margin' />
        </div>
      ) : (
        <NoDataFound />
      )}
      <div>
        {((restricted &&
          !role.includes('directivo') &&
          !role.includes('super_directivo')) ||
          !restricted) && (
          <div className='col-12'>
            {hasFilters && (
              <div
                className='text-content content-leadership text-center'
                style={{
                  paddingTop: 12 + 'rem',
                }}>
                Next, you will find the personality features that may impact
                your leadership style, which ones you consider to have, to what
                degree and how your team perceives them in you.
                <span className='text-bold'>
                  {' '}
                  Hide or enable the graphs by using the following buttons.
                </span>
              </div>
            )}
            {elems?.length > 0 && (
              <div className='section-dark-gray section-partner-owner mt-5'>
                {/*
                        Tabs
                    */}
                <div className='row'>
                  {hasFilters && elems && elems.length > 0 && flagRender && (
                    <div className='col-12 text-center mb-5 multiple-content-page'>
                      <div className='btns-views multiple-content-page'>
                        {elems?.map((el: any, elIndex: number) => {
                          return (
                            <div
                              className='btns-views-items buttonCategory'
                              key={elIndex}>
                              {indexExist(elIndex) && (
                                <button
                                  id='btn-date-schedule'
                                  className={
                                    'btn btn-outline-orange btn-outline-orange-hover'
                                  }
                                  onClick={() => {
                                    handleEvent(elIndex);
                                  }}>
                                  {el?.name}
                                </button>
                              )}
                              {!indexExist(elIndex) && (
                                <button
                                  id='btn-date-schedule'
                                  className={'btn btn-outline-orange'}
                                  onClick={() => {
                                    handleEvent(elIndex);
                                  }}>
                                  {el?.name}
                                </button>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  <div
                    className=''
                    style={{
                      display: 'flex',
                      gridTemplateRows: '1fr 1fr',
                      maxWidth: 100 + '%',
                      flexWrap: 'wrap',
                    }}>
                    <div
                      className='col-md-6 text-md-end text-center'
                      style={{
                        width: 50 + '%',
                      }}>
                      <div className='ind-partner mb-md-0 mb-3'>
                        <span></span>
                        Partner View
                      </div>
                    </div>
                    <div
                      className='col-md-6 text-md-start text-center mb-3 mb-md-0 '
                      style={{
                        width: 50 + '%',
                      }}>
                      {teamSlider && (
                        <div className='ind-team'>
                          <span></span>
                          Team View
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    className='fs-small'
                    style={{
                      display: 'flex',
                      gridTemplateRows: '1fr 1fr',
                      maxWidth: 100 + '%',
                      flexWrap: 'wrap',
                    }}>
                    {flagRender &&
                      elems?.map((el: any, index: number) => {
                        if (indexExist(index)) {
                          return (
                            <div
                              className={`${
                                elems.length - 1 == index ? 'mt-5 pt-5' : 'mt-5'
                              }`}
                              key={index}
                              style={{
                                width: 100 + '%',
                                pageBreakAfter:
                                  index % 2 == 0 ? 'always' : 'avoid',
                              }}>
                              <div className='main-progress-bar horizontal-progress-bar'>
                                <div
                                  className='title-progress-bar text-gold text-lg-end text-center'
                                  data-tip
                                  data-for={'items-gm' + index}>
                                  {el?.name || ''}
                                </div>
                                {el?.description != '' && (
                                  <ReactTooltip
                                    id={'items-gm' + index}
                                    className='ttp-w'>
                                    <strong>{el?.name + ':  '}</strong>
                                    <div className='tw fs-small'>
                                      <GetBodyInnerHTML
                                        content={
                                          el?.description
                                        }></GetBodyInnerHTML>
                                    </div>
                                  </ReactTooltip>
                                )}
                                <RangeValue
                                  partnerValue={el?.partnerValue || '0'}
                                  teamValue={el?.teamValue || '0'}
                                  team={teamSlider}></RangeValue>
                                {el?.child?.map(
                                  (e: any, indexChild: number) => {
                                    return (
                                      <div key={indexChild}>
                                        <div
                                          className='title-progress-bar text-lg-end text-center'
                                          data-tip
                                          data-for={
                                            'items-ls' +
                                            (index +
                                              '' +
                                              (indexChild + index) +
                                              '' +
                                              indexChild)
                                          }>
                                          {e?.name || ''}
                                        </div>

                                        {e?.description != '' && (
                                          <ReactTooltip
                                            id={
                                              'items-ls' +
                                              (index +
                                                '' +
                                                (indexChild + index) +
                                                '' +
                                                indexChild)
                                            }
                                            className='ttp-w'>
                                            <strong>{e?.name + ':  '}</strong>

                                            <div className='tw'>
                                              <GetBodyInnerHTML
                                                content={
                                                  e?.description
                                                }></GetBodyInnerHTML>
                                            </div>
                                          </ReactTooltip>
                                        )}
                                        <RangeValue
                                          partnerValue={e?.partnerValue || '0'}
                                          teamValue={e?.teamValue || '0'}
                                          team={teamSlider}></RangeValue>
                                      </div>
                                    );
                                  },
                                )}
                              </div>
                            </div>
                          );
                        }
                      })}
                  </div>
                </div>
              </div>
            )}
            <div
              style={{
                display: 'flex',
                gridTemplateRows: '1fr 1fr',
                maxWidth: 100 + '%',
                flexWrap: 'wrap',
                paddingTop: 20 + 'px',
              }}>
              {flagRender &&
                elems?.map((el: any, index: number) => {
                  if (indexExist(index)) {
                    return (
                      <div
                        key={index}
                        style={{
                          width: 50 + '%',
                          paddingTop: 20 + 'px',
                          paddingRight: 40 + 'px',
                        }}>
                        <div className='main-progress-bar horizontal-progress-bar '>
                          <h2 className='glosary-title fs-4 lh-sm'>
                            {el?.name || ''}
                          </h2>
                          {el?.description != '' && (
                            <div className=''>
                              <div className=''>
                                <GetBodyInnerHTML
                                  content={el?.description}></GetBodyInnerHTML>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
