import React from 'react';
import UserBarDashboard from '../chartJs/LineChart';

type SingleLineGraphProps = {
  title: string;
  titleClassName?: string;
  labels: string[];
  data: { label: string; color?: string; value: number[] }[];
  colors?: string[];
  hasLegend?: boolean;
  max?: number;
  stepSize?: number;
};

const SingleLineGraph = ({
  title,
  titleClassName,
  labels,
  data,
  colors = ['#f5e003', '#bebebe', '#e5b611'],
  hasLegend,
  max,
  stepSize,
}: SingleLineGraphProps) => {
  const titleClass = `tw mb-3 text-center ${titleClassName}`;

  const assignData = (label: string, color: string, data: number[]) => ({
    label,
    fill: false,
    lineTension: 0.1,
    backgroundColor: color,
    borderColor: color,
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: color,
    pointBackgroundColor: color,
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: color,
    pointHoverBorderColor: color,
    pointHoverBorderWidth: 2,
    pointRadius: 5,
    pointHitRadius: 10,
    data,
  });

  const orderedData = data.map(({ color, label, value }, i) => {
    const getId: (p: number) => number | number = (pos: number) =>
      pos > colors.length ? getId(pos - colors.length) : pos;

    return assignData(label, color || colors[getId(i)], value);
  });

  return (
    <div>
      <p className={titleClass}>{title}</p>
      <div className='mx-1 mx-sm-4'>
        <UserBarDashboard
          {...{ labels, hasLegend, max, stepSize }}
          datasets={orderedData}
          hasYGridLines={true}
          legendPosition='bottom'
        />
      </div>
    </div>
  );
};

export default SingleLineGraph;
