import React, { useState } from 'react';
import { capitalizeFirstLetter } from '../../../../../../services/services';
import { GetBodyInnerHTML } from '../../../../../Layouts/default-section/getDefaultElements';
import { Accordion } from '../../../../../UI/Accordion/Accordion';
import BreadCrumb from '../../../../../UI/BreadCrumb';
import { GenMAZData } from '../../../Partners-profile/Partner-profile/Categories/GenMaz-item/GenMazData';
import { LeaderShipData } from '../../../Partners-profile/Partner-profile/Categories/LeaderShip-item/LeaderShipData';
import { EmployeeData } from '../My-profile/employee-data';

export const DetailTeamPrint = React.memo(
  React.forwardRef((props: any, ref: any) => {
    const { idUser, data, archetypes, date } = props;
    const arqData = data?.dataArchetypes;
    const crumbs = [
      { path: '/home', label: 'MAZ Partners' },
      { path: '/partners/partner-profile', label: 'Partner profile' },
      { path: '/partners/team-profiles', label: 'Team Profiles' },
      { label: 'Detail' },
    ];
    const [items, setItems] = useState<object[]>([]);

    return (
      <div
        className='pdf-content main-profile-executives'
        id='top'
        ref={ref}
        style={{
          width: 90 + '%',
          marginLeft: '2rem',
        }}>
        <div className=''>
          <div
            className='row '
            style={{
              paddingLeft: 'auto',
              paddingRight: 'auto',
            }}>
            <div className='section-dark-gray' style={{ height: 60 + 'rem' }}>
              <h3 className='text-report text-center mt-3 text_center'>
                This is the report of {date}
              </h3>
              <div
                className='row '
                style={{
                  display: 'flex',
                  gridTemplateRows: '1fr 1fr',
                  paddingBottom: 2 + 'rem',
                  paddingLeft: 5 + 'rem',
                }}>
                <div
                  className='col-md-5 text-center'
                  style={{
                    width: 40 + '%',
                    flex: '0 0 auto',
                    float: 'left',
                  }}>
                  <div
                    className='box-profile-executives '
                    style={{
                      width: 100 + '%',
                      paddingLeft: 2 + 'rem',
                    }}>
                    <div className='icon-boxtext-partner'>
                      <img
                        src={
                          data?.dataArchetypes?.userpicture ||
                          `../../../../resources/perfil-default.png`
                        }
                        className='img-fluid'
                      />
                    </div>
                    <div className='tw text-bold fs-4 '>
                      {data?.dataArchetypes?.username}
                    </div>
                    <div
                      className=''
                      style={{
                        margin: 'auto',
                      }}>
                      <div>
                        Bu:
                        <span className='tw'>{data?.dataArchetypes?.bu}</span>
                      </div>
                      <div>
                        Area:
                        <span className='tw'>{data?.dataArchetypes?.area}</span>
                      </div>
                      <div>
                        Role:
                        <span className='tw'>
                          {data?.dataArchetypes?.position}
                        </span>
                      </div>
                      {/* <div>
                                                Band: <span className="tw">{data?.dataArchetypes?.band||'xx'}</span>
                                            </div> */}
                    </div>
                  </div>
                </div>

                <div
                  className='col-md-5 text-center'
                  style={{
                    width: 40 + '%',
                    flex: '0 0 auto',
                    float: 'left',
                  }}>
                  <div
                    className='box-profile-executives '
                    style={{
                      width: 100 + '%',
                      paddingLeft: 2 + 'rem',
                    }}>
                    <div className='icon-boxtext-partner'>
                      <img
                        src={
                          data?.dataArchetypes?.archetype?.image ||
                          `../../../../resources/perfil-default.png`
                        }
                        className='img-fluid'
                      />
                    </div>
                    <div className='tw text-bold fs-4 '>Archetype</div>
                    <div
                      className=''
                      style={{
                        margin: 'auto',
                      }}>
                      <div>
                        <span className='tw'>
                          {' '}
                          {data?.dataArchetypes?.archetype?.name}
                        </span>
                      </div>

                      {/* <div>
                                                Band: <span className="tw">{data?.dataArchetypes?.band||'xx'}</span>
                                            </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='tw arch_description'
                style={{
                  paddingLeft: 2 + 'rem',
                  paddingRight: 4 + 'rem',
                  paddingBottom: 7 + 'rem',
                  fontSize: '100',
                }}>
                <GetBodyInnerHTML
                  content={
                    data?.dataArchetypes?.archetype?.descripcion
                  }></GetBodyInnerHTML>
              </div>
            </div>

            <div
              className=''
              style={{
                width: 100 + '%',
                padding: 0,
                pageBreakBefore: 'always',
              }}>
              <div>-</div>
              <div
                className='section-dark-gray '
                style={{
                  marginTop: 2 + 'rem',
                  marginBottom: 1 + 'rem',
                }}>
                <h2
                  className='text-center'
                  style={{
                    paddingTop: 2 + 'rem',
                  }}>
                  Archetype Composition
                </h2>
                <div
                  className='row text-center'
                  style={{
                    display: 'flex',
                    gridTemplateRows: '1fr 1fr',
                    paddingBottom: 2 + 'rem',
                    paddingTop: 2 + 'rem',
                    width: 95 + '%',
                    paddingLeft: 3 + 'rem',
                  }}>
                  {arqData?.data
                    ?.sort(function (a: any, b: any) {
                      return b?.value - a?.value;
                    })
                    .map((arch: any, indexarch: number) => {
                      return (
                        <div
                          className='col-12 col-md text-center'
                          key={indexarch}
                          style={{
                            width: 20 + '%',
                            flex: '0 0 auto',
                            float: 'left',
                          }}>
                          <div className={'box-profile-executives'}>
                            <div className='tw pt-3 pt-md-0'>
                              <div className='text-gold fs-2 text-bold'>
                                {parseFloat(arch?.value || 0)?.toFixed(0) +
                                  ' %'}
                              </div>
                              <div
                                className='tw'
                                style={{
                                  fontSize: 20,
                                  paddingTop: 3 + 'px',
                                  fontWeight: 'bold',
                                }}>
                                {capitalizeFirstLetter(arch?.name || '')}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

              <GenMAZData {...{ date }} printFlag={true} team={true} />
              <LeaderShipData
                {...{ date }}
                restricted={true}
                printFlag={true}
                team={true}></LeaderShipData>
            </div>
          </div>
        </div>
      </div>
    );
  }),
);
const trigger = () => {};
