import React from 'react';
import { Link } from 'react-router-dom';
import { NavItem } from 'reactstrap';

type NavigationItemProps = {
  url: string;
  Icon: React.FunctionComponent;
  label: string;
  onClick?: () => void;
  outsideURL?: boolean;
};

const NavigationItem = ({
  Icon,
  label,
  url,
  onClick,
  outsideURL,
}: NavigationItemProps) => {
  return (
    <NavItem {...{ onClick }} data-bs-dismiss='offcanvas'>
      {!outsideURL ? (
        <Link to={url} className='nav-link'>
          <Icon />
          <span>{label}</span>
        </Link>
      ) : (
        <a href={url} className='nav-link' target='_blank' rel='noreferrer'>
          <Icon />
          <span>{label}</span>
        </a>
      )}
    </NavItem>
  );
};

export default NavigationItem;
