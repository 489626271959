import React, { useEffect, useState } from 'react';
import { GenMAZ } from '.';
import { postGenMAZ } from '../../../../../../../services/api/api-socios-maz';
import { store as storeApp } from '../../../../../../../modules/appState/store';
import { useLocation } from 'react-router-dom';
import { GenMAZPrint } from './GenMazPrint';
import { GenMAZPrintTeam } from './GenMazPrintTeam';

export const GenMAZData = (props: any) => {
  const location = useLocation();
  const { printFlag, team, date } = props;

  const [user, setUser] = useState(storeApp.getState().AppReducer?.userData);
  const [data, setData] = useState({});
  storeApp.subscribe(() => {
    setUser(storeApp?.getState()?.AppReducer?.userData || '');
  });

  useEffect(() => {
    if (user?.drupal_id && user?.drupal_id != '') getDataProfile();
  }, [user, date]);

  const getDataProfile = async () => {
    //TODO: Id
    let idUser2 = '';
    if (
      (location?.pathname == '/partners/partner-profile/detail' ||
        location?.pathname == '/partners/team-profiles/detail') &&
      location?.search?.split('?')[1]
    ) {
      idUser2 = location?.search?.split('?')[1];
    } else {
      idUser2 = user?.drupal_id || '';
    }
    const response = await postGenMAZ(idUser2 || '', { date })
      ?.then((response: any) => response.text())
      .then((result) => {
        setData(JSON.parse(result));
      });
  };

  // const pData=useGetPartnerProfileByIdQuery(user?.drupal_id);
  // console.log("Data del use:   ", user);
  // console.log("Data del endpoint:  ",pData);
  if (user?.drupal_id != '' && data)
    return (
      <div className='attributes-break'>
        {!printFlag && <GenMAZ idUser={user?.drupal_id} data={data} />}
        {printFlag && !team && (
          <GenMAZPrint idUser={user?.drupal_id} data={data} />
        )}
        {printFlag && team && (
          <GenMAZPrintTeam idUser={user?.drupal_id} data={data} />
        )}
      </div>
    );
  else return <></>;
};
