import React from 'react';

import './BinnacleYearning.scss';

import { ReactComponent as Yearning } from '../../../../../assets/Binnacle/Yearning.svg';
import { GetBodyInnerHTML } from '../../../../Layouts/default-section/getDefaultElements';

const BinnacleYearning = (props: any) => {
  const { aprendizaje } = props;
  return (
    <div className='row mx-3 mx-md-0 binnacle-yearning'>
      <div className='col-md-3 d-flex flex-column'>
        <Yearning />
        <h2 className='text-center tw mt-2'>Aprendizaje</h2>
      </div>
      {aprendizaje && (
        <div className='col-md-9'>
          <GetBodyInnerHTML content={aprendizaje} />
        </div>
      )}
    </div>
  );
};

export default BinnacleYearning;
