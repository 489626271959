import React, { FormEvent, useState } from 'react';
import { LegacyRef } from 'react';

type NewCommentFormProps = {
  onSubmit: (evt: FormEvent, comment: string) => void;
  textareaRef?: LegacyRef<HTMLTextAreaElement>;
};

const NewCommentForm = ({ onSubmit, textareaRef }: NewCommentFormProps) => {
  const [comment, setComment] = useState('');

  const submitComment = (e: FormEvent) => {
    setComment('');
    onSubmit(e, comment.trim());
  };
  const onInputCommentChange = ({ target: { value } }: { target: any }) => {
    setComment(value);
  };

  return (
    <form action='comment' onSubmit={submitComment}>
      <textarea
        ref={textareaRef}
        className='border-0 rounded-3 w-100 py-1 px-2 comment-area'
        placeholder='Write a comment...'
        name='comment'
        id='1'
        cols={20}
        rows={7}
        value={comment}
        onChange={onInputCommentChange}
      />
      <div className='d-flex justify-content-center'>
        <button className='border-0 rounded-3 py-1 px-2 mt-2' type='submit'>
          Comment
        </button>
      </div>
    </form>
  );
};

export default NewCommentForm;
