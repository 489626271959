import React from 'react';
import ReactPaginate from 'react-paginate';

type PaginatorProps = {
  onPageChange?: (e: any) => void;
  pageCount: number;
  nextLabel?: string;
  prevLabel?: string;
};

export const Paginator = ({
  pageCount,
  onPageChange,
  nextLabel = '',
  prevLabel = '',
}: PaginatorProps) => {
  return (
    <ReactPaginate
      {...{ onPageChange, pageCount, nextLabel }}
      breakLabel='...'
      breakClassName='page-item'
      breakLinkClassName='page-link'
      nextClassName='page-item'
      nextLinkClassName='page-link'
      previousLabel={prevLabel}
      previousClassName='page-item'
      previousLinkClassName='page-link'
      pageClassName='page-item'
      pageLinkClassName='page-link'
      activeClassName='active'
      containerClassName='pagination'
      pageRangeDisplayed={2}
      marginPagesDisplayed={2}
    />
  );
};
