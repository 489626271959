import React, { useEffect } from 'react'
import { ToastProvider, useToasts } from 'react-toast-notifications';

export const AlertsGenerator = (props: any) => {

    const { message, type } = props;
    const { addToast } = useToasts();

    useEffect(() => {
        switch (type) {
            case 'error':
                addToast(message || 'Default error', { appearance: 'error', autoDismiss: true, transitionDuration: 1000 });
                break;
            case 'warning':
                addToast(message || 'Default Warning', { appearance: 'warning', autoDismiss: true, transitionDuration: 1000 });
                break;
            default:
                addToast(message || 'Default', { appearance: 'warning', autoDismiss: true, transitionDuration: 1000 });
                break;
        }

    }, [])




    return (
        <></>
    )
}
