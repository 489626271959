import React from 'react';

import './BinnacleGoals.scss';
import { GetBodyInnerHTML } from '../../../Layouts/default-section/getDefaultElements';

const BinnacleGoals = (props: any) => {
  const { texto, anio } = props;
  return (
    <div className='binnacle-goals'>
      {/* <h1>My Goals/Purpose</h1>
      <button className='goals-plus-button'>+</button> */}
      <div className='row'>
        <div className='col-md-3 d-flex flex-column justify-content-center goals-logo-container'>
          <div />
          {/* <h2>2023</h2> */}
        </div>
        <div className='col-md-9 goals-desc'>
          <h1>Job to be done</h1>
          {anio && (
            <h2>{anio}</h2>
          )}
          {texto && (
            <GetBodyInnerHTML
              content={texto}
            />
          )}
          {/* <h3>Aspects to work:</h3>
          <p className='aspects'>Creativity - Management - Team Work</p> */}
        </div>
      </div>
    </div>
  );
};

export default BinnacleGoals;
