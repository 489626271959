import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../../../../UI/BreadCrumb';
import { Link, useLocation } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import SearchBar from '../../../../../UI/SearchBar';
import { capitalizeFirstLetter } from '../../../../../../services/services';

export const PartnerProfileSuperExec = (props: any) => {
  const { crumbs } = props;

  const location = useLocation();
  const [text, setText] = useState('');
  const [flag, setFlag] = useState(true);

  const { title, description, usersData, link } = props;
  const [users, setUsers] = useState<any[]>([]);

  useEffect(() => {
    const getDataUrl = () => {
      const params =
        location?.search && location.search?.split('?')[1]?.split('&');
      const filtersObjects =
        params &&
        params?.map((p: string) => {
          const nameFilter = p?.split('=')[0] || '';
          const values = p?.split('=')[1].split('+') || [];

          return { nameFilter, values };
        });

      return filtersObjects && filtersObjects;
    };

    function isFilterUser(
      user: any,
      filtersData: { nameFilter: string; values: string[] }[],
    ) {
      let include = true;

      for (const { nameFilter = '', values = [''] } of filtersData) {
        const filterMinuscula = nameFilter.toLowerCase();

        if (!values.includes(`${user[`${filterMinuscula}_id`]}`)) {
          include = false;
        }
      }

      return include;
    }

    const filtersData: '' | { nameFilter: string; values: string[] }[] =
      getDataUrl();

    if (usersData) {
      const userArray = filtersData
        ? Object.entries(usersData)
          .map((user) => {
            if (user[1] && isFilterUser(user[1], filtersData)) return user[1];

            return null;
          })
          .filter((v) => v)
        : usersData;

      setFlag(false);
      setUsers(userArray);
      setTimeout(() => {
        setFlag(true);
      }, 10);
    }
  }, [location, usersData]);

  return (
    <div className='main-super-executives'>
      <div className='container-fluid'>
        {flag && (
          <ListUsersFiltereds
            title={title}
            description={description}
            users={users}
            link={link}
          />
        )}
      </div>
    </div>
  );
};

export const ListUsersFiltereds = (props: any) => {
  const { title, description, users, welcomeData, link } = props;
  const [flag, setFlag] = useState(true);
  const [welcome, setWelcome] = useState(welcomeData);
  const [usersRender, setUsersRender] = useState<any[]>(users);
  ///////////   INICIO DE PAGINATOR
  //////////// FIN PAGINATOR
  const search = (e: any) => {
    let users2: any[] = [];
    if (e?.target?.value) {
      if (welcomeData) {
        setWelcome(false);
      }
      Object?.entries(users).forEach((us: any, indexItem: number) => {
        let add = true;

        Object?.entries(us[1]).map((user: any, indexItem: number) => {
          if (
            typeof user[1] == 'string' &&
            user[1]?.toLowerCase()?.includes(e?.target?.value?.toLowerCase()) &&
            add
          ) {
            users2.push(us[1]);
            add = false;
          }
          if (
            typeof user[1] == 'number' &&
            user[1].toString().includes(e?.target?.value?.toLowerCase()) &&
            add
          ) {
            users2.push(us[1]);
            add = false;
          }
        });
      });
      setUsersRender(users2);
    } else {
      if (welcomeData) {
        setWelcome(true);
      }
      setUsersRender(users);
    }
  };
  return (
    <div>
      <div>
        <div className='col-md-10 mx-auto'>
          <SearchBar onChange={search} placeholder='Who are you looking for?' />
        </div>
        {!welcome && (
          <div className='row'>
            {title && description && (
              <div className='col-md-10 mx-auto mt-4'>
                <div className='section-title text-bold mb-3 fs-4'>{title}</div>
                <div className='text-content mb-4 text-center'>
                  {description}
                </div>
              </div>
            )}
            <div className='col-12 mt-4'>
              <div className='section-gray-v2'>
                {flag && usersRender.length > 0 && (
                  <div className='text-center pagination-white'>
                    <PaginatedItems
                      itemsPerPage={5}
                      items={usersRender}
                      link={link}
                    />
                  </div>
                )}
                {flag && usersRender.length == 0 && (
                  <h1 className='text-center mb-5 tw'>Information not found</h1>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {welcome && (
        <div className='col-md-11 mx-auto mt-4'>
          <div className='section-gray-v2'>
            <div className='row justify-content-center align-items-center'>
              <div className='col-md-5 text-center'>
                <img
                  src={`../../../../resources/archetype/welcome-partnetprofile.svg`}
                  className='img-fluid mx-5'
                />
              </div>
              <div className='col-md-7'>
                <div className='section-title fs-4 text-start mb-3 text-bold'>
                  How to access the results?
                </div>
                <div className='tw'>
                  <strong>Click on the icon in the upper left corner</strong>,
                  and find out the results that will help you to have a better
                  understanding of your team´s personality and how their team
                  perceives them.
                  <br />
                  <br />
                  The report consists of 3 pillars:
                  <br />
                  1. Personality traits
                  <br />
                  2. Leadership style
                  <br />
                  3. Attributes of the macro entrepreneur
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const RenderList = (props: any) => {
  const { usersRender, link } = props;
  const Toast = Swal.mixin({
    toast: true,
    position: 'center',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  useEffect(() => { }, [usersRender]);

  return (
    <div className=''>
      {usersRender && usersRender.length > 0 && (
        <div className='main-content-result'>
          {Object?.entries(usersRender).map((u: any, indexU: number) => {
            if (u[1]?.archetype)
              return (
                <Link to={link + '?' + u[1]?.id} key={indexU}>
                  <ItemUser user={u[1]} />
                </Link>
              );
            else
              return (
                <div
                  className=''
                  key={indexU}
                  onClick={() =>
                    Toast.fire({
                      icon: 'error',
                      title:
                        'This user doesn´t have information about indicators and archetypes.',
                    })
                  }>
                  <ItemUser user={u[1]} />
                </div>
              );
          })}
        </div>
      )}
    </div>
  );
};

function PaginatedItems(props: any) {
  const { itemsPerPage, items, link } = props;
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <RenderList usersRender={currentItems} link={link} />
      <div className=''>
        <ReactPaginate
          nextLabel=''
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel=''
          pageClassName='page-item'
          pageLinkClassName='page-link'
          previousClassName='page-item'
          previousLinkClassName='page-link'
          nextClassName='page-item'
          nextLinkClassName='page-link'
          breakLabel='...'
          breakClassName='page-item'
          breakLinkClassName='page-link'
          containerClassName='pagination'
          activeClassName='active'
        />
      </div>
    </>
  );
}

export const ItemUser = (props: any) => {
  const { user } = props;
  return (
    <div>
      <div className='box-description-results'>
        <div className='box-description-img'>
          <div className='img-perfil'>
            <img
              src={
                user?.userimage && user?.userimage != ''
                  ? user?.userimage
                  : `../../../../resources/perfil-default.png`
              }
              className='img-fluid'
            />
          </div>
        </div>
        <div className='main-text-results align-middle'>
          <div className='box-description-name text-left '>
            <span>{user?.name}</span>
          </div>
          <div className='box-description-text'>
            {user?.bu && (
              <div className='tw text-left'>
                <div className="row">
                  <div className="col col-sm-2 ml-2 ">
                    <span className='text-gold'>Bu:</span>
                  </div>
                  <div className="col col-10 tw">
                    {user?.bu}
                  </div>
                </div>
              </div>
            )}
            {user?.area && (
              <div className='tw text-left'>
                <div className="row">
                  <div className="col col-sm-2 ml-2 ">
                    <span className='text-gold'>Area:</span>
                  </div>
                  <div className="col col-10 tw">
                    {user?.area}
                  </div>
                </div>
              </div>
            )}
            {user?.position && (
              <div className='tw text-left'>
                <div className="row">
                  <div className="col col-sm-2 ml-2 ">
                    <span className='text-gold'>Rol:</span>
                  </div>
                  <div className="col col-10 tw text-left">
                    {capitalizeFirstLetter(user?.position?.toLowerCase()||'')}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='box-description-icon'>

          <div className='icon-boxtext-partner'>
            <img
              src={
                user?.imageArchetype && user?.imageArchetype != ''
                  ? user?.imageArchetype
                  : `../../../../resources/lpc/event/No _data.svg`
              }
              width='150'
              className='img-fluid'
            />

          </div>{user?.archetype_year && (<small>Last result:</small>)}
          <div className="tw text-center">
            {user?.archetype_year?.substring(0, 7) || ''}
          </div>
        </div>
      </div>
    </div>
  );
};
