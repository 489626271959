import { Section } from './section.models';

export type StepperCard = { title: string; description: string; icon: string };
export type Category = { id: string; nid: number; title: string };
export type Banner = { alt: string; img: string; outerURL: string };
export type LoyaltyState = {
  title: string;
  subtitle: string;
  description: string;
  loyaltyImgs: string[];
  loyaltyStepperCards: StepperCard[];
  banner: Banner;
  categories: Category[];
  sections: Section[] | [];
  activeSection: string | null;
  isLoading: boolean;
  bu: string;
};
export type LoyaltyPayload = {
  title?: string;
  subtitle?: string;
  description?: string;
  loyaltyImgs?: string[];
  loyaltyStepperCards?: StepperCard[];
  banner?: Banner;
  categories?: Category[];
  sections?: Section[] | [];
  activeSection?: string | null;
  isLoading?: boolean;
  bu?: string;
};
export type LoyaltyAction = {
  type: LoyaltyActionCases;
  payload?: LoyaltyPayload;
};

export enum LoyaltyActionCases {
  INIT_BASE_PAGE = 'INIT_BASE_PAGE',
  SET_SECTIONS = 'SET_SECTIONS',
  SET_CATEGORIES = 'SET_CATEGORIES',
  CHANGE_ACTUAL_SECTION = 'CHANGE_ACTUAL_SECTION',
  SET_LOAD = 'SET_LOAD',
  ON_BU_LOAD = 'ON_BU_LOAD',
}
