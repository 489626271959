import React, { useEffect } from 'react';
import { useGetHomeDefaultContentQuery } from '../../../services/api/api-socios-maz';
import { getDefaultContent } from '../../Layouts/default-section/getDefaultElements';
import BreadCrumb from '../../UI/BreadCrumb';
import { CarouselCards } from '../../UI/CarouselCards';

export const CultureSquads = () => {
  const dataapi: any = useGetHomeDefaultContentQuery('squads').data;
  const elems2: any = getDefaultContent(dataapi);

  const crumbs = [
    { path: '/home', label: 'MAZ Partners' },
    { label: 'Culture Squads' },
  ];
  useEffect(() => {}, []);

  return (
    <div className='main-content'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <BreadCrumb {...{ crumbs }} />
          </div>

          {elems2?.content[0]?.type == 'paragraph--horizontal_card_images' && (
            <div className='col-md-8'>
              <img
                src={elems2?.content[0]?.content?.image1 || ''}
                alt=''
                height='150'
              />
            </div>
          )}
          <div className='col-md-4'>
            <img
              src={elems2?.content[0]?.content?.image2 || ''}
              alt=''
              height='150'
            />
          </div>
        </div>
      </div>
      {elems2?.content[1]?.type == 'cardsGroup' && (
        <div className='carousel-squads'>
          <div className='container-fluid my-4'>
            <div className='row'>
              <div className='col-11 mx-auto position-relative'>
                <CarouselCards
                  items={elems2?.content[1]?.content}></CarouselCards>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
