export type SelectorState = { label: string; value: string };
export type ProfileItem = {
  titleId: number;
  name: string;
  description: string;
  isNew?: boolean;
  itemId?: number;
};
export type FavoriteItem = { id: number; icon: string; name: string };
export type UpdateState = {
  activeNewSection: boolean;
  name: string;
  nick: string;
  position: string;
  zone: string;
  beer: string;
  area: string;
  img: string;
  bu: string;
  idNumber: string;
  email: string;
  beers: FavoriteItem[] | [];
  hobbies: FavoriteItem[] | [];
  items: ProfileItem[];
  video: string;
  selectionHobbies: SelectorState[];
  selectionBeers: SelectorState[];
  selectionItems: SelectorState[];
  requiredItems: { [key: string]: any }[];
};

export enum UpdateCases {
  TOGGLE_NEW_SECTION = 'TOGGLE_NEW_SECTION',
  INIT_SELECTORS = 'INIT_SELECTORS',
  ON_SELECTOR_CHANGE_OR_DELETE = 'ON_SELECTOR_CHANGE_OR_DELETE',
  ON_SELECTOR_CHANGE = 'ON_SELECTOR_CHANGE',
  ON_OBJECT_DELETE = 'ON_OBJECT_DELETE',
  CHANGE_ITEM_BY_NAME = 'CHANGE_ITEM_BY_NAME',
  SET_NEW_ITEM = 'SET_NEW_ITEM',
  UPDATE_SELECTOR_ITEMS = 'UPDATE_SELECTOR_ITEMS',
  DELETE_ITEM = 'DELETE_ITEM',
  SET_REQUIRED_ITEMS = 'SET_REQUIRED_ITEMS',
  SET_HOBBIES_TO_SELECT = 'SET_HOBBIES_TO_SELECT',
}

export type UpdateAction = {
  type: UpdateCases;
  payload?: any;
};
