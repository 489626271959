import GalleryCard from '../../pages/MazResources/Gallery/GalleryCard';

import Carousel from 'react-multi-carousel';
import { Resource } from '../../pages/MazResources/Gallery/GalleryReducer';

type RelatedContentProps = {
  relatedResources: Resource[];
};

const RelatedContent = ({ relatedResources }: RelatedContentProps) => {
  return (
    <>
      <h2>Related Content</h2>
      {relatedResources.length !== 0 ? (
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={3000}
          containerClass='container-with-dots'
          draggable
          infinite
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          responsive={{
            superLargeDesktop: {
              breakpoint: { max: 4000, min: 3000 },
              items: 5,
            },
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 3,
            },
            mobile: {
              breakpoint: {
                max: 576,
                min: 0,
              },
              items: 1,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 576,
              },
              items: 2,
            },
          }}
          shouldResetAutoplay
          slidesToSlide={1}
          swipeable>
          {relatedResources.map((args: Resource) => (
            <GalleryCard key={`related-${args.title}-${args.id}`} {...args} />
          ))}
        </Carousel>
      ) : (
        <div className='w-100'>
          <p className='text-center'>No elements Found.</p>
        </div>
      )}
    </>
  );
};

export default RelatedContent;
