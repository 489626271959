import React from 'react';
import ReactGoogleSlides from 'react-google-slides';
import Audio from './Audio';
import { PresentationTypes } from './PresentationReducer';

type MainResourceManagerProps = {
  type: PresentationTypes;
  src: string;
  audioBanner?: string;
};

const MainResourceManager = ({
  type,
  src,
  audioBanner,
}: MainResourceManagerProps) => {
  switch (type) {
    case 'PRESENTATION': {
      return (
        <>
          {src && (
            <ReactGoogleSlides slidesLink={src} position={1} showControls />
          )}
        </>
      );
    }

    case 'VIDEO': {
      return <video {...{ src }} autoPlay controls />;
    }

    case 'AUDIO': {
      return (
        <>
          {audioBanner && <img src={audioBanner} alt='maz-p-banner' />}
          <Audio {...{ src }} />
        </>
      );
    }
  }

  return <div>MainResourceManager</div>;
};

export default MainResourceManager;
