import React from 'react';

import './GeneralInfo.scss';

interface GeneralInfoProps {
  items: {
    SVG: React.FC;
    label: string;
    information: string;
  }[];
}

const GeneralInfo = ({ items }: GeneralInfoProps) => {
  return (
    <div className='container-fluid'>
      <div className='row'>
        {items.map(({ SVG, information, label }) => (
          <div className='col-4 general-info-item'>
            <SVG />
            <div>
              <p>{label}</p>
              <h6>{information}</h6>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GeneralInfo;
