import React from 'react';

type ButtonProps = {
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  label?: string;
  disabled?: boolean;
  children?: string;
  onClick?: () => void;
};

const Button = ({
  className,
  type = 'button',
  label,
  disabled = false,
  children,
  onClick,
}: ButtonProps) => {
  const newClassName = `btn ${className ?? 'btn-primary'}`;

  return (
    <button {...{ type, disabled, onClick }} className={newClassName}>
      {children || label}
    </button>
  );
};

export default Button;
