import React from 'react';
import { Progress } from 'reactstrap';

export const RangeValue = (props: any) => {
  const { partnerValue, teamValue, team, showValues = false } = props;

  const parseAndReplace = (v: any) =>
    parseFloat(v?.replace(',', '.')).toFixed(1);

  const floatPartnerValue = parseAndReplace(partnerValue);
  const floatTeamValue = parseAndReplace(teamValue);

  return (
    <div className='content-progress-bar'>
      <div className='partner-progress-bar bar-progress position-relative'>
        {partnerValue && (
          <>
            <Progress value={parseFloat(partnerValue) * 20}>
              <div className='percentage'>{floatPartnerValue}</div>
            </Progress>
            <span
              className='position-absolute top-0 end-0 tw'
              style={{ transform: 'translate(40px,-5px)' }}>
              {floatPartnerValue}
            </span>
          </>
        )}
      </div>

      {team && (
        <div className='team-progress-bar bar-progress position-relative'>
          <>
            <Progress className='mt-3' value={parseFloat(teamValue) * 20}>
              <div className='percentage'>{floatTeamValue}</div>
            </Progress>
            <span
              className='position-absolute top-0 end-0 tw'
              style={{ transform: 'translate(40px,-5px)' }}>
              {floatTeamValue}
            </span>
          </>
        </div>
      )}
      <div className='sutitle-info-progress-bar'>
        <div>1</div>
        <div>2</div>
        <div>3</div>
        <div>4</div>
        <div>5</div>
      </div>
    </div>
  );
};
