import React, { useEffect, useState } from 'react';

import './BinnacleInfographic.scss';
import BinnaclePartnerInfo from './BinnaclePartnerInfo';
import BinnacleArchetypeQuestions from './BinnacleArchetypeQuestions';
import BinnacleAreas from './BinnacleAreas';
import BinnacleTensions from './BinnacleTensions';
import BinnacleYearning from './BinnacleYearning';
import { store } from '../../../../modules/appState/store';
import { getImage } from '../../../Layouts/default-section/getDefaultElements';
import { useGetBinnacleOrientationsQuery } from '../../../../services/api/api-socios-maz';

const src = require('../../../../assets/Binnacle/TestingImages/Hero.png');

const BinnacleInfographic = (props: any) => {
  const orientationsData = useGetBinnacleOrientationsQuery('')?.data;
  const [user, setUser] = useState(store.getState().AppReducer?.userData);
  store.subscribe(() => {
    setUser(store?.getState()?.AppReducer?.userData || '');
  });
  const { bitacora } = props;
  const arquetipo = getArchetype(bitacora?.included?.filter((inc: any) => inc?.type == 'taxonomy_term--pp_archetypes'));
  function getArchetype(data: any) {
    let archName = '';
    let archSummary = '';
    let archImage = '';
    if (data) {
      archName = data[0]?.attributes?.name || '';
      archSummary = data[0]?.attributes?.field_descripcion_sumario || '';
      archImage = getImage(data[0]?.relationships?.field_imagen_bitacora?.data?.id, bitacora?.included);
    }

    return {
      name: archName,
      summary: archSummary,
      image: archImage
    }
  }
  useEffect(() => {

  }, [bitacora, user]);
  return (
    <div className='mx-lg-4 px-lg-3 binnacle-infographic'>
      <div className='container-fluid'>
        {arquetipo && (
          <div className='row'>
            <div className='col-4 binnacle-img-container'>
              <img src={`${arquetipo?.image}`} alt='archetype' />
            </div>
            <div className='col-8'>
              <div className='row'>
                <div className='binnacle-title'>{user?.name}</div>
              </div>
              <div className='row'>
                <div className='col-8 col-md-9 binnacle-desc'>
                  <h2>{arquetipo?.name}</h2>
                  <p className='mb-md-4'>
                    {arquetipo?.summary}
                  </p>
                </div>
                <div className='col col-md-3 binnacle-line'>
                  <div />
                </div>
              </div>
            </div>
          </div>
        )}

        {orientationsData?.data && bitacora?.data && bitacora?.data?.length > 0 && (
          <BinnaclePartnerInfo orientationTaxonomy={orientationsData?.data}
            orientationData={bitacora?.included?.filter((inc: any) => inc?.type == 'taxonomy_term--pp_bitacora_orientaciones')}
            motivacion={bitacora?.data[0]?.attributes?.field_motivacion?.value || ''}
            miedoa={bitacora?.data[0]?.attributes?.field_miedo_a?.value || ''}
            puntoDebil={bitacora?.data[0]?.attributes?.field_punto_debil?.value || ''}
          />
        )}



        {user?.drupal_id && bitacora?.data && bitacora?.data?.length > 0 && (
          <>
            <BinnacleArchetypeQuestions
              userId={user?.drupal_id}
              estiloLiderazgo={bitacora?.data[0]?.attributes?.field_estilo_de_liderazgo || ''}
              estiloTrabajo={bitacora?.data[0]?.attributes?.field_estilo_de_trabajo || ''}
              estiloRelacionamiento={bitacora?.data[0]?.attributes?.field_estilo_relacionamiento || ''}
            />
            <BinnacleAreas
              fortalezas={bitacora?.data[0]?.attributes?.field_fortalezas || []}
              areas={bitacora?.data[0]?.attributes?.field_areas_por_mejorar || []}
            />
            <BinnacleTensions
              tensionesIndividuales={bitacora?.data[0]?.attributes?.field_tensiones_individuales || []}
              tensionesEquipo={bitacora?.data[0]?.attributes?.field_tensiones_equipo || []}
            />
            <BinnacleYearning
              aprendizaje={bitacora?.data[0]?.attributes?.field_aprendizaje?.value || ''}
            />
          </>
        )}



      </div>
    </div>
  );
};

export default BinnacleInfographic;
