import React from 'react';
import './BinnacleArchetypeQuestion.scss';
import { useGetBinnacleArchetypeQuery } from '../../../../../services/api/api-socios-maz';

const BinnacleArchetypeQuestions = (props: any) => {
  const { userId, estiloLiderazgo, estiloTrabajo, estiloRelacionamiento } = props;
  const archData = useGetBinnacleArchetypeQuery(userId || '')?.data;
  return (
    <div className='row mt-3 mb-1 mx-2 my-md-5 binnacle-archetype-questions'>
      {archData && (
        <>
          <div className='col-md-4 d-flex'>
            <h2 className='mb-2 my-md-auto archetype-subheader'>
              Mix de Arquetipos
            </h2>
          </div>
          {
            archData?.map((arch: any, indexArch: number) => {
              return (
                <div className='col-6 d-flex col-md-4 row archetype-section' key={indexArch}>
                  <div className='col-6 mx-auto'>
                    <img src={arch?.face} alt="" className='arch-img mx-auto' />
                  </div>
                  <div className='col-6 mx-auto arch-desc'>
                    <div className='row'>
                      <h2 className='tw text-center'>{arch?.value || 0}%</h2>
                    </div>
                    <div className='row'>
                      <p className='text-center tw'>{arch?.name}</p>
                    </div>
                  </div>
                </div>
              )
            })
          }

          <div className='col-md-4 binnacle-question'>
            <p className='tw text-center'>¿Cuál es tu estilo de liderazgo?</p>
            <h2 className='text-center'>{estiloLiderazgo}</h2>
          </div>
          <div className='col-md-4 binnacle-question'>
            <p className='tw text-center'>¿Cuál es tu estilo de trabajo?</p>
            <h2 className='text-center'>{estiloTrabajo}</h2>
          </div>
          <div className='col-md-4 binnacle-question'>
            <p className='tw text-center'>¿Cuál es tu estilo de relacionamiento?</p>
            <h2 className='text-center'>{estiloRelacionamiento}</h2>
          </div>
        </>
      )}
    </div>
  );
};

export default BinnacleArchetypeQuestions;
