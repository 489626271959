import React from 'react';
import { assignSecondColor } from '.';
import HorizontalBarChart from '../../../../../../UI/chartJs/horizontalBarChart';

type MetricsOPRProps = {
  metrics?: { [key: string]: { [sKey: string]: { avg: number } } };
};

const MetricsOPR = ({
  metrics = { '0': { E: { avg: 0 } } },
}: MetricsOPRProps) => {
  const labels = [];
  const data: { label: string; data: any[]; backgroundColor: string }[] = [];

  for (const [id, [level, v]] of Object.entries(metrics).entries()) {
    labels.push(level);

    for (const [idx, [opr, val]] of Object.entries(v as object)
      .sort(([aK], [bK]) => aK.localeCompare(bK))
      .entries()) {
      const pos = data.findIndex(({ label }) => label === opr);

      if (pos >= 0) data[pos].data.push(val.avg);
      else
        data.push({
          label: opr,
          data: [...Array(id).fill(null), val.avg],
          backgroundColor: assignSecondColor(idx + 1),
        });
    }
  }
  return (
    <>
      <p className='tw mb-3 text-center'>OPR Shape</p>
      <div className='mx-1 mx-sm-4'>
        <HorizontalBarChart {...{ labels }} dataInput={data} />
      </div>
    </>
  );
};

export default MetricsOPR;
