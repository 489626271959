import React from 'react';

import Carousel from 'react-multi-carousel';

import { ReactComponent as Destination } from '../../../../assets/LPC/GetReady/Destination.svg';
import { ReactComponent as Schedule } from '../../../../assets/LPC/GetReady/Schedule.svg';
import { ReactComponent as Photos } from '../../../../assets/LPC/GetReady/Photos.svg';
import { ReactComponent as Wellness } from '../../../../assets/LPC/GetReady/WellnessIcon.svg';
import { ReactComponent as ContentLPC } from '../../../../assets/LPC/GetReady/Content LPC.svg';
import { Link } from 'react-router-dom';
import { useGetWellnessPDFQuery } from '../../../../services/api/api-socios-maz';

const RoundedCardsSection = () => {
  const wellnessPDF = useGetWellnessPDFQuery('')?.data;

  return (
    <div className='lpc-navigation'>
      <div className='lpc-navigation-container'>
        <h1 className='text-carousel'>Know the details of the event:</h1>
        <div className='my-4 carousel-rounded-lpc'>
          <div className='mx-auto position-relative'>
            <Carousel
              additionalTransfrom={0}
              arrows={true}
              autoPlaySpeed={3000}
              centerMode={false}
              containerClass='container-with-dots'
              draggable
              focusOnSelect={false}
              infinite
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 4000,
                    min: 768,
                  },
                  items: 3,
                  partialVisibilityGutter: 40,
                },
                mobile: {
                  breakpoint: {
                    max: 768,
                    min: 0,
                  },
                  items: 3,
                  partialVisibilityGutter: 30,
                },
              }}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              showDots={false}
              slidesToSlide={1}
              swipeable>
              <Link className='d-flex flex-column' to='/lpc/city-and-hotel'>
                <div className='item-icon'>
                  <Destination />
                </div>
                <span>City & Hotel</span>
              </Link>
              <Link className='d-flex flex-column' to='/lpc/schedule'>
                <div className='item-icon'>
                  <Schedule />
                </div>
                <span>Schedule</span>
              </Link>
              <Link className='d-flex flex-column' to='/lpc/gallery'>
                <div className='item-icon'>
                  <Photos />
                </div>
                <span>Gallery</span>
              </Link>
              <Link className='d-flex flex-column' to='/lpc/content'>
                <div className='item-icon'>
                  <ContentLPC />
                </div>
                <span>Content LPC</span>
              </Link>
              {!localStorage.getItem('mobile') && wellnessPDF?.fileRouthe && (
                <a
                  className='d-flex flex-column'
                  href={wellnessPDF?.fileRouthe}
                  target='_blank'>
                  <div className='item-icon'>
                    <Wellness />
                  </div>
                  <span>Wellness Activities</span>
                </a>
              )}
              {localStorage.getItem('mobile') && (
                <Link
                  className='d-flex flex-column'
                  to={'/download-pdf-link?${url}'}>
                  <div className='item-icon'>
                    <Wellness />
                  </div>
                  <span>Wellness Activities</span>
                </Link>
              )}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoundedCardsSection;
