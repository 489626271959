import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import CropImage from '../../profile-old/image/crop-image';

type CropModalProps = {
  isModalOpen: boolean;
  toggleModal: () => void;
};

const CropModal = ({ isModalOpen, toggleModal }: CropModalProps) => {
  return (
    <Modal
      isOpen={isModalOpen}
      toggle={toggleModal}
      className='dialogImage modal-dialog-centered'>
      <ModalHeader toggle={toggleModal}>
        Update your profile picture
      </ModalHeader>
      <ModalBody>
        <CropImage onSave={toggleModal} />
      </ModalBody>
    </Modal>
  );
};

export default CropModal;
