import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { apiMainURL } from '../../../../../../modules/settings/environment';
import {
  postGeneralPartner,
  useGetallArchetypesQuery,
  useGetPartnersProfileDatesQuery,
} from '../../../../../../services/api/api-socios-maz';
import { GetBodyInnerHTML } from '../../../../../Layouts/default-section/getDefaultElements';
import { Accordion } from '../../../../../UI/Accordion/Accordion';
import BreadCrumb from '../../../../../UI/BreadCrumb';
import { GenMAZData } from '../../../Partners-profile/Partner-profile/Categories/GenMaz-item/GenMazData';
import { LeaderShipData } from '../../../Partners-profile/Partner-profile/Categories/LeaderShip-item/LeaderShipData';
import { EmployeeData } from '../My-profile/employee-data';
import { capitalizeFirstLetter } from '../../../../../../services/services';
import { DetailTeamPrint } from './detail-team-print';
import { useReactToPrint } from 'react-to-print';
import LoadSpinner from '../../../../../UI/LoadSpinner/LoadSpinner';
import ProfileDropdown from '../../../ProfileDropdown';
import { store } from '../../../../../../modules/appState/store';

export const DetailTeamProfile = React.forwardRef(() => {
  const location = useLocation();
  const [data, setData] = useState({});
  const [date, setDate] = useState('');
  const [load, setLoad] = useState(true);
  const archetypesListData = useGetallArchetypesQuery()?.data;
  const archetypesList = getArchetypes(archetypesListData);
  const crumbs = [
    { path: '/home', label: 'MAZ Partners' },
    { path: '/profile', label: 'Profile' },
    { path: '/partners/team-profiles', label: 'Team profiles' },
    { label: 'Detail' },
  ];
  function getArchetypes(archData: any): any[] {
    let archeTypes: any[] = [];
    // name: 'MagicianArray',
    // image: 'https://api-socios-maz-dev.somosmaz.com/sites/default/files/2022-07/Captura%20de%20Pantalla%202022-07-22%20a%20la%28s%29%2014.48.18%20%281%29.png',
    // id: '1'
    archData?.data?.map((arch: any) => {
      let idImage = arch?.relationships?.field_archetype_image?.data.id || '';
      let imageFile = '';
      archData?.included?.map((inc: any) => {
        if (inc?.id == idImage) {
          archeTypes.push({
            name: arch?.attributes?.name || '',
            image: apiMainURL + inc?.attributes?.uri?.url || '',
            id: arch?.attributes?.drupal_internal__tid || '',
          });
        }
      });
    });

    return archeTypes;
  }
  useEffect(() => {
    const getDataProfile = async () => {
      // console.log("DRUPAL ID2 profile 3333  ",user?.drupal_id);
      postGeneralPartner(location.search.split('?')[1] || '', {
        date,
      })
        ?.then((response: any) => response.text())
        .then((result) => {
          setLoad(false);
          setData(JSON.parse(result));
        });
    };

    load && getDataProfile();
  }, [date, location.search]);

  const dates = useGetPartnersProfileDatesQuery({
    id: location.search.split('?')[1] || '',
  }).data;

  const dropdownItems: any = useMemo(
    () =>
      dates
        ?.filter(
          ({ fecha_lanzamiento = '' }) => !fecha_lanzamiento.startsWith('0000'),
        )
        .map(({ fecha_lanzamiento = '' }) => ({
          value: fecha_lanzamiento,
          label: fecha_lanzamiento.substring(0, 7),
        })) || [],
    [dates],
  );

  const onClick = useCallback((v: string) => {
    setDate(v);
    setLoad(true);
  }, []);

  useEffect(() => {
    setDate(dropdownItems[0]?.value || '');
  }, [dropdownItems]);

  if (location?.search && archetypesList.length > 0)
    return (
      <div>
        <DetailTeamProfileRender
          {...{ date, onClick, setDate }}
          idUser={location.search.split('?')[1] || ''}
          records={dropdownItems}
          data={data}
          archetypes={archetypesList}
        />
        {load && <LoadSpinner />}
      </div>
    );
  else return <LoadSpinner />;
});
export const DetailTeamProfileRender = React.forwardRef(
  (props: any, ref: any) => {
    const { idUser, data, archetypes, date, onClick, records } = props;
    const arqData = data?.dataArchetypes;
    const crumbs = [
      { path: '/home', label: 'MAZ Partners' },
      { path: '/partners/partner-profile', label: 'Partner profile' },
      { path: '/partners/team-profiles', label: 'Team Profiles' },
      { label: 'Detail' },
    ];

    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      documentTitle: 'Profile: MAZ PARTNERS',
    });

    return (
      <div className='main-content main-profile-executives' id='top'>
        <div className=''>
          <div className='hidden'>
            <DetailTeamPrint
              {...{ date }}
              idUser={idUser || ''}
              data={data}
              archetypes={archetypes}
              ref={componentRef}
            />
          </div>
        </div>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <BreadCrumb {...{ crumbs }} />
            </div>
            {records.length !== 0 && (
              <div>
                <ProfileDropdown {...{ records }} onDropdownClick={onClick} />
              </div>
            )}
            <div className='col-12 mt-4'>
              <div className='section-dark-gray p-4 mb-4'>
                <div className='row'>
                  <div className='col-md-5 text-center'>
                    <div className='box-profile-executives'>
                      <div className='icon-boxtext-partner'>
                        <img
                          src={
                            data?.dataArchetypes?.userpicture ||
                            `../../../../resources/perfil-default.png`
                          }
                          className='img-fluid'
                        />
                      </div>
                      <div className='tw text-bold fs-4 mt-4 pe-3'>
                        {data?.dataArchetypes?.username}
                      </div>
                      <div className=''>
                        <div>
                          Bu:{' '}
                          <span className='tw'>{data?.dataArchetypes?.bu}</span>
                        </div>
                        <div>
                          Area:{' '}
                          <span className='tw'>
                            {data?.dataArchetypes?.area}
                          </span>
                        </div>
                        <div>
                          Role:{' '}
                          <span className='tw'>
                            {data?.dataArchetypes?.position}
                          </span>
                        </div>
                        {/* <div>
                                                Band: <span className="tw">{data?.dataArchetypes?.band||'xx'}</span>
                                            </div> */}
                      </div>
                      <button
                        className='mt-3 btn btn-primary btn-reports btn-dr btn-download-mobile'
                        onClick={handlePrint}>
                        Download Report
                      </button>
                    </div>
                  </div>
                  <div className='col-md-7'>
                    <div className='px-4 tw'>
                      <div className='description-profile'>
                        <div className='img-perfil'>
                          <img
                            src={
                              data?.dataArchetypes?.archetype?.image ||
                              `../../../../resources/perfil-default.png`
                            }
                            className='img-fluid'
                          />
                        </div>
                        <div className='description-profile-tile'>
                          {data?.dataArchetypes?.archetype?.name}
                        </div>
                      </div>
                      <GetBodyInnerHTML
                        content={
                          data?.dataArchetypes?.archetype?.descripcion
                        }></GetBodyInnerHTML>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 mt-4'>
              <EmployeeData {...{ date }} />

              <div className='accordion-partner'>
                <Accordion
                  title={'Archetype Composition'}
                  trigger={() => trigger}>
                  <div className='section-dark-gray p-5 mb-4'>
                    <div className='row'>
                      {arqData?.data?.map((arch: any, indexarch: number) => {
                        return (
                          <div
                            className='col-12 col-md text-center'
                            key={indexarch}>
                            <div
                              className={
                                indexarch ==
                                data?.dataArchetypes?.data?.length - 1
                                  ? ''
                                  : 'box-profile-executives'
                              }>
                              <div className='tw pt-3 pt-md-0'>
                                <div className='text-gold fs-2 text-bold'>
                                  {parseFloat(arch?.value || 0)?.toFixed(2) +
                                    ' %'}
                                </div>
                                {capitalizeFirstLetter(arch?.name || '')}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Accordion>
              </div>
              <div className='accordion-partner'>
                <Accordion
                  title={'Macro Entrepreneur Attributes'}
                  trigger={() => trigger}>
                  <GenMAZData {...{ date }} />
                </Accordion>
              </div>
              <div className='accordion-partner'>
                <Accordion title={'Leadership Style'} trigger={() => trigger}>
                  <LeaderShipData
                    {...{ date }}
                    restricted={false}></LeaderShipData>
                </Accordion>
              </div>
              <a href='#top' className='fill-svg'>
                <img
                  src={`../../../../resources/archetype/arrow_top.svg`}
                  width='50'
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  },
);
const trigger = () => {};
