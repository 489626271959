import { useFormik } from 'formik';
import React, { useEffect, useId, useReducer, useState } from 'react';
import { usePopper } from 'react-popper';
import ReactTooltip from 'react-tooltip';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { store } from '../../../../../modules/appState/store';
import { initialState, reducer } from './calculatorReducer';
import InputRangeSlider from './InputRangeSlider';
const FormulaParser = require('hot-formula-parser').Parser;
const parser = new FormulaParser();

const Percentages = (props: any) => {
  const storeApp = store;

  let dollarUSLocale = Intl.NumberFormat('en-US');
  const { dataCalculator } = props;
  let event: void = props?.event;
  const [sop, setSop] = useState(
    props?.dataCalculator?.sop_range[props?.dataCalculator?.sop_range - 1 || 1],
  );
  const [ep, setEp] = useState(
    props?.dataCalculator?.entity_target_range[
      props?.dataCalculator?.entity_target_range - 1 || 1
    ],
  );
  const [it, setIt] = useState(
    props?.dataCalculator?.individual_range[
      props?.dataCalculator?.individual_range - 1 || 1
    ],
  );
  const [tas, setTas] = useState(0);
  const [bonus, setBonus] = useState(0);
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleOnChangeSOP = (event: any) => {
    setSop(event?.target?.value);
  };
  const handleOnChangeEP = (event: any) => {
    setEp(event?.target?.value);
  };
  const handleOnChangeIT = (event: any) => {
    setIt(event?.target?.value);
  };
  const formik1 = useFormik({
    initialValues: {
      sop: dataCalculator?.sop_range[1] || 0,
    },
    onSubmit: (values) => {},
  });
  const formik2 = useFormik({
    initialValues: {
      etr: dataCalculator?.entity_target_range[1] || 0,
    },
    onSubmit: (values) => {},
  });
  const formik3 = useFormik({
    initialValues: {
      ir: dataCalculator?.individual_range[1] || 0,
    },
    onSubmit: (values) => {},
  });
  useEffect(() => {
    //Total Achievement Score
    let mrs = dataCalculator?.reference_salary;
    parser.setVariable('sop', sop);
    parser.setVariable('ep', ep);
    parser.setVariable('it', it);
    setTas(parser.parse(dataCalculator?.formula_achievement)?.result);
    //Bonus
    parser.setVariable('mrs', dataCalculator?.reference_salary);
    parser.setVariable('tas', tas);
    parser.setVariable('bm', dataCalculator?.band_multiplier);

    parser.setVariable('tdr', localStorage.getItem('tdr') || '7');

    parser.setVariable('di', it);

    console.log(
      dataCalculator?.reference_salary,
      ' *((',
      localStorage.getItem('tdr'),
      ' * ',
      dataCalculator?.band_multiplier,
      ' * (',
      parseInt(localStorage.getItem('tdr') || '0'),
      ' * ',
      8.333,
      ' ) * ',
      it,
      ' )/ 1000000)',
    );
    //mrs * ((tas * bm * (tdr * 8.333) * di)/1000000)
    // console.log("RESULT BONUS:    ", parser.parse(dataCalculator?.formula_bonus));
    // console.log("Datos completos:   ", dataCalculator)
    setBonus(parser.parse(dataCalculator?.formula_bonus)?.result);
    storeApp.dispatch({
      type: 'BONUS',
      payload: {
        bonus: parser.parse(dataCalculator?.formula_bonus)?.result,
        currency: dataCalculator?.currency,
        tas: tas,
      },
    });
  }, [sop, ep, it, tas]);

  return (
    <>
      <InputRangeSlider
        inputValue={formik1.values.sop}
        label='Global SOP'
        max={dataCalculator?.sop_range[1] || 0}
        min={dataCalculator?.sop_range[0] || 0}
        onChange={handleOnChangeSOP}
        onInputChange={formik1.handleChange}
        percentage={sop}
        helpMessage='Size of the Pie determines the amount of funds that are available for bonus payments, and is determined by organic EBITDA growth. The SOP calculation is based on a direct linear correlation between the scale expressed in percentages (60% to 140%) and the respective range of organic EBITDA growth assigned to an entity, and may expressed by the formula below.'
        helpLabel='Size of Pie'
      />
      <InputRangeSlider
        inputValue={formik2.values.etr}
        label='Entity Performance'
        max={dataCalculator?.entity_target_range[1] || 0}
        min={dataCalculator?.entity_target_range[0] || 0}
        onChange={handleOnChangeEP}
        onInputChange={formik2.handleChange}
        percentage={ep}
        helpMessage='Entity targets reflect the contribution of the entity to the overall performance of the company. All individuals within the same entity share the same entity performance targets. The assignation of specific entity targets is based on a cluster model of strategic priorities. This model takes into consideration two markets dimensions, maturity and market share. It gives importance to the Zone/BU/Country realities and missions as the central aspects for defining the targets.'
        helpLabel='Entity Performance'
      />
      <InputRangeSlider
        inputValue={formik3.values.ir}
        label='Individual Targets'
        max={dataCalculator?.individual_range[1] || 0}
        min={dataCalculator?.individual_range[0] || 0}
        onChange={handleOnChangeIT}
        onInputChange={formik3.handleChange}
        percentage={it}
        helpMessage='They are composed of types of numerical, nominal, project or dashboard goals that are calculated according to the result assigned to your role. If you are band I to IV you must obtain a minimum of 35% to be eligible for the bonus, while if you are band V or higher the minimum is 50%.'
        helpLabel='Individual Achievement'
      />
    </>
  );
};

export default Percentages;

export const Help = (props: any) => {
  const { title, text } = props;
  const id = useId();

  return (
    <div>
      <div className='help-container'>
        <button
          className='rounded-circle sti-question pointer-h'
          data-tip
          data-for={id}>
          <i className='bi bi-question-circle' />
        </button>
      </div>

      <ReactTooltip {...{ id }} className='ttp-w'>
        <p className='tw fw-normal text-start'>
          <strong>{title}: </strong>
          {text}
        </p>
      </ReactTooltip>
    </div>
  );
};
