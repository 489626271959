import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { apiMainURL } from '../../../../../modules/settings/environment';
import {
  useGetallArchetypesQuery,
  useGetArchetypeByIdQuery,
} from '../../../../../services/api/api-socios-maz';
import {
  GetBodyInnerHTML,
  getContentsArchetype,
  getDefaultContent,
} from '../../../../Layouts/default-section/getDefaultElements';
import BreadCrumb from '../../../../UI/BreadCrumb';
import { RangePercentage } from '../../../../UI/Range-percentage';
import LoadSpinner from '../../../../UI/LoadSpinner/LoadSpinner';
import ArchetypeCarousel from './ArchetypeCarousel';

export const ArchetypesDetails = () => {
  const [img, setImg] = useState(``);
  const idArchetype: string = window.location.href.split('?')[1] + '';
  const archData = useGetArchetypeByIdQuery(idArchetype)?.data;
  const elems: any = getContentsArchetype(archData);
  const navigate = useNavigate();

  const location = useLocation();
  useEffect(() => {}, [location]);
  const archetypesListData = useGetallArchetypesQuery()?.data;
  const archetypesList = getArchetypes(archetypesListData);
  function getArchetypes(archData: any): any[] {
    let archeTypes: any[] = [];
    // name: 'MagicianArray',
    // image: 'https://api-socios-maz-dev.somosmaz.com/sites/default/files/2022-07/Captura%20de%20Pantalla%202022-07-22%20a%20la%28s%29%2014.48.18%20%281%29.png',
    // id: '1'
    archData?.data?.map((arch: any) => {
      let idImage = arch?.relationships?.field_archetype_image?.data.id || '';
      let imageFile = '';
      archData?.included?.map((inc: any) => {
        if (inc?.id == idImage) {
          if (arch?.attributes?.drupal_internal__tid != idArchetype)
            archeTypes.push({
              name: arch?.attributes?.name || '',
              image: apiMainURL + inc?.attributes?.uri?.url || '',
              id: arch?.attributes?.drupal_internal__tid || '',
            });
        }
      });
    });

    return archeTypes;
  }

  const crumbsProfile = [
    { path: '/home', label: 'MAZ Partners' },
    { path: '/profile/partner-profile', label: 'Profile' },
    { label: 'Archetype' },
  ];
  const crumbsPartners = [
    { path: '/home', label: 'MAZ Partners' },
    { path: '/partners/partner-profile', label: 'Partner Profile' },
    { path: '/partners/archetypes-definition', label: 'Archetypes Definition' },
    { label: 'Archetype' },
  ];
  const crumbsPartnersMyProfile = [
    { path: '/home', label: 'MAZ Partners' },
    { path: '/partners/partner-profile', label: 'Partner Profile' },
    {
      path: '/partners/partner-profile/my-partner-profile',
      label: 'My Profile',
    },
    { label: 'Archetype' },
  ];
  const crumbsPartnersOverview = [
    { path: '/home', label: 'MAZ Partners' },
    { path: '/partners/partner-profile', label: 'Partner Profile' },
    { path: '/partners/overview', label: 'Overview' },
    { label: 'Archetype' },
  ];

  function getImageArchetypes(elems: any): string {
    let imageFile = '';
    if (elems?.data?.length > 0) {
      let imageId =
        elems?.data[0]?.relationships?.field_archetype_image?.data?.id || '';
      let imageFile = '';
      elems?.included?.map((inc: any) => {
        if (inc?.id == imageId) {
          setImg(apiMainURL + inc.attributes.uri.url);
          return apiMainURL + inc.attributes.uri.url;
        }
      });
    }
    return imageFile;
  }
  const onBackClick = () => navigate(-1);

  useEffect(() => {
    getImageArchetypes(archData);
  }, [archData, location]);

  // console.log('DATA EN ARCHETYPE DETAIL:   ', archData);

  if (img != '')
    return (
      <div className='main-content'>
        <div className='container-fluid' id='arrowtop'>
          {location?.pathname ==
            '/profile/partner-profile/archetype-detail' && (
            <BreadCrumb crumbs={crumbsProfile} />
          )}
          {location?.pathname ==
            '/partners/archetypes-definition/archetype-detail' && (
            <BreadCrumb crumbs={crumbsPartners} />
          )}
          {location?.pathname ==
            '/partners/partner-profile/my-partner-profile/archetype-detail' && (
            <BreadCrumb crumbs={crumbsPartnersMyProfile} />
          )}
          {location?.pathname == '/partners/overview/archetype-detail' && (
            <BreadCrumb crumbs={crumbsPartnersOverview} />
          )}
          <div className='row'>
            <div className='col-sm-5 ps-5 '>
              <div className=' img-perfil-archetype'>
                <img src={img} className='img-fluid' />
              </div>
            </div>
            <div className='col-sm-7'>
              <div>
                <div className='text-content mb-3'>
                  <strong>{archData?.data[0]?.attributes?.name || ''}</strong>
                </div>
              </div>
              <div className='text-font'>
                <GetBodyInnerHTML
                  content={
                    archData?.data[0]?.attributes.description.value || ''
                  }></GetBodyInnerHTML>
                <br />
                <br />
                <div className='text-gold text-bold fs-5'>
                  <GetBodyInnerHTML
                    content={
                      archData?.data[0]?.attributes?.field_texto_complemento
                        ?.value || ''
                    }></GetBodyInnerHTML>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='py-5'>
          <div className='container-fluid'>
            {elems && elems.length > 0 && (
              <div>
                {elems?.map((e: any, indexE: number) => {
                  if (e?.content) {
                    switch (e?.type) {
                      case 'cardsGroup':
                        return (
                          <div className='' key={indexE}>
                            {indexE != 1 && (
                              <div className=' section-gray-partners archetype-composing row gx-5 gy-3'>
                                <div className='col-12'>
                                  <h2 className='text-center mb-4'>
                                    The attributes of this archetype
                                  </h2>
                                </div>
                                {e?.content?.map(
                                  (c: any, indexCard: number) => {
                                    return (
                                      <div
                                        className='col-md-4 mx-auto mb-4 text-center'
                                        key={indexCard}>
                                        <div className='icon-boxtext-partner'>
                                          <img
                                            src={c?.src || ''}
                                            className='img-fluid'
                                            width='120'
                                          />
                                        </div>
                                        <div className='tw mt-3'>
                                          <GetBodyInnerHTML
                                            content={
                                              c?.description || ''
                                            }></GetBodyInnerHTML>
                                        </div>
                                      </div>
                                    );
                                  },
                                )}
                              </div>
                            )}
                            {indexE == 1 && <TabsRecommendations data={e} />}
                          </div>
                        );
                        break;
                      case 'horizontalCards':
                        {
                          return (
                            <div className='container-fluid'>
                              {e?.content?.map((hc: any, indexHc: number) => {
                                return (
                                  <div className='row' key={indexHc}>
                                    <div className='col-12 mt-5'>
                                      <h1 className='text-center'>
                                        {hc?.title || ''}
                                      </h1>
                                    </div>
                                    <div className='col-sm-6'>
                                      <div className='text-content py-4'>
                                        <GetBodyInnerHTML
                                          content={
                                            hc?.description || ''
                                          }></GetBodyInnerHTML>
                                      </div>
                                    </div>
                                    <div className='col-sm-6'>
                                      <div className='border-imagen-profile'>
                                        <img
                                          src={hc?.src || ''}
                                          className='img-fluid'
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        }
                        break;
                    }
                  }
                })}
              </div>
            )}
          </div>
          <div className='container-fluid'>
            <div
              className='my-5 text-content mt-5 d-flex'
              onClick={onBackClick}>
              <i className='bi bi-chevron-left fs-3' />
              <p className='fs-5 my-auto'>Back</p>
            </div>
          </div>
          <ArchetypeCarousel archetypes={archetypesList} />
        </div>
      </div>
    );
  else return <LoadSpinner></LoadSpinner>;
};

export const TabsRecommendations = (props: any) => {
  const [indexState, setIndex] = useState(0);

  const { data } = props;
  function handleEvent(day: any) {
    setIndex(day);
  }

  function focusInput() {
    document.getElementById('btn-date-schedule')?.focus();
  }

  return (
    <div className='row mx-auto  mt-5'>
      <div className='col-12 recommendations'>
        <h1 className='section-title'>Recommendations</h1>

        <div className='main-btn-category'>
          <div className='row justify-content-center items_list list-partners'>
            {data?.content?.map((recommendation: any, indexProg: number) => {
              return (
                <div
                  className='col-md-auto buttonCategory items mx-2'
                  key={indexProg}>
                  <button
                    id='btn-date-schedule'
                    className={
                      indexState == indexProg
                        ? 'btn btn-outline-orange btn-outline-orange-hover'
                        : 'btn btn-outline-orange recommendations-disabled'
                    }
                    onClick={() => {
                      handleEvent(indexProg);
                    }}>
                    {recommendation?.title || ''}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        {data?.content?.map((recommendation: any, index: number) => {
          if (index == indexState) {
            return (
              <div key={Math.random()} className=''>
                <div className='container-fluid row recommendation-description mt-5'>
                  <GetBodyInnerHTML
                    content={recommendation?.description || ''}
                  />
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};
