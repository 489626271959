import React from 'react';
import UserDashboard from '../../../../../../UI/chartJs/doughnut';

type GenerationProps = {
  title?: string;
  metrics?: { [key: string]: string | number; avg: number }[];
  backgroundColors?: string[];
  labels?: string;
  labelField?: 'generation' | 'gender';
};

const MetricsDoughnut = ({
  title = '',
  metrics = [{ generation: '', avg: 0 }],
  backgroundColors,
  labelField = 'generation',
}: GenerationProps) => {
  const labels = metrics.map((v) => `${v[labelField]}`);
  const data = metrics.map(({ avg = 0 }) => avg);

  return (
    <>
      <p className='tw mb-2 text-center'>{title}</p>
      <div className='w-100 my-auto'>
        <UserDashboard
          {...{ labels, data }}
          backgroundColor={backgroundColors}
          hasLineLabel={true}
        />
      </div>
    </>
  );
};

export default MetricsDoughnut;
