import {
  UpdateAction,
  UpdateState,
} from '../../../../models/profileUpdate.models';

export const initialState = {
  activeNewSection: false,
  selectionHobbies: [{ label: '', value: '' }],
  selectionBeers: [{ label: '', value: '' }],
  requiredItems: [{}],
};

export const updateReducer = (state: UpdateState, action: UpdateAction) => {
  const { type } = action;

  switch (type) {
    case 'TOGGLE_NEW_SECTION': {
      const { isActive } = action.payload;

      return { ...state, activeNewSection: isActive };
    }

    case 'INIT_SELECTORS': {
      const { itemsToSelect, beersToSelect } = action.payload;

      return {
        ...state,
        selectionItems: itemsToSelect,
        selectionBeers: beersToSelect,
      };
    }

    case 'SET_HOBBIES_TO_SELECT': {
      const { hobbiesToSelect } = action.payload;

      return {
        ...state,
        selectionHobbies: [...state.selectionHobbies, ...hobbiesToSelect],
      };
    }

    case 'ON_SELECTOR_CHANGE': {
      const {
        listToChange,
        value,
        maxSelectValue,
      }: {
        listToChange: 'hobbies' | 'beers';
        value: any;
        maxSelectValue: number;
      } = action.payload;
      const newList = [...state[listToChange]];

      if (
        newList.length < maxSelectValue &&
        !newList.some(({ id }) => id === value.id)
      ) {
        newList.push(value);
      }

      return { ...state, [listToChange]: newList };
    }

    case 'ON_OBJECT_DELETE': {
      const {
        idToDelete,
        listToChange,
      }: { idToDelete: number; listToChange: 'hobbies' | 'beers' } =
        action.payload;

      const newList = [...state[listToChange]].filter(
        ({ id }) => id !== idToDelete,
      );

      return { ...state, [listToChange]: newList };
    }

    case 'CHANGE_ITEM_BY_NAME': {
      const { item, description } = action.payload;
      const newItems = state.items;

      newItems.filter(({ name }) => name === item)[0].description = description;

      return { ...state, items: newItems };
    }

    case 'UPDATE_SELECTOR_ITEMS': {
      return {
        ...state,
        selectionItems: state.selectionItems?.filter(
          ({ value }) => !state.items.some(({ titleId }) => titleId === +value),
        ) || [{ label: '', value: '' }],
      };
    }

    case 'SET_NEW_ITEM': {
      const { newItem } = action.payload;
      const newItems = state.items;

      newItems.push(newItem);

      return { ...state, items: newItems };
    }

    case 'DELETE_ITEM': {
      const { title }: { title: string } = action.payload;
      const newItems = state.items;

      return { ...state, items: newItems.filter(({ name }) => name !== title) };
    }

    case 'SET_REQUIRED_ITEMS': {
      const { requiredItems } = action.payload;

      return { ...state, requiredItems };
    }

    default: {
      return { ...state };
    }
  }
};
