export const client_id = '96281f5d-e920-4f31-ba6c-cc2990eb1a3c';

// export const apiMainURL = 'https://api-socios-maz-dev.somosmaz.com';
// export const ssoURL = 'https://alternadev.isaframe.com';
// export const appURL = 'http://localhost:3000';
// export const appBenefiURL = 'https://api-benefi-dev.somosmaz.com';

export const apiMainURL = process.env.REACT_APP_API_URL;
export const ssoURL = process.env.REACT_APP_SSO_URL;
export const appURL = process.env.REACT_APP_APP_URL;
export const appBenefiURL = process.env.REACT_APP_API_BENEFI_URL;

export const fontMAZ = 'Montserrat';
export const fontLPC = 'Montserrat';
