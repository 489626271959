import React, { useEffect } from 'react';

export default function MediaComponent(props: any) {
    useEffect(() => {
    }, []);
    return (
        <>
            {props?.content.map((element: any, index: number) => {
                return (
                    <div key={index} className="multimedia-zone row justify-content-center align-items-center align-content-md-around">
                        {element.type == "video" && (
                            <div className="ratio ratio-21x9 multimedia-item">
                                <video
                                    src={element.src}
                                    width="100%"
                                    height="500"
                                    controls
                                ></video>
                            </div>
                        )}
                        {element.type == "infografia" && (
                            <div className="multimedia-item">
                                <img
                                    key={index}
                                    src={element.src}
                                    alt="Infogr"
                                    className="rounded img-fluid center"
                                />
                            </div>
                        )}
                    </div>
                )
            })}
        </>
    );
};

