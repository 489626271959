import React from 'react';

import { ReactComponent as Strength } from '../../../../../assets/Binnacle/Strength.svg';
import { ReactComponent as Improvement } from '../../../../../assets/Binnacle/Improvement.svg';

import './BinnacleAreas.scss';

const BinnacleAreas = (props:any) => {
  const {fortalezas, areas}=props;
  return (
    <div className='d-flex mx-3 mx-md-0 justify-content-center binnacle-areas'>
      <div className='row area-desc'>
        <div className='col-5'>
          <Strength />
        </div>
        <div className='col-7'>
          <h2>Fortalezas</h2>
          <ul>
            {fortalezas?.map((fortaleza:any,indexFortaleza:number)=>{
              return(
                <li key={indexFortaleza}>{fortaleza}</li>
              )
            })}
          </ul>
        </div>
      </div>
      <div className='separator' />
      <div className='row area-desc'>
        <div className='col-5'>
          <Improvement />
        </div>
        <div className='col-7'>
          <h2>Áreas por mejorar</h2>
          <ul>
          {areas?.map((area:any,indexArea:number)=>{
              return(
                <li key={indexArea}>{area}</li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BinnacleAreas;
