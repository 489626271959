import { StringMap, t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// TODO Multimedia & Linking
// import { Link } from 'react-router-dom';
// import { Carousel } from 'reactstrap';
import { data, dataCategory, dataItems } from '../../../data/dataLayouts';
import { store as storeApp } from '../../../modules/appState/store';
import { apiMainURL, appURL } from '../../../modules/settings/environment';
import {
  deleteSession,
  getUserData,
  useGetHomeDefaultContentQuery,
} from '../../../services/api/api-socios-maz';
import {
  getDefaultContent,
  GetBodyInnerHTML,
} from '../../Layouts/default-section/getDefaultElements';
import CarouselView from '../../UI/Carousel/carousel';
import { RoundedCarouselCards } from '../../UI/CarouselCards/RoundedCarousel';
import { Categorycards } from '../../UI/Category-cards';
import LoadSpinner from '../../UI/LoadSpinner/LoadSpinner';
import Sections from '../../UI/Sections/sections';

import './Landing.scss';

export const Landing = () => {
  function handleEvent() {
    storeApp.dispatch({
      type: 'DATO DESDE HOME',
      payload: {
        image: 'HOME IMAGE URL',
        dark: false,
      },
    });
  }
  const el: any = [{}];
  const [user2, setUser2] = useState('');
  const sharp = localStorage.getItem('sharp') || '';
  const dataapi = useGetHomeDefaultContentQuery('home').data;
  const elem: any = getDefaultContent(dataapi)?.content;
  const { t, i18n } = useTranslation('global');

  useEffect(() => {
    // console.log("FUNCIÓN TRANSLATEEEE..!!!:       ",t("header.welcome-message"));
    getUserData(`${apiMainURL}/api/authenticated-user`, sharp)
      .then((data) => {
        let name = data?.profile?.first_name || '';
        let d_id = data?.status?.drupal_id || '';
        localStorage.setItem('drupal_id', d_id);
        localStorage.setItem('user', name);

        setUser2(name);
      })
      .catch((error) => {
        console.log('error', error);
        deleteSession(window.location.href);
      });
  }, [t]);

  if (elem) {
    return (
      <div className='main-content'>
        {elem && <HomeDefault user={user2} data={elem}></HomeDefault>}
        {/* TODO <button className='btn btn-primary' onClick={() => handleEvent()}>Cambiar</button> */}
      </div>
    );
  } else return <LoadSpinner />;
};

export const HomeDefault = (props: any) => {
  const { data, user } = props;
  const { t, i18n } = useTranslation('global');
  const dataCarousel = [
    {
      title: 'Section 1',
      description:
        '<p>Lorem ipsum dolor sit amet,<br />\r\nconsetetur sadipscing elitr,<br />\r\nsed sadipscing elitr.</p>\r\n',
      src: 'https://api-socios-maz-dev.somosmaz.com/sites/default/files/2022-09/Communities.png',
      link: '/partners-clubs',
    },
    {
      title: 'Section 2',
      description:
        '<p>Lorem ipsum dolor sit amet,<br />\r\nconsetetur sadipscing elitr,<br />\r\nsed sadipscing elitr.</p>\r\n',
      src: 'https://api-socios-maz-dev.somosmaz.com/sites/default/files/2022-09/Test%20your%20ideas.png',
      link: '',
    },
    {
      title: 'Section 3',
      description:
        '<p>Lorem ipsum dolor sit amet,<br />\r\nconsetetur sadipscing elitr,<br />\r\nsed sadipscing elitr.</p>\r\n',
      src: 'https://api-socios-maz-dev.somosmaz.com/sites/default/files/2022-09/Calendar.png',
      link: '/calendar',
    },
    {
      title: 'Section 4',
      description:
        '<p>Lorem ipsum dolor sit amet,<br />\r\nconsetetur sadipscing elitr,<br />\r\nsed sadipscing elitr.</p>\r\n',
      src: 'https://api-socios-maz-dev.somosmaz.com/sites/default/files/2022-09/Calendar.png',
      link: '/calendar',
    },
    {
      title: 'Section 5',
      description:
        '<p>Lorem ipsum dolor sit amet,<br />\r\nconsetetur sadipscing elitr,<br />\r\nsed sadipscing elitr.</p>\r\n',
      src: 'https://api-socios-maz-dev.somosmaz.com/sites/default/files/2022-09/Calendar.png',
      link: '/calendar',
    },
    {
      title: 'Section 6',
      description:
        '<p>Lorem ipsum dolor sit amet,<br />\r\nconsetetur sadipscing elitr,<br />\r\nsed sadipscing elitr.</p>\r\n',
      src: 'https://api-socios-maz-dev.somosmaz.com/sites/default/files/2022-09/Calendar.png',
      link: '/calendar',
    },
  ];
  console.log('PROPS EN HOME DEFAULT:  ', props);
  if (user && data)
    return (
      <div className='container-fluid'>
        <div className=' ps-4 ps-xxl-0'>
          <div className='row '>
            <div className='col-md-4 fs-2 text-welcome bg-gray welcome-section-right'>
              Welcome
              <span className='username'>{user}</span>!
              <div className='mt-3 propose-title'>
                <div className='tw'>{data[0]?.content?.title || ''}</div>
                <div className='text-content fw-semibold tw'>
                  <GetBodyInnerHTML
                    content={data[0]?.content?.primaryText || ''}
                  />
                </div>
              </div>
            </div>
            <div className='col-md-7 carousel-landing bg-gray welcome-section-left'>
              <div className='news-landing'>News</div>
              <CarouselView content={data[1]?.content} />
            </div>
          </div>
        </div>
        <div className=' quick-access-title pt-5 pb-1'>
          <h3> Quick Access</h3>
        </div>
        <div className='carousel-rounded pb-5'>
          <div className=' my-4'>
            <div className='row'>
              <div className='col-11 mx-auto position-relative'>
                <RoundedCarouselCards
                  items={data[2].content?.map((el: any) => {
                    return {
                      src: el?.image || '',
                      description: el?.description,
                      link: el?.link || '',
                      title: el?.title || '',
                    };
                  })}></RoundedCarouselCards>
              </div>
            </div>
          </div>
        </div>
        {/* Segunda parte  */}
        {/* <div className=' ps-4 ps-xxl-0'>
          <div className='row '>
            <div className='col-md-7 carousel-landing bg-gray welcome-section-right fs-2'>
              <div className='complement-landing'>Partner Profile</div>
              <CarouselView content={data[3]?.content} />
            </div>
            <div className='col-md-4 fs-2 text-welcome  '>
              <div className='complement-landing utilities'>
                <h3> Utilities</h3>
              </div>

              <div className='propose-title'>
                {data[4]?.content?.map((item: any, indexItem: number) => {
                  if (indexItem < 3)
                    return (
                      <Link to={item?.link || '/'}>
                        <div className='item-utitities '>
                          <div className='row '>
                            <div className='col col-3 image-utilities my-auto'>
                              <img src={item?.src || ''} alt='' />
                            </div>
                            <div className='col col-9 my-auto title-item-utilities'>
                              {item?.title || ''}
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                })}
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  else return <></>;
};
