import React from 'react';
import BasicBarChart from '../../../../../../UI/chartJs/BasicBarChart';

type MetricsLevelBandsProps = {
  metrics?: { [key: string]: { [sKey: number | string]: number } };
};

const MetricsLevelBands = ({
  metrics = { '1': { 0: 0 } },
}: MetricsLevelBandsProps) => {
  const labels = [];
  const values = [];
  const colors = [];

  const assignColorByLevel = (level: string | number) => {
    switch (+level) {
      case 2:
        return '#e08a2d';

      case 3:
        return '#e5b611';

      case 4:
        return '#bebebe';

      default:
        return '#f5e003';
    }
  };

  for (const [level, levelValue] of Object.entries(metrics))
    for (const [band, value] of Object.entries(levelValue as object)) {
      labels.push(`L${level}-${band}`);
      values.push(value as number);
      colors.push(assignColorByLevel(level));
    }

  return (
    <>
      <p className='tw mb-3 text-center'>Partners by Level/Bands</p>
      <div className='mx-1 mx-sm-4'>
        <BasicBarChart
          labels={labels}
          chartData={values}
          barBackgroundColor={colors}
          stepSize={30}
        />
      </div>
    </>
  );
};

export default MetricsLevelBands;
