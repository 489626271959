import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Accordion } from '../../../UI/Accordion/Accordion';
import { useNavigate } from 'react-router';

export declare interface AccordionProps {
  title?: string;
  filters: any[];
  triggerFilter: () => void;
}
export declare interface filtersUrl {
  name: string;
  value: boolean;
  type: string;
}
export const Filter = ({ title, filters, triggerFilter }: AccordionProps) => {
  const [urlObject, setUrlObject] = useState<any[]>([]);
  const [filtersValues, setFiltersValues] = useState<any[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const getTrigger = () => {
    if (location?.pathname != '/partners/team-profiles') {
      navigate('/partners/team-profiles');
    }
    triggerFilter();
  };
  const getDataUrl = () => {
    let filtersObjects: { nameFilter: string; values: string[] }[] = [];
    if (location?.search != '') {
      let params = location?.search?.split('?')[1]?.split('&');

      params?.map((p: string) => {
        let nameFilter = p?.split('=')[0] || '';
        let valuesFilter = p?.split('=')[1] || '';
        let values: string[] = [];
        valuesFilter?.split('+')?.map((value: any) => {
          values.push(value);
        });
        filtersObjects.push({
          nameFilter: nameFilter || '',
          values: values || [],
        });
      });
    }
    setUrlObject(filtersObjects);

    const activeChecks = filtersObjects.filter(
      ({ nameFilter = '' }: { nameFilter: string }) => nameFilter === title,
    )[0];

    setFiltersValues(
      activeChecks
        ? filters.map((e: any) => ({
            ...e,
            ...(activeChecks.values.includes(`${e.id}`) && { isChecked: true }),
          }))
        : filters,
    );

    return filtersObjects;
  };
  useEffect(() => {
    getDataUrl();
  }, [location, filters]);

  const addFilter = async (e: any) => {
    if (location?.pathname != '/partners/team-profiles') {
      console.log('OJO..', location);
      navigate('/partners/team-profiles');
    } else {
      let filteronChanged = {
        name: e?.target?.name?.replace(' ', '$')?.replace(' ', '$') || '',
        value: e?.target?.checked || false,
        type: title || '',
      };
      if (location.search == '' && e?.target?.checked) {
        navigate(
          `${location.pathname}?${filteronChanged?.type}=${filteronChanged?.name}`,
        );
      } else {
        let a = cleanFilters(pressedFilter(filteronChanged));
        getConverterDataToUrl(a);
      }
    }
  };
  const getConverterDataToUrl = (objectData: any) => {
    let url = '';
    objectData?.map((element: any, indexElement: number) => {
      url =
        indexElement == 0
          ? element?.nameFilter + '='
          : url + '&' + element?.nameFilter + '=';
      let valuesString = '';
      element?.values?.map((value: any, indexValue: number) => {
        valuesString = indexValue == 0 ? value : valuesString + '+' + value;
      });
      url = url + valuesString;
    });
    navigate(`${location.pathname}?${url}`);
  };
  function cleanFilters(filters: any[]) {
    return filters.filter((f: any) => {
      if (f?.values?.length > 0) return f;
    });
  }
  const pressedFilter = (filteronChanged: any): any[] => {
    let addFlag = false;
    let cont = 0;
    let objNew = urlObject?.map((of: any, indexOf) => {
      if (filteronChanged?.type == of?.nameFilter) {
        if (
          of?.values?.includes(
            filteronChanged?.name?.replace(' ', '$')?.replace(' ', '$'),
          )
        ) {
          if (!filteronChanged?.value) {
            let newValues = of.values.filter((el: any) => {
              if (el != filteronChanged?.name) return el;
            });
            return { nameFilter: of?.nameFilter, values: newValues };
          }
          return of;
        } else {
          return filteronChanged?.value
            ? {
                nameFilter: of?.nameFilter,
                values: [...of?.values, filteronChanged?.name],
              }
            : of;
        }
      }
      //TODO: SI NO ESTÁ EL TIPO?
      else {
        cont++;
        if (cont == urlObject.length) {
          addFlag = true;
          return of;
        } else {
          return of;
        }
      }
    });
    if (addFlag) {
      objNew.push({
        nameFilter: filteronChanged?.type,
        values: [filteronChanged?.name],
      });
    }
    return objNew;
  };

  if (title && filtersValues)
    return (
      <div className='desp-menu' key={title}>
        <Accordion title={title} trigger={() => getTrigger()} nested={true}>
          <ul>
            {filtersValues?.map((a: any, indexA: number) => {
              return (
                <li key={title + a + '-ul-' + indexA}>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value={a.id}
                      defaultChecked={a.isChecked}
                      id='flexCheckDefault'
                      name={a.id}
                      onChange={(e) => addFilter(e)}
                    />
                    <label
                      className='form-check-label'
                      htmlFor='flexCheckDefault'>
                      {a.label}
                    </label>
                  </div>
                </li>
              );
            })}
          </ul>
        </Accordion>
      </div>
    );
  else return <></>;
};
