import React, { useEffect } from 'react';
import jwt_decode from 'jwt-decode';

import { getDomain } from '../../../modules/settings/domain';
import {
  appURL,
  client_id,
  ssoURL,
} from '../../../modules/settings/environment';
import { getAPIToken } from '../../../services/api/api-socios-maz';
import LoadSpinner from '../../UI/LoadSpinner/LoadSpinner';
import { generateAlert } from '../../UI/alerts-Service/alerts-function';

const Callback = () => {
  var params;
  useEffect(() => {
    /*  params = window.location.href.split('?')[1]; */
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.state === localStorage.getItem('state')) {
      let codeVerifier = localStorage.getItem('code_verifier');
      if (codeVerifier) {
        var myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        localStorage.setItem('redirect2', `${appURL}/auth/callback`);
        var raw = JSON.stringify({
          client_id: client_id,
          code_verifier: codeVerifier,
          grant_type: 'authorization_code',
          redirect_uri: `${appURL}/auth/callback`,
          code: params?.code,
        });

        var requestOptions: RequestInit = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow',
        };

        fetch(`${ssoURL}/oauth/token`, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            let responseJSON = JSON.parse(result);
            let accessToken = JSON.parse(result).access_token.toString();
            let expiresIn = JSON.parse(result).expires_in.toString();
            sessionStorage.setItem('accessToken', accessToken);
            sessionStorage.setItem('expiresIn', expiresIn);
            var decoded: any = jwt_decode(result);
            let sharp = decoded.sharp_id || '';
            localStorage.setItem('sharp', sharp);

            getAPIToken(
              sessionStorage.getItem('accessToken')?.toString() || '',
              sessionStorage.getItem('expiresIn')?.toString() || '',
            );
          })
          .catch((error) => {
            console.log('error', error);
            // generateAlert('error','SSO: oauth error - ',error)
            localStorage.setItem('errorSession', error);
          });
      }
    }
  });
  return (
    <div>
      <LoadSpinner />
    </div>
  );
};

export default Callback;
