import React, { ChangeEventHandler } from 'react';

import { Input } from 'reactstrap';
import { ReactComponent as MagnifyGlass } from '../../../assets/topNavigationBar/MagnifyingGlass.svg';

import './SearchBar.scss';

type SearchBarProps = {
  inputValue?: string;
  placeholder: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onSearchClick?: () => void;
};

const SearchBar = ({
  inputValue,
  placeholder,
  onChange,
  onSearchClick,
}: SearchBarProps) => {
  return (
    <div className='d-flex gap-3 align-self-center user-input-filter'>
      <Input {...{ onChange, placeholder }} value={inputValue} />
      <div className='me-3 partners-glass'>
        <MagnifyGlass onClick={onSearchClick} />
      </div>
    </div>
  );
};

export default SearchBar;
