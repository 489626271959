import React from 'react';
import { ItemProps } from './CarouselHeader';
import { GetBodyInnerHTML } from '../../../../Layouts/default-section/getDefaultElements';
import { useNavigate } from 'react-router';

const Item = ({
  desc,
  img,
  span,
  title,
  label = 'Your Ticket',
  src,
}: ItemProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (localStorage.getItem('mobile') === '1')
      navigate(`/download-pdf-link?${src}`);
    else window.open(src);
  };

  return (
    <div className='destination-carousel-item'>
      <img src={img} alt='img' />
      <div className='shadow' />
      <div className='container-fluid item-info'>
        <h1>{title}</h1>
        <GetBodyInnerHTML content={desc} />
        <div className='d-flex flex-column'>
          <span>{span}</span>
          <button onClick={handleClick} disabled={!src}>
            <i className='bi bi-download' /> {label}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Item;
