import React, { useEffect, useState } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  deleteSession,
  postGeneralPartner,
} from '../../../services/api/api-socios-maz';
import Menu from '../Menu-header';
import { getDomain } from '../../../modules/settings/domain';
import { useTranslation } from 'react-i18next';
import { store as storeApp } from '../../../modules/appState/store';
import ModalNps from '../Modal-Nps';
import { items } from '../../../data/lateralMenuItems';
import NavigationItem from './NavigationItem';
import NoInformationModal from '../../pages/profile/Partners-profile/NoInformationModal';
import { ReactComponent as Home } from '../../../assets/lateralMenu/Home (uncolored).svg';
import { ReactComponent as LPC } from '../../../assets/lateralMenu/LPC (1).svg';
import { ReactComponent as PartnerProfile } from '../../../assets/lateralMenu/Partner Profile (uncolored).svg';

import './LateralMenu.scss';

const LateralMenu = (props: any) => {
  const [isDark, setIsDark] = useState(storeApp.getState().AppReducer.dark);
  const [user, setUser] = useState(storeApp.getState().AppReducer.userData);
  storeApp.subscribe(() => {
    setUser(storeApp?.getState()?.AppReducer?.userData);
  });

  const filter = isDark
    ? 'invert(58%) sepia(62%) saturate(993%) hue-rotate(13deg) brightness(113%) contrast(87%)'
    : 'none';

  function logout() {
    // Swal.fire('Logout')
    setTimeout(() => {
      deleteSession();
    }, 1000);
  }
  function handleSearch() {}
  function handleDarkMode() {
    storeApp.dispatch({
      type: 'DARK',
    });
  }
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);
  const [roles, setRoles] = useState<any[]>([]);
  const [drupalId, setDrupalId] = useState('');
  const [itemsToShow, setItemsToShow] = useState(items);
  const [showModal, setShowModal] = useState(false);
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  const { t, i18n } = useTranslation('global');

  useEffect(() => {
    const getData = async () => {
      if (user?.name != '' && user?.drupal_id) {
        const generalPartner = await (
          await postGeneralPartner(user?.drupal_id)
        )?.json();

        setItemsToShow((prevItems) => {
          const newItems = [...prevItems];
          const ppIdx = newItems?.findIndex(
            ({ label }) => label === 'Partner Profile',
          );
          const pp = newItems.splice(ppIdx, 1)[0];

          if (
            !user?.rol?.includes('directivo') &&
            !user?.rol?.includes('super_directivo')
          ) {
            const { dataArchetypes: archData } = generalPartner;

            if (archData?.archetype || archData?.data)
              pp.url = '/profile/partner-profile';
            else {
              pp.hasNotPPInfo = true;
              pp.url = '';
            }
          }

          return [...newItems, pp];
        });
      }
    };

    getData();
  }, [user]);
  useEffect(() => {
    const unsubscribe = storeApp.subscribe(() => {
      setIsDark(storeApp.getState().AppReducer.dark);
      setDrupalId(storeApp.getState().AppReducer?.userData?.drupal_id);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div>
      {showModal && <NoInformationModal toggle={setShowModal} />}
      <ModalNps />
      <Menu />
      <div className='lateralmenu'>
        {JSON.stringify(user?.rol)?.length !== 0 && drupalId && (
          <NavLink
            className='icon-menu d-md-none'
            data-bs-toggle='offcanvas'
            href='#offcanvasExample'
            role='button'
            aria-controls='offcanvasExample'>
            <i className='bi bi-list nav_icon icon-bd' />
          </NavLink>
        )}
        <div
          className='offcanvas offcanvas-start'
          id='offcanvasExample'
          aria-labelledby='offcanvasExampleLabel'>
          <div className='offcanvas-header'>
            <button
              type='button'
              className='btn-close text-reset'
              data-bs-dismiss='offcanvas'
              aria-label='Close'>
              x
            </button>
            <NavLink
              className='icon-menu'
              data-bs-toggle='offcanvas'
              href='#offcanvasExample'
              role='button'
              aria-controls='offcanvasExample'>
              <i className='bi bi-list nav_icon icon-bd burger-icon'></i>
            </NavLink>
          </div>
          <Nav navbar>
            {itemsToShow
              .sort((a, b) => a.position - b.position)
              .map(({ position, hasNotPPInfo, ...props }) => (
                <NavigationItem
                  key={`nav-item-${position}-${props.label}`}
                  onClick={() => hasNotPPInfo && setShowModal(true)}
                  {...props}
                />
              ))}
            <NavItem className='last-item'>
              <NavLink onClick={logout} className='logout'>
                <i className='nav_icon'>
                  <img
                    src={`../../../../resources/menu_lateral/go_out.svg`}
                    width='25'
                  />
                </i>
                <span>{t('lateral-menu.logout')}</span>
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </div>
      {/*Menu Footer*/}
      <div className='menu-footer d-md-none'>
        <Nav>
          <NavItem>
            <Link to='/home'>
              <Home />
            </Link>
          </NavItem>
          <NavItem>
            <Link to='/lpc'>
              <LPC />
            </Link>
          </NavItem>
          <NavItem>
            <Link to='/partners/partner-profile'>
              <PartnerProfile />
            </Link>
          </NavItem>
          <NavItem>
            <button
              type='button'
              className='btn px-0'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              title={t('menu.tooltip-message')}>
              <input
                id='toggle'
                className='toggle'
                type='checkbox'
                onClick={() => {
                  handleDarkMode();
                }}
                style={{
                  filter,
                }}
              />
            </button>
          </NavItem>
        </Nav>
      </div>
    </div>
  );
};

export default LateralMenu;
