import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { apiMainURL } from '../../../../../../modules/settings/environment';
import {
  postGeneralPartner,
  useGetallArchetypesQuery,
  useGetPartnersProfileDatesQuery,
} from '../../../../../../services/api/api-socios-maz';
import { PartnerProfileRender } from '../../../Partners-profile/Partner-profile';
import LoadSpinner from '../../../../../UI/LoadSpinner/LoadSpinner';

export const DetailPartnerProfile = () => {
  const location = useLocation();
  const [data, setData] = useState<any>({});
  const [load, setLoad] = useState(true);
  const [actualYear, setActualYear] = useState<string>('');

  const archetypesListData = useGetallArchetypesQuery()?.data;
  const archetypesList = getArchetypes(archetypesListData);
  function getArchetypes(archData: any): any[] {
    let archeTypes: any[] = [];
    archData?.data?.map((arch: any) => {
      let idImage = arch?.relationships?.field_archetype_image?.data.id || '';
      let imageFile = '';
      archData?.included?.map((inc: any) => {
        if (inc?.id == idImage) {
          archeTypes.push({
            name: arch?.attributes?.name || '',
            image: apiMainURL + inc?.attributes?.uri?.url || '',
            id: arch?.attributes?.drupal_internal__tid || '',
          });
        }
      });
    });

    return archeTypes;
  }

  const crumbs = [
    { path: '/home', label: 'MAZ Partners' },
    { path: '/partners/partner-profile', label: 'Partner profile' },
    { label: 'Detail' },
  ];
  const getDataProfile = async () => {
    // console.log("DRUPAL ID2 profile 3333  ",user?.drupal_id);
    const response = await postGeneralPartner(
      location.search.split('?')[1] || '',
      {
        date: actualYear,
      },
    )
      ?.then((response: any) => response.text())
      .then((result) => {
        setLoad(false);
        setData(JSON.parse(result));
      });
  };
  const onClick = (value: string) => {
    setActualYear(value);
    setLoad(true);
  };
  const dates = useGetPartnersProfileDatesQuery({
    id: location.search.split('?')[1] || '',
  }).data;

  const dropdownItems: any = useMemo(
    () =>
      dates
        ?.filter(
          ({ fecha_lanzamiento = '' }) => !fecha_lanzamiento.startsWith('0000'),
        )
        .map(({ fecha_lanzamiento = '' }) => ({
          value: fecha_lanzamiento,
          label: fecha_lanzamiento.substring(0, 7),
        })) || [],
    [dates],
  );

  useEffect(() => {
    setActualYear(dropdownItems[0]?.value || '');

    load && getDataProfile();
  }, [location, load]);

  return (
    <div>
      <PartnerProfileRender
        onClick={onClick}
        idUser={location.search.split('?')[1] || ''}
        data={data}
        archetypes={archetypesList}
        crumbs={crumbs}
        mainArq={data?.dataArchetypes?.data
          ?.sort(function (a: any, b: any) {
            return b?.value - a?.value;
          })
          .slice(0, 1)}
        secArch={data?.dataArchetypes?.data
          ?.sort(function (a: any, b: any) {
            return b?.value - a?.value;
          })
          .slice(1, data?.dataArchetypes?.data?.lenght)}
        records={dropdownItems}
      />
      {load && <LoadSpinner />}
    </div>
  );
};
