import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { postGenMAZ } from '../../../../../../../services/api/api-socios-maz';
import { GetBodyInnerHTML } from '../../../../../../Layouts/default-section/getDefaultElements';
import NoDataFound from '../../../../../../Layouts/NoDataFound';
import { RangeValue } from '../../../../../../UI/Range-value';

export const GenMAZPrintTeam = (props: any) => {
  const { data } = props;
  const [team, setTeam] = useState(false);
  const [items, setItems] = useState<
    { partnerValue: string; teamValue: string }[]
  >([]);

  const a = () => {
    let itemsData: any[] = [];
    if (data?.data) {
      Object?.entries(data?.data).map((i: any, indexItem: number) => {
        setTeam(data?.teamview);
        itemsData.push({
          name: i[0] + '',
          teamValue: i[1]?.Team || '',
          partnerValue: i[1]?.Partner || '',
          description: i[1]?.description || '',
        });
      });
      setItems(itemsData);
    }
  };
  useEffect(() => {
    a();
  }, [props]);

  return (
    <div>
      <h1 className='text-center mt-5'>Macro Entrepreneur Attributes</h1>
      <div className='text-content p-4 text-center'>
        Learn more about the attributes of the macro entrepreneur that we want
        to strengthen in our people. Remember there is no magic formula, this
        are just a few traits that help deliver consistent growth.
      </div>
      <div className='section-dark-gray section-partner-owner'>
        <div className='row gx-5 align-items-end'>
          <div
            className='row justify-content-center'
            style={{ transform: 'translateX(10px)' }}>
            <div className='col-md-5 text-md-end text-center'>
              <div
                className={
                  items[0]?.partnerValue ? 'ind-partner' : 'invisible'
                }>
                <span />
                Partner View
              </div>
            </div>
            <div className='col-md-5 text-md-start text-center mb-3 mb-md-0'>
              <div className={items[0]?.teamValue ? 'ind-team' : 'invisible'}>
                <span />
                Team View
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              gridTemplateRows: '1fr 1fr ',
              maxWidth: 100 + '%',
              flexWrap: 'wrap',
            }}>
            {items?.map((i: any, indexItem: number) => {
              return (
                <div
                  className='col-sm-6 col-md-4 mt-4'
                  key={indexItem}
                  style={{
                    width: 50 + '%',
                    paddingRight: 20 + 'px',
                  }}>
                  <div className='main-progress-bar'>
                    <div
                      className='title-progress-bar'
                      data-tip
                      data-for={'items-gm' + indexItem}>
                      {i?.name}
                    </div>
                    {i?.description != '' && (
                      <ReactTooltip
                        id={'items-gm' + indexItem}
                        className='ttp-w'>
                        <p className='tw text-left'>
                          <strong>{i?.name + ':  '}</strong>
                          <GetBodyInnerHTML
                            content={i?.description}></GetBodyInnerHTML>
                        </p>
                      </ReactTooltip>
                    )}
                    <RangeValue
                      team={true}
                      partnerValue={i?.partnerValue}
                      teamValue={i?.teamValue || '0'}
                      name={i?.name}
                      showValues
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div
        className='page-break'
        style={{
          display: 'flex',
          gridTemplateRows: '1fr 1fr ',
          maxWidth: 100 + '%',
          flexWrap: 'wrap',
        }}>
        {items?.map((i: any, indexItem: number) => {
          if (i?.description) {
            return (
              <div
                className='col-sm-6 col-md-4 mt-4'
                key={indexItem}
                style={{
                  width: 50 + '%',
                  paddingRight: 20 + 'px',
                }}>
                <div className='main-progress-bar'>
                  <h2 className='glosary-title fs-4 lh-sm'>{i?.name || ''}</h2>
                  {i?.description != '' && (
                    <div className=''>
                      <div className=''>
                        <GetBodyInnerHTML
                          content={i?.description}></GetBodyInnerHTML>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          } else return <></>;
        })}
      </div>
    </div>
  );
};
