import { Progress } from 'reactstrap';
export const RangePercentage = (props:any) => {
    const {percentage}=props;
    const p= parseFloat(parseFloat(percentage).toFixed(2) || '0') ;
  return (
    <Progress value={percentage}>
        <div className="percentage">
            {p}%
        </div>
    </Progress>
  )
}
