import React from 'react';

import Card from '../Card';

type OverlayProps = {
  title: string;
  onConfirm: () => void;
  imgSrc: string | JSX.Element;
  children: JSX.Element;
  cardFooter?: JSX.Element;
  hasCancel?: boolean;
  onCancel?: () => void;
};

const Overlay = ({
  hasCancel = false,
  onConfirm,
  title,
  imgSrc,
  children,
  cardFooter,
}: OverlayProps) => {
  return (
    <div className='modal-container'>
      <Card
        {...{ title, hasCancel, onConfirm, imgSrc, cardFooter }}
        className='information-modal'>
        {children}
      </Card>
    </div>
  );
};

export default Overlay;
