import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import {
  getAllGalleryLikes,
  useGetAllGalleryLikesQuery,
  useGetGalleryDatesQuery,
  useGetGalleryImagesQuery,
  useGetHomeDefaultContentQuery,
} from '../../../../services/api/api-socios-maz';

import BreadCrumb from '../../../UI/BreadCrumb';
import Gallery from '../../../Layouts/Gallery';

import './Gallery.scss';
import NavButtons from './NavButtons';
import {
  GetBodyInnerHTML,
  getDefaultContent,
} from '../../../Layouts/default-section/getDefaultElements';
import LoadSpinner from '../../../UI/LoadSpinner/LoadSpinner';
import ImageView from './ImageView';
import NoDataFound from '../../../Layouts/NoDataFound';

const LPCGallery = () => {
  const [searchParams] = useSearchParams();

  const id = searchParams.get('id');
  const limit = 24;
  const initialOffset = 0;
  const type = 'Galería';

  const [galleryObjects, setGalleryObjects] = useState<Array<any>>([]);
  const [newOffset, setNewOffset] = useState<number>(initialOffset);
  const [isLoading, setIsLoading] = useState(true);
  const [idActualDate, setIdActualDate] = useState(0);

  const dates = useGetGalleryDatesQuery()?.data;
  const datesToUse = useMemo(
    () =>
      dates?.data?.map(
        ({
          attributes: { name = '', field_fecha = '', drupal_internal__tid = 0 },
        }: any) => ({
          title: name,
          date: field_fecha,
          id: drupal_internal__tid,
        }),
      ),
    [dates?.data],
  );

  const crumbs = [
    { path: '/home', label: 'MAZ Partners' },
    { path: '/lpc', label: 'LPC' },
    { label: 'Gallery' },
  ];
  console.log(newOffset, newOffset * limit);
  const imagesData = useGetGalleryImagesQuery(
    JSON.stringify({
      type: type,
      date: idActualDate,
      limit: limit || initialOffset,
      offset: newOffset * limit || 0,
    }),
  ).data;
  const dataapi = useGetHomeDefaultContentQuery('lpc/gallery')?.data;
  const parentId = dataapi?.data[0]?.attributes?.drupal_internal__nid || 0;
  const elem: any = getDefaultContent(dataapi);
  const desc =
    elem?.content?.find(({ type = '' }) => type === 'text')?.content?.value ||
    '';
  const error = elem?.content
    ?.find(({ type = '' }) => type === 'horizontal_card_textos')
    ?.content?.primaryText.replace(
      /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g,
      '',
    );
  const likes = useGetAllGalleryLikesQuery('');
  const imagesToShow = useMemo(
    () =>
      imagesData &&
      likes.data &&
      imagesData?.data?.map(
        ({
          id: fieldID,
          attributes: { changed, title, drupal_internal__nid },
          relationships: {
            field_imagen: {
              data: { id },
            },
          },
        }: {
          id: string;
          attributes: any;
          relationships: any;
        }) => {
          const [
            {
              attributes: {
                uri: { url },
              },
            },
          ] = imagesData.included.filter(
            ({ id: imgID }: { id: string }) => imgID === id,
          );

          const hasLike =
            likes.data
              .map(({ entity_id }: any) => +entity_id)
              .indexOf(drupal_internal__nid) > -1;

          const eventDate = new Date(changed).toLocaleDateString('en-US', {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
          });

          return {
            date: eventDate,
            title,
            url,
            id: fieldID,
            drupalId: drupal_internal__nid,
            hasLike,
          };
        },
      ),
    [imagesData, likes.data],
  );
  const showButton = !(imagesToShow?.length < limit);
  const reload = useCallback(async () => {
    const galleryLikes = await getAllGalleryLikes();

    setGalleryObjects((prevObjects) =>
      prevObjects.map(({ hasLike, ...args }) => {
        const newHasLike =
          galleryLikes
            .map(({ entity_id }: any) => +entity_id)
            .indexOf(args.drupalId) > -1;

        return { ...args, hasLike: newHasLike };
      }),
    );
  }, []);
  const handleShowMore = useCallback(() => {
    setIsLoading(true);
    setNewOffset((prevOff) => prevOff + 1);
  }, []);
  const handleNavClick = useCallback((date?: number) => {
    setIdActualDate(date || 0);
    setIsLoading(true);
    setNewOffset(0);
  }, []);

  useEffect(() => {
    if (imagesToShow) {
      setGalleryObjects((prevImages) =>
        newOffset === 0
          ? imagesToShow
          : prevImages
              .concat(imagesToShow)
              .filter(
                ({ id = '' }, idx, self) =>
                  idx === self.findIndex((s) => s.id === id),
              ),
      );
      setTimeout(() => setIsLoading(false), 1000);
    }
  }, [imagesToShow, newOffset]);
  useEffect(() => {
    setIdActualDate(datesToUse?.at(0)?.id);
  }, [datesToUse]);

  const actualDate =
    datesToUse?.find(({ id = 0 }) => id === idActualDate)?.date || '';

  return (
    <>
      {isLoading && <LoadSpinner />}
      {id && <ImageView {...{ id, actualDate, reload }} />}
      <div className='main-content lpc-bg gallery-content'>
        <div className='container-fluid'>
          <BreadCrumb {...{ crumbs }} />
          <h1 className='text-center'>{elem?.title}</h1>
          <div className='gallery-description tw'>
            <GetBodyInnerHTML content={desc} />
          </div>
          <NavButtons dates={datesToUse} onNavButtonClick={handleNavClick} />
          {galleryObjects.length !== 0 ? (
            <Gallery
              {...{ galleryObjects, type, parentId }}
              actualDateId={idActualDate}
            />
          ) : (
            <NoDataFound message={error} className='gallery-no-data' />
          )}
          <div className='d-flex justify-content-center'>
            {showButton && (
              <button
                className='gallery-show-more-button'
                onClick={handleShowMore}>
                Show More
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LPCGallery;
