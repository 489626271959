import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiMainURL } from '../../../../modules/settings/environment';
import {
  deleteSession,
  getUserData,
  useGetCalculatorSTIQuery,
  useGetHomeDefaultContentQuery,
} from '../../../../services/api/api-socios-maz';
import {
  GetBodyInnerHTML,
  getDefaultContent,
} from '../../../Layouts/default-section/getDefaultElements';
import BigImage from '../../../UI/BigImage';
import BreadCrumb from '../../../UI/BreadCrumb';
import CarouselView from '../../../UI/Carousel/carousel';
import LoadSpinner from '../../../UI/LoadSpinner/LoadSpinner';
import Calculator from './Calculator';
import { ReactComponent as Warning } from '../../../../assets/AboutMeAtABI/STI/Warning.svg';

import './STI.scss';

export const STI_BonusComponent = () => {
  const [user2, setUser2] = useState({
    band: '',
    country: '',
    sharp: '',
  });

  const dataapi = useGetHomeDefaultContentQuery(
    'about-me-at-abi/sti-bonus',
  ).data;
  const sharp = localStorage.getItem('sharp') || '';
  const elem: any = getDefaultContent(dataapi)?.content;

  useEffect(() => {
    getUserData(`${apiMainURL}/api/authenticated-user`, sharp)
      .then((data) => {
        setUser2({
          band: data?.profile?.band || '',
          country: data?.profile?.country?.toLowerCase() || '',
          sharp: data?.profile?.global_id,
        });
      })
      .catch((error) => {
        console.log('error', error);
        deleteSession(window.location.href);
      });
  }, []);
  if (elem && user2?.band != '')
    return <STI_Bonus content={elem} dataUser={user2}></STI_Bonus>;
  else return <LoadSpinner></LoadSpinner>;
};

export const STI_Bonus = (props: any) => {
  const { t, i18n } = useTranslation('global');
  const [band, setBand] = useState('');

  if (!localStorage.getItem('tdr')) {
    localStorage.setItem('tdr', '12');
  }

  const cont = props?.content;
  const dc = props?.dataUser;
  const dataCalculator = useGetCalculatorSTIQuery(
    //  JSON.stringify({
    //   band: 'I-B',
    //   country: dc?.country
    // }),
    JSON.stringify({
      band: dc?.band,
      country: dc?.country,
    }),
  )?.data;
  const crumbs = [
    { path: '/home', label: 'MAZ Partners' },
    { path: '/about-me-at-abi', label: t('about-me-at-abi.title') },
    { label: t('about-me-at-abi.sti-bonus.title') },
  ];

  useEffect(() => {
    getUserData(`${apiMainURL}/api/authenticated-user`, '')
      .then(async (data) => {
        const elem = await data?.profile?.band;
        setBand(elem);
      })
      .catch((error) => {
        console.log('error', error);
        deleteSession(window.location.href);
      });
  }, [t]);

  if (band != '')
    return (
      <div>
        <div className='main-content'>
          <div className='container-fluid'>
            <div className='col-12'>
              <BreadCrumb {...{ crumbs }} />
              <h1 className='section-title'>{cont[0]?.content?.value}</h1>
              <div className='description-content'>
                <GetBodyInnerHTML
                  content={cont[1]?.content?.value}></GetBodyInnerHTML>
              </div>
              {dataCalculator?.data &&(
                <div className="">
                <div className='row'>
                  <div className='col-sm-8 col-md-6 col-lg-5 mx-auto'>
                    <div className='sti-calculator position-relative my-4'>
                      {dataCalculator && (
                        <Calculator
                          dataCalculator={dataCalculator?.data}
                          band={band}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-center my-3 calculator-info'>
                  <div className='d-flex'>
                    <Warning />
                    <GetBodyInnerHTML content={cont[2]?.content?.value} />
                  </div>
                </div>
                <h1 className='mt-5 section-title'>{cont[3]?.content?.value}</h1>
                <BigImage
                  className='sti-formula'
                  src={cont[4].content[0].src.src}
                />
              </div>
              )}
              {!dataCalculator?.data &&(
                <h1 className='mt-5 text-center my-auto'>No cuenta con los permisos suficientes para acceder a calcular el bono STI</h1>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  else return <></>;
};
