import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import ContentCard from '../Card';

import './Section.scss';

type SectionProps = {
  hasShowButton?: boolean;
  title: string;
  cardsInfo?: {
    id?: string;
    img?: string;
    category?: string;
    title?: string;
    content?: string;
  }[];
  onLoadMore: (value: string) => void;
};

const Section = ({
  title,
  hasShowButton,
  cardsInfo,
  onLoadMore,
}: SectionProps) => {
  const { pathname } = useLocation();

  return (
    <div className='py-4'>
      <p className='fs-4 section-title'>{title}</p>
      <div className='row row-cols-1 row-cols-md-2 row-cols-lg-4 justify-content-center'>
        {cardsInfo?.map((card) => (
          <div key={`card-presentation-${card.id}`} className='col px-3 mb-4'>
            <Link
              className='d-flex justify-content-center text-decoration-none'
              to={`${pathname}/${card.id}`}>
              <ContentCard {...card} category={title} />
            </Link>
          </div>
        ))}
      </div>
      <div className='d-flex'>
        {hasShowButton && (
          <button
            className='mx-auto px-3 py-1 show-more-button'
            onClick={() => onLoadMore(title)}>
            Show More
          </button>
        )}
      </div>
    </div>
  );
};

export default Section;
