import React from 'react';

import { GetBodyInnerHTML } from '../../../../Layouts/default-section/getDefaultElements';
import { ReactComponent as PhotoSeal } from '../../../../../assets/LPC/GetReady/PhotoSeal.svg';

import './Letter.scss';

interface LetterProps {
  title: string;
  description: string;
  src?: string;
}

const Letter = ({ description: desc, title, src }: LetterProps) => {
  return (
    <div className='lpc-letter'>
      <div className='container-fluid'>
        <div className='px-2 row justify-content-center gap-5'>
          <div className='col-md-4 lpc-letter-img'>
            <div>
              <img
                className='person-avatar'
                {...{ src }}
                alt='LPC Letter Img.'
              />
              <PhotoSeal className='seal' />
            </div>
          </div>
          <div className='col-md-6 px-0'>
            {/* <h1>{title}</h1> */}
            <div className='quote-section'>
              <i className='bi bi-quote open-quote' />
              <GetBodyInnerHTML content={desc} />
              <i className='bi bi-quote close-quote' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Letter;
