import React, { useEffect, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import {
  getUserData,
  postGeneralPartner,
  postMenuItems,
  postMenuItemsData,
  useGetHomeDefaultContentQuery,
} from '../../../../../services/api/api-socios-maz';
import {
  GetBodyInnerHTML,
  getDefaultContent,
} from '../../../../Layouts/default-section/getDefaultElements';

import BreadCrumb from '../../../../UI/BreadCrumb';

import { store } from '../../../../../modules/appState/store';
import LoadSpinner from '../../../../UI/LoadSpinner/LoadSpinner';
import { apiMainURL } from '../../../../../modules/settings/environment';
import NoInformationModal from '../NoInformationModal';

export const WhatIsYourPartnerProfile = () => {
  const [showModal, setShowModal] = useState(false);

  const dataapi = useGetHomeDefaultContentQuery(
    'profile/what-your-partner-profile',
  ).data;
  const [load, setLoad] = useState(false);
  const [role, setRole] = useState<Array<string>>([]);
  const [data2, setData2] = useState<any>(undefined);

  const getDataProfile = async () => {
    setLoad(true);
    getUserData(`${apiMainURL}/api/authenticated-user`, '')
      .then((data) => {
        setShowModal(false);
        setRole(data?.status?.roles);
      })
      .catch((error) => {
        console.log('error', error);
        setShowModal(true);
      });
  };
  useEffect(() => {
    if (role.length == 0) getDataProfile();
  }, [role]);

  const elems: any = getDefaultContent(dataapi);
  if (role.length > 0) {
    return (
      <div>
        {showModal && <NoInformationModal toggle={setShowModal} />}
        <WhatIsYourPartnerProfileComponent
          data={elems}
          role={role}></WhatIsYourPartnerProfileComponent>
      </div>
    );
  } else
    return (
      <div className=''>
        {showModal && <NoInformationModal toggle={setShowModal} />}
        {!showModal && <LoadSpinner />}
      </div>
    );
};

export const WhatIsYourPartnerProfileComponent = (props: any) => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const { data, role } = props;
  const crumbs = [
    { path: '/home', label: 'MAZ Partners' },
    { label: 'What Is Your Partner Profile' },
  ];

  const onGoToProfileButtonClick = () => {
    postGeneralPartner(
      store.getState().AppReducer?.userData?.drupal_id || '',
    ).then(async (res) => {
      const { dataArchetypes: archData } = await res?.json();
      if (archData.archetype || archData.data)
        navigate('/profile/partner-profile');
      else setShowModal(true);
    });
  };
  const onGoToPartnersButtonClick = () => {
    postGeneralPartner(
      store.getState().AppReducer?.userData?.drupal_id || '',
    ).then(async (res) => {
      // const { dataArchetypes: archData } = await res?.json();
      navigate('/profile/what-you-can-find');
    });
  };
  if (role)
    return (
      <div className='main-content'>
        <div className='container-fluid'>
          {showModal && <NoInformationModal toggle={setShowModal} />}
          <BreadCrumb {...{ crumbs }} />
          <div className='row'>
            <div className='col-12 my-3'>
              <h1 className='text-center mb-3'> {data?.title}</h1>
            </div>
            <div className='col-md-9 mx-auto mb-5'>
              <div className='text-center video-profile'>
                <video
                  src={data?.content[0]?.content[0]?.src || ''}
                  height='100%'
                  width='75%'
                  controls
                  autoPlay
                  muted></video>
              </div>
            </div>

            <div className='col-md-6'>
              <div className='text-content'>
                <div className='text-center text-md-start'>
                  <span className='text-gold font-normal fs-2'>
                    {data?.content[1]?.content?.value}
                  </span>
                </div>

                <div className='mt-3 box-text-partners'>
                  <GetBodyInnerHTML
                    content={
                      data?.content[2]?.content?.value
                    }></GetBodyInnerHTML>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='border-imagen-profile'>
                <img
                  src={data?.content[3]?.content[0]?.src || ''}
                  className='img-fluid'
                />
              </div>
            </div>
            <div className='col-md-12 my-4 text-center text-md-start'>
              <span className='text-gold font-normal fs-2'>
                {data?.content[4]?.content?.value || ''}
              </span>
            </div>
            {data?.content[5]?.type == 'cardsGroup' &&
              data?.content[5]?.content?.map((card: any, indexCard: number) => {
                return (
                  <div className='col-sm-6' key={indexCard}>
                    <div className='row'>
                      <div className='col-4 py-3'>
                        <div className='icon-boxtext-partner'>
                          <img
                            src={card?.src}
                            width='150'
                            className='img-fluid'
                          />
                        </div>
                      </div>
                      <div className='col-8 text-content py-3'>
                        <GetBodyInnerHTML
                          content={card?.description}></GetBodyInnerHTML>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className='text-center text-md-end'>
            {!role.includes('directivo') && !role.includes('super_directivo') && (
              <button
                className='btn login-without btn-secondary mt-4 mt-md-5 '
                onClick={onGoToProfileButtonClick}>
                I want to know my profile
              </button>
            )}
            {(role.includes('directivo') ||
              role.includes('super_directivo')) && (
              <button
                className='btn login-without btn-secondary mt-4 mt-md-5'
                onClick={onGoToPartnersButtonClick}>
                Continue
              </button>
            )}
          </div>
        </div>
      </div>
    );
  else return <LoadSpinner />;
};
