import { apiMainURL } from "../../../modules/settings/environment";
import { postExHubAccessManagement, useGetAppsHubApiQuery } from "../../../services/api/api-socios-maz";
import { getURL } from "../../Layouts/default-section/getDefaultElements";
import { Exhub } from "./ex-hub";
import { Category } from '../../../models/loyalty.models';
import { useEffect, useState } from "react";


export const AppsHub = (props: any) => {
    const [apps, setApps] = useState<any[]>([]);
    const [categories, setCategories] = useState<any[]>([]);

    useEffect(() => {
      nueva();
   
    }, []);
    async function nueva(){
        const res = await postExHubAccessManagement();

        let dd:any[]=appsInSection(res);

        setApps(dd);
        setCategories(CategoriesInSection(dd));
    }
    // const dataApps = useGetAppsHubApiQuery('')?.data;
    function appsInSection(section: any): any[] {

        var elements: any[] = [];
        section?.map((result: any, i: number) => {

            elements[i] = {
                author: result?.author || '',
                name: result?.name || '',
                src: result?.icon || '',
                text_info: result?.description||'',
                logo_pais: '',
                text_dis: ':',
                /* text_dis: 'Disponible en:', */
                href: result?.access_url || '',
                tag: result?.group || '',
                width: '20',
            };
        });

        return elements;
    }
    function CategoriesInSection(section: any): any[] {
        let categories:any[]=[];
         section?.map((result: any, i: number) => {
            if(!CategoryExist(result?.tag,categories)){
                   categories.push({
                 name:result?.tag||'',
                 status:false
             });
            }
         });
         
         return categories;
     }
     function CategoryExist(category:string, array:any[]): boolean {
       let exist=false;
        array.filter((elem:any)=>{
            if(elem.name==category){
                exist=true;
            }
        })
         return exist;
     }

    return (
        <>
           {apps&&categories.length>0&& <Exhub imgs={apps} filters={categories}/>}
        </>
    )
}