import React, { useEffect, useState } from 'react';
import { store } from '../../../../../modules/appState/store';
import { apiMainURL } from '../../../../../modules/settings/environment';
import { generateAlert } from '../../../../UI/alerts-Service/alerts-function';
import InfoSection from './InfoSection';
import TotalValue from './TotalValue';
import { ReactComponent as Question } from '../../../../../assets/AboutMeAtABI/STI/Question.svg';

const Shares = () => {
  const dollarUSLocale = Intl.NumberFormat('de-DE');
  const storeApp = store;
  const [bonus, setBonus] = useState({
    bonus: storeApp.getState().AppReducer?.bonus?.bonus,
    currency: storeApp.getState().AppReducer?.bonus?.currency,
  });
  const [values, setValues] = useState({
    additional_shares: '0',
    cash: '0',
    cash_percentage: '0',
    discounted_shares: '0',
    matching_shares: '0',
    shares_percentage: '0',
    total_anual_value: '0',
    voluntary_shares: '0',
  });
  function queryBonus() {
    var myHeaders = new Headers();
    myHeaders.append(
      'Authorization',
      `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
    );
    myHeaders.append('Content-Type', 'application/json');

    var raw = JSON.stringify({
      type: 3,
      gross_bonus: dollarUSLocale.format(bonus?.bonus),
    });

    var requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(`${apiMainURL}/api/get-bonus-election`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let b = JSON.parse(result);

        setBonus({
          bonus: b?.total_anual_value,
          currency: bonus?.currency,
        });
        setValues({
          ...b,
        });
      })
      .catch((error) => {
        console.log('error', error);
        generateAlert(
          'error',
          'Error GetBonus shares.',
          '/api/get-bonus-election',
        );
      });
  }
  // storeApp.subscribe(() => {
  //   // setUser2({ image: storeApp.getState().AppReducer });

  //   setBonus({
  //     bonus: storeApp.getState().AppReducer?.bonus?.bonus,
  //     currency: storeApp.getState().AppReducer?.bonus?.currency
  //   });
  //   queryBonus();

  // });
  useEffect(() => {
    if (bonus?.bonus) {
      queryBonus();
    }
  }, []);
  return (
    <div className='my-2 py-2'>
      <InfoSection
        label='Voluntary Shares (Gross)'
        value={`${values?.voluntary_shares || 0} ${bonus.currency}`}
        descriptionMessage='Voluntary Shares (Gross) Mix description pendient'
        descriptionTitle='Voluntary Shares (Gross)'
      />
      <InfoSection
        label='Discounted Shares (RSU-Gross)'
        value={`${values?.discounted_shares || 0} ${bonus.currency}`}
        descriptionMessage='Discounted Shares (RSU-Gross) Mix description pendient'
        descriptionTitle='Discounted Shares (RSU-Gross)'
      />
      <InfoSection
        label='Matching Shares (RSU-Gross)'
        value={`${values?.matching_shares || 0} ${bonus.currency}`}
        descriptionMessage='Matching Shares (RSU-Gross) Mix description pendient'
        descriptionTitle='Matching Shares (RSU-Gross)'
        hasInferiorDivision={false}
      />
      <TotalValue
        label='Total Annual Value (Gross)'
        amount={values?.total_anual_value || 0}
        currency={bonus?.currency || ''}
        descriptionMessage='Total Annual Value (Gross)Shares description pendient.'
        descriptionTitle='Total Annual Value (Gross) Shares'
      />
      <p className='warning-text fw-bold'>
        *The other 1.5 will be implemented in the LTI bonus
      </p>
      <div className='d-flex mb-2'>
        <Question />
        <p className='fw-bolder my-auto'>
          Remember that do receive all the shares must not be withdrawn before
          three years
        </p>
      </div>
    </div>
  );
};

export default Shares;
