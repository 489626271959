import React, { useEffect, useState } from 'react';
import { store } from '../../../../modules/appState/store';
import {
  useGetHomeDefaultContentQuery,
  useGetPartnerUserQuery,
} from '../../../../services/api/api-socios-maz';
import {
  GetBodyInnerHTML,
  getDefaultContent,
} from '../../../Layouts/default-section/getDefaultElements';
import CarouselView from '../../../UI/Carousel/carousel';
import LoadSpinner from '../../../UI/LoadSpinner/LoadSpinner';
import { NavElementsLPC } from '../../../UI/Buttons-LPC';
import { Categorycards } from '../../../UI/Category-cards';
import BreadCrumb from '../../../UI/BreadCrumb';
import CarouselHeader from './CarouselHeader';

import { ReactComponent as Currency } from '../../../../assets/LPC/Destiny/Currency.svg';
import { ReactComponent as Population } from '../../../../assets/LPC/Destiny/Population.svg';
import { ReactComponent as Weather } from '../../../../assets/LPC/Destiny/Wheater.svg';

import './Destination.scss';
import GeneralInfo from './GeneralInfo';
import GeneralRecommendations from './GeneralRecommendations';
import HotelDownload from './HotelDownload';
import CarouselImg from './CarouselImg';
import Restaurants from './Restaurants';
import { getPartnerData } from '../GetReady/lpc';

export const LPC_Component_Destiny = () => {
  const storeApp = store;
  const [user2, setUser2] = useState({
    image: storeApp.getState().AppReducer,
  });

  const crumbs = [
    { path: '/home', label: 'MAZ Partners' },
    { path: '/lpc', label: 'LPC' },
    { label: 'City & Hotel' },
  ];

  const dataapi = useGetHomeDefaultContentQuery('lpc/destiny').data;

  const elem: any = getDefaultContent(dataapi);

  storeApp.subscribe(() => {
    setUser2({ image: storeApp.getState().AppReducer });
  });
  const userCompleted = getPartnerData(
    useGetPartnerUserQuery(user2?.image?.userData?.drupal_id || '')?.data,
  );

  const getSVG = (label: string) => {
    if (label === 'Currency') return Currency;
    else if (label === 'Population') return Population;
    else return Weather;
  };

  const headerProps: any = elem?.content[0]?.content?.map((props: any) => ({
    img: props?.src || '',
    title: props?.title || '',
    desc: props?.description || '',
    label: props?.label,
    src: userCompleted?.ticketPDF,
  })) || [{ img: '', title: '', desc: '', span: '' }];
  const generalInfoProps = elem?.content[1]?.content?.map((props: any) => ({
    SVG: getSVG(props?.title || ''),
    label: props?.title || '',
    information: props?.label || '',
  })) || [{ SVG: Currency, label: '', information: '' }];
  const recommendDesc = {
    desc: elem?.content[2]?.content?.primaryText || '',
    secondDesc: elem?.content[2]?.content?.secondaryText,
  };
  const hotelProps = {
    title: elem?.content[3]?.content?.title || '',
    desc: elem?.content[3]?.content?.primaryText || '',
    label: 'Your Reservation',
    breakfastDesc: elem?.content[3]?.content?.secondaryText || '',
    src: userCompleted?.hotelPDF,
  };
  const carouselContent = elem?.content[4]?.content?.map(
    ({ src = { src: '', type: '' } }) => ({ src }),
  );
  const restaurants = elem?.content[5]?.content?.map(
    ({
      src = '',
      title = '',
      description = '',
      hrefLabel = '',
      link = '',
    }) => ({
      img: src,
      title,
      desc: description,
      reserveLabel: hrefLabel,
      reservation: link,
    }),
  ) || [
    {
      img: '',
      title: '',
      desc: '',
      reservation: '',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='main-content destination-content lpc-bg pb-5 '>
      {!elem && <LoadSpinner />}
      <div className='container-fluid breadcrumb-destination'>
        <BreadCrumb {...{ crumbs }} />
      </div>
      <div className='container-fluid destination-title'>
        <h1 className='text-center'>City & Hotel</h1>
        <nav className='destination-nav-buttons'>
          <a href='#des-hotel'>Hotel</a>
          <a href='#des-restaurants'>Restaurants</a>
        </nav>
      </div>
      <CarouselHeader items={headerProps} />
      <GeneralInfo items={generalInfoProps} />
      <GeneralRecommendations {...recommendDesc} />
      <div id='des-hotel' />
      <HotelDownload {...hotelProps} />
      <CarouselImg content={carouselContent} />
      <div id='des-restaurants' />
      <Restaurants {...{ restaurants }} />
    </div>
  );
};

export const LpcDestiny = (props: any) => {
  const eliminaDuplicados = (arr: string[]) => {
    return arr.filter((valor: string, indice: number) => {
      return arr.indexOf(valor) === indice;
    });
  };
  const cat: string[] = eliminaDuplicados(props?.categories);

  const planes = props?.data.filter((p: any) => {
    if (p?.type == 'plan') return p;
  });

  return (
    <div>
      <CarouselView content={props?.data[0]?.content}></CarouselView>
      <NavElementsLPC actived={1}></NavElementsLPC>
      <div className='pt-5'>
        <h3 className='section-title'>{props?.data[1]?.content?.value}</h3>
        <div className='text-content-lpc'>
          <GetBodyInnerHTML
            content={props?.data[2]?.content?.value}></GetBodyInnerHTML>
        </div>
      </div>

      <CarouselView content={props?.data[3]?.content}></CarouselView>
      <h3 className='section-title'>{props?.data[4]?.content?.value}</h3>
      {/* <Sections content={props?.data[5]?.content}></Sections> */}
      <Categorycards data={planes} categories={cat}></Categorycards>
    </div>
  );
};
