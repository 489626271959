import React from 'react';
import { useNavigate } from 'react-router';
import { postGalleryEventLike } from '../../../../services/api/api-socios-maz';

import ContentCard from '../../../Layouts/GallerysSections/Card';

type GalleryCardProps = {
  description: string;
  img: string;
  title: string;
  topic: string;
  id: number;
  likeValue: boolean;
};

const GalleryCard = ({
  description,
  img,
  title,
  topic,
  id,
  likeValue,
}: GalleryCardProps) => {
  const navigate = useNavigate();

  const onLikeClick = async (v: boolean) => {
    await postGalleryEventLike(`${id}`, !v);
  };
  const onCardClick = () => {
    navigate(`/maz-resources/${id}`);
  };

  return (
    <ContentCard
      {...{ img, title, likeValue }}
      category={topic}
      content={description}
      hasHtmlDescription
      onImageClick={onCardClick}
      onLikeEvent={onLikeClick}
      onTitleClick={onCardClick}
    />
  );
};

export default GalleryCard;
