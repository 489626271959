import React from 'react';
import { Link } from 'react-router-dom';

type SpecificDataProps = {
  icon: string;
  label: string;
};
export type ContentEventProps = {
  date: string;
  name: string;
  description: string;
  specificData: {
    modality: SpecificDataProps;
    location: SpecificDataProps;
    category: SpecificDataProps;
  };
  id: number;
};

const ContentEvent = ({
  date,
  name,
  description,
  specificData,
  id,
}: ContentEventProps) => {
  return (
    <div className='content-event'>
      <div className='row align-items-center mt-4'>
        <div className='col-md-3 fs-4 fw-bold'>{date}</div>
        <div className='col-md-6'>
          <div className='text-gold'>
            <h3>{name}</h3>
          </div>
          <div className='text-content'>{description}</div>
          <div className='main-icons d-flex gap-3'>
            {Object.entries(specificData).map(([k, { icon, label }]) => (
              <div key={k}>
                <span>
                  <img
                    className='me-1'
                    src={icon}
                    alt={k}
                    width='40'
                    height='40'
                  />
                </span>{' '}
                <span>{label}</span>
              </div>
            ))}
          </div>
        </div>
        <div className='col-md-3 text-center'>
          <Link
            to={`/calendar/detail/${id}`}
            className='btn btn-outline-primary more-details-button'>
            + More Details
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ContentEvent;
