import React from 'react';
import { useGetHomeDefaultContentQuery } from '../../../services/api/api-socios-maz';
import {
  GetBodyInnerHTML,
  getDefaultContent,
} from '../../Layouts/default-section/getDefaultElements';
import BreadCrumb from '../../UI/BreadCrumb';
import Sections from '../../UI/Sections/sections';

import './AboutMeAtABI.scss';

export const AboutMeAtABI = () => {
  const genericData: any =
    useGetHomeDefaultContentQuery('about-me-at-abi').data;
  const defaultContent = getDefaultContent(genericData);
  const title = defaultContent?.content[0].content.value;
  const description = defaultContent?.content[1].content.value;
  const links = defaultContent?.content[2].content;
  const crumbs = [{ path: '/home', label: 'MAZ Partners' }, { label: title }];

  return (
    <div className='main-content about-me-at-abi'>
      <div className='container-fluid'>
        <BreadCrumb {...{ crumbs }} />
        <h1 className='text-center mb-4'>{title}</h1>
        <div className='mb-4'>
          <GetBodyInnerHTML content={description} />
        </div>
        {/* <div className='remuneration-and-stocks d-flex gap-4 w-100 p-4 rounded-3 position-relative mb-4'>
          <div>
            <p className='rem-title fw-bolder mb-0'>
              Fixed remuneration
              <sup className='rem-et-st-opts rounded-circle px-1'>?</sup>
            </p>
            <p>$10.000.000</p>
          </div>
          <div>
            <p className='rem-title fw-bolder mb-0'>
              Variable Remuneration
              <sup className='rem-et-st-opts rounded-circle px-1'>?</sup>
            </p>
            <p>$5.000.000</p>
          </div>
          <div>
            <p className='rem-title fw-bolder mb-0'>
              Your stocks
              <sup className='rem-et-st-opts rounded-circle px-1'>?</sup>
            </p>
            <p className=''>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </div>
          <button className='position-absolute top-100 start-50 translate-middle rounded-pill py-1 px-3'>
            Access your portfolio
          </button>
        </div> */}
        <h2 className='text-center my-2'>What do you want to do?</h2>
        <Sections content={links} />
      </div>
    </div>
  );
};
