import React, { useCallback, useEffect, useState } from 'react';
import ButtonItem from './ButtonItem';

import './Filters.scss';
import { v4 } from 'uuid';
import HeartLike from '../../../../UI/HeartLike';

type FiltersProps = {
  items: { label: string; isActive: boolean }[];
};

const Filters = ({ items }: FiltersProps) => {
  const [activeFilters, setActiveFilters] = useState(['All']);
  const [setFavorites, setSetFavorites] = useState(false);

  const favoritesClass = `d-flex justify-content-center ms-auto mb-auto gap-2 ${
    setFavorites ? 'active' : ''
  }`;
  const uuid = v4();

  const onClick = useCallback((v: string) => {
    if (v === 'All') {
      setActiveFilters(['All']);
    } else {
      setActiveFilters((prevFilters) => {
        const actualFilters = [...prevFilters];

        if (actualFilters.includes('All')) actualFilters.pop();
        if (actualFilters.includes(v)) {
          actualFilters.splice(actualFilters.indexOf(v), 1);

          return [...actualFilters];
        }

        return [...actualFilters, v];
      });
    }
  }, []);
  const onFavoriteClick = () => {
    setSetFavorites((prevFav) => !prevFav);
  };

  return (
    <div className='row my-4'>
      <div className='col-lg-9'>
        <nav>
          <ul className='d-flex flex-column flex-lg-row filters-list'>
            {items.map(({ isActive, label }) => (
              <ButtonItem
                key={uuid + '-' + label}
                {...{ label, isActive }}
                onButtonClick={onClick}
                isActive={activeFilters.includes(label)}
              />
            ))}
          </ul>
        </nav>
      </div>
      <div className='col-lg-3 d-flex button-item'>
        <button className={favoritesClass} onClick={onFavoriteClick}>
          <span>
            <HeartLike isLiked={setFavorites} />
          </span>
          My Favorites
        </button>
      </div>
    </div>
  );
};

export default Filters;
