export type FilterProps = {
  bu?: string;
  month?: string;
  cat?: string;
};

export type CalendarState = {
  title: string;
  description: string;
  comments: { name: string; description: string }[];
  actualFilter: FilterProps;
};

export enum CalendarCases {
  SET_INITIAL_INFO = 'SET_INITIAL_INFO',
  LOAD_FILTERS = 'LOAD_FILTERS',
}

export type CalendarAction = {
  type: CalendarCases;
  payload?: any;
};

export const calendarInitialState = {
  title: 'Calendar',
  description: '',
  comments: [{ name: '', description: '' }],
  actualFilter: {},
};

export const calendarReducer = (
  state: CalendarState,
  action: CalendarAction,
) => {
  const { payload } = action;

  switch (action.type) {
    case 'SET_INITIAL_INFO': {
      const {
        title,
        description,
        comments,
      }: {
        title: string;
        description: string;
        comments: { name: string; description: string }[];
      } = action.payload;

      return { ...state, title, description, comments };
    }

    case 'LOAD_FILTERS': {
      const { keyFilter, value }: { keyFilter: string; value: string } =
        payload;
      const { actualFilter } = state;

      return {
        ...state,
        actualFilter: { ...actualFilter, [keyFilter]: value },
      };
    }

    default:
      return { ...state };
  }
};
