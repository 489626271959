import React from 'react';

import Card from './Card';

import './Cards.scss';
import { Link } from 'react-router-dom';

const Cards = () => {
  return (
    <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3'>
      <Link className='text-decoration-none' to='./1'>
        <Card />
      </Link>
      <Link className='text-decoration-none' to='./1'>
        <Card />
      </Link>
      <Link className='text-decoration-none' to='./1'>
        <Card />
      </Link>
    </div>
  );
};

export default Cards;
